import CTable from './table'
import Td from './td'
import Th from './th'
import Tr from './tr'

const Table = CTable

Table.Td = Td
Table.Th = Th
Table.Tr = Tr

export default Table
