import { TableCell, TableRow, TableHead as Head } from 'presentation/components/atoms'
import { OrderIcon } from 'presentation/components'
import paginatorMethods from '@/utils/paginatorMethods'

import { Wrapper } from '../../../../styles'

export const TableHead = ({ setPaginator, paginator }) => {
  return (
    <Head>
      <TableRow>
        <TableCell>Data Pedido</TableCell>
        <TableCell>
          <Wrapper>
            Nr. Pedido
            <OrderIcon
              clickUp={() => setPaginator(paginatorMethods.setOrder(paginator, 'code'))}
              clickDown={() => setPaginator(paginatorMethods.setOrder(paginator, 'code', false))}
            />
          </Wrapper>
        </TableCell>
        <TableCell>Qtde. Cartões </TableCell>
        <TableCell>Benefício(s)</TableCell>
        <TableCell>Status do pedido</TableCell>
        <TableCell style={{ textAlign: 'center' }}>Ações</TableCell>
      </TableRow>
    </Head>
  )
}
