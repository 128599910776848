import { useRef } from 'react'
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useNotifications } from 'main/hooks'
import { errorHandler } from 'main/utils/functions/errorHandler'

export const QueryProvider = ({ children }: { children: any }) => {
  const notifications = useNotifications()

  const onError = (error: any, query: any) => {
    if (!query?.meta?.silent) notifications.push(errorHandler({ error: error?.response?.data?.errors }))
  }

  const queryClient = useRef<QueryClient>(
    new QueryClient({
      queryCache: new QueryCache({ onError }),
      mutationCache: new MutationCache({ onError }),
    }),
  )

  return <QueryClientProvider client={queryClient.current}>{children}</QueryClientProvider>
}
