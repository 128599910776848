interface IInfos {
  numberOfColaborators: number
  dueDate: null
  orderDate: string
  code: number
  confirmation: {
    paymentFeeValue: number
    transferFeeValue: number
    useOfWallet: number
    useOfCheckingAccount: boolean
    issFeeValue: number
    subTotalValue: number
    totalAmountPayable: number
    cardsCount: number
    interestRates: [
      {
        resaleId: string
        companyId: string
        days: number
        rate: number
        nextDueDate: string
        rateValue: number
      },
    ]
  }
  ticketingBalance: number
  oldTicketingBalance: number
  walletBalance: number
}

type IInfosData = {
  infos: IInfos
}

export interface IOrder {
  administrateFee: number
  transferFeeValue: number
  ticketingBalanceValue: number
  oldTicketingBalance: number
  issFeeValue: number
  accountBalance: number
  totalBeforeBalanceUsage: number
  orderDate: string
  numberOfColaborators: number
  orderValue: number
}

export const infosData = ({ infos }: IInfosData) => {
  return {
    ...infos,
    administrateFee: infos.confirmation.paymentFeeValue,
    transferFeeValue: infos.confirmation.transferFeeValue,
    ticketingBalanceValue: infos.ticketingBalance,
    oldTicketingBalance: infos.oldTicketingBalance,
    issFeeValue: infos.confirmation.issFeeValue,
    accountBalance: infos.walletBalance,
    totalBeforeBalanceUsage: infos.confirmation.totalAmountPayable,
    confirmation: infos.confirmation,
    code: infos.code,
    numberOfColaborators: infos?.numberOfColaborators || infos?.confirmation?.cardsCount,
    orderDate: infos.orderDate,
    orderValue: infos.confirmation.subTotalValue,
    interestRates: infos.confirmation.interestRates,
  }
}
