import { all, takeLatest, put, call } from 'redux-saga/effects'
import { trackHistorySuccess, trackHistoryFailure } from './actions'
import api from '@/infra/api'

function* requestHistory({ payload }) {
  try {
    const response = yield call(api.get, `rh-bff/cards/history${payload}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const history = response.data
    yield put(trackHistorySuccess(history))
  } catch (error) {
    yield put(trackHistoryFailure())
  }
}

export default all([takeLatest('@trackHistory/TRACKHISTORY_REQUEST', requestHistory)])
