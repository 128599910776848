import { createContext, useState } from 'react'
import { Divider, Grid, Paper } from 'presentation/components/atoms'
import { Content } from 'presentation/components'
import { Payment as PaymentX, CartSkeleton, Details, NoItems } from 'presentation/components/orders'
import { PageHeader } from 'presentation/components/molecules'
import { useLocation } from 'react-router-dom'
import { getResaleSiteFromUrl } from '@/utils/functionUtils'
import { UseFormReturn, useForm } from 'react-hook-form'
import { EPaymentMethod } from './components/payment/components/method/method'

interface IPaymentFormValues {
  paymentMethod: EPaymentMethod
  dayForCredit: string
  walletUsage: string
  balanceProjection: boolean
}
interface IPaymentConfirmationContext {
  form: UseFormReturn<any>
  walletBalance: number
}

export const PaymentConfirmationContext = createContext<IPaymentConfirmationContext>({} as IPaymentConfirmationContext)

export const PaymentConfirmation = ({
  data,
  details,
  isLoading,
  cancelOrder,
  confirmOrder,
  removeAsSoonAsPossibleDays,
}) => {
  const location = useLocation()
  const [method, setMethod] = useState<'PIX' | 'SLIP' | 'TERM_BILL'>('SLIP')
  const [rate, setRate] = useState({
    interestRateDays: 0,
    interestRate: 0,
    nextDueDate: '',
    rateValue: 0,
    totalValueWithInterestRate: 0,
  })

  const orderId = location.state?.orderId
  const isDealer = !location?.state?.isBroker
  const form = useForm<IPaymentFormValues>({
    defaultValues: {
      paymentMethod: EPaymentMethod.Slip,
      dayForCredit: '',
      walletUsage: '230',
      balanceProjection: false,
    },
  })
  const [balanceToBeUsed, setBalanceToBeUsed] = useState(0)
  const [useBalanceProjection, setUseBalanceProjection] = useState(getResaleSiteFromUrl() === 'motiva' ? true : false)

  const isEmpty = location.state?.quantity <= 0
  return (
    <PaymentConfirmationContext.Provider
      value={{
        form,
        walletBalance: 22422.93,
      }}
    >
      <Content>
        <Paper sx={{ padding: '32px' }}>
          <Grid container justifyContent='space-between' spacing={2}>
            <Grid item xs={12}>
              <PageHeader pageTitle='Comprar agora' noDivider padding='0' />
            </Grid>
          </Grid>
          <Divider sx={{ margin: '32px 0' }} />
          {isLoading ? (
            <CartSkeleton />
          ) : (
            <Grid container columnSpacing='32px' rowSpacing='32px'>
              <Grid item xs={12} md={7}>
                {isEmpty ? (
                  <NoItems />
                ) : (
                  <Details
                    total={data.totalBeforeBalanceUsage}
                    title={location.state?.title}
                    items={details}
                    operatorCardName={location.state?.operatorCardName}
                    cancelOrder={() => cancelOrder({ orderId })}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={5}>
                <PaymentX
                  isDealer={isDealer}
                  isEmpty={isEmpty}
                  order={data}
                  balanceToBeUsed={balanceToBeUsed}
                  setBalanceToBeUsed={setBalanceToBeUsed}
                  setUseBalanceProjection={setUseBalanceProjection}
                  shouldUseProjection={useBalanceProjection}
                  isNewApi
                  removeAsSoonAsPossibleDays={removeAsSoonAsPossibleDays}
                  confirmOrder={() =>
                    confirmOrder({
                      orderId,
                      method,
                      useBalanceProjection,
                      balanceUsage: balanceToBeUsed,
                      data,
                      rate,
                    })
                  }
                  setMethod={setMethod}
                  method={method}
                  setRate={setRate}
                  rate={rate}
                />
              </Grid>
            </Grid>
          )}
        </Paper>
      </Content>
    </PaymentConfirmationContext.Provider>
  )
}
