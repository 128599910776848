import styled from 'styled-components'

export const OptionsContainer = styled.div`
  padding: 8px 0;
  cursor: pointer;
  transition: all 0.26s;
  :hover {
    ${({ disabled }) => (!disabled ? '  color: var(--color-primary-01);' : '')}
  }
  color: ${({ disabled }) => (disabled ? 'var(--color-secundary-06)' : 'initial')};
`
