import styled from 'styled-components'
import styledMUI from '@emotion/styled'
import { Row as GridRow } from 'react-grid-system'

import { DropdownButton } from 'presentation/components'

export const Row = styled(GridRow)`
  & + div {
    margin-top: 40px;
  }
`

export const Wrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-transform: capitalize;
  white-space: 'nowrap';
`

export const StatusDot = styled.div`
  height: 12px;
  width: 12px;
  border-radius: 50%;
  margin-right: 8px;

  ${({ status }) => (status === 'ATIVO' ? 'background-color: var(--color-status-01)' : null)}
  ${({ status }) => (status === 'BLOQUEADO' ? 'background-color: var(--color-status-02)' : null)}
    ${({ status }) => (status === 'INATIVO' ? 'background-color: var(--color-status-06)' : null)}
`

export const Body = styled.div`
  padding: 32px;
`

export const Subtitle = styled.p`
  color: var(--color-primary-04);
  font-weight: var(--font-weight-bold);
  font-size: 1.25rem;
  letter-spacing: 0.03625rem;
`

export const Description = styled.p`
  font-size: 0.9rem;
  padding: 15px 0;
`

export const AlignRight = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  gap: 16px;
`

export const ColorDest = styled.span`
  color: var(--color-primary-01);
  font-weight: var(--font-weight-semi-bold);
`

export const TableBody = styled.tbody`
  tr {
    cursor: pointer;
  }
`

export const CentralizeText = styled.p`
  text-align: center;
`

export const Popover = styled.div`
cursor: pointer;

.popover {
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
  z-index: 999;
  border-radius: 5px;
  h4 {
    font-size: 16px;
    padding-block-end: 4px;
  }
  ul {
    padding: 0 20px;
    li {
      list-style-type: disc;
    }
  }
  `

export const CustomDropdownButton = styledMUI(DropdownButton)(({ theme }) => ({
  width: '200px !important',
  fontWeight: 700,
  fontSize: 18,
  color: '#fff !important',
  backgroundColor: theme.palette.primary.main + '!important',
  zIndex: '100 !important',

  svg: {
    stroke: 'none',
    fill: theme.palette.brand.secondary.light,
  },

  '@media (max-width: 600px)': {
    width: '100% !important',
  },
}))
