import api from '@/infra/api'
import apiCallHandler from './apiCallHandler'
import axios from 'axios'

export const postCreateCompany = (payload, token) => {
  return apiCallHandler(() =>
    axios.post(`${process.env.REACT_APP_API_HOST}/rh-bff/register/company`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  )
}

export const putUpdateCompany = (payload, token) => {
  return apiCallHandler(() =>
    axios.put(`${process.env.REACT_APP_API_HOST}/companies-service/${payload.id}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  )
}

export const getCompanyInfo = (site, cnpj, token) => {
  return apiCallHandler(() =>
    axios.get(
      `${process.env.REACT_APP_API_HOST}/rh-bff/Register/verifyIfResaleCompanyExist?site=${site}&cnpj=${cnpj}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    ),
  )
}

export const getActivityBranches = (token) => {
  return apiCallHandler(() =>
    axios.get(`${process.env.REACT_APP_API_HOST}/rh-bff/Register/Branches`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  )
}

export const getAddressesTypesForCompanyRegister = (token) => {
  return apiCallHandler(() =>
    axios.get(`${process.env.REACT_APP_API_HOST}/rh-bff/address/address-types`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  )
}

export const getLaneTypesForCompanyRegister = (token) => {
  return apiCallHandler(() =>
    axios.get(`${process.env.REACT_APP_API_HOST}/rh-bff/address/lane-types`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  )
}

export const getCityCode = (ibge, local, token) => {
  return apiCallHandler(() =>
    axios.get(`${process.env.REACT_APP_API_HOST}/rh-bff/address/cities?ibge=${ibge}&name=${local}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  )
}
