import { SxProps } from '@mui/material'
import { StyledTablePagination } from './styles'

export interface TablePaginationProps {
  count?: number
  rowsPerPage: number
  rowsPerPageOptions?: Array<number>
  displayText?: string
  page: number
  totalPages: number
  onRowsPerPageChange?: (e: any) => void
  counterVariant?: 'results' | 'interval'
  sx?: SxProps
}

export const TablePagination = ({
  count = 0,
  rowsPerPageOptions = [10, 20, 25, 50, 100],
  displayText = 'Exibir',
  counterVariant = 'results',
  page,
  rowsPerPage,
  ...props
}: TablePaginationProps) => {
  let counterText = ''
  if (counterVariant === 'interval') {
    const init = (page - 1) * rowsPerPage + 1
    const end = page * rowsPerPage
    counterText = ` ${init} - ${end > count ? count : end} de ${count || 0}`
  } else counterText = `resultados de ${count || 0}`
  return (
    <StyledTablePagination
      component='div'
      page={page}
      rowsPerPage={rowsPerPage}
      count={count}
      labelRowsPerPage={displayText}
      rowsPerPageOptions={rowsPerPageOptions}
      labelDisplayedRows={() => counterText}
      nextIconButtonProps={{ style: { display: 'none' } }}
      backIconButtonProps={{ style: { display: 'none' } }}
      onPageChange={() => null}
      counterVariant={counterVariant}
      {...props}
    />
  )
}
