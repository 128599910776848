import styled from 'styled-components'

import { Table } from 'presentation/components'
import { Button } from 'presentation/components/atoms'

export const AddToCart = styled(Button)`
  max-width: 100% !important;
  white-space: nowrap;
`

export const StepsWrapper = styled.div`
  max-width: 800px;
  margin: auto;
  margin-bottom: 48px;
`

export const Title = styled.p`
  font-weight: bold;
  font-size: 22px;
  margin-top: 15px;
`

export const Text = styled.p`
  padding-top: 10px;
  margin-bottom: 50px;
`

export const CustomTable = styled(Table)`
  td:last-child,
  th:last-child {
    text-align: right;
  }

  thead {
    font-weight: bold;
  }
`

export const Add = styled.div`
  background-color: var(--main-color);
  width: 15px;
  height: 15px;
  border-radius: 50%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2px;
  margin-right: 5px;
`

export const TextAdd = styled.div`
  color: var(--main-color);
  font-weight: bold;
`

export const ButtonWrapper = styled.div`
  display: flex;
  button {
    margin-left: 16px;
    max-width: 190px;
  }
`
