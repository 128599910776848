import api from '@/infra/api'

export const getCollaborators = async (setLoading, orderId, setData, requestError) => {
  setLoading(true)

  try {
    const response = await api.get(`rh-bff/Recharge/Broker/${orderId}/items`)
    setData(response?.data)
    setLoading(false)
  } catch (e) {
    requestError(e)
    setLoading(false)
  }
}

export const getRegistration = async (id, requestError) => {
  try {
    const response = await api.get(`rh-bff/recharge/broker/employee/${id}`)

    return response?.data
  } catch (e) {
    // requestError(e);
  }
}

export const create = async (id, data, setLoading, requestError, refresh) => {
  setLoading(true)

  try {
    await api.post(`rh-bff/Recharge/Broker/${id}/items`, data)
    setLoading(false)
    refresh()
  } catch (e) {
    setLoading(false)
    requestError(e)
  }
}

export const editOrder = async (orderId, itemId, data, setLoading, requestError, refresh) => {
  setLoading(true)

  try {
    await api.put(`rh-bff/recharge/broker/${orderId}/items/${itemId}`, data)
    setLoading(false)
    refresh()
  } catch (e) {
    setLoading(false)
    requestError(e)
  }
}

export const remove = async (id, brokerId, setLoading, requestError, refresh) => {
  setLoading(true)

  try {
    await api.delete(`rh-bff/recharge/broker/${brokerId}/items/${id}`)
    setLoading(false)
    refresh()
  } catch (e) {
    requestError(e)
    setLoading(false)
  }
}
