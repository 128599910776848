import React from 'react'
import { Body, TextConfirm, SessionButton, Close } from './style'
import { Button, Icon } from 'presentation/components'
import 'react-responsive-modal/styles.css'
import { Modal as ModalBox } from 'react-responsive-modal'

const ErrorModal = ({ children, open, cancel }) => {
  return (
    <ModalBox open={open} onClose={cancel} center showCloseIcon={false} styles={{ borderRadius: '5px' }}>
      <Body>
        <Close onClick={cancel}>
          <Icon name='x' />
        </Close>
        <TextConfirm>{children}</TextConfirm>
        <SessionButton>
          <Button width='200px' onClick={cancel}>
            Ok
          </Button>
        </SessionButton>
      </Body>
    </ModalBox>
  )
}

export default ErrorModal
