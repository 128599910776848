import { Icon } from 'presentation/components/atoms/Icon'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'
import { CarouselContainer, CarouselContent, CarouselWindow } from './styles'

export const Carousel = ({ children, ...props }) => {
  const [position, setPosition] = useState(0)
  const [carouselWindowRef, setCarouselWindowRef] = useState(0)
  const stillHidden = carouselWindowRef.scrollWidth - carouselWindowRef.offsetWidth + position

  const isLeftArrowVisible = position < 0
  const isRightArrowVisible = stillHidden > 0

  const handleLeftClick = () => {
    if (position * -1 < carouselWindowRef.offsetWidth) setPosition(0)
    else setPosition((state) => (state += carouselWindowRef.offsetWidth))
  }

  const handleRigthClick = () => {
    if (stillHidden <= 0) return
    if (stillHidden >= carouselWindowRef.offsetWidth) setPosition((state) => (state -= carouselWindowRef.offsetWidth))
    else setPosition((state) => (state -= stillHidden + 4))
  }

  return (
    <CarouselContainer {...props}>
      {!isLeftArrowVisible ? null : <Icon icon={faChevronLeft} onClick={handleLeftClick} />}
      <CarouselWindow
        ref={setCarouselWindowRef}
        isLeftArrowVisible={isLeftArrowVisible}
        isRightArrowVisible={isRightArrowVisible}
      >
        <CarouselContent position={position}>{children}</CarouselContent>
      </CarouselWindow>
      {!isRightArrowVisible ? null : <Icon icon={faChevronRight} onClick={handleRigthClick} />}
    </CarouselContainer>
  )
}
