import Modal from 'react-responsive-modal'
import { Title, Close } from './styles'
import { Input, ErrorMessage, Button, Spinner } from 'presentation/components'
import { Col, Row } from 'react-grid-system'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { getRegistration, create, editOrder } from '../requests'
import { useState, useEffect } from 'react'

const ModalComponent = ({ open, close, orderId, refresh, requestError, edit, data }) => {
  const [loading, setLoading] = useState(false)
  const [disableName, setDisableName] = useState(false)
  const [rechargeValue, setRechargeValue] = useState(null)
  const [dailyValue, setDailyValue] = useState(null)
  const [days, setDays] = useState(null)

  const schema = yup.object({
    registration: yup.string().required('Campo obrigatório'),
    dailyValue: yup.string().required('Campo obrigatório'),
    quantity: yup.string().required('Campo obrigatório'),
    employeeName: yup.string().required('Campo obrigatório'),
    cardNumber: yup.string().required('Campo obrigatório'),
  })

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  })

  const itemId = data?.id
  const save = (data) => {
    const alter = data.dailyValue.replace(',', '.')
    const parse = parseFloat(alter).toFixed(2)
    data.dailyValue = Number(parse)
    data.quantity = Number(data.quantity)

    if (!edit) {
      create(orderId, data, setLoading, requestError, refresh)
    } else {
      editOrder(orderId, itemId, data, setLoading, requestError, refresh)
    }
  }

  useEffect(() => {
    if (data) {
      setValue('registration', data.registration, { shouldValidate: true })
      setValue('employeeIntegrationCode', data.employeeIntegrationCode, { shouldValidate: true })
      setValue('dailyValue', data.dailyValue, { shouldValidate: true })
      setValue('quantity', data.quantity, { shouldValidate: true })
      setValue('employeeName', data.employeeName, { shouldValidate: true })
      setValue('cardNumber', data.cardNumber, { shouldValidate: true })
      setRechargeValue(data?.rechargeValue, { shouldValidate: true })
    }
    // eslint-disable-next-line
  }, [data])

  const [getEmployeeDataLoading, setGetEmployeeDataLoading] = useState(false)
  const requestRegistration = async (value) => {
    setGetEmployeeDataLoading(true)
    const response = await getRegistration(value, requestError)
    setValue('employeeName', response?.fullName, { shouldValidate: true })
    setValue('cardNumber', response?.cardNumber, { shouldValidate: true })
    setValue('employeeIntegrationCode', response?.employeeIntegrationCode, { shouldValidate: true })
    if (response?.employeeName) {
      setDisableName(true)
    }
    setGetEmployeeDataLoading(false)
  }

  useEffect(() => {
    if (dailyValue && days) {
      const day = Number(days)
      const alter = dailyValue.replace(',', '.')
      const value = Number(alter)
      const calc = day * value
      setRechargeValue(calc)
    }
  }, [dailyValue, days])

  return (
    <Modal
      open={open}
      onClose={close}
      showCloseIcon={false}
      styles={{
        borderRadius: '5px',
        modalContainer: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
        },
        modal: {
          width: '680px',
        },
      }}
    >
      <Title>{edit ? 'Editar colaborador' : 'Adicionar Colaborador'}</Title>
      <form onSubmit={handleSubmit(save)}>
        {loading || getEmployeeDataLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center', padding: '32px' }}>
            <Spinner />
          </div>
        ) : (
          <Row>
            <Col sm={6}>
              <label>Matrícula Colaborador</label>
              <Input
                {...register('registration')}
                onBlur={(ev) => {
                  const value = ev.target.value
                  if (Boolean(value)) requestRegistration(value)
                }}
                disabled={edit ? true : false}
              />
              {errors.registration && <ErrorMessage>{errors?.registration?.message}</ErrorMessage>}
            </Col>
            <Col sm={6}>
              <label>Matrícula Ótimo</label>
              <Input {...register('employeeIntegrationCode')} name='employeeIntegrationCode' />
              {errors.employeeIntegrationCode && (
                <ErrorMessage>{errors?.employeeIntegrationCode?.message}</ErrorMessage>
              )}
            </Col>
            <Col sm={6} style={{ marginTop: '15px' }}>
              <label>Nome Completo</label>
              <Input {...register('employeeName')} name='employeeName' disabled={edit || disableName ? true : false} />
              {errors.employeeName && <ErrorMessage>{errors?.employeeName?.message}</ErrorMessage>}
            </Col>

            <Col sm={6} style={{ marginTop: '15px' }}>
              <label>Número do cartão</label>
              <Input {...register('cardNumber')} />
              {errors.cardNumber && <ErrorMessage>{errors?.cardNumber?.message}</ErrorMessage>}
            </Col>
            <Col sm={6} style={{ marginTop: '15px' }}>
              <label>Valor diário</label>
              <Controller
                control={control}
                name='dailyValue'
                render={({ field: { onChange, value, ref } }) => (
                  <Input.Decimal
                    placeholder='00,00'
                    value={value}
                    onChange={onChange}
                    ref={ref}
                    onBlur={(ev) => setDailyValue(ev.target.value)}
                  />
                )}
              />
              {errors.dailyValue && <ErrorMessage>{errors?.dailyValue?.message}</ErrorMessage>}
            </Col>

            <Col sm={6} style={{ marginTop: '15px' }}>
              <label>Valor da Recarga</label>
              <Input.Decimal placeholder='00,00' disabled={true} value={rechargeValue} />
            </Col>

            <Col sm={3} style={{ marginTop: '15px' }}>
              <label>Qtd de dias</label>
              <Input {...register('quantity')} type='number' onBlur={(ev) => setDays(ev.target.value)} />
              {errors.quantity && <ErrorMessage>{errors?.quantity?.message}</ErrorMessage>}
            </Col>
            <Col
              sm={12}
              style={{
                marginTop: '35px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ width: '100px' }}>
                <Close onClick={() => close()}>Voltar</Close>
              </div>

              <div style={{ width: '200px' }}>
                <Button type='submit'>Salvar</Button>
              </div>
            </Col>
          </Row>
        )}
      </form>
    </Modal>
  )
}

export default ModalComponent
