import { ETipoPedido } from '@/main/enums'
import { DataContainer, IconContainer, OrderNumber, OrderValue, SummaryContainer } from './styles'
import { Typography, formatMoney } from '@stationkim/front-ui'
import { ReactComponent as CardIcon } from 'presentation/assets/icons/card.svg'
import { ReactComponent as RechargeIcon } from 'presentation/assets/icons/recharge.svg'

interface IOrderSummary {
  type: ETipoPedido
  orderNumber: string | number
  value: number
  itemsOnOrder: number
}

export interface OrderSummaryProps {
  order: IOrderSummary
}

const typeStructure = {
  recharge: {
    icon: <RechargeIcon />,
    itemsText: 'Colab. no pedido',
    title: () => 'Recarga',
  },
  card: {
    icon: <CardIcon />,
    itemsText: 'Cartões no pedido',
    title: (type: ETipoPedido) => `${type === ETipoPedido.PrimeiraViaVtDealer ? 1 : 2}ª via`,
  },
}

export const OrderSummary = ({ order }: OrderSummaryProps) => {
  const isRecharge = [ETipoPedido.RecargaVtBroker, ETipoPedido.RecargaVtDealer].includes(order.type)

  const typeInfo = typeStructure[isRecharge ? 'recharge' : 'card']
  return (
    <SummaryContainer>
      <IconContainer>{typeInfo.icon}</IconContainer>
      <DataContainer>
        <OrderNumber>
          {typeInfo.title(order.type)} <span>#{order.orderNumber}</span>
        </OrderNumber>
        <Typography variant='body2'>
          {typeInfo.itemsText}: {order.itemsOnOrder}
        </Typography>
      </DataContainer>
      <OrderValue>
        <small>R$</small> {formatMoney(order.value)}
      </OrderValue>
    </SummaryContainer>
  )
}
