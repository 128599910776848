import { all } from 'redux-saga/effects'

import user from './user/sagas'
import employee from './employee/sagas'
import employees from './employees/sagas'
import notifications from './notification/sagas'
import cards from './cards/sagas'
import extracts from './extracts/sagas'
import department from './department/sagas'
import departments from './departments/sagas'
import globalState from './globalState/sagas'
import empresa from './empresa/sagas'
import recharge from './recharge/sagas'
import recharges from './recharges/sagas'
import report from './report/sagas'
import cardOrder from './orders/cards/one/sagas'
import cardsOrders from './orders/cards/all/sagas'
import trackHistory from './cards/trackHistory/sagas'
import genericAuth from './genericAuth/sagas'
import users from './users/sagas'

export default function* rootSaga() {
  return yield all([
    user,
    cardOrder,
    cardsOrders,
    empresa,
    employee,
    employees,
    genericAuth,
    notifications,
    cards,
    extracts,
    department,
    departments,
    globalState,
    recharge,
    recharges,
    report,
    trackHistory,
    users,
  ])
}
