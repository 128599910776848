import { Icon } from 'presentation/components/atoms/Icon'
import { StyledBox } from './styles'

export const Contact = ({ icon, name, value, type, ...props }) => {
  const contactType = type === 'phone' ? 'tel' : 'mailto'
  return (
    <StyledBox {...props}>
      <Icon icon={icon} />
      <span>{name}</span>
      <a href={`${contactType}:${value}`}>{value}</a>
    </StyledBox>
  )
}
