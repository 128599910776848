import { EStatusCompra, EStatusPedido } from '@/main/enums'
import { ORDER_STATUS } from '@/main/utils/constants'

export const equalizeStatus = (orderStatus: EStatusPedido, purchaseStatus: EStatusCompra) => {
  if (purchaseStatus === EStatusCompra.AguardandoPagamento) return ORDER_STATUS.WAITING_PAYMENT
  if (purchaseStatus === EStatusCompra.ProcessandoFormaPagamento) return 99
  const convertedStatus = {
    [EStatusPedido.NoCarrinho]: ORDER_STATUS.DRAFT,
    [EStatusPedido.Efetivando]: ORDER_STATUS.EFFECTING,
    [EStatusPedido.Efetivado]: ORDER_STATUS.WAITING_PAYMENT,
    [EStatusPedido.AguardandoProcessamento]: ORDER_STATUS.WAITING_FOR_PROCESSING,
    [EStatusPedido.EmProcessamento]: ORDER_STATUS.PROCESSING,
    [EStatusPedido.EmAndamento]: ORDER_STATUS.RUNNING,
    [EStatusPedido.DisponivelRetirada]: ORDER_STATUS.AVAILABLE_PICKUP,
    [EStatusPedido.Entrega]: ORDER_STATUS.DELIVERING,
    [EStatusPedido.Finalizado]: ORDER_STATUS.FINISHED,
    [EStatusPedido.Cancelando]: ORDER_STATUS.CANCELING,
    [EStatusPedido.Cancelado]: ORDER_STATUS.CANCELED,
  }
  return convertedStatus[orderStatus]
}
