import { styled } from '@mui/material'
import { Box } from '@stationkim/front-ui'

export const ProjectionContainer = styled(Box)<{ notAllowProjection: boolean }>(({ theme, notAllowProjection }) => ({
  border: '1px solid ' + theme.palette.primary.main,
  borderRadius: '4px',
  padding: '8px 22px',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  marginTop: '22px',
  '& label': {
    marginBottom: 0 + '!important',
    color: theme.palette.primary.main,
    fontSize: '18px',
    width: '100%',
  },
  '& b': {
    margin: 'auto',
    whiteSpace: 'wrap',
  },
  'input, label': notAllowProjection
    ? {
        cursor: 'not-allowed',
      }
    : {
        cursor: 'pointer',
      },
}))
