import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'
import { DatePickerProps as MUIDatePickerProps, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { ptBR } from '@mui/x-date-pickers/locales'
import { StyledDatePicker } from './styles'
import { IFieldStatus } from '@stationkim/front-ui'

export interface DatePickerProps<TDate> extends MUIDatePickerProps<TDate>, IFieldStatus {
  id?: string
}

export function DatePicker<TDate>({ id, value, ...props }: DatePickerProps<TDate>) {
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}
      adapterLocale='pt-br'
    >
      <StyledDatePicker
        value={dayjs(String(value))}
        format='DD/MM/YYYY'
        dayOfWeekFormatter={(_, date) => {
          const dayLabel = date.format('ddd')
          return dayLabel.charAt(0).toUpperCase() + dayLabel.slice(1, 3)
        }}
        slotProps={{
          textField: {
            inputProps: { id },
            error: false,
          },
        }}
        {...(props as any)}
      />
    </LocalizationProvider>
  )
}
