import React, { createContext } from 'react'
import _ from 'lodash'
import { ButtonProps, CircularProgress, MenuList } from '@mui/material'
import { Button, Menu } from 'presentation/components/atoms'
import Spinner from '../../spinner'

export const DropdownButtonContext = createContext<string>('DropdownButton')

interface DropdownButton extends ButtonProps {
  label?: string
  isLoading?: boolean
}

export const DropdownButton = (params: DropdownButton) => {
  const { children, label, id, isLoading, ...props } = params
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        id={id || 'dropdown_button'}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant='contained'
        {...props}
      >
        {label}
        {isLoading && <Spinner style={{ width: 'fit-content', marginLeft: '8px' }} />}
      </Button>
      <DropdownButtonContext.Provider value={'DropdownButton'}>
        <Menu
          id={id || 'dropdown_button_menu'}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': id || 'dropdown_button_menu',
          }}
          sx={{
            '& .MuiPaper-root': {
              marginTop: '-12px',
              minWidth: anchorEl?.offsetWidth,
            },
          }}
        >
          <MenuList>{children}</MenuList>
        </Menu>
      </DropdownButtonContext.Provider>
    </>
  )
}
