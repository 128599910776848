import { useMutation, useQueryClient } from '@tanstack/react-query'
import api from '@/infra/api'
import { useNavigate } from 'react-router-dom'

export const useAddCartItem = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const addToCart = useMutation({
    mutationFn: ({ orderId }: { orderId: string }) => api.post(`rh-bff/basket/add/order/?orderId=${orderId}`),
    onSuccess: () => {
      queryClient.invalidateQueries(['cart'])
      navigate('/carrinho', {
        state: {
          bypass: true,
        },
      })
    },
  })

  return { addToCart }
}
