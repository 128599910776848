import styled from 'styled-components'

export const ReportContainer = styled.li`
  height: 45px;
  font-size: 1.125rem;
  padding: 0 32px;
  border-bottom: 1px solid var(--color-secundary-05);
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ReportLink = styled.a`
  color: var(--color-secundary-08);
  font-size: 1rem;
  font-weight: var(--font-weight-semi-bold);
  letter-spacing: 0.26px;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`

export const LoadingWrapper = styled.div`
  width: 128px;
  text-align: center;
`
