import { styled } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

export const StyledDatePicker = styled(DatePicker)(() => ({
  '& .css-lkwr4w-MuiInputBase-root-MuiOutlinedInput-root': {
    height: 48,
    borderRadius: 8,
  },
}))

export const Label = styled('div')(() => ({
  fontSize: 16,
  fontWeight: 500,
}))
