import { styled } from '@mui/material'

import { Box, Typography } from 'presentation/components/atoms'

export const ItemsContainer = styled(Box)(({ theme }) => ({
  padding: '16px 16px ',
  position: 'relative',
}))

export const TooltipContent = styled('div')(({ theme }) => ({
  '& p': {
    color: '#616161',

    '& span': {
      fontWeight: 'bold',
      fontStyle: 'italic',
    },
  },
  '& p:last-child': {
    marginTop: '20px',
    fontWeight: 'bold',
    '& small': {
      color: '#1976D2',
    },
  },
}))

export const OrderDetailHeader = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignFlex: 'center',
  justifyContent: 'flex-start',
  gap: '32px',
}))

export const OrderDetail = styled(Typography)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '16px',
}))

export const TotalValue = styled(Typography)(() => ({
  fontWeight: 700,
  marginLeft: 'auto',
  '&:before': {
    content: "'R$ '",
    fontSize: '0.625rem',
    fontWeight: 500,
  },
}))

export const CustomButton = styled(Box)(({ theme }) => ({
  border: 'none',
  background: 'none',
  color: theme.palette.primary.main,
  cursor: 'pointer',
}))
