import { useContext } from 'react'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  Typography,
} from 'presentation/components/atoms'
import { CartContext } from 'presentation/pages/cart/cart'
import { Summary } from 'presentation/components/molecules'
import { TotalValue } from '../../styles'
import { formatMoney } from 'utils/functionUtils'
import { useTheme } from '@mui/material'
import { ItemBasicInfo } from '../../items'

interface ItemRemovalDialogProps {
  orderToRemove: ItemBasicInfo | null
  setOrderToRemove: (value: ItemBasicInfo | null) => any
  title: string
  OrderIcon: any
}

export const ItemRemovalDialog = ({ orderToRemove, setOrderToRemove, title, OrderIcon }: ItemRemovalDialogProps) => {
  const { removeItem } = useContext(CartContext)
  const theme = useTheme()
  let itemText = ''
  if (orderToRemove) itemText = orderToRemove.totalItens > 1 ? 'itens' : 'item'
  return (
    <Dialog open={orderToRemove} disableEscapeKeyDown maxWidth='lg'>
      <Icon icon={faTriangleExclamation} sx={{ color: theme.palette.error.main, height: '48px' }} />
      <DialogTitle sx={{ color: theme.palette.grey[700], justifyContent: 'center', margin: '16px 0 32px' }}>
        Remover item do carrinho
      </DialogTitle>
      <DialogContent>
        <Box sx={{ border: `1px solid ${theme.palette.grey[100]}`, padding: '16px', borderRadius: '4px' }}>
          <Summary
            title={
              <Box sx={{ display: 'flex', justifyContent: 'spaceBetween', gap: '32px' }}>
                <Typography variant='h4'>
                  {title} - {orderToRemove?.operatorCardName}
                </Typography>
                <TotalValue variant='h4'>{formatMoney(orderToRemove?.totalValue)}</TotalValue>
              </Box>
            }
            subtitle={`${orderToRemove?.totalItens} ${itemText}`}
            icon={<OrderIcon />}
          />
        </Box>
        <Typography sx={{ margin: '32px 0', textAlign: 'center' }}>
          Remover este item do carrinho é uma ação irreversível,
          <br />e todo o progresso será perdido.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button variant='outlined' sx={{ filter: 'grayscale(100%)' }} onClick={() => setOrderToRemove(null)}>
          Cancelar
        </Button>
        <Button
          variant='contained'
          color='error'
          onClick={() => {
            removeItem.mutate({ orderId: orderToRemove?.orderId })
            setOrderToRemove(null)
          }}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
