import { IconButton, styled } from '@mui/material'

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  height: 24,
  width: 24,
  borderRadius: 8,
  background: theme.palette.grey[300],
  '& svg': {
    height: 14,
    width: 14,
    color: theme.palette.primary.main,
  },
}))
