import axios from 'axios'

const reportApi = axios.create({
  baseURL: process.env.REACT_APP_REPORT_API_HOST,
  headers: {
    common: {
      'time-zone': 'America/Sao_Paulo',
    },
  },
})

export default reportApi
