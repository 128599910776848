import React, { useEffect, useRef, useState } from 'react'
import { Modal } from 'react-responsive-modal'
import { Col, Row } from 'react-grid-system'
import { Button, ErrorMessage, ErrorWrapper, Input, Spinner } from 'presentation/components'
import { useDispatch } from 'react-redux'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Body, TitleModal, Flex, Close, Subtitle, Options, Label } from './styles'
import { reduceBigTextLines } from '@/utils/functionUtils'
import { Controller, useForm } from 'react-hook-form'
import { pushNotification } from '@/store/modules/notification/actions'
import successHandler from '@/utils/successHandler'
import useService from 'main/hooks/useService'
import { getDepartmentCreationData, postCreateDepartment } from '@/services/departmentsServices'

const NewDepartamento = ({ close, open, refresh }) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(
      yup.object().shape({
        abortEarly: false,
        departmentName: yup
          .string()
          .max(100)
          .required('O nome do departamento é obrigatório')
          .test(
            'only-letters-digits',
            'Apenas letras e números',
            (value) => !value.match(/[^a-z|0-9| |ªºáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ']/gi),
          ),
      }),
    ),
  })

  const [isNameAlreadyInUse, setIsNameAlreadyInUse] = useState(false)
  const [employeesToAdd, setEmployeesToAdd] = useState([])

  const employees = useRef([])
  const namesInUse = useRef([])
  const dispatch = useDispatch()

  const [creationDataState, creationDataRequest] = useService(getDepartmentCreationData, {
    onCompleted: (response) => {
      employees.current = response.data.employees
      namesInUse.current = response.data.notAvailableNames
    },
  })

  const [createDepartmentState, createDepartmentRequest] = useService(postCreateDepartment, {
    onCompleted: () => {
      dispatch(pushNotification(successHandler('Departamento criado')))
      refresh()
      close()
    },
  })

  // eslint-disable-next-line
  useEffect(() => {
    creationDataRequest()
  }, [])

  const handleCheckBoxClick = (id) => {
    const isIn = employeesToAdd.includes(id)
    if (isIn) {
      const index = employeesToAdd.indexOf(id)
      let newEmployeesToAdd = [...employeesToAdd]
      newEmployeesToAdd.splice(index, 1)
      setEmployeesToAdd(newEmployeesToAdd)
    } else {
      setEmployeesToAdd([...employeesToAdd, id])
    }
  }

  const checkNameAvailability = (newName) => {
    const isNameInUse = namesInUse.current.some((inUseName) => inUseName === newName)
    setIsNameAlreadyInUse(isNameInUse)
  }

  const save = (data) => {
    if (isNameAlreadyInUse) return
    const payload = {
      name: data.departmentName.trim(),
      employees: employeesToAdd,
    }
    createDepartmentRequest(payload)
    setEmployeesToAdd([])
  }

  return (
    <>
      <Modal open={open} onClose={close} center showCloseIcon={false} styles={{ borderRadius: '5px' }}>
        <Body>
          <Flex>
            <TitleModal>Adicionar departamento</TitleModal>
            <Close onClick={close}>x</Close>
          </Flex>
          <Row style={{ marginTop: '20px' }}>
            <Col sm={8} style={{ marginTop: '15px' }}>
              <form onSubmit={handleSubmit(save)} id='newDepartment'>
                <ErrorWrapper error={errors.departmentName || isNameAlreadyInUse}>
                  <Label>Nome do departamento</Label>
                  <Controller
                    control={control}
                    name='departmentName'
                    required
                    render={({ field: { onChange, value, ref } }) => (
                      <Input
                        placeholder='Nome do departamento'
                        value={value}
                        {...register('departmentName')}
                        disabled={createDepartmentState.loading}
                        onChange={(e) => {
                          checkNameAvailability(e.target.value)
                          onChange(e)
                        }}
                      />
                    )}
                  />
                  {isNameAlreadyInUse ? (
                    <ErrorMessage>Nome já utilizado</ErrorMessage>
                  ) : (
                    <ErrorMessage>{errors.departmentName && errors.departmentName.message}</ErrorMessage>
                  )}
                </ErrorWrapper>
              </form>
            </Col>
          </Row>
          <Subtitle style={{ marginTop: '30px', marginBottom: '15px' }}>
            {employees.current?.length ? 'Associar colaboradores ao departamento' : 'Sem colaboradores disponíveis'}
          </Subtitle>
          <div style={{ height: '300px', overflow: 'auto' }}>
            {creationDataState.loading || createDepartmentState.loading ? (
              <Spinner.Box>
                <Spinner />
              </Spinner.Box>
            ) : employees.current.length ? (
              employees.current.map((employee, i) => (
                <Options key={i}>
                  <Input.CheckBox onClick={() => handleCheckBoxClick(employee.id)} />
                  <div style={{ marginLeft: '10px' }} />
                  {reduceBigTextLines(employee.name, 45)}
                </Options>
              ))
            ) : null}
          </div>
          {!createDepartmentState.loading && (
            <div style={{ width: '120px', margin: '15px auto 0 auto ' }}>
              <Button variant='action' form='newDepartment'>
                Confirmar
              </Button>
            </div>
          )}
        </Body>
      </Modal>
    </>
  )
}

export default NewDepartamento
