import { forwardRef } from 'react'
import { IMaskInput } from 'react-imask'
import { TextField, TextFieldProps } from '../textField/textField'
import { IFieldStatus } from 'main/interfaces'

interface MaskedTextFieldProps extends IFieldStatus, TextFieldProps {
  id?: string
  mask: any
}

interface IMaskComponentProps {
  mask: any
  unmask?: boolean
  onChange: (value: any) => void
}

const IMaskComponent = forwardRef<HTMLInputElement, IMaskComponentProps>(
  ({ onChange, unmask = true, ...props }, ref) => {
    return <IMaskInput inputRef={ref} onAccept={onChange} unmask={unmask} overwrite {...props} />
  },
)

export const MaskedTextField = forwardRef<HTMLInputElement, MaskedTextFieldProps>((props: any, ref) => {
  const { mask, unmask, onChange } = props

  return (
    <TextField
      components={{
        Input: IMaskComponent,
      }}
      componentsProps={{ input: { mask: mask, unmask: unmask, onChange } }}
      inputRef={ref}
      {...props}
    />
  )
})
