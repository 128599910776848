import styled from 'styled-components'

export const TableName = styled.span`
  font-size: 18px;
  font-weight: var(--font-weight-semi-bold);
  letter-spacing: 0.29px;
`

export const TextDecoration = styled.span`
  color: var(--color-primary-01);
  text-decoration: underline;
  cursor: pointer;
`

export const Border = styled.div`
  padding: 32px;

  > p {
    text-align: center;
  }
`
