import { InputLabel, styled } from '@mui/material'
import { InputLabelProps } from './inputLabel'
//

interface IPositionStyles {
  flexDirection: 'row' | 'row-reverse' | 'column' | 'column-reverse'
  justifyContent: 'flex-end' | 'flex-start' | 'center'
}

export const StyledInputLabel = styled(InputLabel)<InputLabelProps>(({ position = 'top' }) => {
  let positionStyles: IPositionStyles = { flexDirection: 'row', justifyContent: 'flex-start' }
  if (position === 'top') positionStyles.flexDirection = 'column'
  else if (position === 'bottom') positionStyles.flexDirection = 'column-reverse'
  else if (position === 'left') positionStyles.flexDirection = 'row'
  else
    positionStyles = {
      justifyContent: 'flex-end',
      flexDirection: 'row-reverse',
    }
  return {
    fonFamily: 'Montserrat',
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '24px',
    display: 'flex',
    gap: 8,
    ...positionStyles,
  }
})
