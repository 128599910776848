import { Box, styled, useMediaQuery } from '@mui/material'

export const StyledBox = styled(Box)(({ theme }) => {
  const underMedium = useMediaQuery(theme.breakpoints.down('md'))

  return {
    color: theme.palette.common.white,
    whiteSpace: 'nowrap',
    '& a': {
      color: theme.palette.common.white,
      display: underMedium ? 'initial' : 'block',
    },
    '& span': {
      display: underMedium ? 'none' : 'initial',
    },
    '& svg': {
      marginRight: 12,
    },
  }
})
