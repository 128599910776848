import { Accordion, Box, Icon, Paper } from '@/presentation/components/atoms'
import { styled } from '@mui/material'

export const StyledBox = styled(Box)(({ theme }) => ({
  height: '100vh',
  width: '100vw',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.brand.primary.light,
}))

export const StyledPaper = styled(Paper)(() => ({
  minHeight: '40%',
  maxWidth: '680px',
  minWidth: '448px',
  width: '60%',
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  padding: '16px',
  flexDirection: 'column',
}))

export const StyledIcon = styled(Icon)(({ theme }) => ({
  height: '96px',
  color: theme.palette.error.main,
  margin: '16px 0',
}))

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  background: theme.palette.error.light + '10',
  color: theme.palette.error.light,
  boxShadow: '0px 1px 4px ' + theme.palette.grey[400],
}))

export const ErrorCopyPositionBox = styled(Box)(() => ({
  position: 'relative',
}))

export const CopyIcon = styled(Icon)(({ theme }) => ({
  position: 'absolute',
  right: 48,
  top: 16,
  cursor: 'pointer',
  color: theme.palette.grey[600],
  zIndex: 1,
  transition: 'all linear',
  transitionDuration: theme.transitions.duration.short,
  '&:active': {
    transform: 'scale(.8)',
  },
}))
