import { Grid, Skeleton } from 'presentation/components/atoms'

export const OrderTrackingSkeleton = () => {
  return (
    <Grid container spacing='32px' justifyContent='space-between'>
      <Grid item xs={12} md={8} lg={8}>
        <Skeleton height={380} variant='rounded' />
        <Skeleton height={146} variant='rounded' sx={{ marginTop: '32px' }} />
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <Skeleton height={622} variant='rounded' />
      </Grid>
    </Grid>
  )
}
