import { useContext } from 'react'
import { useTheme } from '@mui/material'
import { faTruck } from '@fortawesome/free-solid-svg-icons'
import { applyMask, orderStatusToColors } from 'main/utils/functions'
import { ORDER_STATUS, ORDER_STATUS_KEY } from 'main/utils/constants'
import { Icon, Typography } from 'presentation/components/atoms'
import { OrderTrackingContext } from 'presentation/pages/orderTracking/orderTracking'
import { DetailCard } from '../detailCard/detailCard'

export const DestinationAddressCard = () => {
  const { destinationAddress, orderStatus } = useContext(OrderTrackingContext)
  const theme = useTheme()
  const statusColors = orderStatusToColors({ theme, status: orderStatus })

  return (
    <DetailCard
      icons={
        <Icon icon={faTruck} style={{ background: theme.palette.grey[100], borderRadius: '50%', padding: '16px' }} />
      }
      title={`${destinationAddress?.laneName}, n ° ${destinationAddress?.number}`}
      subtitle={`${applyMask({ mask: '#####-###', text: destinationAddress?.cep })} - ${destinationAddress?.city},${
        destinationAddress?.state
      }`}
      helperText={
        <Typography variant='label3' sx={{ color: statusColors.main }}>
          {ORDER_STATUS[orderStatus as ORDER_STATUS_KEY]}
        </Typography>
      }
    />
  )
}
