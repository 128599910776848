import { Avatar } from 'presentation/components/atoms/avatar'
import { Box } from 'presentation/components/atoms/box'
import { Tooltip } from 'presentation/components/atoms/tooltip'
import { useRef } from 'react'
import { isOverflowing } from 'utils/functionUtils'
import { StyledTypography } from './styles'

export const UserIdentification = ({ userName, companyName, profilePicSrc, onClick, ...props }) => {
  const userNameRef = useRef(null)
  const companyNameRef = useRef(null)

  const renderInfo = (value, ref, variant = undefined) => {
    const isElementOverflowing = isOverflowing(ref.current)
    return (
      <Tooltip title={isElementOverflowing ? value : null}>
        <StyledTypography variant={variant} ref={ref}>
          {value}
        </StyledTypography>
      </Tooltip>
    )
  }

  return (
    <Box
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        gap: '32px',
        height: 'fit-content',
      }}
      {...props}
    >
      <Avatar
        sx={{
          height: '42px',
          width: '42px',
          cursor: 'pointer',
        }}
        src={profilePicSrc}
        onClick={onClick}
      />
      <Box
        sx={{
          maxWidth: '148px',
          whiteSpace: 'nowrap',
          display: { xs: 'none', md: 'initial', lg: 'none', xl: 'initial' },
        }}
      >
        {renderInfo(userName, userNameRef, 'h4')}
        {renderInfo(companyName, companyNameRef)}
      </Box>
    </Box>
  )
}
