import { CheckboxProps } from '@mui/material'
import { Checkbox, FieldWrapper, InputLabel } from 'presentation/components/atoms'

interface CheckboxFieldProps extends CheckboxProps {
  value: boolean
  label: any
}

export const CheckboxField = ({ value, label, ...props }: CheckboxFieldProps) => {
  return (
    <FieldWrapper sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
      <InputLabel label={label} position='right'>
        <Checkbox checked={value} {...props} />
      </InputLabel>
    </FieldWrapper>
  )
}
