import styled from 'styled-components'

export const PageName = styled.div`
  font-weight: var(--font-weight-semi-bold);
  font-size: 1.625rem;
  white-space: nowrap;
`

export const PageHeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 80px;
  ${({ borderless }) => (borderless ? null : 'border-bottom: 1px solid var(--color-secundary-04);')}
  padding-left: 32px;
`
