import { useSelector } from 'react-redux'

export const useCards = () => {
  const storeData = useSelector((state) => state.cards)
  const cardsData = storeData?.cards
  const isLoading = storeData?.loading

  return {
    cards: cardsData?.data || [],
    isLoading,
    cardsPagination: {
      pageSize: cardsData?.pageSize,
      totalItens: cardsData?.totalItens,
      totalPages: cardsData?.totalPages,
      pageNumber: cardsData?.pageNumber,
    },
  }
}
