import produce from 'immer'
import { ACTIONS } from './actions'

const INITIAL_STATE = {
  cards: null,
  loading: false,
  requestFailured: false,
}

export default function associatedCard(state = INITIAL_STATE, action) {
  const { type, payload } = action
  return produce(state, (draft) => {
    switch (type) {
      case ACTIONS.GET_LIST_REQUEST: {
        draft.loading = true
        break
      }

      case ACTIONS.GET_LIST_SUCCESS: {
        draft.loading = false
        draft.cards = payload
        break
      }

      case ACTIONS.GET_LIST_FAILURE: {
        draft.loading = false
        draft.requestFailured = true
        break
      }

      default:
    }
  })
}
