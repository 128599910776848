import { styled } from '@mui/material'
import { Box, Card } from 'presentation/components/atoms'

export const StyledCard = styled(Card)(() => ({
  padding: '12px',
  display: 'flex',
  gap: '16px',
}))

export const IconContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '64px',
  width: '64px',
  padding: '14px',
  position: 'relative',
  '& svg': {
    height: '32px',
    width: '32px',
  },
  '& svg:not(:only-child)': {
    transform: `scale(0.7)`,
  },
  '& svg:not(:only-child):first-of-type': {
    transformOrigin: 'top left',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  '& svg:not(:only-child):last-of-type': {
    transformOrigin: 'bottom right',
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
}))
