const ACTION = '@recharge/'

export const ACTIONS = {
  GET_RECHARGE_REQUEST: ACTION + 'GET_RECHARGE_REQUEST',
  GET_DRAFT_RECHARGE_REQUEST: ACTION + 'GET_DRAFT_RECHARGE_REQUEST',
  RECHARGE_REQUEST_SUCCESS: ACTION + 'RECHARGE_REQUEST_SUCCESS',
  RECHARGE_REQUEST_FAILURE: ACTION + 'RECHARGE_REQUEST_FAILURE',
}

export function getRechargeRequest(rechargeOrderId) {
  return {
    type: ACTIONS.GET_RECHARGE_REQUEST,
    payload: { rechargeOrderId },
  }
}

export function getDraftRechargeRequest(rechargeOrderId) {
  return {
    type: ACTIONS.GET_DRAFT_RECHARGE_REQUEST,
    payload: { rechargeOrderId },
  }
}

export function rechargeRequestSuccess(data) {
  return {
    type: ACTIONS.RECHARGE_REQUEST_SUCCESS,
    payload: { data },
  }
}

export function rechargeRequestFailure(data) {
  return {
    type: ACTIONS.RECHARGE_REQUEST_FAILURE,
    payload: { data },
  }
}
