import { styled } from '@mui/material'

export const StyledImage = styled('div')(({ imageSrc, theme }) => ({
  height: 96,
  minWidth: imageSrc ? 128 : 96,
  width: 'fit-content',
  backgroundRepeat: 'no-repeat',
  backgroundSize: imageSrc ? '100px 56px' : '66px 65px',
  backgroundPosition: 'center',
  backgroundColor: theme.palette.grey[300],
  borderRadius: 8,
  backgroundImage: `url(${imageSrc})`,
}))
