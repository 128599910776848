const ACTION = '@extracts/'

export const ACTIONS = {
  GET_EXTRACTS_REQUEST: ACTION + 'GET_EXTRACTS_REQUEST',
  EXTRACTS_REQUEST_SUCCESS: ACTION + 'EXTRACTS_REQUEST_SUCCESS',
  EXTRACTS_REQUEST_FAILURE: ACTION + 'EXTRACTS_REQUEST_FAILURE',
}

export function getExtractsRequest(query) {
  return {
    type: ACTIONS.GET_EXTRACTS_REQUEST,
    payload: { query },
  }
}

export function extractsRequestSuccess(data) {
  return {
    type: ACTIONS.EXTRACTS_REQUEST_SUCCESS,
    payload: { data },
  }
}

export function extractsRequestFailure(data) {
  return {
    type: ACTIONS.EXTRACTS_REQUEST_FAILURE,
    payload: { data },
  }
}
