import styled, { css } from 'styled-components'
import { HiDotsHorizontal } from 'react-icons/hi'

export const MenuContainer = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`

export const FloatingMenu = styled.div`
  background-color: var(--color-primary-05);
  border-radius: 0.5rem;
  box-shadow: 0 0.125rem 0.3125rem var(--color-secundary-04);
  color: var(--color-primary-04);
  position: absolute;
  width: 230px;
  z-index: 10001;
  transition: all 0.2s;
  padding: 0 16px;
  overflow: hidden;
  ${({ visible }) =>
    !visible &&
    css`
      opacity: 0;
      max-height: 0;
    `}
  ${({ position }) =>
    position &&
    css`
      bottom: ${position.top}px;
      right: ${position.right}px;
    `}
`

export const Dots = styled(HiDotsHorizontal)`
  height: 24px;
  width: 24px;
  cursor: pointer;
  :hover {
    fill: var(--color-primary-01);
  }
`
