import { forwardRef } from 'react'
import { StyledInputBase } from './styles'
import { InputBaseProps as MUIInputBaseProps } from '@mui/material'

export interface InputBaseProps extends MUIInputBaseProps {
  blocked?: boolean
  error?: boolean
  success?: boolean
}

export const InputBase = forwardRef<HTMLInputElement, InputBaseProps>((props: InputBaseProps, ref) => {
  return <StyledInputBase inputRef={ref} disabled={props.disabled || props.blocked} {...props} />
})
