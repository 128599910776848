import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { useBlocker } from 'react-router-dom'
import { Button, Dialog, DialogActions, DialogTitle } from '../atoms'
import { DialogContent } from '@mui/material'

interface PageChangePromptProps {
  children: ReactElement
  title?: string
  cancelText?: string
  confirmText?: string
  bypass?: Array<string>
  onCancel?: () => void
  onConfirm?: () => void
}

const PageChangePrompt = ({
  title = 'Ei, tem certeza?',
  cancelText = 'Cancelar',
  confirmText = 'Confirmar',
  children,
  bypass = [],
  onCancel,
  onConfirm,
}: PageChangePromptProps) => {
  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    if (nextLocation.state?.bypass || bypass.includes(nextLocation.pathname)) return false
    return currentLocation.pathname !== nextLocation.pathname
  })

  const isBlocked = blocker.state === 'blocked'

  const handleCancel = () => {
    onCancel && onCancel()
    blocker.reset()
  }

  const handleConfirm = () => {
    onConfirm && onConfirm()
    blocker.proceed()
  }

  return (
    <Dialog open={isBlocked} onClose={handleCancel}>
      {title && <DialogTitle sx={{ marginBottom: '32px' }}>{title}</DialogTitle>}
      <DialogContent sx={{ textAlign: 'left', padding: 0 }}>{children}</DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '32px' }}>
        <Button variant='outlined' onClick={handleCancel}>
          {cancelText}
        </Button>
        <Button variant='contained' onClick={handleConfirm}>
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PageChangePrompt
