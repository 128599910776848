import { useEffect, useState } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import api from '@/infra/api'

interface IUseSWR {
  orderId: string
}

interface IPixData {
  numberOfColaborators: number
  dueDate: null
  orderDate: Date
  code: number
  status: number
  operatorName: string
  confirmation: {
    paymentFeeValue: number
    transferFeeValue: number
    useOfWallet: number
    useOfCheckingAccount: boolean
    issFeeValue: number
    subTotalValue: number
    totalValue: number
    totalAmountPayable: number
  }
  ticketingBalance: number
  oldTicketingBalance: number
  subTotalValue: number
  walletBalance: number
  companyCity: string
  companyName: string
  tradeName: string
  pix: {
    qrCode: string
  }
}

export const usePixCart = ({ orderId }: IUseSWR) => {
  const [enabled, setEnabled] = useState(true)

  const getPix = async () => {
    try {
      const { data } = await api.get(`rh-bff/basket/getbycompanyid/pix/${orderId}`)

      return data
    } catch (e) {
      return e
    }
  }

  const { data, isLoading } = useQuery<IPixData>({
    queryKey: ['pix-cart'],
    queryFn: getPix,
    refetchInterval: 5000,
    cacheTime: 0,
    enabled,
  })

  useEffect(() => {
    if (data.status > 3) {
      setEnabled(false)
    }
  }, [data])

  return {
    data,
    loading: isLoading,
  }
}
