import React, { useState } from 'react'
import { Input, ModalV2, Spinner } from 'presentation/components'
import useDownloadReport from 'main/hooks/useDownloadReport'
import { getBalanceProjectionOrderReport } from '@/services/reportsServices'
import { removeObjectEmptyItems } from '@/utils/functionUtils'
import { Controller, useForm } from 'react-hook-form'
import { ModalTitle } from '../styles'
import { Label } from './styles'
import { Button, Grid, Typography } from '@/presentation/components/atoms'
import { ReportFormatSelector } from '../../components/reportFormatSelector'
import useDebounce from '@/main/hooks/useDebouce'
import { useFillCardsSelect } from '../../hooks/useFillCardsSelect'

const BalanceProjectionOrderReportFilter = ({
  open,
  title = 'Relatório de Pedido',
  onClose,
  availableFormatsOptions = [
    {
      value: 'pdf',
      label: 'PDF',
    },
  ],
}) => {
  const debounce = useDebounce()
  const [download, requestDownload] = useDownloadReport(getBalanceProjectionOrderReport)
  const [maxDate, setMaxDate] = useState(new Date())
  const [minDate, setMinDate] = useState(null)
  const [cardToSearch, setCardToSearch] = useState('')
  const [initialDateMinDate, setInitialDateMinDate] = useState(null)
  const [finalDateMaxDate, setFinalDateMaxDate] = useState(new Date())
  const [selectedFormat, setSelectedFormat] = useState(availableFormatsOptions[0].value)
  const { cardsOptions, isLoading: isLoadingSelectCard } = useFillCardsSelect({ cardNumber: cardToSearch })

  const {
    control,
    handleSubmit,
    formState: { dirtyFields },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      number: '',
      cardIds: [],
      initialDate: null,
      finalDate: null,
    },
  })

  const isPeriodEmpty = !dirtyFields.initialDate || !dirtyFields.finalDate
  const isGenerateDisabled = !dirtyFields.number && (!dirtyFields.cardIds || isPeriodEmpty) && isPeriodEmpty

  const invertDateString = (string) => {
    const dateArray = string.split('-')
    return `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`
  }

  const onSubmit = (data) => {
    const reportName = data.number
      ? `Relatório de pedido de recarga com projeção de saldo #${data.number}.${selectedFormat}`
      : `Relatório de pedido de recarga com projeção de saldo - De ${invertDateString(
          data.initialDate,
        )} Até ${invertDateString(data.finalDate)}.${selectedFormat}`
    requestDownload(
      reportName,
      removeObjectEmptyItems({
        code: Number(data.number),
        startDate: data.initialDate,
        endDate: data.finalDate,
        'suborders.any.itens.any.cardId':
          data.cardIds.length === 0 ? null : `[${String(data.cardIds.map((card) => card.id))}]`,
      }),
      selectedFormat,
    )
  }

  const handleCardSearch = (inputValue) => {
    debounce(() => setCardToSearch(inputValue), 500)
  }

  return (
    <ModalV2 open={open} onClose={onClose}>
      <Spinner style={{ display: download.loading ? 'initial' : 'none' }} />
      <form
        style={{ display: download.loading ? 'none' : 'initial' }}
        onSubmit={handleSubmit(onSubmit)}
        id='balanceProjectionOrderReportFilterForm'
      >
        <Grid container spacing={'16px'}>
          <Grid item xs={12}>
            <ModalTitle>{title}</ModalTitle>
          </Grid>
          <Typography
            variant='label'
            sx={(theme) => ({
              textAlign: 'left',
              margin: '8px 0 0 16px',
              color: theme.palette.grey[500],
            })}
          >
            Para gerar um relatório informe os dados da seguinte forma:
            <br />
            • Somente Número de Pedido
            <br />
            • Número de Cartão e Período
            <br />• Somente Período
          </Typography>
          <Grid item xs={12}>
            <Label isTitle>Dados</Label>
          </Grid>

          <Grid item xs={12}>
            <Label>N° do pedido</Label>
            <Controller
              control={control}
              name='number'
              render={({ field: { onChange, value, ref } }) => (
                <Input.Masked
                  placeholder='Insira o número do pedido'
                  getInputRef={ref}
                  onChange={onChange}
                  value={value}
                  maxLength={18}
                  disabled={!isPeriodEmpty || dirtyFields.cardIds}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Label>N° do cartão</Label>
            <Controller
              control={control}
              name='cardIds'
              render={({ field: { onChange, value } }) => (
                <Input.MultiSelect
                  placeholder='Selecione os cartões'
                  multiSelectionLabel='cartões selecionados'
                  value={value}
                  onChange={onChange}
                  getOptionValue={(option) => option.id}
                  getOptionLabel={(option) => option.number}
                  options={cardsOptions}
                  onInputChange={handleCardSearch}
                  isDisabled={dirtyFields.number}
                  isLoading={isLoadingSelectCard}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Label isTitle>Período</Label>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Label>De</Label>
            <Controller
              control={control}
              name='initialDate'
              render={({ field: { onChange, value, ref } }) => (
                <Input.Date
                  placeholder='00/00/0000'
                  maxDate={maxDate}
                  minDate={initialDateMinDate}
                  getInputRef={ref}
                  value={new Date(value?.replaceAll('-', '/'))}
                  disabled={dirtyFields.number}
                  onChange={(e) => {
                    if (e !== '') {
                      const inputDate = new Date(e)
                      const today = new Date()
                      const inputDatePlusSixMonths = new Date(inputDate.setMonth(inputDate.getMonth() + 6))
                      const finalDate = inputDatePlusSixMonths > today ? today : inputDatePlusSixMonths
                      setFinalDateMaxDate(finalDate)

                      onChange(e?.toISOString().split('T')[0])
                      setMinDate(e)
                    } else {
                      onChange(null)
                      setMinDate(null)
                    }
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Label>Até</Label>
            <Controller
              control={control}
              name='finalDate'
              render={({ field: { onChange, value, ref } }) => (
                <Input.Date
                  placeholder='00/00/0000'
                  maxDate={finalDateMaxDate}
                  minDate={minDate}
                  getInputRef={ref}
                  value={new Date(value?.replaceAll('-', '/'))}
                  disabled={dirtyFields.number}
                  onChange={(e) => {
                    if (e !== '') {
                      const inputDate = new Date(e)
                      setInitialDateMinDate(new Date(inputDate.setMonth(inputDate.getMonth() - 6)))
                      onChange(e?.toISOString().split('T')[0])
                      setMaxDate(e)
                    } else {
                      onChange(null)
                      setMaxDate(null)
                    }
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Label isTitle>Formato</Label>
            <ReportFormatSelector
              availableFormatsOptions={availableFormatsOptions}
              onChange={(value) => setSelectedFormat(value)}
              value={selectedFormat}
              customRadio={Input.CheckBox}
              sx={{ margin: '16px 0' }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button variant='outlined' fullWidth onClick={onClose} sx={{ filter: 'grayscale(100)' }}>
              Cancelar
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button variant='contained' fullWidth type='submit' disabled={isGenerateDisabled}>
              Gerar Relatório
            </Button>
          </Grid>
        </Grid>
      </form>
    </ModalV2>
  )
}

export default BalanceProjectionOrderReportFilter
