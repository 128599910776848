const ACTION = '@genericAuth/'

export const ACTIONS = {
  SIGN_IN_REQUEST: ACTION + 'SIGN_IN_REQUEST',
  SIGN_IN_REQUEST_SUCCESS: ACTION + 'SIGN_IN_REQUEST_SUCCESS',
  SIGN_IN_REQUEST_FAILURE: ACTION + 'SIGN_IN_REQUEST_FAILURE',
  SIGN_OUT_REQUEST: ACTION + 'SIGN_OUT_REQUEST',
}

export function genericUserSignInRequest(site) {
  return {
    type: ACTIONS.SIGN_IN_REQUEST,
    payload: { site },
  }
}

export function genericUserSignInRequestSuccess(token) {
  return {
    type: ACTIONS.SIGN_IN_REQUEST_SUCCESS,
    payload: { token },
  }
}

export function genericUserSignInRequestFailure(data) {
  return {
    type: ACTIONS.SIGN_IN_REQUEST_FAILURE,
    payload: { data },
  }
}

export function genericUserSignOutRequest() {
  return {
    type: ACTIONS.SIGN_OUT_REQUEST,
  }
}
