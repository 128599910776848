import React, { useState } from 'react'
import { Content } from 'presentation/components'

import InsertCnpj from './insertCnpj'
import Form from '../form'
import RegisterConfirmation from './registerConfirmation'
import { useLocation } from 'react-router-dom'

const CompanyRegistration = () => {
  const location = useLocation()
  const [done, setDone] = useState(false)
  const mode = location?.state?.mode
  window.history.replaceState(null, '')

  return (
    <Content>
      {!mode && <InsertCnpj />}
      {mode === 'FORM' && !done && <Form done={() => setDone(true)} />}
      {done && <RegisterConfirmation />}
    </Content>
  )
}

export default CompanyRegistration
