import { Box, Divider, Typography } from 'presentation/components/atoms'
import { formatMoney } from 'utils/functionUtils'
import { nameFields } from './utils/nameFields'
import { tooltipContent } from './utils/tooltip'
import { ReactComponent as CardIcon } from 'presentation/assets/icons/card.svg'
import { ReactComponent as RechargeIcon } from 'presentation/assets/icons/recharge.svg'

import { ItemsContainer, OrderDetail, OrderDetailHeader, TotalValue } from './styles'
import { Summary } from 'presentation/components/molecules'
import _ from 'lodash'
import { IDetails } from '@/presentation/pages/recharge/rechargePaymentConfirmation/hooks/useRequestInfos/utils/detailsData'

export type OrderType = 'recharge' | 'rechargeBroker' | 'rechargeImport' | 'firstCopy' | 'secondCopy'

interface ItemsProps {
  orders: IDetails[]
  orderType: OrderType
  title: string
  operatorCardName: string
}

export const Items = ({ orders, orderType, title, operatorCardName }: ItemsProps) => {
  const totalSumOfAllOrders = orders?.reduce((initValue: number, currentValue: any) => {
    return initValue + currentValue?.totalValue
  }, 0)

  if (!(orders?.length > 0)) return null

  const OrderIcon = orderType === 'recharge' ? RechargeIcon : CardIcon

  return (
    <ItemsContainer>
      <Summary
        title={
          <Box sx={{ display: 'flex', justifyContent: 'spaceBetween' }}>
            <Typography variant='h4'>{title}</Typography>
            <TotalValue variant='h4'>{formatMoney(totalSumOfAllOrders)}</TotalValue>
          </Box>
        }
        subtitle={`${orders.length} ${orders.length > 1 ? 'itens' : 'item'}`}
        icon={<OrderIcon />}
      >
        {orders.map((order: any, index: number) => {
          return (
            <Box key={_.uniqueId()}>
              {index !== 0 && <Divider sx={{ margin: '16px 0' }} />}
              <OrderDetailHeader>
                <Typography variant='label2'>
                  {index + 1}. {operatorCardName || order.operatorCardName}
                </Typography>
              </OrderDetailHeader>
              {Object.entries(nameFields).map((values, index) => {
                const name = values[1]
                const key = values[0]
                const value = order[key]
                const isLast = index + 1 === Object.entries(nameFields).length
                const valueLabel = 'totalSubOrder' === key && orderType === 'recharge' ? 'Valor da recarga' : name

                if (
                  (orderType !== 'recharge' && key === 'ticketingBalance') ||
                  (key === 'ticketingBalance' && (order.oldTicketingBalance === 0 || !order.oldTicketingBalance))
                )
                  return null
                return (
                  <OrderDetail key={_.uniqueId()}>
                    <span>
                      <Typography variant='label2' sx={{ fontWeight: isLast ? 700 : 400, marginRight: '16px' }}>
                        {valueLabel}
                      </Typography>
                      {key === 'ticketingBalance' && tooltipContent({ value: order.oldTicketingBalance })}
                    </span>
                    <Typography
                      variant='label2'
                      sx={{ fontWeight: isLast ? 700 : 400, fontStyle: 'italic', whiteSpace: 'nowrap' }}
                    >
                      {['issFee', 'issFee', 'ticketingBalance'].includes(key) && value > 0 && '- '}
                      {key !== 'totalItens' ? `R$ ${formatMoney(value)}` : value}
                    </Typography>
                  </OrderDetail>
                )
              })}
            </Box>
          )
        })}
      </Summary>
    </ItemsContainer>
  )
}
