import { useSelector } from 'react-redux'

export const useDepartments = () => {
  const storeData = useSelector((state) => state.departments)

  const departments = storeData.data?.departments
  const employesWithoutDepartmentCount = storeData.data?.employesWithoutDepartmentCount
  const additionalInfo = storeData.data
  const isLoading = storeData?.loading
  const failure = storeData.failure

  return {
    departments,
    employesWithoutDepartmentCount,
    additionalInfo,
    isLoading,
    failure,
  }
}
