import styled from 'styled-components'
import Bg from 'presentation/assets/images/login.png'

export const Main = styled.div`
  height: 100vh;
  background: url(${Bg}) no-repeat center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const Body = styled.div`
  border-radius: 15px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 43%;

  button {
    width: 100%;
  }

  form {
    width: 100%;
    max-width: 370px;
  }

  p {
    max-width: 370px;
  }
`

export const Title = styled.h1`
  color: #707070;
  font-size: 1.5rem;
  margin-bottom: 24px;
  margin-top: 56px;
  width: 370px;
`

export const Label = styled.div`
  margin-bottom: 10px;
`

export const ChangePasswordSuccess = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const OkBallContainer = styled.div`
  position: relative;
  margin-bottom: 60px;
  svg {
    position: absolute;
    top: -30px;
    left: 40px;
    stroke: #47f06a;
    height: 200px;
    width: 200px;
  }
`

export const OkBall = styled.div`
  border-radius: 50%;
  height: 200px;
  width: 200px;
  background: transparent linear-gradient(137deg, #7048ff 0%, #47f06a 100%) 0% 0% no-repeat padding-box;
`

export const SuccessText = styled.p`
  font-weight: var(--font-weight-bold);
  letter-spacing: 0;
  text-align: center;
  font-size: 1.25rem;
  padding-bottom: 80px;
`
