import styled from 'styled-components'
import { DropdownButton } from 'presentation/components'
import { Row as GridRow } from 'react-grid-system'
import { BREAK_POINTS } from '@/utils/constants'

export const CustomDropdownButton = styled(DropdownButton)`
  margin-right: 32px;
  min-width: 300px;
  max-width: 300px;
  @media (max-width: ${BREAK_POINTS.md}) {
    margin: 32px 0;
  }
`

export const Row = styled(GridRow)`
  & + div {
    margin-top: 40px;
  }
`

export const Wrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-transform: capitalize;
`

export const StatusDot = styled.div`
  height: 12px;
  width: 12px;
  border-radius: 50%;
  margin-right: 8px;

  ${({ status }) => (status === 'ATIVO' ? 'background-color: var(--color-status-01)' : null)}
  ${({ status }) => (status === 'BLOQUEADO' ? 'background-color: var(--color-status-02)' : null)}
    ${({ status }) => (status === 'INATIVO' ? 'background-color: var(--color-status-06)' : null)}
`

export const Body = styled.div`
  padding: 32px;
`

export const Subtitle = styled.p`
  color: var(--color-primary-04);
  font-weight: var(--font-weight-bold);
  font-size: 1.25rem;
  letter-spacing: 0.03625rem;
`

export const Description = styled.p`
  font-size: 0.9rem;
  padding: 15px 0;
`

export const AlignRight = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 300px;
  margin-top: 20px;
`

export const ColorDest = styled.span`
  color: var(--color-primary-01);
  font-weight: var(--font-weight-semi-bold);
`

export const TableBody = styled.tbody`
  tr {
    cursor: pointer;
  }
`

export const CentralizeText = styled.p`
  text-align: center;
`
