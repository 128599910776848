import React, { useState } from 'react'
import { EMPLOYEE_STATUS, FORM_MODE } from '@/utils/constants'
import DeleteComponent from './parts/deleteComponent'
import IconList from './parts/iconList'
import TableRow from '@mui/material/TableRow'
import {
  Box,
  CircularProgress,
  ColorDot,
  Icon,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Tooltip,
} from 'presentation/components/atoms'
import { faCircleExclamation, faClockRotateLeft, faEllipsis } from '@fortawesome/free-solid-svg-icons'
import { OrderSelector } from 'presentation/components/molecules'
import { EMPLOYEE_OPERATOR_INTEGRATION } from 'utils/constants'
import AssociateCardModal from './parts/associateCardModal'
import { useNavigate } from 'react-router-dom'

const initialMenuRef = {
  anchor: null,
  index: null,
}

const EmployeeTable = ({
  paginator,
  alternateUserStatus,
  employeesData = [],
  loading,
  order,
  sendToStore,
  overflow,
  refreshList,
}) => {
  const [id, setId] = useState(null)
  const [isAssociatingCard, setIsAssociatingCard] = useState(false)

  const [remove, setRemove] = useState(false)
  const navigate = useNavigate()
  const [employeeMenuRef, setEmployeeMenuRef] = useState(initialMenuRef)

  const viewEmployee = (employeeData) => {
    sendToStore(employeeData)
    navigate('/colaboradores/visualizar', {
      state: { mode: FORM_MODE.VIEW },
    })
  }

  const editUser = (e, employeeData) => {
    sendToStore(employeeData)
    navigate('/colaboradores/editar', {
      state: { mode: FORM_MODE.EDIT },
    })
  }

  const removeEmployee = (e, employee) => {
    setRemove(true)
    setId(employee.employeeId)
  }

  const Wrapper = ({ ...props }) => {
    return (
      <Box
        sx={{
          display: 'flex',
          gap: '8px',
        }}
        {...props}
      />
    )
  }
  const generateTableHeader = () => {
    return (
      <TableRow>
        <TableCell id='employees_tableHeader_employee'>
          <Wrapper>
            Colaborador
            <OrderSelector
              onClickUp={() => {
                order('fullname', true)
              }}
              onClickDown={() => {
                order('fullname', false)
              }}
            />
          </Wrapper>
        </TableCell>
        <TableCell id='employees_tableHeader_registration'>Matrícula</TableCell>
        <TableCell id='employees_tableHeader_departament'>
          <Wrapper>
            Departamento
            <OrderSelector
              onClickUp={() => {
                order('departmentName', true)
              }}
              onClickDown={() => {
                order('departmentName', false)
              }}
            />
          </Wrapper>
        </TableCell>
        <TableCell id='employees_tableHeader_benefit'>Benefício</TableCell>
        <TableCell align='center' id='employees_tableHeader_status'>
          Status
        </TableCell>
        <TableCell align='center' id='employees_tableHeader_actions'>
          Ações
        </TableCell>
      </TableRow>
    )
  }

  const generateTableRow = () => {
    if (employeesData?.length === 0 || loading)
      return (
        <TableRow>
          <TableCell sx={{ textAlign: 'center', overflow: 'hidden' }} colSpan={6}>
            {loading ? <CircularProgress /> : 'Sem registros'}
          </TableCell>
        </TableRow>
      )
    return employeesData?.map((employee, index) => {
      const isEmployeeActive = employee.status === EMPLOYEE_STATUS.ACTIVE
      const isEmployeeInactive = employee.status === EMPLOYEE_STATUS.INACTIVE
      const isWaitingSync = employee.integrationsStatus === EMPLOYEE_OPERATOR_INTEGRATION.RUNNING
      return (
        <TableRow
          key={employee.id}
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            viewEmployee(employee)
          }}
        >
          <TableCell>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '16px' }}>
              {employee.fullName}
              {employee.integrationsStatus === EMPLOYEE_OPERATOR_INTEGRATION.OK ? null : (
                <Tooltip title={isWaitingSync ? 'Aguardando Sincronismo' : 'Erro de Sincronismo'}>
                  <Box>
                    <Icon
                      icon={isWaitingSync ? faClockRotateLeft : faCircleExclamation}
                      sx={(theme) => ({
                        color: isWaitingSync ? theme.palette.warning.main : theme.palette.error.main,
                      })}
                    />
                  </Box>
                </Tooltip>
              )}
            </Box>
          </TableCell>
          <TableCell>{employee.registration}</TableCell>
          <TableCell>{employee.departmentName}</TableCell>
          <TableCell>
            {!employee.status ? '' : <IconList itemsList={employee.benefits.length > 0 ? employee.benefits : []} />}
          </TableCell>
          <TableCell align='center'>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <ColorDot color={isEmployeeActive ? 'success' : isEmployeeInactive ? 'grey' : 'error'} />

              {EMPLOYEE_STATUS[employee.status]}
            </Box>
          </TableCell>
          <TableCell align='center' onClick={(e) => e.stopPropagation()}>
            <Icon
              icon={faEllipsis}
              onClick={(event) => {
                setEmployeeMenuRef({
                  anchor: event.currentTarget,
                  index: index,
                })
              }}
            />
            <Menu
              id={'employee-menu-' + index}
              anchorEl={employeeMenuRef.anchor}
              open={employeeMenuRef.index === index}
              onClose={() => setEmployeeMenuRef(initialMenuRef)}
              onClick={(e) => {
                e.stopPropagation()
                setEmployeeMenuRef(initialMenuRef)
              }}
            >
              <MenuItem onClick={(e) => editUser(e, employee)}>Editar</MenuItem>
              {!isEmployeeActive && !isEmployeeInactive ? null : (
                <MenuItem onClick={() => alternateUserStatus(employee)}>
                  {!isEmployeeActive ? 'Ativar' : 'Inativar'}
                </MenuItem>
              )}
              {employee.removable && <MenuItem onClick={(e) => removeEmployee(e, employee)}>Excluir</MenuItem>}
            </Menu>
          </TableCell>
        </TableRow>
      )
    })
  }

  const onAssociateCardClose = (isAssociated) => {
    setIsAssociatingCard(false)
    if (isAssociated) {
      refreshList()
    }
  }

  return (
    <TableContainer sx={{ minWidth: '650px', minHeight: loading ? 'auto' : '576px' }}>
      <Table loading={loading} overflow={overflow} sx={{ minHeight: '100%' }} aria-label='custom pagination table'>
        <TableHead>{generateTableHeader()}</TableHead>
        <TableBody>{generateTableRow()}</TableBody>
      </Table>

      {remove && <DeleteComponent paginator={paginator} open={remove} close={() => setRemove(false)} id={id} />}
      {isAssociatingCard && <AssociateCardModal isOpen={isAssociatingCard} onClose={onAssociateCardClose} id={id} />}
    </TableContainer>
  )
}

export default EmployeeTable
