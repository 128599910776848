import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { styled } from '@mui/material'
import { DatePickerProps } from './datePicker'

export const StyledDatePicker = styled(DatePicker)<DatePickerProps<any>>(({ theme, blocked }) => ({
  '& .MuiInputBase-root': {
    height: 48,
    borderRadius: 8,
    backgroundColor: blocked ? theme.palette.grey[50] : theme.palette.common.white,
    overflow: 'hidden',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: blocked ? 'none' : '',
  },
  '& .MuiInputBase-input': {
    padding: '12px 8px',
    textFillColor: 'unset !important',
    color: theme.palette.grey[700],
  },
}))
