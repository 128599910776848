import * as yup from 'yup'
import { validateCNPJ } from 'validations-br'

const schema = yup.object().shape({
  cnpj: yup
    .string()
    .required('Preencha o CNPJ')
    .test('is-cnpj', 'CNPJ invalido', (value) => validateCNPJ(value)),
})

export default schema
