import { getRecharges } from '@/services/rechargesServices'
import { all, takeLatest, put, call } from 'redux-saga/effects'
import { ACTIONS, rechargesRequestFailure, rechargesRequestSuccess } from './actions'

function* fetchRecharges({ payload }) {
  const { response, error } = yield call(getRecharges, payload.query)
  if (response) yield put(rechargesRequestSuccess(response.data))
  else yield put(rechargesRequestFailure(error.response))
}

export default all([takeLatest(ACTIONS.GET_RECHARGES_REQUEST, fetchRecharges)])
