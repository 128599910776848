import React from 'react'
import { Triangle, Wrapper } from './style'

const OrderIcon = ({ clickUp, clickDown, props }) => {
  return (
    <Wrapper {...props}>
      <Triangle onClick={clickUp} id='up' />
      <Triangle onClick={clickDown} down id='down' />
    </Wrapper>
  )
}

export default OrderIcon
