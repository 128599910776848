import { all, takeLatest, put, call } from 'redux-saga/effects'
import { ACTIONS, employeesRequestFailure, employeesRequestSuccess } from './actions'
import {
  getEmployees,
  getEmployeesApi,
  getEmployeesForCardFirstCopy,
  getEmployeesForCardSecondCopy,
} from '@/services/employeesServices'

function* fetchEmployees({ payload }) {
  const { response, error } = yield call(getEmployees, payload.query)
  if (response) yield put(employeesRequestSuccess(response?.data))
  else yield put(employeesRequestFailure(error.response))
}

function* fetchEmployeesApi({ payload }) {
  const { response, error } = yield call(getEmployeesApi, payload.query)
  if (response) yield put(employeesRequestSuccess(response))
  else yield put(employeesRequestFailure(error.response))
}

function* fetchEmployeesForCardFirstCopy({ payload }) {
  const { response, error } = yield call(getEmployeesForCardFirstCopy, payload.query)
  if (response) yield put(employeesRequestSuccess(response.data))
  else yield put(employeesRequestFailure(error.response))
}

function* fetchEmployeesForCardSecondCopy({ payload }) {
  const { response, error } = yield call(getEmployeesForCardSecondCopy, payload.query)
  if (response) yield put(employeesRequestSuccess(response.data))
  else yield put(employeesRequestFailure(error.response))
}

export default all([
  takeLatest(ACTIONS.GET_EMPLOYEES_REQUEST, fetchEmployees),
  takeLatest(ACTIONS.GET_EMPLOYEES_REQUEST_API, fetchEmployeesApi),
  takeLatest(ACTIONS.GET_EMPLOYEES_FOR_CARD_FIRST_COPY_REQUEST, fetchEmployeesForCardFirstCopy),
  takeLatest(ACTIONS.GET_EMPLOYEES_FOR_CARD_SECOND_COPY_REQUEST, fetchEmployeesForCardSecondCopy),
])
