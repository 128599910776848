import * as yup from 'yup'
import { validateCPF } from 'validations-br'

const compositeNameRegex =
  /^[0-9A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ'\s]{2,}\s[0-9A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ'\s]{2,}/

function calculateYearsFromToday(years) {
  return new Date(new Date().setFullYear(new Date().getFullYear() + years))
}

const MAX_AGE = 75
const MIN_AGE = 16

const maxDate = calculateYearsFromToday(-MIN_AGE)
const minDate = calculateYearsFromToday(-MAX_AGE)

const schema = yup.object().shape({
  abortEarly: false,
  fullname: yup
    .string()
    .matches(compositeNameRegex, {
      message: 'O nome deve ser composto',
      excludeEmptyString: true,
    })
    .max(120, 'Campo limitado a 120 caracteres')
    .required('Nome é um campo requerido'),
  birthDate: yup
    .date()
    .typeError('Insira uma data válida')
    .transform((curr, orig) => (orig === '' ? null : curr))
    .nullable()
    .min(minDate, 'Data fora do limite permitido')
    .max(maxDate, 'Data fora do limite permitido'),
  cpf: yup
    .string()
    .nullable()
    .test('is-cpf', 'CPF invalido', (value) => (value ? validateCPF(value) : true)),
  rg: yup.string().nullable().max(10, 'Campo limitado a 10 caracteres'),
  issueDate: yup
    .date()
    .typeError('Insira uma data válida')
    .transform((curr, orig) => (orig === '' ? null : curr))
    .nullable()
    .max(new Date(), 'Data fora do limite permitido'),
  issuingBody: yup.string().nullable().max(10, 'Campo limitado a 10 caracteres'),
  motherName: yup
    .string()
    .nullable()
    .matches(compositeNameRegex, {
      message: 'O nome da mãe deve ser composto',
      excludeEmptyString: true,
    })
    .max(120, 'Campo limitado a 120 caracteres'),
  gender: yup.object().shape({
    label: yup.string(),
    value: yup.string(),
  }),
})

export default schema
