import api from '@/infra/api'
import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { useEffect, useRef } from 'react'
import { EStatusCompra } from '../enums'

interface IApiReturn {
  mensagem: string[]
  sucesso: boolean
  valor: {
    boleto: {
      codigo: string
      url: string
    } | null
    pix: {
      copiaECola: string
      dataVencimento: string
    } | null
    status: EStatusCompra
  }
}

export const usePayment = ({
  purchaseId,
  refetchUntilPaid = false,
}: {
  purchaseId: string
  refetchUntilPaid?: boolean
}) => {
  const slipFetchTries = useRef(0)

  const { data, isLoading, refetch } = useQuery<AxiosResponse<IApiReturn>>({
    queryKey: ['slip', purchaseId],
    queryFn: () => api.get(`/rh-pedidos-bff/carrinho/confirmado/pagamento?idCompra=${purchaseId}`),
    //refetchInterval: 5000,
    cacheTime: 0,
    retry: 6,
    retryDelay: 5000,
    enabled: Boolean(purchaseId),
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    const rawData = data?.data?.valor
    const isGettingPaymentData =
      !rawData?.boleto && !rawData?.pix?.copiaECola && slipFetchTries.current < 6 && !isLoading
    if (isGettingPaymentData || (refetchUntilPaid && rawData?.status <= EStatusCompra.AguardandoPagamento)) {
      slipFetchTries.current++
      setTimeout(() => {
        refetch()
      }, 5000)
    }
  }, [data, isLoading, refetch, refetchUntilPaid])

  return {
    paymentData: data,
    refetch,
    isLoading,
  }
}
