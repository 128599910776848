import { useContext, useState } from 'react'
import { useAtomValue, useSetAtom } from 'jotai'
import { AccountPersistentStore, orderIdAtom } from 'main/store'
import _ from 'lodash'
import { Theme } from '@mui/material'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { ValuesSummary } from 'presentation/components/molecules'
import { Accordion, AccordionDetails, AccordionSummary, Divider, Icon, Typography } from 'presentation/components/atoms'
import { ORDER_STATUS } from 'main/utils/constants'
import { Box } from '@stationkim/front-ui'
import { OrderTrackingContext } from '../../../../orderTracking'

export const CartInfo = () => {
  const account = useAtomValue(AccountPersistentStore)
  const { cart, orderStatus } = useContext(OrderTrackingContext)
  const [open, setOpen] = useState(false)
  const isWaitingPayment = orderStatus < ORDER_STATUS.WAITING_FOR_PROCESSING

  if (!cart) return null

  return (
    <Accordion
      elevation={0}
      onChange={(_e, isOpen) => setOpen(isOpen)}
      sx={{
        backgroundColor: 'transparent',
        '&:before': {
          display: 'none',
        },
        marginTop: '16px',
      }}
      square
    >
      <AccordionSummary
        expandIcon={<Icon icon={faChevronDown} />}
        sx={(theme: Theme) => ({
          //
          padding: 0,
          '& svg': { color: theme.palette.primary.main },
        })}
      >
        <Box sx={{ display: 'flex', gap: '16px', flexDirection: 'column' }}>
          <Typography sx={(theme) => ({ color: theme.palette.primary.main })} variant='label3'>
            {open ? 'Fechar detalhes do carrinho' : 'Ver detalhes do carrinho'}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>
        <Typography sx={{ fontWeight: 500 }}>Detalhes do carrinho</Typography>
        <Divider sx={{ margin: '22px 0' }} />
        <ValuesSummary
          values={[
            cart.hasRecharge ? { label: 'Pedido de Recarga', value: cart.cartSummary.rechargeValue } : null,
            cart.hasFirstCopy ? { label: 'Pedido de Cartão - 1ª Via', value: cart.cartSummary.firstCopy } : null,
            cart.hasSecondCopy ? { label: 'Pedido de Cartão - 2ª Via', value: cart.cartSummary.secondCopy } : null,
            { label: 'Taxas administrativas', value: cart.cartSummary.administrativeFee, hideZero: true },
            { label: 'Taxas de processamento', value: cart.cartSummary.processingFee, hideZero: true },
            { label: 'Taxa de repasse', value: cart.cartSummary.transferFee, hideZero: true },
            { label: 'Retenção ISS', value: cart.cartSummary.issFee, isNegative: true, color: 'green' },
          ]}
        />
        <Divider sx={{ margin: '22px 0' }} />
        <ValuesSummary
          values={[
            { label: 'Total em pedidos:', value: cart.cartSummary.orderTotal, size: 'md' },
            {
              label: `${account?.resale?.digitalAccountName}:`,
              value: cart.cartSummary.walletUsage,
              isNegative: true,
              color: 'green',
              size: 'md',
              hideZero: true,
            },
            {
              label: 'Conta corrente total:',
              value: cart.cartSummary.ticketingBalance,
              isNegative: true,
              color: 'green',
              size: 'md',
              hideZero: true,
            },
            {
              label: isWaitingPayment ? 'Total a pagar:' : 'Total pago:',
              value: cart.cartSummary.totalPaid,
              isBold: true,
              size: 'md',
            },
          ]}
        />
      </AccordionDetails>
    </Accordion>
  )
}
