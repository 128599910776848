import { styled } from '@mui/material'

export const StyledDropzone = styled('div')(({ theme, ...props }) => {
  const getColor = (props) => {
    if (props.isDragReject || props.error) {
      return theme.palette.error.main
    }
    if (props.isDragAccept || props.hasFile) {
      return theme.palette.success.main
    }
    return theme.palette.grey[500]
  }

  return {
    height: 208,
    width: 512,
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    textAlign: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    color: theme.palette.grey[500],
    '& svg': {
      height: 60,
      width: 45,
      margin: '0 auto',
      color: getColor(props),
    },
    '& strong': {
      color: theme.palette.common.purple,
    },
    padding: 10,
    backgroundImage: `linear-gradient(to right, ${getColor(
      props,
    )} 50%, transparent 50%), linear-gradient(to right, ${getColor(
      props,
    )} 50%, transparent 50%), linear-gradient(to bottom, ${getColor(
      props,
    )} 50%, transparent 50%), linear-gradient(to bottom, ${getColor(props)} 50%, transparent 50%)`,
    backgroundPosition: 'left top, left bottom, left top, right top',
    backgroundRepeat: 'repeat-x, repeat-x, repeat-y, repeat-y',
    backgroundSize: '40px 1px, 40px 1px, 1px 40px, 1px 40px',
  }
})
