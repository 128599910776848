import { styled } from '@mui/material'
import { Box, Typography } from 'presentation/components/atoms'

export const SummaryContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  border: '1px solid ' + theme.palette.grey[200],
  borderRadius: '8px',
  padding: '16px',
}))

export const SummaryHeader = styled(Box)(({ theme }) => ({
  width: '100%',
  background: theme.palette.grey[100],
  borderRadius: '8px',
  padding: '16px',
  display: 'flex',
  flexWrap: 'wrap',
  marginBottom: '32px',
  gap: '32px',
  '& span': {
    whiteSpace: 'nowrap',
  },
  overflow: 'hidden',
}))

export const IconContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '40px',
  height: '40px',
  borderRadius: '50%',
  backgroundColor: theme.palette.grey[50],
  '& svg': {
    height: '26px',
    width: '26px',
  },
  '& svg > path': {
    height: '26px',
    width: '26px',
    fill: theme.palette.primary.main,
  },
}))

export const TotalValue = styled(Typography)(() => ({
  fontWeight: 700,
  marginLeft: 'auto',
  '&:before': {
    content: "'R$ '",
    fontSize: '0.625rem',
    fontWeight: 500,
  },
}))
