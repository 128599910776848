import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import api from '@/infra/api'

interface IUseOrderSlip {
  orderId?: string
  isCart?: boolean
  config?: any
}

export const useOrderSlip = ({ orderId, isCart = false, config = {} }: IUseOrderSlip) => {
  const { data, ...rest } = useQuery<AxiosResponse<any>>({
    queryKey: ['orderSlip', orderId, isCart],
    queryFn: ({ queryKey }) => api.get(`rh-bff/cards/orders/${queryKey[1]}/slipbasket`),
    refetchOnWindowFocus: false,
    ...config,
    retry: 10,
    retryDelay: 10000,
    meta: {
      silent: true,
    },
  })

  return {
    slip: data?.data,
    ...rest,
  }
}
