import React, { useCallback, useEffect, useState } from 'react'
import CompanyData from './companyData'
import Addresses from './addresses'
import { useDispatch, useSelector } from 'react-redux'
import {
  companyIdStore,
  companyDataStore,
  companyAddressesStore,
  companyContactsStore,
  companyDocumentsStore,
  companyDataClear,
} from '@/store/modules/empresa/actions'

import { Tabs, TabList, Tab, TabPanel, Spinner } from 'presentation/components'

import Contacts from './contacts'
import Documents from './documents'
import { BENEFIT_TYPES, FORM_MODE, NOTIFICATION_TYPE } from '@/utils/constants'
import { postCreateCompany, putUpdateCompany } from '@/services/companiesServices'
import { pushNotification } from '@/store/modules/notification/actions'
import errorHandler from '@/utils/errorHandler'
import successHandler from '@/utils/successHandler'
import { Paper } from 'presentation/components/atoms/paper'

import { CategorizedItemsSelection } from 'presentation/components/organisms/categorizedItemsSelection'
import useService from 'main/hooks/useService'
import { getBenefits } from '@/services/registerServices'
import { Box } from 'presentation/components/atoms/box'
import { getResaleSiteFromUrl } from '@/utils/functionUtils'
import { useLocation, useNavigate } from 'react-router-dom'

const defaultCategories = [
  { name: 'Vale Alimentação', id: BENEFIT_TYPES.SNACK },
  { name: 'Vale Refeição', id: BENEFIT_TYPES.FOOD },
  { name: 'Vale Combustível', id: BENEFIT_TYPES.FUEL },
  { name: 'VT Eletrônico', id: BENEFIT_TYPES.TRANSPORT },
  { name: 'Vale Transporte Papel', id: BENEFIT_TYPES.TRANSPORT_PAPER },
]

const Form = ({ done, location }) => {
  const navigate = useNavigate()
  const {
    state: { token },
  } = useLocation()
  const [tabIndex, setTabIndex] = useState(0)
  const [fullfieldTab, setFullfieldTab] = useState(0)
  const [operatorsCard, setOperatorsCard] = useState([])
  const [sendToApi, setSendToApi] = useState(false)
  const [loading, setLoading] = useState(false)
  const [mode] = useState(location?.state?.mode)
  const tabCount = 4
  const dispatch = useDispatch()
  const companyData = useSelector((state) => state.empresa)
  let isNavegable = false
  let formSubmit = null

  const saveApi = useCallback(
    async (payload) => {
      const { error, response } = await postCreateCompany(payload, token)
      if (response) {
        dispatch(pushNotification(successHandler('Salvo com sucesso')))
        return response
      } else dispatch(pushNotification(errorHandler(error.response)))
    },
    [dispatch],
  )

  const updateApi = useCallback(
    async (payload) => {
      const { error, response } = await putUpdateCompany(payload, token)
      if (response) {
        dispatch(pushNotification(successHandler('Salvo com sucesso')))
        return response
      } else dispatch(pushNotification(errorHandler(error.response)))
    },
    [dispatch],
  )

  const apiCall = useCallback(async () => {
    if (sendToApi) {
      setLoading(true)
      let response
      if (mode === FORM_MODE.ADD || !mode)
        response = await saveApi({
          ...companyData.data,
          OperatorsCard: operatorsCard.map((benefit) => ({
            OperatorId: benefit.operatorId,
            OperatorCardId: benefit.operatorCardId,
          })),
        })
      if (mode === FORM_MODE.EDIT) response = await updateApi(companyData.data)
      if (response && (!mode || mode === FORM_MODE.ADD)) {
        dispatch(companyIdStore({ id: response.data }))
        nextTab()
      }
      setSendToApi(false)
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [sendToApi])

  const [fetchBenefitsState, fetchBenefitsRequest] = useService(getBenefits, {
    onCompleted: (res) => {
      generateCategories(res.data)
      if (res.data.length === 1) {
        setOperatorsCard(res.data)
        setIsSelectingBenefits(false)
      }
    },
  })

  // eslint-disable-next-line
  useEffect(() => {
    apiCall()
  }, [sendToApi])
  // eslint-disable-next-line
  useEffect(() => {
    fetchBenefitsRequest(getResaleSiteFromUrl(), token)
  }, [])

  function changeForm(source) {
    formSubmit = source
  }

  function nextTab() {
    const newTab = tabIndex + 1 < tabCount ? tabIndex + 1 : 0
    setFullfieldTab((value) => value + 1)
    setTabIndex(newTab)
  }

  function prevTab() {
    const newTab = tabIndex - 1 >= 0 ? tabIndex - 1 : 0
    setTabIndex(newTab)
  }

  function handleTabNavigation(index) {
    formSubmit && formSubmit()
    if (!isNavegable || index > fullfieldTab + 1) return
    setFullfieldTab((value) => value + 1)
    setTabIndex(index)
  }

  function controlNavigation(isValid) {
    isNavegable = isValid
  }

  function returnToList() {
    navigate('/colaboradores')
  }

  const [categories, setCategories] = useState(defaultCategories)
  const [isSelectingBenefits, setIsSelectingBenefits] = useState(true)

  const generateCategories = (benefits) => {
    const categoriesInUse = defaultCategories.filter((category) => {
      return benefits.some((benefit) => String(benefit?.benefitType) === String(category.id))
    })
    setCategories(categoriesInUse)
  }
  return (
    <>
      {isSelectingBenefits ? (
        <Paper sx={{ padding: '64px 32px' }}>
          {fetchBenefitsState.loading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Spinner />
            </Box>
          ) : (
            <CategorizedItemsSelection
              title={'Selecionar benefícios'}
              searchHolder={'Buscar benefício...'}
              items={fetchBenefitsState.response?.data}
              categories={categories}
              getItemCategory={(category) => category.benefitType}
              getItemName={(item) => item.cardName}
              getItemId={(item) => item.operatorCardId}
              onClose={() => {
                dispatch(companyDataClear())
                navigate('/cadastro')
              }}
              onConfirm={() => {
                if (operatorsCard.length < 1) {
                  dispatch(
                    pushNotification({
                      type: NOTIFICATION_TYPE.WARNING,
                      content: 'Para continuar é obrigatório a seleção de pelo menos 1 benefício',
                    }),
                  )
                  return
                }
                setIsSelectingBenefits(false)
              }}
              value={operatorsCard}
              onChange={setOperatorsCard}
            />
          )}
        </Paper>
      ) : (
        <Tabs
          selectedTabClassName='is-selected'
          disabledTabClassName='is-disabled'
          selectedTabPanelClassName='is-selected'
          selectedIndex={tabIndex}
          onSelect={handleTabNavigation}
        >
          <TabList>
            <Tab disabled={tabIndex > 2}>Dados da Empresa</Tab>
            <Tab disabled={tabIndex > 2}>Endereço</Tab>
            <Tab disabled={tabIndex > 2}>Contato</Tab>
            <Tab disabled>Documentos</Tab>
          </TabList>

          <TabPanel>
            <CompanyData
              companyData={companyData.data}
              changeForm={changeForm}
              controlNavigation={controlNavigation}
              mode={mode}
              saveOnStore={(data) => {
                dispatch(companyDataStore(data))
              }}
              nextTab={nextTab}
              returnToList={returnToList}
            />
          </TabPanel>

          <TabPanel>
            <Addresses
              companyData={companyData.data}
              changeForm={changeForm}
              controlNavigation={controlNavigation}
              mode={mode}
              saveOnStore={(data) => {
                dispatch(companyAddressesStore(data))
              }}
              nextTab={nextTab}
              prevTab={prevTab}
              returnToList={returnToList}
            />
          </TabPanel>

          <TabPanel>
            <Contacts
              companyData={companyData.data}
              changeForm={changeForm}
              controlNavigation={controlNavigation}
              mode={mode}
              saveOnStore={(data) => {
                dispatch(companyContactsStore(data))
              }}
              nextTab={nextTab}
              prevTab={prevTab}
              sendToApi={setSendToApi}
              loading={loading}
            />
          </TabPanel>

          <TabPanel>
            <Documents
              companyData={companyData.data}
              changeForm={changeForm}
              controlNavigation={controlNavigation}
              mode={mode}
              saveOnStore={(data) => {
                dispatch(companyDocumentsStore(data))
              }}
              nextTab={nextTab}
              prevTab={prevTab}
              done={done}
            />
          </TabPanel>
        </Tabs>
      )}
    </>
  )
}

export default Form
