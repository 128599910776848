import { Row } from 'react-grid-system'
import styled from 'styled-components'

export const CustomRow = styled(Row)`
  & + & {
    margin-top: 32px;
  }
`

export const Label = styled.label`
  display: block;
  text-align: left;
  font-size: ${({ isTitle }) => (isTitle ? '1.25rem' : '1rem')};
  font-weight: ${({ isTitle }) => (isTitle ? '600' : '400')};
  margin: ${({ isTitle }) => (isTitle ? '16px 0 0' : '0 0 8px')};
  color: ${({ isTitle }) => (isTitle ? 'black' : '#9E9E9E')};
`
