import { TableCell, TableRow } from 'presentation/components/atoms'

import { TableHead as Head } from './styles'

export const TableHead = () => {
  return (
    <Head>
      <TableRow>
        <TableCell id='recharge_manual_thead_emplyees'>Colaboradores</TableCell>
        <TableCell id='recharge_manual_thead_benefits'>Benefícios associados</TableCell>
        <TableCell id='recharge_manual_thead_quantity_days'>Qtd Dias</TableCell>
        <TableCell id='recharge_manual_thead_recharge_value'>Valor Recarga</TableCell>
        <TableCell></TableCell>
      </TableRow>
    </Head>
  )
}
