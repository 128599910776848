import styled from 'styled-components'

export const Container = styled.div`
  margin: auto;
  margin-top: 80px;
  width: 70%;
`

export const InfoRow = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
  width: 100%;
  span + span {
    margin-left: 180px;
  }
`

export const HighlightedText = styled.p`
  font-size: 3.125rem;
  font-weight: var(--font-weight-bold);
  letter-spacing: 0.05rem;
  width: 100%;
  text-align: center;
`

export const Atention = styled.p`
  font-size: 1.125rem;
  font-weight: var(--font-weight-bold);
`

export const CommonText = styled.p`
  font-size: 1rem;
  font-weight: var(--font-weight-regular);
  letter-spacing: 0.01625rem;
  margin-top: 8px;
`

export const InputFile = styled.label`
  background-color: var(--color-primary-01);
  border-radius: 24px;
  color: var(--color-primary-05);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  margin: auto;
  margin-top: 145px;
  margin-bottom: 40px;
  letter-spacing: 0.01625rem;
  height: 44px;
  width: 370px;

  & > svg {
    margin-right: 8px;
  }

  &:hover {
    background-color: var(--color-primary-02);
  }
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const DownloadLink = styled.div`
  display: flex;
  color: var(--color-primary-01);
  font-weight: var(--font-weight-semi-bold);
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }

  > svg {
    margin-left: 8px;
  }

  a {
    color: var(--color-primary-01);
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: min(30%, 190px);

  button {
    width: 170px;
  }

  button + button {
    margin-left: 16px;
  }
`
