import { Box, styled } from '@mui/material'

export const StyledBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'inline-block',
  height: '24px',
  width: 'fit-content',
  '& svg': {
    cursor: 'pointer',
    position: 'absolute',
  },
  '& svg:firt-of-type': {
    top: 0,
  },
  '& svg:last-of-type': {
    top: 8,
  },
}))
