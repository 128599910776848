import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Moment from 'react-moment'
import { Content, Title, Spacing, Breadcrumb, Button, Panel, Spinner } from 'presentation/components'
import { Container, Resume, ResumeRow, ResumeTitle } from './styles'
import { formatMoney } from '@/utils/functionUtils'
import api from '@/infra/api'

const OrderPayment = () => {
  const { state: orderInfo } = useLocation()
  const [boletoLink, setBoletoLink] = useState(null)

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await api.get(`rh-bff/cards/orders/${orderInfo.id}/slip`)
        setBoletoLink(response.data)
      } catch (e) {
        return
      }
    }
    fetchData()
    // eslint-disable-next-line
  }, [])
  return (
    <Content>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Title style={{ display: 'inline' }}>Pagamento</Title>
      </div>
      <Spacing top='9px' bottom='9px'>
        <Breadcrumb />
      </Spacing>
      <Panel style={{ padding: '32px' }}>
        <Container>
          <Title.SubTitle>Pedido gerado!</Title.SubTitle>
          <p>Efetue o pagamento para finalizar seu pedido.</p>
          <p>{'As informações do seu pedido ficarão na opção "Cartões > Pedidos" do menu.'}</p>
          <Resume>
            <ResumeTitle>Pedido n° {orderInfo.code}</ResumeTitle>
            <ResumeRow>
              <p>Taxa administrativa</p>
              <p>R$ {formatMoney(orderInfo?.confirmation?.paymentFeeValue)}</p>
            </ResumeRow>
            <ResumeRow>
              <p>Data de recarga</p>
              {orderInfo.orderDate && <Moment format='DD/MM/YYYY'>{orderInfo.orderDate}</Moment>}
            </ResumeRow>
            {orderInfo?.confirmation?.paymentFeeValue
              ? ''
              : '' && (
                  <ResumeRow>
                    <p>Saldo em conta utilizado</p>
                    <p>
                      R$ {orderInfo.walletBalance >= 0 ? '' : '-'} {formatMoney(orderInfo.walletBalance)}
                    </p>
                  </ResumeRow>
                )}
            <ResumeRow>
              <p>Total a pagar</p>
              <p>R$ {formatMoney(orderInfo.totalToPay)}</p>
            </ResumeRow>
          </Resume>
          <a href={boletoLink} target='_blank' rel='noreferrer noopener'>
            <Button style={{ width: '370px' }}>
              {!boletoLink ? (
                <>
                  <Spinner /> Gerando Boleto
                </>
              ) : (
                'Baixar boleto'
              )}
            </Button>
          </a>
        </Container>
      </Panel>
    </Content>
  )
}

export default OrderPayment
