export const ALLOWED_LOGIN_ROLES = 'company'
export const FIVE_SECONDS = 5000
export const TEN_SECONDS = 10000
export const FIFTEEN_SECONDS = 15000
export const NULL_ID = '00000000-0000-0000-0000-000000000000'
export const FIVE_MB = 5000000
export const TEN_MB = 10000000
export const DATE_TO_IGNORE = '0001-01-01'
export const TEMP_OPERATOR_ID = '7e17d3a1-4f26-443f-b8ff-0a4a227e2758'
export const TEMP_CONTEXT_ID = '79638307-2d03-4780-8159-971b6a4c22a8'
export const FORM_MODE = {
  ADD: 'ADD',
  EDIT: 'EDIT',
  VIEW: 'VIEW',
}

export const NOTIFICATION_TYPE = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  1: 'success',
  2: 'error',
  3: 'warning',
}

export const BREAK_POINTS = {
  sm: '575px',
  md: '767px',
  lg: '991px',
  xl: '1199px',
  xxl: '1599px',
}

export const BENEFIT_TYPES = {
  1: 'Vale Transporte Eletrônico',
  2: 'Vale Refeição',
  3: 'Vale Alimentação',
  4: 'Vale Combustível',
  5: 'Vale Transporte Papel',
  TRANSPORT: 1,
  FOOD: 2,
  SNACK: 3,
  FUEL: 4,
  TRANSPORT_PAPER: 5,
}

export const CARD_TYPES = {
  1: 'Vale Transporte Eletrônico',
  TRANSPORT: 1,
}

export const CARD_STATUS = {
  1: 'Ativo',
  2: 'Inativo',
  3: 'Bloqueado',
  ACTIVE: 1,
  INACTIVE: 2,
  BLOQUED: 3,
}

export const BINDED_BENEFIT_STATUS = {
  1: 'Ativo',
  ACTIVE: 1,
  2: 'Inativo',
  INACTIVE: 2,
  3: 'Bloqueado',
  BLOCKED: 3,
  4: 'Aguardando sincronismo',
  WAITING_SYNCHRONISM: 4,
  5: 'Erro de sincronismo',
  SYNCHRONISM_ERROR: 5,
}

export const DEPARTMENTS_STATUS = {
  INACTIVE: 0,
  ACTIVE: 1,
  WAITING_SYNCHRONISM: 2,
  SYNCHRONISM_ERROR: 3,
  0: 'Inativo',
  1: 'Ativo',
  2: 'Aguardando sincronismo',
  3: 'Erro de sincronismo',
}

export const DEPARTMENTS_SITUATION = {
  ACTIVE: 0,
  INACTIVE: 1,
  DELETED: 2,
  0: 'Ativo',
  1: 'Inativo',
  2: 'Apagado',
}

export const EMPLOYEE_STATUS = {
  0: 'Inativo',
  INACTIVE: 0,
  1: 'Ativo',
  ACTIVE: 1,
}
export const EMPLOYEE_OPERATOR_INTEGRATION = {
  OK: 1,
  1: 'OK',
  RUNNING: 2,
  2: 'Aguardando sincronismo',
  ERROR: 3,
  3: 'Erro de sincronismo',
}

export const ORDER_TYPES = {
  1: 'Primeira Via',
  2: 'Segunda Via',
  3: 'Recarga',
  5: 'Broker',
  FIRSTCOPY: 1,
  SECONDCOPY: 2,
  RECHARGE: 3,
  BROKER: 5,
}

export const ORDER_STATUS = {
  0: 'Rascunho',
  1: 'Efetivando',
  3: 'Aguardando Pagamento',
  4: 'Aguardando Processamento',
  5: 'Em Processamento',
  6: 'Processamento operadora',
  10: 'Disponível para Retirada',
  11: 'Enviando',
  15: 'Concluído',
  16: 'Cancelado',
  17: 'Cancelando',
  DRAFT: 0,
  EFFECTING: 1,
  WAITING_PAYMENT: 3,
  WAITING_FOR_PROCESSING: 4,
  PROCESSING: 5,
  RUNNING: 6,
  AVAILABLE_PICKUP: 10,
  DELIVERING: 11,
  FINISHED: 15,
  CANCELED: 16,
  CANCELING: 17,
}

export const ORDER_ITEMS_STATUS = {
  DRAFT: 0,
  RUNNING: 0,
  CALCULATED_BALANCE_PROJECTION: 2,
  CREATED_CARD: 4,
  FINISHED: 10,
  CANCELED: 11,
  CARD_BLOCKED_AND_REFUNDED: 12,
  CARD_BLOCKED: 13,
}

export const ORDER_PAYMENT_TYPE = {
  1: 'Boleto',
  3: 'Cartão de Crédito',
  6: 'Boleto a Prazo',
  10: 'Conta Corrente',
  14: 'Pix',
  17: 'Gratuito',
  18: 'Carteira digital',
  SLIP: 1,
  DEBITCARD: 2,
  CREDITCARD: 3,
  TRANSFER: 4,
  DEPOSIT: 5,
  TICKETING_BALANCE: 10,
  PIX: 14,
  FREE: 17,
  ACCOUNT_BALANCE: 18,
  TERM_BILL: 6,
}

export const ORDER_METHOD = {
  0: 'Manual',
  1: 'Importação',
  MANUAL: 0,
  IMPORT: 1,
}

export const PRODUCT_TYPES = {
  RATES: 1,
}

export const REPORTS_CATEGORIES = {
  ORDERS: 'Pedidos',
  CARDS: 'Cartões',
  //EMPLOYESS: "Colaboradores",
  //BENEFIT: "Benefícios",
  BALANCE: 'Saldo',
}

export const FILE_CONTEXT = {
  NONE: 0,
  COMPANY: 1,
  RESALE: 2,
  USER: 3,
}

export const CARD_USAGE = {
  NONE: 0,
  DEALER: 1,
  BROKER: 2,
}

export const FILE_SUBCONTEXT = {
  NONE: 0,
  TERM: 1,
  IMAGE: 2,
  PAYMENT: 3,
  REPORT: 4,
}

export const FILE_KIND = {
  NONE: 0,
  LOGO_LARGE: 1,
  LOGO_LARGE_DARK: 2,
  LOGO_SMALL: 3,
  LOGO_SMALL_DARK: 4,
  FAVICON: 5,
  AVATAR: 6,
  TERM: 7,
  PAYMENT: 8,
  PDFREPORT: 9,
  XLSREPORT: 10,
  CSVREPORT: 11,
  COMPANY_DOCUMENT: 12,
}

export const BROKER_OPTIONS = {
  OTIMO: 1,
}
