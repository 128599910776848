import _ from 'lodash'
import { Content } from '@/presentation/components'
import { Image } from './styles'
import Img from '@/presentation/assets/images/home.png'
import { PageHeader, Panel } from '@/presentation/components/molecules'
import { Box } from '@/presentation/components/atoms'
import { useAtom, useAtomValue } from 'jotai'
import { AccountPersistentStore, GlobalThemePersistentStore } from '@/main/store'
import { useEffect } from 'react'
import { defaultTheme } from '@/config/themes/defaultTheme'
import banner from './banner.png'
import { decode } from 'jsonwebtoken'
const Home = () => {
  const account = useAtomValue(AccountPersistentStore)
  const [theme, setTheme] = useAtom(GlobalThemePersistentStore)
  useEffect(() => {
    if (!account.resale?.theme) return
    if (Object.keys(JSON.parse(account.resale.theme)).length === 0) {
      setTheme(defaultTheme)
      return
    }
    setTheme(_.merge(theme, JSON.parse(account.resale.theme)))
  }, [])
  // todo remove this as soon as possible
  const resaleId = decode(account.auth.accessToken)?.resaleId
  const isTargetResale = resaleId === '79638307-2d03-4780-8159-971b6a4c22a8'
  const isMessageValid = new Date() < new Date('2024/11/16')
  return (
    <>
      {isMessageValid && isTargetResale ? (
        <Panel sx={{ minHeight: '50vh', width: '720px' }}>
          <PageHeader pageTitle='Aviso' />
          <Box
            sx={{
              padding: '32px',
              backgroundImage: `url(${banner})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              height: '800px',
              margin: 'auto',
            }}
          />
        </Panel>
      ) : (
        <Content>
          <Image src={Img} />
        </Content>
      )}
    </>
  )
}

export default Home
