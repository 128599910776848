import React, { useEffect, useState } from 'react'
import { Container, Col, Row, useScreenClass } from 'react-grid-system'
import DepartmentsTable from './components/departmentsTable'
import DepartmentDetails from './components/departmentDetails'
import ModalNew from './components/new'
import ModalDelete from './components/delete'
import Associate from './components/associate'
import paginatorMethods from '@/utils/paginatorMethods'
import { useDispatch } from 'react-redux'
import { getDepartmentsRequest } from '@/store/modules/departments/actions'
import { usePaginationOld } from 'main/hooks/usePaginationOld'
import {
  Background,
  ButtonV2,
  Content,
  Panel,
  Pagination,
  Spinner,
  PageHeader,
  HorizontalScrollWrapper,
} from 'presentation/components'
import { ButtonWrapper, FloatingInfo, LoadingWrapper, PaginatorWrapper } from './styles'
import { useDepartments } from './hooks/useDepartments'
import { useNotification } from 'main/hooks/useNotification'

const Departments = () => {
  const screenClass = useScreenClass()
  const [isWaitingResponse, setIsWaitingResponse] = useState(false)
  const [details, setDetails] = useState(false)
  const [associate, setAssociate] = useState(false)
  const [insert, setInsert] = useState(false)
  const [remove, setRemove] = useState(false)

  const [id, setId] = useState(null)

  const { additionalInfo, departments, employesWithoutDepartmentCount, isLoading, failure } = useDepartments()

  const { paginator, setPaginator } = usePaginationOld({ query: {} })
  const { errorNotification } = useNotification()

  const dispatch = useDispatch()

  useEffect(() => {
    paginator.doRequest && dispatch(getDepartmentsRequest(paginatorMethods.buildQuery(paginator)))
    // eslint-disable-next-line
  }, [paginator])

  useEffect(() => {
    setPaginator(
      paginatorMethods.updatePaginator(paginator, {
        page: departments?.pageNumber,
        totalItens: departments?.totalItens,
        totalPages: departments?.totalPages,
      }),
    )
    // eslint-disable-next-line
  }, [departments])

  useEffect(() => {
    if (failure !== null) {
      const errorMessage = failure
      errorNotification(errorMessage)
    }
  }, [failure, dispatch, errorNotification])

  const detailsDep = (id) => {
    setId(id)
    setDetails(true)
  }

  const associateEmployees = (id) => {
    setId(id)
    setAssociate(true)
  }

  return (
    <>
      <Content>
        <Panel noPadding>
          <Container fluid style={{ padding: 0 }}>
            <Row align='center' justify='between' nogutter>
              <Col md={4}>
                <PageHeader>Departamentos</PageHeader>
              </Col>
              <Col md={5} lg={5} xl={3}>
                <ButtonWrapper>
                  <ButtonV2 icon='grupo' onClick={() => setInsert(true)}>
                    Adicionar Departamento
                  </ButtonV2>
                </ButtonWrapper>
              </Col>
            </Row>
            {isWaitingResponse || isLoading ? (
              <LoadingWrapper>
                <Spinner />
              </LoadingWrapper>
            ) : (
              <>
                <Row justify={['sm', 'md', 'lg'].includes(screenClass) ? 'center' : 'start'}>
                  <Col sm='content'>
                    <FloatingInfo color='#cfffb9 '>
                      <span style={{ color: '#2C2D5C' }}>
                        Colaboradores <br />
                        sem departamento
                      </span>
                      <span style={{ color: '#2C2D5C' }}>{employesWithoutDepartmentCount}</span>
                    </FloatingInfo>
                  </Col>
                  <Col sm='content'>
                    <FloatingInfo color='#18A4E1'>
                      <span>Total de departamentos</span>
                      <span>{additionalInfo?.departmentsCount}</span>
                    </FloatingInfo>
                  </Col>
                </Row>
                <Row sm={12}>
                  <Col>
                    <HorizontalScrollWrapper padding={true} paddingSize='32px 54px'>
                      <DepartmentsTable
                        setDetails={detailsDep}
                        setRemove={setRemove}
                        setAssociate={associateEmployees}
                        setId={setId}
                      />
                    </HorizontalScrollWrapper>
                  </Col>
                </Row>
                <Row justify='between' align='center' nogutter>
                  <Col>
                    <PaginatorWrapper>
                      <Pagination.Footer paginator={paginator} />
                      <Pagination
                        paginator={paginator}
                        setPage={(wantedPage) => setPaginator(paginatorMethods.setPage(paginator, wantedPage))}
                      />
                    </PaginatorWrapper>
                  </Col>
                </Row>
              </>
            )}
          </Container>
        </Panel>
      </Content>
      {associate && (
        <Associate
          open={associate}
          close={() => {
            setAssociate(false)
          }}
          departmentId={id}
          updateDepartmentList={() => setPaginator(paginatorMethods.refresh(paginator))}
        />
      )}
      {details && (
        <DepartmentDetails
          open={details}
          close={() => {
            setDetails(false)
            setPaginator(paginatorMethods.refresh(paginator))
          }}
          id={id}
          setIsWaitingResponse={setIsWaitingResponse}
        />
      )}
      {insert && (
        <ModalNew
          open={insert}
          close={() => setInsert(false)}
          refresh={() => dispatch(getDepartmentsRequest(paginatorMethods.buildQuery(paginator)))}
          setIsWaitingResponse={setIsWaitingResponse}
        />
      )}
      {remove && (
        <ModalDelete
          open={remove}
          close={() => setRemove(false)}
          refresh={() => dispatch(getDepartmentsRequest(paginatorMethods.buildQuery(paginator)))}
          id={id}
          paginator={paginator}
          setIsWaitingResponse={setIsWaitingResponse}
        />
      )}
    </>
  )
}

export default Departments
