import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { useAtomValue } from 'jotai'
import { orderIdAtom } from 'main/store'
import { organizeData } from './orderHooksSharedItems'
import api from '@/infra/api'

export const useOrderDetail = (params: any = {}) => {
  const { config = {} } = params
  const orderId = useAtomValue(orderIdAtom) //'46ec9e69-55a9-434e-8983-ad0afa13b420' //
  const { data, ...rest } = useQuery<AxiosResponse<any>>({
    queryKey: ['orderDetail', orderId],
    queryFn: () => orderId !== '' && api.get(`rh-bff/recharge/orderdetail/${orderId}`),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    ...config,
  })

  return {
    order: organizeData({ data }),
    ...rest,
  }
}
