import { faX } from '@fortawesome/free-solid-svg-icons'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  MenuItem,
} from 'presentation/components/atoms'
import { MaskedTextField, Select } from 'presentation/components/molecules'
import { TextField } from 'presentation/components/molecules/textField/textField'
import { Controller } from 'react-hook-form'
import { BENEFIT_TYPES, BINDED_BENEFIT_STATUS, CARD_USAGE } from 'utils/constants'
import { Multiselect } from 'presentation/components/organisms/multiselect'
import { rules } from '../rules'
import { BindCardToEmployeeMode, defaultValue } from '..'
import { Switch } from 'presentation/components/atoms/switch'
import { useEffect } from 'react'

export const CardSelectionDialog = ({
  form,
  employeeId,
  benefits,
  cardsFeesData,
  requestFees,
  open,
  onClose,
  onSave,
  isLoading,
  swapDialogs,
  mode,
  setEdit,
}) => {
  const benefitsUnfiltered = benefits || []
  const benefitsTypes = benefitsUnfiltered.filter((type) => type.operators.length > 0) || []

  const [selectedBenefit, selectedOperator, selectedCard] = form.watch(['benefitType', 'operator', 'operatorCard'])

  const benefitInUse = benefitsTypes?.[selectedBenefit]
  const operatorInUse = benefitInUse?.operators?.find((operator) => operator.id === selectedOperator)
  const cardInUse = operatorInUse?.operatorCards?.find((operatorCard) => operatorCard.operatorCardId === selectedCard)
  const isCardInUseDealer = cardInUse?.processType === CARD_USAGE.DEALER
  const bindedCardNumber = cardsFeesData?.cardNumber
  const operatorRegistration = cardsFeesData?.operatorRegistration
  const isTransportBenefit =
    benefitInUse?.benefitType === BENEFIT_TYPES.TRANSPORT || benefitInUse?.benefitType === BENEFIT_TYPES.TRANSPORT_PAPER
  const isEditing = mode === BindCardToEmployeeMode.edit

  const onSubmit = (data) => {
    if (isTransportBenefit) {
      const defaultQuantity = 2
      const cardsSelectedsFees = data.productsIds.map((productId) => {
        const product = data.products.find((product) => {
          if (Array.isArray(product.productId)) return product.productId[0] === productId
          return product.productId === productId
        })
        const productQuantity = product?.quantity || defaultQuantity
        return {
          productId: [productId],
          quantity: productQuantity,
          dailyValue: String(
            productQuantity * cardsFeesData?.dailyRates.find((fee) => fee.productId === productId)?.value,
          ),
        }
      })
      form.setValue('products', cardsSelectedsFees, { shouldValidate: true })
      swapDialogs()
    } else {
      onSave(data)
    }
  }
  const customFormReset = (skip = []) => {
    const resetTo = { ...defaultValue }
    skip.forEach((item) => {
      delete resetTo[item]
    })
    Object.keys(resetTo).forEach((key) => form.resetField(key))
  }

  useEffect(() => {
    setEdit(operatorInUse?.operatorCards?.name)
  }, [operatorInUse])

  return (
    <Dialog open={open} maxWidth='md' onClose={onClose}>
      <DialogTitle id='employeeBenefitDialog_title'>
        {isEditing ? (
          <>
            Editar cartão
            {isLoading ? null : (
              <Controller
                control={form.control}
                name='status'
                render={({ field: { value, onChange } }) => (
                  <Switch
                    sx={{ marginLeft: 'auto' }}
                    color='success'
                    checked={value === BINDED_BENEFIT_STATUS.ACTIVE}
                    onChange={(e) =>
                      onChange(e.target.checked ? BINDED_BENEFIT_STATUS.ACTIVE : BINDED_BENEFIT_STATUS.INACTIVE)
                    }
                  />
                )}
              />
            )}
          </>
        ) : (
          'Vincular Benefício'
        )}
        <Icon icon={faX} id='employeeBenefitDialog_close' onClick={onClose} sx={{ marginLeft: '16px' }} />
      </DialogTitle>
      {isLoading ? (
        <CircularProgress
          sx={{
            margin: '32px auto',
          }}
        />
      ) : (
        <>
          <DialogContent>
            <form
              id='cardToBindSelectionForm'
              onSubmit={form.handleSubmit(onSubmit)}
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '32px',
                margin: '32px 0',
              }}
            >
              <Grid container columnSpacing='16px' rowSpacing='16px'>
                <Grid item xs={12} sm={6}>
                  <Controller
                    control={form.control}
                    name='benefitType'
                    rules={rules.benefitType}
                    render={({ field: { value, onChange }, fieldState: { error, isDirty } }) => (
                      <Select
                        label='Benefício'
                        id='employeeBenefitDialog_benefitType'
                        value={value}
                        onChange={(e) => {
                          customFormReset(['benefitType'])
                          onChange(e)
                        }}
                        fullWidth
                        success={isDirty && !error}
                        error={!!error}
                        helperText={error?.message}
                        blocked={isEditing}
                      >
                        {benefitsTypes.map((benefit, index) => (
                          <MenuItem
                            id={'employeeBenefitDialog_benefitType_option_' + (index + 1)}
                            key={index + '_benefit_option'}
                            value={index}
                          >
                            {benefit.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    control={form.control}
                    name='operator'
                    rules={rules.operator}
                    render={({ field: { value, onChange }, fieldState: { error, isDirty } }) => (
                      <Select
                        label='Operadora'
                        value={value}
                        id='employeeBenefitDialog_operator'
                        onChange={(e) => {
                          customFormReset(['benefitType', 'operator'])
                          onChange(e)
                        }}
                        fullWidth
                        success={isDirty && !error}
                        error={!!error}
                        helperText={error?.message}
                        blocked={isEditing}
                      >
                        {benefitInUse?.operators
                          ? benefitInUse.operators.map((operator, index) => (
                              <MenuItem
                                id={'employeeBenefitDialog_operator_option_' + (index + 1)}
                                key={index + '_operator_option'}
                                value={operator.id}
                              >
                                {operator.tradeName}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Controller
                    control={form.control}
                    name='operatorCard'
                    rules={rules.operatorCard}
                    render={({ field: { value, onChange }, fieldState: { error, isDirty } }) => {
                      return (
                        <Select
                          label='Nome Cartão'
                          value={value}
                          id='employeeBenefitDialog_operatorCard'
                          onChange={(e) => {
                            customFormReset(['benefitType', 'operator', 'operatorCard'])
                            onChange(e)
                            requestFees(employeeId, e.target.value)
                          }}
                          fullWidth
                          success={isDirty && !error}
                          error={!!error}
                          helperText={error?.message}
                          blocked={isEditing}
                        >
                          {operatorInUse?.operatorCards
                            ? operatorInUse.operatorCards.map((operatorCard, index) => (
                                <MenuItem
                                  id={'employeeBenefitDialog_operatorCard_option_' + (index + 1)}
                                  key={index + '_operator_card_option'}
                                  value={operatorCard.operatorCardId}
                                >
                                  {operatorCard.name}
                                </MenuItem>
                              ))
                            : null}
                        </Select>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    control={form.control}
                    name='operatorRegistration'
                    rules={rules.operatorRegistration(!isCardInUseDealer)}
                    render={({ field: { value, onChange }, fieldState: { error, isDirty } }) => {
                      const checkIfIsDisabled = () => {
                        if (!cardInUse || !cardsFeesData) return true
                        return isCardInUseDealer || !selectedOperator
                      }
                      return (
                        <TextField
                          value={value}
                          onChange={(e) => onChange(e.target.value.replaceAll(/[^a-z0-9ç]/gi, ''))}
                          success={isDirty && !error}
                          id='employeeBenefitDialog_operatorRegistration'
                          error={error}
                          label={`Matrícula ${operatorInUse ? operatorInUse.tradeName : ''}`}
                          placeholder='XXXXX'
                          blocked={checkIfIsDisabled()}
                          helperText={error?.message}
                          maxLength='15'
                        />
                      )
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Controller
                    control={form.control}
                    name='cardNumber'
                    render={({ field: { value, onChange }, fieldState: { error, isDirty } }) => {
                      const checkIfIsDisabled = () => {
                        if (!cardsFeesData) return true
                        const hasValue = Boolean(cardsFeesData.cardNumber)
                        return (
                          hasValue ||
                          !Boolean(operatorRegistration) ||
                          !Boolean(cardInUse) ||
                          cardInUse.cards.length === 0
                        )
                      }
                      if (isCardInUseDealer && !bindedCardNumber) {
                        return (
                          <Select
                            label='Número do cartão'
                            value={value}
                            onChange={onChange}
                            fullWidth
                            success={isDirty && !error}
                            error={error}
                            placeholder={cardInUse.cards.length === 0 ? 'Sem cartões' : 'Selecione'}
                            helperText={error?.message}
                            blocked={checkIfIsDisabled()}
                            id='employeeBenefitDialog_cardNumber'
                          >
                            {cardInUse
                              ? cardInUse.cards.map((availableCards, index) => (
                                  <MenuItem
                                    id={'employeeBenefitDialog_cardNumber_option_' + (index + 1)}
                                    key={index + '_operator_card_option'}
                                    value={availableCards.number}
                                  >
                                    {availableCards.number}
                                  </MenuItem>
                                ))
                              : null}
                          </Select>
                        )
                      } else {
                        return (
                          <TextField
                            label='Número do cartão'
                            placeholder='00000000'
                            success={isDirty && !error}
                            error={error}
                            helperText={error?.message}
                            id='employeeBenefitDialog_cardNumber'
                            value={value}
                            blocked={!Boolean(cardInUse) || (isEditing && isCardInUseDealer)}
                            onChange={(e) => onChange(e.target.value.replaceAll(/[^0-9]/g, ''))}
                            disabled={isCardInUseDealer}
                            maxLength={25}
                          />
                        )
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  {benefitInUse?.benefitType === BENEFIT_TYPES.TRANSPORT ||
                  benefitInUse?.benefitType === BENEFIT_TYPES.TRANSPORT_PAPER ? (
                    <Controller
                      control={form.control}
                      name='productsIds'
                      rules={rules.productsIds}
                      render={({ field: { value, onChange }, fieldState: { error, isDirty } }) => (
                        <Multiselect
                          label='Tarifas diárias'
                          id='employeeBenefitDialog_productsIds'
                          options={cardsFeesData?.dailyRates}
                          placeholder='Selecione'
                          getOptionLabel={(option) => option?.description}
                          getOptionValue={(option) => option?.productId}
                          autoWidth={false}
                          value={value}
                          onChange={(value) => onChange(value)}
                          fullWidth={true}
                          success={isDirty && !error}
                          error={error}
                          helperText={error?.message}
                          variant='outlined'
                          blocked={!cardInUse}
                        />
                      )}
                    />
                  ) : (
                    <Controller
                      control={form.control}
                      name='dailyValue'
                      rules={rules.dailyValue}
                      render={({ field: { value, onChange }, fieldState: { error } }) => (
                        <MaskedTextField
                          radix=','
                          max={1000}
                          startAdornment='R$'
                          label='Valor diário'
                          placeholder='00,00'
                          blocked={!cardInUse}
                          error={error}
                          helperText={error?.message}
                          value={value}
                          onChange={(value, mask) => {
                            onChange(value)
                          }}
                        />
                      )}
                    />
                  )}
                </Grid>
              </Grid>
            </form>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'space-between' }}>
            <Button id='employeeBenefitDialog_button_goBack' onClick={onClose}>
              Voltar
            </Button>
            <Button
              id='employeeBenefitDialog_button_save'
              variant='contained'
              sx={{ width: '248px' }}
              type='submit'
              form='cardToBindSelectionForm'
            >
              Salvar
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}
