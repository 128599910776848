import { AccountPersistentStore } from '@/main/store'
import { Typography } from '@mui/material'
import { useAtomValue } from 'jotai'

export const AvailablePickUp = ({ address }: { address?: string }) => {
  const account = useAtomValue(AccountPersistentStore)
  const isMotiva = String(account?.resale?.site).toLowerCase() === 'motiva'
  return (
    <>
      <Typography variant='h3' sx={{ fontWeight: 500 }}>
        {isMotiva ? 'Cartão chegará em seu endereço.' : 'Seu pedido já está disponível para a retirada:'}
      </Typography>

      {isMotiva ? (
        <Typography variant='h5' sx={{ fontWeight: 400, marginTop: '32px', display: 'inline-block' }}>
          <>
            Gentileza manter seu endereço atualizado via <b>FALE CONOSCO</b> pelo chat.
          </>
        </Typography>
      ) : (
        <>
          <Typography variant='h5' sx={{ fontWeight: 400, marginTop: '32px', display: 'inline-block' }}>
            Retire seu pedido no local:
          </Typography>
          <Typography variant='h5' sx={{ display: 'inline-block', marginLeft: '8px' }}>
            {address ?? 'Endereço padrão'}.
          </Typography>
        </>
      )}
    </>
  )
}
