import { useQuery } from '@tanstack/react-query'

import api from '@/infra/api'
import { AxiosResponse } from 'axios'
import { IStandardResponse } from '@/main/interfaces'
import { ETipoPedido } from '@/main/enums'

interface IPurchaseOrderSummary {
  id: number
  codigo: number
  status: number
  tipo: number
  valorTotal: number
  nomeOperadora: string
  qtdItensUnicos: number
}

interface ApiResponse extends IStandardResponse<Array<IPurchaseOrderSummary>> {}

interface UsePurchaseOrderProps {
  purchaseId: string | number
}

interface UsePurchaseOrderReturn {
  purchaseOrders: Array<{
    type: ETipoPedido
    orderNumber: string | number
    value: number
    itemsOnOrder: number
  }>
  isLoading: boolean
}

export const usePurchaseOrder = ({ purchaseId }: UsePurchaseOrderProps): UsePurchaseOrderReturn => {
  const { data, ...rest } = useQuery<AxiosResponse<ApiResponse>>({
    queryKey: ['purchaseOrdersToCancel', purchaseId],
    queryFn: () => api.get(`rh-pedidos-bff/pedido/detalhamento/pedidos?idCompra=${purchaseId}`),
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: Boolean(purchaseId),
  })

  const organizeData = (): UsePurchaseOrderReturn['purchaseOrders'] => {
    const rawData = data?.data?.valor
    if (!rawData) return undefined
    return rawData.map((order) => ({
      itemsOnOrder: order.qtdItensUnicos,
      orderNumber: order.codigo,
      type: order.tipo,
      value: order.valorTotal,
    }))
  }

  return {
    purchaseOrders: organizeData(),
    isLoading: rest.isLoading,
  }
}
