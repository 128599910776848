import api from '@/infra/api'
import { PRODUCT_TYPES } from '@/utils/constants'

export const checkIfCpfIsInUse = async (cpf) => {
  try {
    return await api.get(`/employees-service/${cpf}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  } catch (e) {
    return
  }
}

export const getRates = async () => {
  try {
    const response = await api.get(`/products-service/?ProductType=${PRODUCT_TYPES.RATES}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return response.data
  } catch (e) {
    return
  }
}

export const getAddresses = async (companyId) => {
  try {
    const response = await api.get(`/companies-service/${companyId}/Addresses`, {
      headers: { 'Content-Type': 'application/json' },
    })
    return response.data
  } catch (e) {
    return
  }
}

export const activeOrInactiveEmployee = async (data, id) => {
  try {
    return await api.put(`/employees-service/${id}`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  } catch (e) {
    return
  }
}

export const disassociateCard = async (cardId) => {
  try {
    await api.patch(`/cards/${cardId}/disassociate`)
  } catch (e) {
    return
  }
}
