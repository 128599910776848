import produce from 'immer'

const INITIAL_STATE = {
  data: null,
  loading: false,
  success: null,
  failure: null,
}

export default function users(state = INITIAL_STATE, action) {
  const { type, payload } = action

  return produce(state, (draft) => {
    switch (type) {
      case '@users/GETUSERS_REQUEST': {
        draft.data = null
        draft.loading = true
        break
      }

      case '@users/GETUSERS_SUCCESS': {
        draft.loading = false
        draft.data = payload
        draft.failure = null
        break
      }

      case '@users/GETUSERS_FAILURE': {
        draft.loading = false
        draft.data = null
        draft.failure = payload.data
        break
      }

      default:
    }
  })
}
