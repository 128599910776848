import { Button, ErrorMessage, ErrorWrapper, Input, ModalV2, Spinner, Title } from 'presentation/components'
import { ORDER_PAYMENT_TYPE } from '@/utils/constants'
import React, { useState } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { Description, Label, LoadingContainer } from './styles'

const GenerateSlipModal = ({ loading, onConfirm, ...props }) => {
  const [value, setValue] = useState(null)
  const [date, setDate] = useState(null)
  const valueError = value < 1 && value !== null
  const isInvalidDate = !(date instanceof Date && !isNaN(date))
  const isButtonDisabled = valueError || date === null || value === null || isInvalidDate
  const today = new Date()
  const ONE_DAY_IN_HOURS = 24
  const FIFTEEN_DAYS_IN_HOURS = 24 * 15

  const brlStringToDecimal = (string) => {
    const parsedString = string.replaceAll(' ', '').replaceAll('.', '').replace(',', '.').replace('R$', '')
    return Number(parsedString)
  }

  return (
    <ModalV2 optionText='Gerar Boleto' buttonPositon='end' {...props}>
      {loading ? (
        <LoadingContainer>
          <Spinner />
        </LoadingContainer>
      ) : (
        <>
          <Title.SubTitle>Depositar na Conta Digital</Title.SubTitle>
          <Container fluid style={{ padding: 0 }}>
            <Row>
              <Col sm={3}>
                <ErrorWrapper error={valueError}>
                  <Label>Valor</Label>
                  <Input.Decimal
                    prefix={'R$ '}
                    placeholder='R$ 0,00'
                    value={value}
                    onChange={(e) => {
                      if (e.target.value === '') setValue(null)
                      else setValue(brlStringToDecimal(e.target.value))
                    }}
                    allowNegative={false}
                  />
                  {valueError && <ErrorMessage>O valor mínimo é de R$ 1,00</ErrorMessage>}
                </ErrorWrapper>
              </Col>
              <Col sm={7}>
                <Label>Data de Vencimento</Label>
                <Input.Date
                  placeholder='00/00/0000'
                  minDate={new Date(today.setHours(today.getHours() + ONE_DAY_IN_HOURS))}
                  maxDate={new Date(today.setHours(today.getHours() + FIFTEEN_DAYS_IN_HOURS))}
                  value={date}
                  onChange={setDate}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Description>
                  *Os boletos que não forem pagos serão cancelados após o vencimento. Dessa forma, o serviço não dispõe
                  de juros ou multa por atrasos no pagamento.
                </Description>
              </Col>
            </Row>
            <Row justify='end'>
              <Col sm={4}>
                <Button
                  disabled={isButtonDisabled}
                  onClick={() =>
                    onConfirm({
                      dueDate: date.toISOString().split('T')[0],
                      amount: value,
                      paymentType: ORDER_PAYMENT_TYPE.SLIP,
                    })
                  }
                >
                  Gerar Boleto
                </Button>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </ModalV2>
  )
}

export default GenerateSlipModal
