import { BENEFIT_TYPES, ORDER_STATUS } from './constants'

export const states = [
  { label: 'AC', value: 'AC' },
  { label: 'AL', value: 'AL' },
  { label: 'AM', value: 'AM' },
  { label: 'AP', value: 'AP' },
  { label: 'BA', value: 'BA' },
  { label: 'CE', value: 'CE' },
  { label: 'DF', value: 'DF' },
  { label: 'ES', value: 'ES' },
  { label: 'GO', value: 'GO' },
  { label: 'MA', value: 'MA' },
  { label: 'MT', value: 'MT' },
  { label: 'MS', value: 'MS' },
  { label: 'MG', value: 'MG' },
  { label: 'PA', value: 'PA' },
  { label: 'PB', value: 'PB' },
  { label: 'PR', value: 'PR' },
  { label: 'PE', value: 'PE' },
  { label: 'PI', value: 'PI' },
  { label: 'RJ', value: 'RJ' },
  { label: 'RN', value: 'RN' },
  { label: 'RO', value: 'RO' },
  { label: 'RS', value: 'RS' },
  { label: 'RR', value: 'RR' },
  { label: 'SC', value: 'SC' },
  { label: 'SE', value: 'SE' },
  { label: 'SP', value: 'SP' },
  { label: 'TO', value: 'TO' },
]

export const branches = [
  { label: 'Atendimento ao Cliente', value: '47' },
  { label: 'Biologia', value: '43' },
  { label: 'Compras', value: '41' },
  { label: 'Contabilidade', value: '42' },
  { label: 'Informática', value: '40' },
  { label: 'Sociologia', value: '44' },
  { label: 'Televendas', value: '45' },
  { label: 'Vendas', value: '39' },
]

export const numberOfBeneficiaries = [
  { label: 'Individual/Autônomo', value: 1 },
  { label: '2 a 10 colaboradores', value: 2 },
  { label: '11 a 50 colaboradores', value: 3 },
  { label: '51 a 100 colaboradores', value: 4 },
  { label: '101 a 500 colaboradores', value: 5 },
  { label: 'Mais de 500 colaboradores', value: 6 },
]

export const productStatus = [
  { label: 'ATIVO', value: '1' },
  { label: 'INATIVO', value: '2' },
  { label: 'BLOQUEADO', value: '3' },
]

export const cardStatus = [
  { label: 'ATIVO', value: '0' },
  { label: 'INATIVO', value: '1' },
]

export const orderStatus = [
  { label: 'Efetivando', value: ORDER_STATUS.EFFECTING },
  { label: 'Aguardando pagamento', value: ORDER_STATUS.WAITING_PAYMENT },
  { label: 'Aguardando processamento', value: ORDER_STATUS.WAITING_FOR_PROCESSING },
  { label: 'Processamento operadora', value: ORDER_STATUS.RUNNING },
  { label: 'Disponível para retirada', value: ORDER_STATUS.AVAILABLE_PICKUP },
  { label: 'Concluído', value: ORDER_STATUS.FINISHED },
  { label: 'Rascunho', value: ORDER_STATUS.DRAFT },
]

export const benefitsTypes = [
  { label: 'Vale Transporte Eletrônico', value: BENEFIT_TYPES.TRANSPORT },
  { label: 'Vale Alimentação', value: BENEFIT_TYPES.SNACK },
  { label: 'Vale Refeição', value: BENEFIT_TYPES.FOOD },
  { label: 'Vale Combustível', value: BENEFIT_TYPES.FUEL },
  { label: 'Vale Transporte Papel', value: BENEFIT_TYPES.TRANSPORT_PAPER },
]
