import { styled } from '@mui/material/styles'
import { HsvColorPicker } from 'react-colorful'

export const StyledColorPicekrContainer = styled('div')(() => ({
  width: 290,
}))

export const StyledColorPicker = styled(HsvColorPicker)(() => ({
  '&.react-colorful': {
    width: '100%',
    height: 232,
  },
  '.react-colorful__saturation': {
    borderRadius: 8,
  },
  '.react-colorful__hue': {
    height: 10,
    borderRadius: '20px',
    margin: '12px 0',
  },
  '.react-colorful__saturation-pointer, .react-colorful__hue-pointer': {
    width: 26,
    height: 26,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  '.react-colorful__pointer-fill': {
    height: 14,
    width: 14,
    position: 'unset',
  },
}))

export const StyledColorInputsContainer = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  gap: 8,
}))

export const StyledColorInputLabel = styled('label')(() => ({
  width: 52,
  marginLeft: 4,
  fontWeight: 'bold',
  '&:first-of-type': {
    width: 96,
  },
}))

export const StyledColorInput = styled('input')(() => ({
  all: 'unset',
  boxSizing: 'border-box',
  height: 32,
  border: '0.816062px solid #E5E8E8',
  borderRadius: 8,
  padding: '6px 6px 6px 12px',
  width: '100%',
  marginTop: 10,
  marginLeft: -4,
  fontWeight: 'initial',
}))
