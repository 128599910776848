import React, { useEffect, useState } from 'react'
import { Body, Title, SessionButton, Close } from './style'
import { Button, Icon, Input } from 'presentation/components'
import 'react-responsive-modal/styles.css'
import Modal from 'react-responsive-modal'
import { useForm, Controller } from 'react-hook-form'

const MODAL_STYLE = {
  borderRadius: '5px',
  modalContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    position: 'relative',
  },
  modal: {
    maxWidth: '380px',
    height: 'calc(100vh - 90px)',
    margin: '0',
    padding: '0',
    overflow: 'initial',
  },
}

const Filter = ({ actualFilters, onClose, resetFilters, filter, open }) => {
  const [minDate, setMinDate] = useState(new Date())
  const [maxDate, setMaxDate] = useState(new Date())

  const getPreSelectedDates = (dateString) => {
    let initialDate
    let finalDate
    if (dateString?.includes('>=')) {
      const extractedDate = dateString.replace('>=', '')
      initialDate = extractedDate
    } else if (dateString?.includes('<=')) {
      const extractedDate = dateString.replace('<=', '')
      finalDate = extractedDate
    } else if (dateString?.includes('><')) {
      const dates = dateString.split('><')
      initialDate = dates[0]
      finalDate = dates[1]
    }
    return {
      initialDate,
      finalDate,
    }
  }

  useEffect(() => {
    const preSelectedDates = getPreSelectedDates(actualFilters?.orderDate)
    setMinDate(new Date(preSelectedDates.initialDate))
    setMaxDate(new Date(preSelectedDates.finalDate))
  }, [actualFilters])

  const preSelectedDates = getPreSelectedDates(actualFilters?.orderDate)
  const { control, handleSubmit } = useForm({
    defaultValues: {
      initialDate: preSelectedDates.initialDate,
      finalDate: preSelectedDates.finalDate,
    },
  })
  const onSubmit = (data) => {
    let query = {}
    if (data.initialDate && !data.finalDate) query = { orderDate: `>=${data.initialDate}` }
    if (!data.initialDate && data.finalDate) query = { orderDate: `<=${data.finalDate}` }
    if (data.initialDate && data.finalDate) query = { orderDate: `${data.initialDate}><${data.finalDate}` }
    filter(query)
    onClose()
  }

  return (
    <Modal open={open} onClose={onClose} showCloseIcon={false} styles={MODAL_STYLE}>
      <Body>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Title>Filtrar</Title>
          <Close onClick={onClose}>
            <Icon name='x' />
          </Close>
        </div>
        <p>Filtre pela data do pedido de recarga.</p>
        <form id='filterOrders' onSubmit={handleSubmit(onSubmit)}>
          <label>De</label>
          <Controller
            control={control}
            name='initialDate'
            render={({ field: { onChange, value, ref } }) => (
              <Input.Date
                id='orders_filter_initialDate'
                placeholder='00/00/0000'
                maxDate={maxDate}
                getInputRef={ref}
                value={new Date(value?.replaceAll('-', '/'))}
                onChange={(e) => {
                  onChange(e !== '' ? e?.toISOString().split('T')[0] : e)
                  setMinDate(e)
                }}
              />
            )}
          />
          <label>Até</label>
          <Controller
            control={control}
            name='finalDate'
            render={({ field: { onChange, value, ref } }) => (
              <Input.Date
                id='orders_filter_finalDate'
                placeholder='00/00/0000'
                maxDate={new Date()}
                minDate={minDate}
                getInputRef={ref}
                value={new Date(value?.replaceAll('-', '/'))}
                onChange={(e) => {
                  onChange(e !== '' ? e?.toISOString().split('T')[0] : e)
                  setMaxDate(e)
                }}
              />
            )}
          />
        </form>

        <SessionButton>
          <Button
            id='orders_button_cancel'
            variant='outlined'
            width='200px'
            onClick={() => {
              resetFilters()
              onClose()
            }}
          >
            Limpar
          </Button>
          <div style={{ paddingLeft: '15px' }} />
          <Button width='250px' form='filterOrders' id='orders_button_confirm'>
            Filtrar
          </Button>
        </SessionButton>
      </Body>
    </Modal>
  )
}

export default Filter
