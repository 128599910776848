import _ from 'lodash'
import { Box, Typography } from 'presentation/components/atoms'
import { ItemContainer, ItemHeader, ItemLabel, ItemValuesContainer } from './styles'
import { IOrderItem } from '@/presentation/pages/orderTracking/hooks/utils/interfaces/iOrderItems'
import { formatMoney } from '@stationkim/front-ui'
import { BENEFIT_TYPE } from '@/main/utils/constants'
import Decimal from 'decimal.js'

const Value = ({ label, value, onlyValue = false }: { label: string; value: string | number; onlyValue?: boolean }) => {
  return (
    <ItemValuesContainer sx={{ whiteSpace: 'nowrap' }}>
      {!onlyValue && <ItemLabel variant='text2'>{label}</ItemLabel>}
      {value}
    </ItemValuesContainer>
  )
}

const getFeesTotal = (fees: IOrderItem['fees']): number => {
  let total = new Decimal(0)
  if (!fees) return 0
  fees.forEach((fee) => {
    total = total.add(fee.total)
  })
  return total.toNumber()
}

export const OrderItem = ({ orderItem }: { orderItem: IOrderItem }) => {
  const shouldTotalBeVisible = orderItem.fees.length > 1
  const feesTotal = shouldTotalBeVisible ? getFeesTotal(orderItem.fees) : 0

  return (
    <ItemContainer>
      <ItemHeader>
        <Typography>{orderItem.employeeRegistration}</Typography>
        <Typography>{orderItem.employeeName}</Typography>
        {shouldTotalBeVisible && (
          <Typography sx={{ marginLeft: 'auto' }}>Total: R$ {formatMoney(feesTotal)}</Typography>
        )}
      </ItemHeader>
      <Box
        sx={{
          display: 'flex',
          gap: '32px',
          padding: '8px',
        }}
      >
        <Value label='Produto' value={BENEFIT_TYPE[1]} />
        <Value label='N° Cartão' value={orderItem.cardNumber} />
        <Value label='Mat. Operadora' value={orderItem.operatorRegistration} />
        <Box
          container
          columnSpacing='32px'
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',
            columnGap: '32px',
            rowGap: '8px',
          }}
        >
          {orderItem.fees.map((fee, index) => {
            const notFirst = index > 0
            return (
              <>
                <Value label='Tarifa' value={fee.operatorName} onlyValue={notFirst} />
                <Value label='Dias' value={fee.days} onlyValue={notFirst} />
                <Value label='Valor dia' value={'R$ ' + formatMoney(fee.dayValue || 0)} onlyValue={notFirst} />
                <Value label='Valor Mês' value={'R$ ' + formatMoney(fee.total || 0)} onlyValue={notFirst} />
              </>
            )
          })}
        </Box>
      </Box>
    </ItemContainer>
  )
}
