import { useLocation, useNavigate } from 'react-router-dom'
import { useCancelOrder, useNotifications } from '@/main/hooks'
import { usePix as useOldPix } from '@/main/hooks/usePix'
import { IntegrationSwapper } from '@/presentation/components'
import { Pix as PixPage } from './pix'
import { usePayment } from 'main/hooks/usePayment'
import { useOrderCancelation } from '@/main/hooks/useOrderCancelation'
import { EMessageType } from '@/main/store'
import { ORDER_STATUS } from '@/utils/constants'
import { EStatusCompra } from '@/main/enums'

const OldIntegration = () => {
  const navigate = useNavigate()

  const location = useLocation()
  const orderType = location?.state?.orderType
  const orderId = location?.state?.id
  const title = location?.state?.title
  const { cancelOrder } = useCancelOrder({
    onSuccess: () => navigate(orderType === 'recharge' ? '/recargas' : '/cartoes'),
  })
  const { data, loading } = useOldPix({ orderId, orderType })

  const paymentFinished = data?.status > ORDER_STATUS.WAITING_PAYMENT || data?.confirmation?.totalAmountPayable === 0
  const orders = [
    {
      totalItens: data?.numberOfColaborators,
      totalRechargeValue: data?.confirmation?.subTotalValue,
      administrateFee: data?.confirmation?.paymentFeeValue,
      totalTransferFeeValue: data?.confirmation?.transferFeeValue,
      ticketingBalance: data?.ticketingBalance,
      operatorCardName: data?.tradeName,
      code: data?.code,
      issFee: data?.confirmation?.issFeeValue,
      totalValue: data?.confirmation?.totalValue,
    },
  ]

  return (
    <PixPage
      data={data}
      orders={orders}
      loading={loading}
      title={title}
      cancelOrder={cancelOrder}
      orderId={orderId}
      paymentFinished={paymentFinished}
    />
  )
}

const NewIntegration = () => {
  const location = useLocation()
  const notifications = useNotifications()
  const navigate = useNavigate()
  const { purchaseId, details, totalToPay, code, balanceUsage } = location?.state || {}

  const { cancelPurchase } = useOrderCancelation()
  const cancelOrder = {
    mutate: () =>
      cancelPurchase.mutate(
        { purchaseId },
        {
          onSuccess: () => {
            notifications.push({ content: 'Pedido cancelado!', type: EMessageType.Success })
            navigate('/recargas')
          },
        },
      ),
  }
  const { isLoading, paymentData } = usePayment({ purchaseId, refetchUntilPaid: totalToPay > 0 })
  const orderStatus = paymentData?.data?.valor?.status
  const pixDueDate = paymentData?.data?.valor?.pix?.dataVencimento
  return (
    <PixPage
      data={{
        code: code,
        status: paymentData?.data?.valor?.status === 5 ? 15 : orderStatus,
        orderDate: new Date(),
        confirmation: {
          totalAmountPayable: totalToPay,
          useOfWallet: balanceUsage,
        },
        pix: {
          qrCode: paymentData?.data?.valor?.pix?.copiaECola,
        },
        isCanceled: [EStatusCompra.Cancelado, EStatusCompra.Cancelando].includes(orderStatus),
        cancelDate: pixDueDate ? new Date(pixDueDate).toLocaleDateString() : '',
      }}
      orders={details}
      loading={isLoading}
      title={'Recarga'}
      cancelOrder={cancelOrder}
      orderId={'123'}
      paymentFinished={totalToPay === 0 || paymentData?.data?.valor?.status > 3}
    />
  )
}

export const Pix = () => {
  const location = useLocation()
  return (
    <IntegrationSwapper
      isDealer={location?.state?.isDealer}
      components={{
        ifIsMotiva: <OldIntegration />,
        ifIsNotMotiva: <NewIntegration />,
      }}
    />
  )
}
