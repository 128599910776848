import { Box, styled } from '@mui/material'

export const StyledBox = styled(Box)(({ theme }) => ({
  color: theme.palette.common.white,
  whiteSpace: 'nowrap',
  display: 'flex',
  gap: 26,
  '& svg': {
    height: 34,
    width: 34,
  },
  '& a': {
    color: theme.palette.common.white,
    height: 34,
    width: 34,
  },
}))
