import styled from 'styled-components'

export const Title = styled.h1`
  font-size: 20px;
  margin-bottom: 20px;
`

export const Close = styled.p`
  text-decoration: underline;
  cursor: pointer;
  padding-top: 17px;
`
