import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import api from '@/infra/api'

export const useFillCardsSelect = (params: any = {}) => {
  const { config = {}, cardNumber } = params

  const { data, ...rest } = useQuery<AxiosResponse<any>>({
    queryKey: ['reportsCardsSelectsOptions', cardNumber],
    queryFn: () => api.get(`rh-bff/cards?pageSize=50&filterProps='number'&filterValue=${cardNumber}`),
    refetchOnWindowFocus: false,
    ...config,
  })

  return {
    cardsOptions: data?.data?.data || [],
    ...rest,
  }
}
