import { featureFlags } from 'main/utils/featuresFlags'

const features =
  process.env.REACT_APP_ENV === 'development'
    ? featureFlags.development
    : process.env.REACT_APP_ENV === 'production'
    ? featureFlags.production
    : featureFlags.stage

export type Feature = keyof typeof features

export const useFeatures = () => {
  return {
    features,
  }
}
