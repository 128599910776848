import api from '@/infra/api'
import { useQuery } from '@tanstack/react-query'
import { useAtom } from 'jotai'
import { useEffect } from 'react'
import { GlobalThemePersistentStore } from '../store'

export const useSession = (resaleSite: string, shouldUpdateTheme?: boolean) => {
  const [, setTheme] = useAtom(GlobalThemePersistentStore)

  const { data, ...rest } = useQuery({
    queryKey: ['session', resaleSite],
    queryFn: ({ queryKey }) => api.get(`/rh-bff/Session?resale=${queryKey[1]}`),
    meta: { silent: true },
  })

  useEffect(() => {
    if (data && shouldUpdateTheme) {
      const theme = data.data.resale.theme
      setTheme(JSON.parse(theme))
    }
  }, [data, setTheme, shouldUpdateTheme])

  return {
    sessionData: data?.data,
    ...rest,
  }
}
