import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import api from '@/infra/api'
import Decimal from 'decimal.js'

interface IClosedCard {
  code: string
  orders: Array<any>
  orderDate: string
  totalItens: number
  totalValue: number
  walletValue: number
  ticketingBalanceValue: number
  slipValue: number
}

const defaultCart = {
  code: '',
  orders: [],
  orderDate: '',
  totalItens: 0,
  totalValue: 0,
}

export const useClosedCart = (params: any = {}) => {
  const { config = {}, cartId } = params
  const { data, ...rest } = useQuery<AxiosResponse<any>>({
    queryKey: ['cart', cartId],
    queryFn: ({ queryKey }) => api.get(`rh-bff/basket/${queryKey[1]}`),
    refetchOnWindowFocus: false,
    ...config,
  })

  const organizeData = () => {
    const actualData = data?.data

    if (!actualData) return defaultCart

    const totalValue = new Decimal(actualData.rechargeValueWithFee)

    const organizedData = {
      code: actualData.code,
      orders: [...actualData.rechargeOrders, ...actualData.firstCopyOrders, ...actualData.secondCopyOrders],
      orderDate: actualData.creationDate,
      totalItens:
        actualData.firstCopyOrders.length + actualData.secondCopyOrders.length + actualData.rechargeOrders.length,
      totalValue: totalValue.toNumber(),
      walletValue: actualData.walletValue,
      slipValue: actualData.slipValue,
      ticketingBalanceValue: actualData.ticketingBalanceValue,
    }
    return organizedData || {}
  }

  return {
    cart: organizeData() as IClosedCard,
    ...rest,
  }
}
