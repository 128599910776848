import { styled } from '@mui/material'
import { Box, Icon } from 'presentation/components/atoms'

export const SummaryContainer = styled(Box)(({ isSelected, theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '8px',
  padding: '4px 8px 4px 8px',
  borderRadius: '8px',
  border: `1px solid ${isSelected ? theme.palette.error.main : theme.palette.grey[300]}`,
  backgroundColor: isSelected ? theme.palette.error.light + '08' : 'initial',
  '&  div > svg:only-child > path': {
    fill: `${theme.palette.grey[600]} !important`,
  },
}))

export const DialogIcon = styled(Icon)(({ theme }) => ({
  color: theme.palette.error.main,
  padding: '12px',
  height: '40px',
  width: 'fit-content',
  backgroundColor: theme.palette.grey[50],
  borderRadius: '50%',
  alignSelf: 'center',
  marginBottom: '16px',
}))
