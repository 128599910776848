import { useMutation, useQueryClient } from '@tanstack/react-query'
import api from '@/infra/api'

export const useCancelCart = (params: { onSuccess?: () => void } = {}) => {
  const { onSuccess } = params
  const queryClient = useQueryClient()

  const cancelCart = useMutation({
    mutationFn: ({ cartId }: { cartId: string }) => api.patch(`rh-bff/basket/cancelbasket/${cartId}`),
    onSuccess: () => {
      queryClient.invalidateQueries(['cart'])
      onSuccess && onSuccess()
    },
  })

  return { cancelCart }
}
