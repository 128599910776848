import React, { useEffect, useState } from 'react'
import Modal from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import { useForm, Controller } from 'react-hook-form'
import { faX } from '@fortawesome/free-solid-svg-icons'
import { Icon } from '@stationkim/front-ui'
import { Body, Title, SessionButton, Close } from './style'
import { Button } from 'presentation/components'
import InputDate from '@/presentation/components/inputDate'
import dayjs from 'dayjs'

const MODAL_STYLE = {
  borderRadius: '5px',
  modalContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    position: 'relative',
  },
  modal: {
    maxWidth: '380px',
    height: 'calc(100vh - 90px)',
    margin: '0',
    padding: '0',
    overflow: 'initial',
  },
}

const today = dayjs(new Date())

const initialize = (value) => {
  return value ? dayjs(value) : null
}

export const NewFilter = ({ open, onClose, resetFilters, onFilter, filter }) => {
  const [minDate, setMinDate] = useState()
  const [maxDate, setMaxDate] = useState(today)

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      DataPedidoInicio: null,
      DataPedidoFim: null,
    },
  })

  useEffect(() => {
    setValue('DataPedidoInicio', initialize(filter?.DataPedidoInicio))
    setValue('DataPedidoFim', initialize(filter?.DataPedidoFim))
  }, [filter, setValue])

  const onSubmit = (data) => {
    if (!onFilter) return
    const parsedDate = {
      DataPedidoInicio: data.DataPedidoInicio ? data.DataPedidoInicio.toISOString() : null,
      DataPedidoFim: data.DataPedidoFim ? data.DataPedidoFim.toISOString() : null,
    }
    onFilter(parsedDate)
  }

  return (
    <Modal open={open} onClose={onClose} showCloseIcon={false} styles={MODAL_STYLE}>
      <Body>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Title>Filtrar</Title>
          <Close onClick={onClose}>
            <Icon icon={faX} />
          </Close>
        </div>
        <p>Filtre pela data do pedido de recarga.</p>
        <form id='filterOrders' onSubmit={handleSubmit(onSubmit)}>
          <label>De</label>
          <br />
          <Controller
            control={control}
            name='DataPedidoInicio'
            render={({ field: { onChange, value } }) => (
              <InputDate
                id='orders_filter_initialDate'
                onChange={(value) => {
                  onChange(value)
                  setMinDate(value)
                }}
                value={value}
                sx={{ width: '100%' }}
                maxDate={maxDate}
              />
            )}
          />
          <br />
          <label>Até</label>
          <br />
          <Controller
            control={control}
            name='DataPedidoFim'
            render={({ field: { onChange, value } }) => (
              <InputDate
                id='orders_filter_finalDate'
                onChange={(value) => {
                  onChange(value)
                  setMaxDate(value)
                }}
                value={value}
                sx={{ width: '100%' }}
                minDate={minDate}
                maxDate={today}
              />
            )}
          />
        </form>

        <SessionButton>
          <Button
            id='orders_button_cancel'
            variant='outlined'
            width='200px'
            onClick={() => {
              resetFilters()
              onClose()
            }}
          >
            Limpar
          </Button>
          <div style={{ paddingLeft: '15px' }} />
          <Button width='250px' form='filterOrders' id='orders_button_confirm'>
            Filtrar
          </Button>
        </SessionButton>
      </Body>
    </Modal>
  )
}
