/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'

import { getCardsFilterData } from 'services/cardsServices'
import useService from 'main/hooks/useService'

export const useBenefitTypes = () => {
  const [fetchFilterValuesState, fetchFilterValuesRequest] = useService(getCardsFilterData, {
    silent: false,
  })

  useEffect(() => {
    fetchFilterValuesRequest()
  }, [])

  const benefitTypes = fetchFilterValuesState?.response?.data?.benefitTypes.map((benefit) => {
    return {
      label: benefit.name,
      value: benefit.id,
    }
  })

  return {
    benefitTypes,
    loading: fetchFilterValuesState.loading,
  }
}
