import styled from 'styled-components'

export const Label = styled.label`
  display: inline-block;
  margin-top: 32px;
  margin-bottom: 8px;
`

export const Description = styled.p`
  margin-top: 64px;
  margin-bottom: 32px;
  color: var(--color-secundary-06);
`

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
`
