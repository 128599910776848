import api from '@/infra/api'

import apiCallHandler from './apiCallHandler'
import { queryStringToJSON } from '@/utils/functionUtils'

export const getEmployeeApi = (employeeId) => {
  return apiCallHandler(() => api.get(`rh-bff/employees/${employeeId}`))
}

export const getEmployees = (query) => {
  return apiCallHandler(() => api.get(`rh-bff/employees${query}`))
}

export const getEmployeesWithoutCard = (cardId) => {
  return apiCallHandler(() => api.get(`rh-bff/cards/association/${cardId}`))
}

export const getEmployeesApi = (query) => {
  return apiCallHandler(() => api.get(`/employees-service/${query || ''}`))
}

export const getEmployeesWithoutDepartment = () => {
  return apiCallHandler(() => api.get(`rh-bff/departments/employeeswihoutdepartment`))
}

export const patchActivateEmployee = (employeeId) => {
  return apiCallHandler(() => api.patch(`/rh-bff/employees/${employeeId}/activate`))
}

export const patchInactivateEmployee = (employeeId) => {
  return apiCallHandler(() => api.patch(`/rh-bff/employees/${employeeId}/inactivate`))
}

export const patchUpdateEmployee = (payload) => {
  return apiCallHandler(() => api.patch(`/employees-service/`, payload))
}

export const getEmployeesForCardFirstCopy = (query) => {
  return apiCallHandler(() => api.get(`rh-bff/cards/new/first/employees/paged${query || ''}`))
}

export const getEmployeesForCardSecondCopy = (query) => {
  return apiCallHandler(() => api.get(`rh-bff/cards/new/second/employees/paged${query || ''}`))
}

export const postCreateEmployee = (payload) => {
  return apiCallHandler(() => api.post('rh-bff/employees/', payload))
}

export const putEditEmployee = (payload) => {
  return apiCallHandler(() => api.put(`rh-bff/employees/${payload.id}`, payload))
}

export const getEmplyeesUsageHistory = (playloadQuery) => {
  const queryString = queryStringToJSON(playloadQuery)

  return apiCallHandler(() => api.get(`/rh-bff/employees/${queryString.employeeID}/usage-history/${playloadQuery}`))
}

export const getEmplyeesBenefits = () => {
  return apiCallHandler(() => api.get(`/rh-bff/Benefit/employees/paged`))
}

export const getEmployeesFilterData = () => {
  return apiCallHandler(() => api.get(`rh-bff/employees/filter`))
}

export const getBenefitsEmployees = () => {
  return apiCallHandler(() => api.get(`/rh-bff/Benefit/employees?Enabled=true`))
}

export const patchInsertWorkingDays = (days, payload) => {
  return apiCallHandler(() => api.patch(`rh-bff/employees/professionaldata/workingDays?days=${days}`, payload))
}
