import styled from 'styled-components'

export const DecoratedText = styled.p`
  color: var(--color-primary-01);
  cursor: pointer;
  text-decoration: underline;
`

export const StatusDot = styled.div`
  height: 12px;
  width: 12px;
  border-radius: 50%;
  margin-right: 8px;

  ${({ status }) => (status ? 'background-color: var(--color-status-01)' : 'background-color: var(--color-status-06)')}
`

export const Wrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const Body = styled.div`
  padding: 30px;
  z-index: 99;
`

export const TextConfirm = styled.p`
  text-align: center;
  font-size: 22px;
  padding: 50px 0;
`

export const ColorDest = styled.span`
  color: #632ecb;
  font-weight: bold;
`

export const SessionButton = styled.div`
  display: flex;
  justify-content: center;
  width: 65%;
  margin: 0 auto;
  padding-bottom: 20px;
`

export const Close = styled.p`
  cursor: pointer;
  text-align: right;
  font-size: 20px;
`

export const ModalContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  width: 100%;
  height: 100%;
`
export const ModalTitle = styled.p`
  color: #632ecb;
  font-weight: bold;
  font-size: 18px;
`

export const ModalHeader = styled.header`
  display: flex;
  justify-content: space-between;
`
export const ModalBody = styled.main`
  display: flex;
  flex: 1;
  overflow: auto;
`

export const ModalFooter = styled.footer`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin: 0 auto;
`
