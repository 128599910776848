import { StyledImage } from './styles'
import defaultLogo from 'presentation/assets/defaultLogo.png'
import { useRef } from 'react'

export const Logo = ({ ...props }) => {
  const ref = useRef()
  const handleError = () => {
    ref.current.src = defaultLogo
  }
  return <StyledImage ref={ref} onError={handleError} {...props} />
}
