import { styled } from '@mui/material'
import { Box } from '@/presentation/components/atoms/box'
import { Paper } from '@/presentation/components/atoms/paper'

export const StyledBox = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  overflow: 'auto hidden',
  background: `radial-gradient(${theme.palette.primary.dark}, ${theme.palette.primary.main})`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 32,
}))

export const StyledPaper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: 300,
  width: 490,
  padding: 32,
  borderRadius: 16,
  transition: 'max-height .25s ease-out',
  '& > form': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  '& > form > h1': {
    fontWeight: 700,
    marginBottom: 16,
    color: theme.palette.primary.main,
  },
  '& > form > a': {
    marginTop: 24,
    marginRight: 'auto',
  },
  '& > form > span': {
    textAlign: 'center',
    marginBottom: 48,
  },
  '& > form > div + div': {
    marginTop: 32,
  },
}))

export const Logo = styled('img')(() => ({
  height: 100,
  margin: '0 auto',
  marginBottom: 48,
}))
