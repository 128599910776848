import produce from 'immer'
import { ACTIONS } from './actions'

const INITIAL_STATE = {
  motivaCashBalanceVisibility: false,
}

export default function globalState(state = INITIAL_STATE, action) {
  const { type, payload } = action

  return produce(state, (draft) => {
    switch (type) {
      case ACTIONS.SET_GLOBAL_STATE: {
        if (draft[payload.atributeName] === 'undefined') return
        draft[payload.atributeName] = payload.value
        break
      }

      case ACTIONS.DELETE_GLOBAL_STATE: {
        delete draft[payload.atributeName]
        break
      }

      default:
    }
  })
}
