import React from 'react'
import { Body, TextConfirm, SessionButton, Close } from './style'
import { Button, Icon, Spinner } from 'presentation/components'
import 'react-responsive-modal/styles.css'
import { Modal as ModalBox } from 'react-responsive-modal'

const Modal = ({ children, open, message, loading, confirm, cancel }) => {
  return (
    <ModalBox open={open} onClose={cancel} center showCloseIcon={false} styles={{ borderRadius: '5px' }}>
      <Body>
        <Close onClick={cancel}>
          <Icon name='x' />
        </Close>
        {loading ? (
          <Spinner />
        ) : (
          <>
            {children ? children : <TextConfirm>{message}</TextConfirm>}
            <SessionButton>
              <Button variant='outlined' width='200px' onClick={cancel}>
                Não
              </Button>
              <div style={{ paddingLeft: '15px' }} />
              <Button width='250px' onClick={confirm}>
                Sim
              </Button>
            </SessionButton>
          </>
        )}
      </Body>
    </ModalBox>
  )
}

export default Modal
