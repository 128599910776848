import styled from 'styled-components'

export const TitlePanel = styled.p`
  color: var(--color-primary-04);
  font-weight: 600;
  font-size: 16px;
  margin-top: 15px;
  margin-bottom: 15px;
`

export const Subtitle = styled.p`
  color: var(--color-primary-04);
  font-weight: 600;
  font-size: 20px;
`

export const Options = styled.div`
  padding: 12px 0 12px 12px;
  border-bottom: 1px solid #efe7e7;
  color: #757576;
  display: flex;
  justify-content: space-between;
`

export const Text = styled.p``

export const Delivery = styled.div`
  margin-top: 15px;
  padding-top: 15px;
  display: flex;
`

export const TextAlt = styled.p`
  text-align: right;
  color: var(--color-primary-01);
  padding-top: 56px;
  font-weight: 600;
  cursor: pointer;
`

export const SessionConfirm = styled.div`
  background-color: #e8e7e7;
  border-radius: 5px;
  padding: 10px;
`

export const SessionText = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dcdcdc;
  margin-top: 15px;
  padding-bottom: 20px;
`

export const Container = styled.div`
  padding: 32px;
`
