import { TableBody, styled } from '@mui/material'

export const StyledTableBody = styled(TableBody)(({ theme }) => ({
  '& tr:nth-of-type(odd)': {
    backgroundColor: theme.palette.grey[100],
  },
  '& tr > td:first-of-type': {
    borderRadius: '8px 0 0 8px',
  },
  '& tr > td:last-of-type': {
    borderRadius: '0 8px 8px 0',
  },
  '& tr > td:only-of-type': {
    borderRadius: '8px',
  },
}))
