import api from '@/infra/api'
import apiCallHandler from './apiCallHandler'

export const getDepartment = (companyId, departmentId) => {
  return apiCallHandler(() => api.get(`/companies-service/${companyId}/Departments/${departmentId}`))
}

export const getDepartments = (query = '') => {
  return apiCallHandler(() => api.get(`rh-bff/departments${query}`))
}

export const getDepartmentCreationData = () => {
  return apiCallHandler(() => api.get(`rh-bff/departments/new`))
}

export const postCreateDepartment = (payload) => {
  return apiCallHandler(() => api.post(`rh-bff/departments/new`, payload))
}

export const getDepartmentEditData = (departmentId) => {
  return apiCallHandler(() => api.get(`rh-bff/departments/edit/${departmentId}`))
}

export const patchDepartmentUpdate = (departmentId, payload) => {
  return apiCallHandler(() => api.patch(`rh-bff/departments/edit/${departmentId}`, payload))
}

export const getAllDepartments = (companyId) => {
  return apiCallHandler(() => api.get(`/companies-service/${companyId}/Departments?situation=0`))
}
