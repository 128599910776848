import { ORDER_STATUS as OS } from 'utils/constants'

export const convertStatusToStep = ({ status }: { status: number }): number => {
  const statusToStepObj: Record<number, any> = {}

  statusToStepObj[OS.EFFECTING] = 0
  statusToStepObj[OS.WAITING_PAYMENT] = 1
  statusToStepObj[OS.WAITING_FOR_PROCESSING] = 2
  statusToStepObj[OS.PROCESSING] = 2
  statusToStepObj[OS.RUNNING] = 2
  statusToStepObj[OS.AVAILABLE_PICKUP] = 3
  statusToStepObj[OS.FINISHED] = 4

  return statusToStepObj[status]
}
