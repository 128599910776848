import styled from 'styled-components'
import { BREAK_POINTS } from '@/utils/constants'

export const TabPanelHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ToggleHolder = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ToggleMod = styled.span`
  width: 80px;
  transform: scale(0.7);
`

export const ButtonWrapper = styled.div`
  padding-right: ${({ last }) => (last ? '32px' : '0')};

  button {
    max-width: 250px;
    @media (max-width: ${BREAK_POINTS.xl}) {
      margin: 24px auto;
      padding: 32px;
    }
  }

  @media (max-width: ${BREAK_POINTS.xl}) {
    padding-right: 0;
  }
`

export const SearchWrapper = styled.div`
  padding-left: 54px;
  margin-top: 24px;
  @media (max-width: ${BREAK_POINTS.md}) {
    padding: 0 54px;
  }
`

export const HasOderText = styled.span`
  font-size: 1rem;

  p:first-of-type {
    font-weight: var(--font-weight-semi-bold);
    font-size: 1.5rem;
    margin-top: 64px;
  }
  p:last-of-type {
    margin-bottom: 64px;
  }

  svg {
    height: 128px;
    width: 128px;
    transform: rotate(180deg);
    fill: #cfffb9;
  }
`
