import { Box } from 'presentation/components/atoms/box'
import { styled } from '@mui/material'

export const CarouselContainer = styled(Box)(({ full }) => ({
  display: 'flex',
  alignItems: 'center',
  justfyItems: 'space-between',
  width: '100%',
  '& svg': {
    height: '18px',
    width: '15px',
    cursor: 'pointer',
    transition: 'all 0.1s ease-in-out',
    '&:hover': {
      transform: 'scale(1.2)',
    },
    '&:active': {
      transform: 'scale(1)',
    },
  },
}))

export const CarouselWindow = styled(Box)(({ isLeftArrowVisible, isRightArrowVisible, theme }) => ({
  padding: '4px',
  overflow: 'hidden',
  position: 'relative',

  '&:after, &:before': {
    position: 'absolute',
    height: '100%',
    width: '20px',
    top: 0,
    zIndex: 1,
  },
  '&:after': {
    content: "' '",
    display: isLeftArrowVisible ? 'initial' : 'none',
    background: `linear-gradient(90deg, ${theme.palette.background.default}, transparent)`,
    left: 0,
  },
  '&:before': {
    content: "' '",
    display: isRightArrowVisible ? 'initial' : 'none',
    background: `linear-gradient(90deg, transparent, ${theme.palette.background.default})`,
    right: 0,
  },
}))

export const CarouselContent = styled(Box)(({ position, theme }) => ({
  transform: `translateX(${position}px)`,
  left: position,
  transition: `transform .25s ease-in-out`,
  width: 'fit-content',
}))
