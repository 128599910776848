import { styled } from '@mui/material'

export const StyledSpinner = styled('div')(({ theme }) => ({
  margin: 'auto',
  width: 'fit-content',
  overflow: 'hidden',
  '& svg': {
    animation: 'loading 1.5s infinite linear',
    '@keyframes loading': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
  },
}))
