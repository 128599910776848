import { useState } from 'react'

import { TableCell, TableRow, TableBody as Body } from 'presentation/components/atoms'

import pencilRecharge from 'presentation/assets/icons/pencil-recharge.svg'
import confirmRecharge from 'presentation/assets/icons/confirm-recharge.svg'
import cancelRecharge from 'presentation/assets/icons/cancel-recharge.svg'

import { moneyValueToServerFormat } from '../../manualRechargeValueDefinition/utils/moneyValueToServerFormat'

import { CustomInput } from '../../manualRechargeValueDefinition/styles'
import { Actions } from './styles'

export const TableBody = ({ employeesData, updateTotalRecharge, updateDays }) => {
  const [edit, setEdit] = useState({ edit: false, index: null })

  const [totalRecharge, setTotalRecharge] = useState()
  const [days, setDays] = useState()

  const update = async ({ employee }) => {
    await Promise.all([
      totalRecharge && updateTotalRecharge({ employee, value: totalRecharge }),
      days && updateDays({ employee, value: days }),
    ])
  }

  if (!employeesData?.length) return

  return (
    <Body>
      {employeesData.map((employee, index) => {
        const editItem = edit.edit && edit.index === index

        return (
          <TableRow key={employee.employeeName}>
            <TableCell id={`recharge_manual_tbody_emplyee_${index}`}>{employee.employeeName}</TableCell>
            <TableCell id={`recharge_manual_tbody_operator_${index}`}>{employee.operatorName}</TableCell>
            <TableCell id={`recharge_manual_tbody_total_quantity_${index}`}>
              {editItem ? (
                <CustomInput
                  defaultValue={employee.totalQuantity}
                  placeholder='00'
                  onChange={(e) => setDays(e.target.value)}
                  value={days}
                />
              ) : (
                employee.totalQuantity
              )}
            </TableCell>
            <TableCell id={`recharge_manual_tbody_total_value_${index}`}>
              {editItem ? (
                <CustomInput
                  defaultValue={employee.totalValue}
                  decimalSeparator={','}
                  thousandSeparator={'.'}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  prefix={'R$ '}
                  placeholder='R$ 00,00'
                  onChange={(e) => setTotalRecharge(moneyValueToServerFormat(e.target.value))}
                />
              ) : (
                `R$ ${employee.totalValue?.toFixed(2)}`
              )}
            </TableCell>
            <TableCell>
              <Actions>
                {editItem ? (
                  <>
                    <img
                      src={confirmRecharge}
                      alt='confirm'
                      onClick={async () => {
                        await update({ employee })
                        setEdit({ edit: false, index: null })
                      }}
                      id={`recharge_manual_tbody_action_confirm_${index}`}
                    />
                    <img
                      id={`recharge_manual_tbody_action_cancel_${index}`}
                      src={cancelRecharge}
                      alt='cancel'
                      onClick={() => setEdit({ edit: false, index: null })}
                    />
                  </>
                ) : (
                  <>
                    <img
                      id={`recharge_manual_tbody_action_pencil_${index}`}
                      src={pencilRecharge}
                      alt='pencil'
                      onClick={() => setEdit({ edit: true, index })}
                    />
                  </>
                )}
              </Actions>
            </TableCell>
          </TableRow>
        )
      })}
    </Body>
  )
}
