import { Panel, Spacing } from 'presentation/components'

import { TitlePanel, Search } from './styles'

const ListPanel = ({ children, filter, setFilter, title, label }) => {
  return (
    <Panel
      style={{
        padding: '24px',
        maxWidth: '100%',
        borderTopRightRadius: '0',
        borderTopLeftRadius: '0',
      }}
    >
      <div
        style={{
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <TitlePanel>{title}</TitlePanel>
        <div style={{ border: '1px solid rgba(0, 0, 0, 0.12)', padding: '8px', borderRadius: '8px', paddingTop: 0 }}>
          <div style={{ marginTop: '15px', width: '100%', marginBottom: '20px' }}>
            <Search
              placeholder='Pesquisar'
              value={filter}
              onChange={(e) => setFilter(e)}
              id={'recharges_manualOrder_search'}
            />
          </div>
          {children}
        </div>
      </div>
    </Panel>
  )
}

export default ListPanel
