const ACTION = '@employees/'

export const ACTIONS = {
  GET_EMPLOYEES_REQUEST: ACTION + 'GET_EMPLOYEES_REQUEST',
  GET_EMPLOYEES_REQUEST_API: ACTION + 'GET_EMPLOYEES_REQUEST_API',
  GET_EMPLOYEES_FOR_CARD_FIRST_COPY_REQUEST: ACTION + 'GET_EMPLOYEES_FOR_CARD_FIRST_COPY_REQUEST',
  GET_EMPLOYEES_FOR_CARD_SECOND_COPY_REQUEST: ACTION + 'GET_EMPLOYEES_FOR_CARD_SECOND_COPY_REQUEST',
  EMPLOYEES_REQUEST_SUCCESS: ACTION + 'EMPLOYEES_REQUEST_SUCCESS',
  EMPLOYEES_REQUEST_FAILURE: ACTION + 'EMPLOYEES_REQUEST_FAILURE',
}

export function getEmployeesRequest(query) {
  return {
    type: ACTIONS.GET_EMPLOYEES_REQUEST,
    payload: { query },
  }
}

export function getEmployeesRequestApi(query) {
  return {
    type: ACTIONS.GET_EMPLOYEES_REQUEST_API,
    payload: { query },
  }
}

export function getEmployeesForCardFirstCopyRequest(query) {
  return {
    type: ACTIONS.GET_EMPLOYEES_FOR_CARD_FIRST_COPY_REQUEST,
    payload: { query },
  }
}

export function getEmployeesForCardSecondCopyRequest(query) {
  return {
    type: ACTIONS.GET_EMPLOYEES_FOR_CARD_SECOND_COPY_REQUEST,
    payload: { query },
  }
}

export function employeesRequestSuccess(data) {
  return {
    type: ACTIONS.EMPLOYEES_REQUEST_SUCCESS,
    payload: { data },
  }
}

export function employeesRequestFailure(data) {
  return {
    type: ACTIONS.EMPLOYEES_REQUEST_FAILURE,
    payload: { data },
  }
}
