export const InfoCircle = () => {
  return (
    <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16 8C11.5625 8 8 11.5938 8 16C8 20.4375 11.5625 24 16 24C20.4062 24 24 20.4375 24 16C24 11.5938 20.4062 8 16 8ZM16 22.5C12.4062 22.5 9.5 19.5938 9.5 16C9.5 12.4375 12.4062 9.5 16 9.5C19.5625 9.5 22.5 12.4375 22.5 16C22.5 19.5938 19.5625 22.5 16 22.5ZM16 17.5C16.4062 17.5 16.75 17.1875 16.75 16.75V12.75C16.75 12.3438 16.4062 12 16 12C15.5625 12 15.25 12.3438 15.25 12.75V16.75C15.25 17.1875 15.5625 17.5 16 17.5ZM16 18.5625C15.4375 18.5625 15 19 15 19.5312C15 20.0625 15.4375 20.5 16 20.5C16.5312 20.5 16.9688 20.0625 16.9688 19.5312C16.9688 19 16.5312 18.5625 16 18.5625Z'
        fill='#672ED7'
      />
    </svg>
  )
}
