import { Typography } from '@mui/material'
import { useAtomValue } from 'jotai'

import qrcodeFinished from '@/presentation/assets/icons/qrcode-finished.svg'
import checkCircleGreen from '@/presentation/assets/icons/check-circle-green.svg'

import { Box } from 'presentation/components/atoms'
import { OrderStatusComponentsProps } from '../orderStatusComponentsProps'
import { ORDER_TYPES } from 'main/utils/constants'

import { PaymentFinished } from './styles'
import { formatMoney } from '@/utils/functionUtils'
import { usePix } from '@/main/hooks/usePix'
import { orderIdAtom } from '@/main/store'
import { useContext } from 'react'
import { OrderTrackingContext } from '@/presentation/pages/orderTracking/orderTracking'

export const Finished = ({ orderType = 0, paymentType }: OrderStatusComponentsProps) => {
  const orderId = useAtomValue(orderIdAtom)

  const { orderSummary } = useContext(OrderTrackingContext)
  if (paymentType.includes('PIX')) {
    return (
      <PaymentFinished>
        <h5>
          Pagamento confirmado <img src={checkCircleGreen} height={20} width={20} alt='check circle green' />
        </h5>
        <Box sx={{ margin: '24px 0' }}>
          <p>
            Valor: <span>R$ {formatMoney(orderSummary.totalPaid)}</span>
          </p>
        </Box>
        <Box className='qrcode-finished'>
          <img src={qrcodeFinished} alt='qrcode' />
          <img src={checkCircleGreen} alt='confirmed pix' />
        </Box>
      </PaymentFinished>
    )
  }

  let finalText = ''
  if ([ORDER_TYPES.FIRST_COPY, ORDER_TYPES.SECOND_COPY].includes(orderType))
    finalText = ' e os produtos já foram retirados'
  else if ([ORDER_TYPES.RECHARGE, ORDER_TYPES.BROKER].includes(orderType)) finalText = ' e a recarga já foi efetivada'
  return (
    <Typography variant='h3' sx={{ fontWeight: 500 }}>
      Seu pedido foi concluído{finalText}.
    </Typography>
  )
}
