import { useState } from 'react'
import { Controller } from 'react-hook-form'
import { Col } from 'react-grid-system'
import { Input, ErrorMessage, ErrorWrapper, Title, Spacing } from 'presentation/components'
import { Label, Trash, Row } from '../style'
import { getCep } from '../buscaCep'
import { pushNotification } from '@/store/modules/notification/actions'
import { useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import useService from 'main/hooks/useService'
import { getCityCode } from 'services/companiesServices'
import { NOTIFICATION_TYPE } from 'utils/constants'
import { useLocation } from 'react-router-dom'

const AddressesGrid = ({ errors, form, isMain, name, remove, addressTypeCodes, isLoading, laneTypeCodes }) => {
  const {
    state: { token },
  } = useLocation()
  const dispatch = useDispatch()
  const [errorCep, setErrorCep] = useState(false)
  const [hasReturnedNeighborhood, setHasReturnedNeighborhood] = useState(false)
  const [hasReturnedAddress, setHasReturnedAddress] = useState(false)

  const [, cityInfoRequest] = useService(getCityCode, {
    silent: true,
    onCompleted: (res) => {
      const cityCodeResponse = res?.data.data
      form.setValue(`${name}.cityCode`, cityCodeResponse[0].code)
    },
    onError: () => {
      dispatch(
        pushNotification({
          type: NOTIFICATION_TYPE.ERROR,
          content: 'Erro ao obter dados do endereço, tente inserir o CEP novamente em alguns instantes.',
        }),
      )
    },
  })

  async function cepInput(cep) {
    const cepWithoutMask = cep && cep.replace('-', '')
    if (cepWithoutMask?.length !== 8) return
    const response = await getCep(cepWithoutMask)
    form.setValue(`${name}.state`, response.uf, { shouldValidate: true })
    form.setValue(`${name}.city`, response.localidade, { shouldValidate: true })
    form.setValue(`${name}.neighborhood`, response.bairro, { shouldValidate: true })
    form.setValue(`${name}.laneName`, response.logradouro, { shouldValidate: true })
    cityInfoRequest(response.ibge, response.localidade, token)
    setHasReturnedNeighborhood(!!response.bairro)
    setHasReturnedAddress(!!response.logradouro)
    setErrorCep(!!response.erro)
  }

  return (
    <>
      <Row gutterWidth={40}>
        <Col sm={12}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Title.SubTitle>{isMain ? 'Endereço Principal' : 'Endereço de Entrega'}</Title.SubTitle>
            <Spacing left='16px' />
            {!isMain && (
              <Trash>
                <FontAwesomeIcon icon={faTrashAlt} onClick={remove} />
              </Trash>
            )}
          </div>
        </Col>
      </Row>
      <Row gutterWidth={40}>
        <Col sm={3}>
          <ErrorWrapper error={errors?.cep || errorCep}>
            <Label>CEP *</Label>
            <Controller
              control={form.control}
              name={`${name}.cep`}
              render={({ field: { onChange, value, ref } }) => (
                <Input.Masked
                  value={value}
                  format='#####-###'
                  mask=''
                  placeholder='00000-000'
                  getInputRef={ref}
                  onChange={(e) => {
                    cepInput(e.target.value)
                    onChange(e)
                  }}
                />
              )}
            />
            {errors?.cep && <ErrorMessage>{errors?.cep.message}</ErrorMessage>}
            {errorCep && !errors?.cep && <ErrorMessage>CEP inválido</ErrorMessage>}
          </ErrorWrapper>
        </Col>
        <Col sm={3}>
          <Label>Estado</Label>
          <Input placeholder='Estado' disabled={true} {...form['register'](`${name}.state`)} />
        </Col>
        <Col sm={3}>
          <Label>Cidade</Label>
          <Input
            onChange={(e) => {
              alert()
            }}
            placeholder='Cidade'
            disabled={true}
            {...form['register'](`${name}.city`)}
          />
        </Col>
        <Col sm={3}>
          <ErrorWrapper error={errors?.neighborhood}>
            <Label>Bairro *</Label>
            <Input
              placeholder='Bairro'
              maxLength='50'
              disabled={hasReturnedNeighborhood}
              {...form['register'](`${name}.neighborhood`)}
            />
            {errors?.neighborhood && <ErrorMessage>{errors.neighborhood.message}</ErrorMessage>}
          </ErrorWrapper>
        </Col>
      </Row>
      <Row gutterWidth={40}>
        <Col sm={3}>
          <ErrorWrapper error={errors?.laneTypeCode}>
            <Label>Tipo de Logradouro *</Label>
            <Controller
              control={form.control}
              name={`${name}.laneTypeCode`}
              render={({ field: { onChange, value, ref } }) => (
                <Input.Select
                  placeholder='Selecione'
                  value={laneTypeCodes.find((laneType) => laneType.code === value)}
                  inputRef={ref}
                  onChange={(option) => onChange(option.code)}
                  options={laneTypeCodes}
                  getOptionValue={(option) => option.code}
                  getOptionLabel={(option) => option.description}
                  isLoading={isLoading}
                  isDisabled={isLoading}
                  isClearable={false}
                />
              )}
            />
            {errors?.laneTypeCode && <ErrorMessage>{errors.laneTypeCode?.code?.message}</ErrorMessage>}
          </ErrorWrapper>
        </Col>
        <Col sm={4}>
          <ErrorWrapper error={errors?.laneName}>
            <Label>Endereço *</Label>
            <Input
              placeholder='Endereço'
              maxLength='120'
              disabled={hasReturnedAddress}
              {...form['register'](`${name}.laneName`)}
            />
            {errors?.laneName && <ErrorMessage>{errors?.laneName.message}</ErrorMessage>}
          </ErrorWrapper>
        </Col>
        <Col sm={2}>
          <ErrorWrapper error={errors?.number}>
            <Label>Número</Label>
            <Input maxLength='5' placeholder='000' {...form['register'](`${name}.number`)} />
            {errors?.number && <ErrorMessage>{errors?.number.message}</ErrorMessage>}
          </ErrorWrapper>
        </Col>
        <Col sm={3}>
          <ErrorWrapper error={errors?.complement}>
            <Label>Complemento</Label>
            <Input maxLength='30' placeholder='Nº apartamento, bloco...' {...form['register'](`${name}.complement`)} />
            {errors?.complement && <ErrorMessage>{errors?.complement.message}</ErrorMessage>}
          </ErrorWrapper>
        </Col>

        <input style={{ display: 'none' }} {...form['register'](`${name}.cityCode`)} />
      </Row>
      <Row gutterWidth={40}>
        <Col sm={5}>
          <ErrorWrapper error={errors?.addressCode}>
            <Label>Tipo de Endereço *</Label>
            <Controller
              control={form.control}
              name={`${name}.addressCode`}
              render={({ field: { onChange, value, ref } }) => (
                <Input.Select
                  placeholder='Selecione'
                  value={addressTypeCodes.find((addressType) => addressType.code === value)}
                  inputRef={ref}
                  onChange={(option) => onChange(option.code)}
                  options={isMain ? addressTypeCodes : addressTypeCodes.filter((value) => value.code !== 'P')}
                  getOptionValue={(option) => option.code}
                  getOptionLabel={(option) => option.description}
                  isLoading={isLoading}
                  isDisabled={isLoading}
                  isClearable={false}
                />
              )}
            />
            {errors?.addressCode && <ErrorMessage>{errors.addressCode?.code?.message}</ErrorMessage>}
          </ErrorWrapper>
        </Col>
        <Col sm={3}>
          <ErrorWrapper error={errors?.name}>
            <Label>Nome do Endereço *</Label>
            <Input maxLength='30' placeholder='Filial, Sede...' {...form['register'](`${name}.name`)} />
            {errors?.name && <ErrorMessage>{errors?.name.message}</ErrorMessage>}
          </ErrorWrapper>
        </Col>
      </Row>
      <Row style={{ display: isMain ? '' : 'none' }}>
        <Col sm={12}>
          <Controller
            control={form.control}
            name={`${name}.canDeliver`}
            render={({ field: { onChange, value } }) => (
              <Input.CheckBox onChange={onChange} checked={!isMain ? true : value} />
            )}
          />
          {isMain && (
            <div style={{ display: 'none' }}>
              <Controller
                control={form.control}
                name={`${name}.isMain`}
                render={({ field: { onChange } }) => <Input.CheckBox onChange={onChange} checked={true} />}
              />
            </div>
          )}
          <span style={{ marginLeft: '8px' }}>Usar este endereço como endereço de entrega também</span>
        </Col>
      </Row>
    </>
  )
}

export default AddressesGrid
