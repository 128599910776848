import api from '@/infra/api'
import { useMutation } from '@tanstack/react-query'

export const useOrderCancelation = () => {
  const cancelOrder = useMutation({
    mutationKey: ['cancelNewApiOrder'],
    mutationFn: ({ orderId }: { orderId: number }) => {
      return api.patch(`rh-pedidos-bff/pedido/cancelar/${orderId}`)
    },
  })

  const cancelPurchase = useMutation({
    mutationKey: ['cancelNewApiPurchase'],
    mutationFn: ({ purchaseId }: { purchaseId: number | string }) => {
      return api.patch(`rh-pedidos-bff/carrinho/${purchaseId}/cancelar`)
    },
  })

  return {
    cancelOrder,
    cancelPurchase,
  }
}
