import styled from 'styled-components'
import { Row as GridRow } from 'react-grid-system'
import { BREAK_POINTS } from '@/utils/constants'

export const Label = styled.div`
  margin-bottom: 10px;
  white-space: nowrap;

  @media (max-width: ${BREAK_POINTS.md}) {
    margin-top: 16px;
  }
`

export const Row = styled(GridRow)`
  margin-top: 32px;
  @media (max-width: ${BREAK_POINTS.md}) {
    margin-top: 0px;
  }
  @media (max-width: ${BREAK_POINTS.xl}) {
    div:nth-child(4) {
      margin-top: 16px;
      div {
        margin-top: 0;
      }
    }
  }
`

export const FieldSet = styled.fieldset`
  border: none;
`

export const TextDecoration = styled.p`
  color: var(--color-primary-01);
  font-size: 0.875rem;
  font-weight: var(--font-weight-semi-bold);
  letter-spacing: 0;
`

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-top: 29px;
  }
`

export const DualLabel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > :last-child {
    cursor: ${({ pointer }) => pointer && 'pointer'};
    margin-bottom: 10px;
  }
`

export const CentralizeText = styled.div`
  text-align: center;
`

export const HasOderText = styled.span`
  font-size: 1rem;

  p:first-of-type {
    font-weight: var(--font-weight-semi-bold);
    font-size: 1.5rem;
    margin-top: 64px;
  }
  p:last-of-type {
    margin-bottom: 64px;
  }

  svg {
    height: 128px;
    width: 128px;
    transform: rotate(180deg);
    fill: #cfffb9;
  }
`

export const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;

  > :last-child {
    margin-left: 16px;
    margin-bottom: 0;
  }
`
