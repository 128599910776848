import { all, takeLatest, call, put } from 'redux-saga/effects'
import { getUsersInSuccess, getUsersInFailure } from './actions'
import api from '@/infra/api'

function* getUsers({ payload }) {
  try {
    const response = yield call(api.get, `/users-service/data/paged${payload.query}`)

    const data = response.data

    yield put(getUsersInSuccess(data))
  } catch (error) {
    yield put(getUsersInFailure(error.response))
  }
}

export default all([takeLatest('@users/GETUSERS_REQUEST', getUsers)])
