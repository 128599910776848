import Breadcrumbs from '@mui/material/Breadcrumbs'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { StyledTypography } from './styles'
import { Icon } from 'presentation/components/atoms'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { breadcrumbNameMap } from 'main/utils/breadcrumbNameMap'

export const Breadcrumb = ({ lastItemTitle }: { lastItemTitle?: string | null }) => {
  const location = useLocation()
  const pathnames = location.pathname.split('/').filter((x) => x)

  return (
    <Breadcrumbs
      separator={<Icon icon={faChevronRight} sx={{ height: '.8rem', marginTop: '2px' }} />}
      aria-label='breadcrumb'
    >
      <Link to='/'>
        <StyledTypography variant='text2'>Início</StyledTypography>
      </Link>
      {pathnames.map((value, index) => {
        const last = index === pathnames.length - 1
        const to = `/${pathnames.slice(0, index + 1).join('/')}`
        const displayName = breadcrumbNameMap[to] ? breadcrumbNameMap[to] : to.replace('/', '')
        return last ? (
          <StyledTypography key={to} variant='text2' sx={{ color: 'primary.main' }}>
            {lastItemTitle ?? displayName}
          </StyledTypography>
        ) : (
          <Link color='inherit' to={to} key={to}>
            <StyledTypography key={to} variant='text2' sx={{ textTransform: 'capitalize' }}>
              {displayName}
            </StyledTypography>
          </Link>
        )
      })}
    </Breadcrumbs>
  )
}
