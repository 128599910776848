/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'

import { getRechageOrderDetails, patchRechargeExactValue, patchRechargeValueByDays } from '@/services/rechargesServices'

import { useNotification } from 'main/hooks/useNotification'

export const useUpdateValues = ({ location }) => {
  const dispatch = useDispatch()
  const { errorNotification } = useNotification()

  const orderId = location.state.orderId
  const [orderInfo, setOrderInfo] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [employeesData, setEmployeesData] = useState(null)
  const [isGlobalOrderModalVisible, setIsGlobalOrderModalVisible] = useState(true)

  const daysInitialValue = useRef(null)
  const rechargeValueInitialValue = useRef(null)

  useEffect(() => {
    ;(async () => {
      setIsLoading(true)
      const { error, response } = await getRechageOrderDetails(orderId)
      setIsLoading(false)
      if (response) {
        setOrderInfo(response.data)
        setEmployeesData(response.data?.items)
      } else {
        errorNotification(error.response)
      }
    })()
  }, [orderId, dispatch])

  const updateOrderValues = async (request) => {
    setIsLoading(true)
    const { error, response } = await request()
    setIsLoading(false)
    if (response) {
      setOrderInfo(response.data)
      setEmployeesData(response.data?.items)
      setIsGlobalOrderModalVisible(false)
    } else {
      errorNotification(error.response)
    }
  }

  const updateTotalRecharge = ({ employee, value }) => {
    if (rechargeValueInitialValue.current === value) return
    updateOrderValues(() => patchRechargeExactValue(orderId, employee.itemId, value))
  }

  const updateDays = ({ employee, value }) => {
    if (daysInitialValue.current === value) return
    updateOrderValues(() => patchRechargeValueByDays(orderId, employee.itemId, value))
  }

  return {
    updateOrderValues,
    orderInfo,
    isLoading,
    orderId,
    employeesData,
    isGlobalOrderModalVisible,
    setIsGlobalOrderModalVisible,
    daysInitialValue,
    rechargeValueInitialValue,
    updateTotalRecharge,
    updateDays,
  }
}
