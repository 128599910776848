import { Row, Col } from 'react-grid-system'
import IconWithPopoverBenefit from '../IconWithPopoverBenefit/iconWithPopoverBenefit'

const { faUtensils, faBus, faGasPump, faShoppingCart } = require('@fortawesome/free-solid-svg-icons')

const IconListBenefit = ({ itemsList }) => {
  const EBenefitType = {
    Transport: 1,
    Food: 2,
    Snack: 3,
    Fuel: 4,
    TransportPaper: 5,
    1: 'Vale Transporte Eletrônico',
    2: 'Vale Refeição',
    3: 'Vale Alimetação',
    4: 'Vale Combustível',
    5: 'Vale Transporte Papel',
  }

  const items = itemsList.map((benefit) => {
    let title
    let icon
    let color
    switch (benefit.benefitType) {
      case EBenefitType.Transport:
        title = EBenefitType[EBenefitType.Transport]
        icon = faBus
        color = '#5e28ca'
        break
      case EBenefitType.Food:
        title = EBenefitType[EBenefitType.Food]
        icon = faUtensils
        color = '#f76c39'
        break
      case EBenefitType.Snack:
        title = EBenefitType[EBenefitType.Snack]
        icon = faShoppingCart
        color = '#e01fff'
        break
      case EBenefitType.Fuel:
        title = EBenefitType[EBenefitType.Fuel]
        icon = faGasPump
        color = '#00dc75'
        break
      case EBenefitType.TransportPaper:
        title = EBenefitType[EBenefitType.TransportPaper]
        icon = faBus
        color = '#5e28ca'
        break
      default:
        title = ''
    }

    return {
      icon,
      title,
      listItems: benefit?.cards?.map((card) => card.cardName),
      color,
    }
  })

  return (
    <Row>
      {items.map((item, index) => (
        <Col key={item} xs={6} sm={3}>
          <IconWithPopoverBenefit {...item} />
        </Col>
      ))}
    </Row>
  )
}

export default IconListBenefit
