import React, { useState } from 'react'
import { Container, Col } from 'react-grid-system'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { getCep } from './buscaCep'
import { Input, Button, ErrorMessage, ErrorWrapper } from 'presentation/components'
import { Fieldset, Label, Row } from './style'
import schema from '../schema/addressInformation'
import { FORM_MODE } from '@/utils/constants'

const AddressInformation = ({ cancel, changeForm, mode, nextTab, prevTab, saveOnStore, employeeData }) => {
  const [hasReturned, setHasReturned] = useState({
    district: !!employeeData?.address?.district,
    addressDescript: !!employeeData?.address?.addressDescript,
  })
  const [errorCep, setErrorCep] = useState(false)

  const readOnly = mode === FORM_MODE.VIEW ? true : false
  let defaultValues = {}
  if (employeeData != null) {
    defaultValues = {
      cep: employeeData?.address?.cep || undefined,
      state: employeeData?.address?.state || undefined,
      city: employeeData?.address?.city || undefined,
      addressDescript: employeeData?.address?.addressDescript || undefined,
      numberAddress: employeeData?.address?.numberAddress || undefined,
      complement: employeeData?.address?.complement || undefined,
      district: employeeData?.address?.district || undefined,
    }
  }

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    defaultValues: defaultValues,
    mode: 'onChange',
    resolver: yupResolver(schema),
  })

  const requestCep = async (ev) => {
    if (!ev) {
      return
    }

    const removed = ev.replace('-', '')

    if (removed.length === 8) {
      const search = await getCep(removed, setErrorCep)
      setValue('city', search?.localidade)
      setValue('state', search?.uf)
      setValue('addressDescript', search?.logradouro)
      setValue('district', search?.bairro)
      setHasReturned({
        district: !!search?.bairro,
        addressDescript: !!search?.logradouro,
      })
      search?.erro ? setErrorCep(true) : setErrorCep(false)
    } else if (removed.length === 0) {
      reset({})
      setErrorCep(false)
      setHasReturned({
        district: false,
        addressDescript: false,
      })
    }
  }

  const inputCep = (onChange, value) => {
    onChange(value)
    requestCep(value.target.value)
  }

  const onSubmit = (data) => {
    if (errorCep) return
    saveOnStore(data)
    nextTab()
  }

  changeForm(() => {
    if (errorCep) return
    handleSubmit(saveOnStore)()
  })

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} id='addressInformation'>
        <Fieldset disabled={readOnly}>
          <Container style={{ margin: 0, padding: 0, maxWidth: 'initial' }}>
            <Row>
              <Col sm={12} md={3}>
                <span>
                  <ErrorWrapper error={errors.cep || errorCep}>
                    <Label>CEP</Label>
                    <Controller
                      control={control}
                      name='cep'
                      required
                      render={({ field: { onChange, value, ref } }) => (
                        <Input.Masked
                          value={value}
                          format='#####-###'
                          mask=''
                          placeholder='00000-000'
                          id='employeeForm_cep'
                          getInputRef={ref}
                          onChange={(ev) => inputCep(onChange, ev)}
                        />
                      )}
                    />
                    {errors.cep && <ErrorMessage>{errors.cep.message}</ErrorMessage>}
                    {errorCep && !errors.cep && <ErrorMessage>CEP inválido</ErrorMessage>}
                  </ErrorWrapper>
                </span>
              </Col>
              <Col sm={12} md={3}>
                <span>
                  <Label>Estado</Label>
                  <Input placeholder='Estado' id='employeeForm_state' disabled={true} {...register('state')} />
                </span>
              </Col>
              <Col sm={12} md={3}>
                <span>
                  <Label>Cidade</Label>
                  <Input placeholder='Cidade' id='employeeForm_city' disabled={true} {...register('city')} />
                </span>
              </Col>
              <Col sm={12} md={3}>
                <ErrorWrapper error={errors.district}>
                  <Label>Bairro</Label>
                  <Input
                    placeholder='Bairro'
                    maxLength='60'
                    disabled={!!hasReturned.district}
                    id='employeeForm_district'
                    {...register('district')}
                  />
                  {errors.district && <ErrorMessage>{errors.district.message}</ErrorMessage>}
                </ErrorWrapper>
              </Col>
            </Row>

            <Row>
              <Col sm={12} md={4} xl={3}>
                <ErrorWrapper error={errors.addressDescript}>
                  <Label>Endereço</Label>
                  <Input
                    placeholder='Endereço'
                    maxLength='120'
                    disabled={!!hasReturned.addressDescript}
                    id='employeeForm_addressDescript'
                    {...register('addressDescript')}
                  />
                  {errors.addressDescript && <ErrorMessage>{errors.addressDescript.message}</ErrorMessage>}
                </ErrorWrapper>
              </Col>
              <Col sm={12} md={4} xl={3}>
                <ErrorWrapper error={errors.numberAddress}>
                  <Label>Número</Label>
                  <Input
                    maxLength='5'
                    placeholder='000'
                    id='employeeForm_numberAddress'
                    {...register('numberAddress')}
                  />
                  {errors.numberAddress && <ErrorMessage>{errors.numberAddress.message}</ErrorMessage>}
                </ErrorWrapper>
              </Col>
              <Col sm={12} md={4} xl={3}>
                <ErrorWrapper error={errors.complement}>
                  <Label>Complemento</Label>
                  <Input
                    maxLength='50'
                    id='employeeForm_complement'
                    placeholder='Nº apartamento, bloco...'
                    {...register('complement')}
                  />
                  {errors.complement && <ErrorMessage>{errors.complement.message}</ErrorMessage>}
                </ErrorWrapper>
              </Col>
            </Row>
          </Container>
        </Fieldset>
      </form>
      <Row justify='end' gutterWidth={16} style={{ marginTop: '8vh' }}>
        <Col sm='content'>
          {mode === FORM_MODE.VIEW ? (
            <Button variant='outlined' onClick={prevTab} id='voltar'>
              Voltar
            </Button>
          ) : (
            <Button variant='outlined' onClick={cancel} id='cancelar'>
              Cancelar
            </Button>
          )}
        </Col>
        <Col sm='content'>
          {mode === FORM_MODE.VIEW ? (
            <Button type='button' onClick={nextTab} id='proximo'>
              Próximo
            </Button>
          ) : (
            <Button type='submit' form='addressInformation' id='avancar'>
              Avançar
            </Button>
          )}
        </Col>
      </Row>
    </>
  )
}
export default AddressInformation
