import { useCallback } from 'react'
import { AxiosResponse } from 'axios'
import { useAtomValue } from 'jotai'
import { useQuery } from '@tanstack/react-query'
import { IFetchHook } from '@stationkim/front-ui'
import { AccountPersistentStore } from '@/main/store'
import api from '@/infra/api'
import { decode } from 'jsonwebtoken'
interface IOrder {
  id: number
  idCompra: number
  numeroPedido: number
  dataCriacao: string
  operadora: string
  valor: number
  status: number
  statusCompra: number
  tipoPedido: number
  idCompraCriptografado: string
  compraComMaisDeUmPedido: boolean
  quantidadeItensUnicos: number
}

interface IValor {
  pageCount: number
  pageNumber: number
  pageSize: number
  totalItemCount: number
  pedidos: IOrder[]
}

interface IApiResponse {
  mensagens: string[]
  sucesso: boolean
  valor: IValor
}

export interface IOrderRow {
  id: number
  purchaseIdEncrypted: string
  multipleItemsOnPurchase: boolean
  code: number
  purchaseId: number
  orderDate: string
  operatorName: string
  status: number
  purchaseStatus: number
  orderType: number
  totalValue: number
  itemsOnOrder: number
}
interface IOrganizedData {
  rows: Array<IOrderRow>
  page: number
  pageSize: number
  totalPages: number
  totalItens: number
}

export const useRechargesList = ({ filter, pagination, immutableQuery }: IFetchHook) => {
  const account = useAtomValue(AccountPersistentStore)
  const companyId = account.company?.id
  const resaleId = decode(account.auth.accessToken)?.resaleId

  const { data, ...rest } = useQuery<AxiosResponse<IApiResponse>>({
    queryKey: ['rechargesList', companyId, resaleId, filter, pagination, immutableQuery],
    queryFn: () =>
      api.get(`rh-pedidos-bff/pedido?idEmpresa=${companyId}&idRevenda=${resaleId}`, {
        params: { ...pagination, ...filter, ...immutableQuery },
      }),
  })

  const organizeData = useCallback((): IOrganizedData => {
    const dataToManipulate = data?.data?.valor
    if (!dataToManipulate) return {} as IOrganizedData

    return {
      rows: dataToManipulate.pedidos.map((order) => ({
        id: order.id,
        purchaseIdEncrypted: order.idCompraCriptografado,
        multipleItemsOnPurchase: order.compraComMaisDeUmPedido,
        code: order.numeroPedido,
        purchaseId: order.idCompra,
        orderDate: order.dataCriacao,
        operatorName: order.operadora,
        status: order.status,
        purchaseStatus: order.statusCompra,
        orderType: order.tipoPedido,
        totalValue: order.valor,
        itemsOnOrder: order.quantidadeItensUnicos,
      })),
      page: dataToManipulate.pageNumber,
      pageSize: dataToManipulate.pageSize,
      totalPages: dataToManipulate.pageCount,
      totalItens: dataToManipulate.totalItemCount,
    }
  }, [data])

  return {
    isLoading: rest.isLoading,
    ...organizeData(),
  }
}
