import styled from 'styled-components'

export const Body = styled.div`
  padding: 30px;
  z-index: 99;

  p + p {
    margin-top: 16px;
  }
`

export const Title = styled.p`
  color: var(--color-primary-01);
  font-weight: var(--font-weight-bold);
  font-size: 1.125rem;
  letter-spacing: 0.29px;
  padding-bottom: 16px;
`

export const ColorDest = styled.span`
  color: #632ecb;
  font-weight: bold;
`

export const SessionButton = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-width: 50vh;
  margin: 10vh auto 0 auto;
  button {
    max-width: 150px;
  }
`

export const Close = styled.p`
  cursor: pointer;
  text-align: right;
  font-size: 20px;

  svg {
    fill: var(--color-primary-03);
  }
`
