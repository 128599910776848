import produce from 'immer'
import { ACTIONS } from './actions'

const INITIAL_STATE = {
  data: {
    address: {},
    professionalData: {},
  },
  loading: false,
  success: null,
  failure: null,
}

const CLEAN_FIELD_REGEX = /\(|\)|-|\.| /g

export default function employee(state = INITIAL_STATE, action) {
  const { type, payload } = action

  return produce(state, (draft) => {
    switch (type) {
      case ACTIONS.GET_EMPLOYEE_REQUEST: {
        draft.data = null
        draft.loading = true
        break
      }

      case ACTIONS.EMPLOYEE_REQUEST_SUCCESS: {
        draft.data = payload.data
        draft.loading = false
        draft.failure = null
        break
      }

      case ACTIONS.EMPLOYEE_REQUEST_FAILURE: {
        draft.loading = false
        draft.failure = payload.data
        break
      }

      case ACTIONS.STORE_EMPLOYEE_PERSONAL_DATA: {
        payload.cpf = payload?.cpf?.replace(CLEAN_FIELD_REGEX, '')
        payload.gender = payload.gender ? Number(payload.gender.value) : undefined
        payload.state = payload.state ? payload.state.value : undefined
        draft.data = { ...state.data, ...payload }
        break
      }

      case ACTIONS.STORE_EMPLOYEE_ADDRESS_DATA: {
        payload.cep = payload.cep ? payload?.cep?.replace(CLEAN_FIELD_REGEX, '') : undefined
        draft.data = { ...state.data, address: payload }
        break
      }

      case ACTIONS.STORE_EMPLOYEE_PROFESSIONAL_DATA: {
        payload.sector = payload.sector ? payload.sector.value : undefined
        payload.workingPlace = payload.workingPlace ? payload.workingPlace.id : undefined
        payload.cellphoneNumber = payload.cellphoneNumber
          ? payload.cellphoneNumber.replace(CLEAN_FIELD_REGEX, '')
          : undefined
        payload.secondCellphoneNumber = payload.secondCellphoneNumber
          ? payload.secondCellphoneNumber.replace(CLEAN_FIELD_REGEX, '')
          : undefined
        payload.dailyRates = payload.dailyRates
          ? payload.dailyRates.map((rate) => ({ ...rate, productId: rate.productId?.id }))
          : undefined
        payload.associateCard = payload.associateCard?.cardId
        payload.departmentId = payload.departmentId?.id
        if (Boolean(payload.id)) {
          draft.data = { ...state.data, id: payload.id, professionalData: payload }
        } else {
          draft.data = { ...state.data, professionalData: payload }
        }
        break
      }

      case ACTIONS.STORE_EMPLOYEE_DATA: {
        draft.data = payload
        break
      }

      case ACTIONS.CLEAR_EMPLOYEE_STORE: {
        draft.data = null
        break
      }

      default:
    }
  })
}
