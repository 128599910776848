import { Button, Input, ModalV2, Spinner } from 'presentation/components'
import { getAnaliticCardBalanceReport } from '@/services/reportsServices'
import React, { useState } from 'react'
import { Col } from 'react-grid-system'
import { useForm } from 'react-hook-form'
import { CheckBoxWrapper, CustomRow, ModalTitle } from './styles'
import { CustomContainer } from '../styles'
import CardInfoFields from '../../formForFilter/cardInfoFields'
import useDownloadReport from 'main/hooks/useDownloadReport'
import { ReportFormatSelector } from '../../components/reportFormatSelector'

const AnaliticCardBalanceReportFilter = ({
  open,
  onClose,
  availableFormatsOptions = [
    {
      value: 'pdf',
      label: 'PDF',
    },
  ],
}) => {
  const [allCards, setAllCards] = useState(false)
  const [selectedFormat, setSelectedFormat] = useState(availableFormatsOptions[0].value)
  const [downloadState, download] = useDownloadReport((queryData) =>
    getAnaliticCardBalanceReport(queryData, selectedFormat),
  )

  const { handleSubmit, control, formState } = useForm({
    mode: 'onChange',
    defaultValues: {
      cardIds: [],
      cardStatus: null,
    },
  })

  const isButtonDisabled = !formState.dirtyFields.cardIds && !formState.dirtyFields.cardStatus && !allCards

  const onSubmit = (data) => {
    if (!data) return
    let queryData = {}
    if (!allCards) {
      if (data.cardIds.length > 0) queryData.cardIds = data.cardIds.map((card) => card.id)
      if (data.cardStatus) queryData.cardStatus = Number(data.cardStatus.value)
    }
    download(`Relatório de Saldo Analítico.${selectedFormat}`, queryData)
  }

  return (
    <ModalV2 open={open} onClose={onClose}>
      {downloadState.loading && <Spinner />}
      <form
        onSubmit={handleSubmit(onSubmit)}
        id='AnaliticCardBalanceReportFilterForm'
        style={{ display: downloadState.loading ? 'none' : 'initial' }}
      >
        <CustomContainer fluid>
          <CustomRow>
            <Col>
              <ModalTitle>Relatório de Saldo Analítico</ModalTitle>
            </Col>
          </CustomRow>
          <CardInfoFields control={control} formState={formState} disabled={allCards} />
          <CustomRow>
            <Col>
              <CheckBoxWrapper>
                <Input.CheckBox controlled checked={allCards} onClick={() => setAllCards((state) => !state)} />
                <span>Todos os cartões</span>
              </CheckBoxWrapper>
            </Col>
          </CustomRow>
          <CustomRow>
            <Col sm={2}>
              <ReportFormatSelector
                availableFormatsOptions={availableFormatsOptions}
                onChange={(value) => setSelectedFormat(value)}
                value={selectedFormat}
                customRadio={Input.CheckBox}
                sx={{ margin: '16px 0' }}
              />
            </Col>
          </CustomRow>
          <CustomRow justify='end'>
            <Col sm={5}>
              <Button disabled={isButtonDisabled}>Gerar Relatório</Button>
            </Col>
          </CustomRow>
        </CustomContainer>
      </form>
    </ModalV2>
  )
}

export default AnaliticCardBalanceReportFilter
