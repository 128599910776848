import { SimplePaletteColorOptions, createTheme } from '@mui/material'

declare module '@mui/material/styles' {
  interface Theme {
    staticSize: {
      header: number
      footer: number
    }
  }
  interface ThemeOptions {
    staticSize: {
      header: number
      footer: number
    }
  }
  interface Palette {
    brand: { primary: SimplePaletteColorOptions; secondary: SimplePaletteColorOptions }
    benefits: {
      food: SimplePaletteColorOptions
      fuel: SimplePaletteColorOptions
      snack: SimplePaletteColorOptions
      transport: SimplePaletteColorOptions
    }
  }
  interface PaletteOptions {
    brand: { primary: SimplePaletteColorOptions; secondary: SimplePaletteColorOptions }
    benefits: {
      food: SimplePaletteColorOptions
      fuel: SimplePaletteColorOptions
      snack: SimplePaletteColorOptions
      transport: SimplePaletteColorOptions
    }
  }
  interface CommonColors {
    light: string
    purple: string
  }
  interface TypographyVariants {
    label?: React.CSSProperties
    label2?: React.CSSProperties
    label3?: React.CSSProperties
    text2?: React.CSSProperties
    fontWeightSemiBold?: number
  }
  interface TypographyVariantsOptions {
    label?: React.CSSProperties
    label2?: React.CSSProperties
    label3?: React.CSSProperties
    text2?: React.CSSProperties
    fontWeightSemiBold?: number
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    text2: true
    label: true
    label2: true
    label3: true
  }
}

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1336,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: '#1976D2',
      light: '#42A5F5',
      dark: '#1565C0',
    },
    secondary: {
      main: '#82aad1',
      light: '#42A5F5',
      dark: '#1565C0',
    },
    error: {
      main: '#D32F2F',
      light: '#EF5350',
      dark: '#C62828',
    },
    success: {
      main: '#00DC75',
      light: '#32FF9F',
      dark: '#02AA5B',
    },
    warning: {
      main: '#ED6C02',
      light: '#FF9800',
      dark: '#E65100',
    },
    info: {
      main: '#5E26CA',
      light: '#7131F2',
      dark: '#421C8D',
    },
    grey: {
      50: '#F3F6F9',
      100: '#E7EBF0',
      200: '#E0E3E7',
      300: '#E0E0E0',
      400: '#B2BAC2',
      500: '#9E9E9E',
      600: '#6F7E8C',
      700: '#616161',
      800: '#2D3843',
      900: '#212121',
    },
    common: {
      light: '#BDBDBD',
      purple: '#672ED7',
      black: '#060606',
    },
    brand: {
      primary: {
        main: '#672ED7',
        light: '#803FFF',
        dark: '#432184',
      },
      secondary: {
        main: '#F76C39',
        light: '#FF9169',
        dark: '#F44C0E',
      },
    },
    benefits: {
      food: {
        main: '#FF6AC3',
        light: '#FF7AE0',
        dark: '#D95AA6',
      },
      fuel: {
        main: '#24E19D',
        light: '#29FFB5',
        dark: '#1FBF85',
      },
      snack: {
        main: '#FFB054',
        light: '#FFCA61',
        dark: '#D99647',
      },
      transport: {
        main: '#6D49FF',
        light: '#9061FF',
        dark: '#5D3ED9',
      },
    },
  },
  staticSize: {
    header: 72,
    footer: 72,
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif',
    body1: {
      lineHeight: '1.5rem',
      fontSize: '1rem',
      fontWeight: 400,
      fontFamily: 'Montserrat, sans-serif',
    },
    button: {
      lineHeight: '1.5rem',
      fontSize: '1.125rem',
      fontWeight: 700,
      fontFamily: 'Montserrat, sans-serif',
    },
    h1: {
      lineHeight: '2.5rem',
      fontSize: '2rem',
      fontWeight: 700,
      fontFamily: 'Montserrat, sans-serif',
    },
    h2: {
      lineHeight: '2rem',
      fontSize: '1.5rem',
      fontWeight: 700,
      fontFamily: 'Montserrat, sans-serif',
    },
    h3: {
      lineHeight: '2rem',
      fontSize: '1.5rem',
      fontWeight: 400,
      fontFamily: 'Montserrat, sans-serif',
    },
    h4: {
      lineHeight: '1.5rem',
      fontSize: '1.125rem',
      fontWeight: 700,
      fontFamily: 'Montserrat, sans-serif',
    },
    h5: {
      lineHeight: '1.5rem',
      fontSize: '1.125rem',
      fontWeight: 600,
      fontFamily: 'Montserrat, sans-serif',
    },
    label: {
      lineHeight: '1.5rem',
      fontSize: '1rem',
      fontWeight: 500,
      fontFamily: 'Montserrat, sans-serif',
    },
    label2: {
      lineHeight: '1.125rem',
      fontSize: '0.875em',
      fontWeight: 600,
      fontFamily: 'Montserrat, sans-serif',
    },
    label3: {
      fontFamily: 'Montserrat, sans-serif',
      lineHeight: '1.5rem',
      fontSize: '0.875em',
      fontWeight: 500,
    },
    subtitle1: {
      lineHeight: '1.5rem',
      fontSize: '1.125rem',
      fontWeight: 600,
      fontFamily: 'Montserrat, sans-serif',
    },
    subtitle2: {
      lineHeight: '1.5rem',
      fontSize: '1rem',
      fontWeight: 700,
      fontFamily: 'Montserrat, sans-serif',
    },
    text2: {
      lineHeight: '1rem',
      fontSize: '0.75em',
      fontWeight: 500,
      fontFamily: 'Montserrat, sans-serif',
    },
    fontWeightSemiBold: 600,
  },
})

export const defaultTheme = createTheme(theme, {
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
      variants: [
        {
          props: { variant: 'text' },
          style: {
            textDecoration: 'underline',
            color: theme.palette.grey[500],
            '&:hover': {
              textDecoration: 'underline',
              backgroundColor: 'transparent',
            },
          },
        },
        {
          props: { variant: 'softText' },
          style: ({ ownerState, theme }: { ownerState: any; theme: any }) => ({
            color: theme.palette[ownerState.color].main,
            fontWeight: 400,
            '& svg': {
              height: 18,
              width: 16,
            },
          }),
        },
        {
          props: { size: 'small' },
          style: {
            height: '36px',
            fontSize: '.875rem',
          },
        },
        {
          props: { size: 'medium' },
          style: {
            height: '42px',
            fontSize: '1rem',
          },
        },
        {
          props: { size: 'large' },
          style: {
            height: '48px',
            fontSize: '1rem',
          },
        },
      ],
    },
    MuiChip: {
      variants: [
        {
          props: { shape: 'rectangle' },
          style: {
            padding: '6px 4px',
            borderRadius: 8,
          },
        },
      ],
    },
  },
})
