import { BREAK_POINTS } from '@/utils/constants'
import { Row } from 'react-grid-system'
import styled from 'styled-components'

export const CustomRow = styled(Row)`
  & + & {
    margin-top: 16px;
  }
`

export const CheckBoxWrapper = styled.div`
  display: flex;
  span {
    margin-left: 8px;
  }

  @media (max-width: ${BREAK_POINTS.md}) {
    margin-top: 16px;
  }
`

export const ModalTitle = styled.div`
  font-size: 1.25rem;
  font-weight: var(--font-weight-bold);
  margin-bottom: 16px;
`
