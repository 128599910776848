interface Iinfos {
  numberOfColaborators: number
  dueDate: null
  orderDate: string
  code: number
  confirmation: {
    paymentFeeValue: number
    transferFeeValue: number
    useOfWallet: number
    useOfCheckingAccount: boolean
    issFeeValue: number
    subTotalValue: number
    totalAmountPayable: number
    cardsCount: number
  }
  ticketingBalance: number
  oldTicketingBalance: number
  walletBalance: number
}

type IInfosData = {
  infos: Iinfos
  orderId: string
}

export interface IDetails {
  administrateFee: number
  totalTransferFeeValue: number
  ticketingBalance: number
  oldTicketingBalance: number
  issFee: number
  totalValue: number
  totalItens: number
  totalRechargeValue: number
  orderId?: string
  operatorCardName?: string
}

export const detailsData = ({ infos, orderId }: IInfosData) => {
  return [
    {
      orderId,
      administrateFee: infos.confirmation.paymentFeeValue,
      totalTransferFeeValue: infos.confirmation.transferFeeValue,
      ticketingBalance: infos.ticketingBalance,
      oldTicketingBalance: infos.oldTicketingBalance,
      issFee: infos.confirmation.issFeeValue,
      totalValue: infos.confirmation.totalAmountPayable,
      totalItens: infos?.numberOfColaborators || infos?.confirmation?.cardsCount,
      totalRechargeValue: infos.confirmation.subTotalValue,
      confirmation: infos.confirmation,
      code: infos.code,
      numberOfColaborators: infos?.numberOfColaborators,
    },
  ]
}
