import { useMutation, useQueryClient } from '@tanstack/react-query'
import api from '@/infra/api'

export const useRemoveCartItem = () => {
  const queryClient = useQueryClient()

  const removeItem = useMutation({
    mutationFn: ({ orderId }: { orderId: string }) => api.patch(`rh-bff/basket/cancelorder/${orderId}`),
    onSuccess: () => {
      queryClient.invalidateQueries(['cart'])
    },
  })
  return { removeItem }
}
