import { DropdownButton } from 'presentation/components'
import { BREAK_POINTS } from '@/utils/constants'
import styled, { css } from 'styled-components'

export const PaddingBox = styled.div`
  height: 100%;
  width: 100%;
  padding: 16px;
  position: relative;
`

export const HeaderFilter = styled.div`
  background-color: var(--color-primary-05);
  display: flex;
  height: 45px;
  width: 100%;
  cursor: pointer;

  ${({ activeFilter }) => css`
    & > :nth-child(${activeFilter}) {
      color: var(--color-primary-01);
      font-weight: var(--font-weight-semi-bold);
      border-bottom: 3px solid var(--color-primary-01);
    }
  `}
`

export const FilterOption = styled.div`
  color: var(--color-primary-04);
  font-size: 1rem;
  letter-spacing: 0.01625rem;
  padding: 14px 16px;
  height: 100%;
  box-sizing: border-box;
  white-space: nowrap;
`

export const CustomDropdownButton = styled(DropdownButton)`
  margin-right: 32px;
  min-width: 300px;
  max-width: 300px;

  @media (max-width: ${BREAK_POINTS.md}) {
    margin: 32px 0;
  }
`
