import React, { useEffect, useState } from 'react'
import PersonalData from './personalData'
import DadosProfissionais from './professionalData'
import AddressInformation from './addressInformation'
import { useDispatch, useSelector } from 'react-redux'
import {
  employeePersonalDataStore,
  employeeAddressStore,
  employeeProfessionalDataStore,
} from '@/store/modules/employee/actions'
import CancelModal from './cancelModal'
import { getEmployeeRequest } from '@/store/modules/employee/actions'
import {
  Tabs,
  TabList,
  Tab,
  TabPanel,
  Button,
  Title,
  Content,
  Spinner,
  PageHeader,
  Panel,
} from 'presentation/components'
import { TabPanelHeader } from './style'
import { FORM_MODE } from '@/utils/constants'
import ExtractTable from './extract/extract'
import BenefitTable from './benefit/benefit'
import { StatusChip } from 'presentation/components/atoms/statusChip'
import { EMPLOYEE_STATUS } from 'utils/constants'
import { useLocation, useNavigate } from 'react-router-dom'

const EmployeeForm = () => {
  const location = useLocation()
  const { mode, tab } = location?.state
  const newTab = tab || Number(localStorage.getItem('tab')) || 0
  const navigate = useNavigate()
  const storeData = useSelector((state) => state.employee)
  const employeeData = storeData.data
  const [tabIndex, setTabIndex] = useState(newTab)
  useEffect(() => {
    tab && setTabIndex(tab)
  }, [tab])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const tabCount = 4
  const subTitle = {
    ADD: 'Adicionar Colaborador',
    EDIT: 'Editar Colaborador',
    VIEW: 'Visualizar Colaborador',
  }
  const dispatch = useDispatch()
  let isNavegable = false
  let formSubmit = null

  function changeForm(source) {
    formSubmit = source
  }

  function handleCancelClick() {
    setIsModalVisible(true)
  }

  function nextTab() {
    const newTab = tabIndex + 1 < tabCount ? tabIndex + 1 : 0
    setTabIndex(newTab)
  }

  function prevTab() {
    const newTab = tabIndex - 1 >= 0 ? tabIndex - 1 : 0
    setTabIndex(newTab)
  }

  function handleTabNavigation(index) {
    formSubmit && formSubmit()
    if (!isNavegable) return
    setTabIndex(index)
    localStorage.setItem('tab', index)
  }

  function controlNavigation(isValid) {
    isNavegable = isValid
  }

  function returnToList() {
    navigate('/colaboradores')
  }

  useEffect(() => {
    if (employeeData?.id) {
      localStorage.setItem('employeeId', employeeData?.id)
    }
  }, [employeeData])

  useEffect(() => {
    if (!employeeData?.id && localStorage.getItem('employeeId')) {
      dispatch(getEmployeeRequest(localStorage.getItem('employeeId')))
    }
    // eslint-disable-next-line
  }, [employeeData])

  useEffect(() => {
    return () => {
      localStorage.removeItem('employeeId')
      localStorage.removeItem('tab')
      setTabIndex(0)
    }
  }, [])

  function generateTabPanelHeader() {
    return (
      <TabPanelHeader>
        <Title.SubTitle id={`employees_subtitle`}>{subTitle[mode]}</Title.SubTitle>
        {mode === FORM_MODE.VIEW && employeeData?.enabled && (
          <Button
            type='button'
            icon='edit'
            variant='action'
            width='105px'
            id='editar'
            onClick={() => {
              navigate('/colaboradores/editar', {
                state: { mode: FORM_MODE.EDIT, tab: tabIndex },
              })
            }}
          >
            Editar
          </Button>
        )}
      </TabPanelHeader>
    )
  }

  let statusColor =
    employeeData?.status === EMPLOYEE_STATUS.SYNCHRONISM_ERROR ||
    employeeData?.status === EMPLOYEE_STATUS.WAITING_SYNCHRONISM
      ? 'error'
      : 'active'
  if (employeeData?.status === EMPLOYEE_STATUS.ACTIVE) statusColor = 'active'
  if (employeeData?.status === EMPLOYEE_STATUS.INACTIVE) statusColor = 'inactive'
  return (
    <>
      <Content>
        <Panel noPadding>
          <PageHeader>{mode ? employeeData?.fullname : 'Cadastro'}</PageHeader>

          {isLoading || storeData.loading ? (
            <Spinner.Box style={{ padding: '32px' }}>
              <Spinner />
            </Spinner.Box>
          ) : (
            <Tabs
              selectedTabClassName='is-selected'
              disabledTabClassName='is-disabled'
              selectedTabPanelClassName='is-selected'
              selectedIndex={tabIndex}
              onSelect={handleTabNavigation}
            >
              <TabList>
                <Tab className='employee_tab_1'>Dados Pessoais</Tab>
                <Tab className='employee_tab_2'>Endereço </Tab>
                <Tab className='employee_tab_3'>Dados Profissionais</Tab>
                {mode !== FORM_MODE.ADD ? (
                  <>
                    <Tab className='employee_tab_4'>Benefícios</Tab>
                    <Tab className='employee_tab_5'>Extrato</Tab>
                    <StatusChip
                      sx={{
                        float: 'right',
                        margin: '6.5px 32px 0 0',
                      }}
                      status={statusColor}
                      label={EMPLOYEE_STATUS[employeeData?.status] || 'Ativo'}
                    />
                  </>
                ) : null}
              </TabList>

              <TabPanel>
                {generateTabPanelHeader()}
                <PersonalData
                  employeeData={employeeData}
                  cancel={handleCancelClick}
                  changeForm={changeForm}
                  controlNavigation={controlNavigation}
                  mode={mode}
                  saveOnStore={(data) => {
                    dispatch(employeePersonalDataStore(data))
                  }}
                  nextTab={nextTab}
                  prevTab={prevTab}
                  returnToList={returnToList}
                />
              </TabPanel>

              <TabPanel>
                {generateTabPanelHeader()}

                <AddressInformation
                  employeeData={employeeData}
                  cancel={handleCancelClick}
                  changeForm={changeForm}
                  controlNavigation={controlNavigation}
                  mode={mode}
                  saveOnStore={(data) => {
                    dispatch(employeeAddressStore(data))
                  }}
                  nextTab={nextTab}
                  prevTab={prevTab}
                  returnToList={returnToList}
                />
              </TabPanel>

              <TabPanel>
                {generateTabPanelHeader()}
                <DadosProfissionais
                  employeeData={employeeData}
                  cancel={handleCancelClick}
                  changeForm={changeForm}
                  controlNavigation={controlNavigation}
                  mode={mode}
                  saveOnStore={(data) => {
                    dispatch(employeeProfessionalDataStore(data))
                  }}
                  prevTab={prevTab}
                  returnToList={returnToList}
                  setIsLoading={setIsLoading}
                />
              </TabPanel>

              {mode !== FORM_MODE.ADD ? (
                <>
                  <TabPanel>
                    {controlNavigation(true)}
                    <BenefitTable />
                  </TabPanel>
                  <TabPanel>
                    {controlNavigation(true)}
                    <ExtractTable employeeId={employeeData?.id} employeeName={employeeData?.fullname} />
                  </TabPanel>
                </>
              ) : null}
            </Tabs>
          )}
        </Panel>
      </Content>
      {isModalVisible && (
        <CancelModal open={isModalVisible} close={() => setIsModalVisible(false)} mode={mode} cancel={returnToList} />
      )}
    </>
  )
}

export default EmployeeForm
