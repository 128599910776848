import React, { useMemo, useState } from 'react'
import api from '@/infra/api'
import Moment from 'react-moment'

import { Content, Panel, Icon, Message, Spinner, PageHeader } from 'presentation/components'
import { TitlePanel, Subtitle, Options, Text, Delivery, SessionConfirm, SessionText } from './styles'
import { pushNotification } from '@/store/modules/notification/actions'
import errorHandler from '@/utils/errorHandler'
import successHandler from '@/utils/successHandler'
import { useDispatch } from 'react-redux'
import { patchConfirmCardOrder, patchPayConfirmCardOrder } from '@/services/cardsServices'
import { formatMoney } from '@/utils/functionUtils'
import { Box, Button, Grid } from 'presentation/components/atoms'
import { FeatureToggle } from 'utils/featureToggle/featureToggle'
import { PageChangePrompt, TextDecoration } from 'presentation/components'
import { useAddCartItem } from 'main/hooks/useAddCartItem'
import { useQueryClient } from '@tanstack/react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import { useNotifications } from '@/main/hooks'
import { EMessageType } from 'main/store'

const FirstCopy = ({ ...props }) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { addToCart } = useAddCartItem()
  const location = useLocation()
  const { orderId } = location.state
  const [data, setData] = useState(null)
  const [editAddress] = useState(false)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const dispatch = useDispatch()
  const { push } = useNotifications()

  const requestInfo = async ({ hasWarn = true }) => {
    setLoading(true)
    try {
      const { data: employees } = await api.get(`rh-bff/cards/new/first/confirmation/${orderId}`)

      const warn = employees?.inelegibleEmploees?.length

      if (warn && hasWarn) {
        const names = employees?.inelegibleEmploees?.map((employee) => employee.fullname)?.join(', ')

        setData(employees)

        throw Error(
          `Colaborador ${names}, não está apto na bilhetagem para solicitar cartão, ${
            warn?.length > 1 ? 'eles foram removidos' : 'ele foi removido'
          } do pedido.`,
        )
      }

      setLoading(false)
      setData(employees)
    } catch (e) {
      setLoading(false)
      push({ content: e?.message, type: EMessageType.Warning })
    }
  }

  useMemo(() => {
    if (location?.state) {
      requestInfo({ hasWarn: true })
    }
    // eslint-disable-next-line
  }, [])

  const convert = (value) => {
    if (value) {
      const price = value.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      })
      return price
    } else {
      return 'R$ 0,00'
    }
  }

  const confirmOrder = async () => {
    setLoading(true)
    const confirm = await patchConfirmCardOrder(orderId, {
      address: null,
      payments: null,
    })

    if (confirm.error || !confirm.response) {
      dispatch(pushNotification(errorHandler(confirm.error?.response)))
      setLoading(false)
      return
    }

    if (confirm.response && !confirm.error) {
      const pay = await patchPayConfirmCardOrder(orderId)
      if (confirm.response && pay.response) {
        queryClient.invalidateQueries(['cart'])
        dispatch(pushNotification(successHandler('Pedido realizado')))
        setSuccess(true)
        navigate(`/cartoes/acompanhamento/#orderId="${orderId}"`, { state: { bypass: true } })
      } else {
        pay.error && dispatch(pushNotification(errorHandler(pay.error?.response)))
      }
    }
    setLoading(false)
  }

  const removeEmployee = async (employeeId) => {
    setLoading(true)
    try {
      await api.patch(`/orders-service/FirstCopy/${orderId}/remove`, [employeeId])
      const employeeIndex = data?.employees.findIndex((employee) => employee.id === employeeId)
      const newEmployees = [...data?.employees]
      newEmployees.splice(employeeIndex, 1)
      setData({ ...data, employees: newEmployees })
      setLoading(false)
      requestInfo({ hasWarn: false })
    } catch (e) {
      setLoading(false)
    }
  }

  const cancelOrder = async () => {
    setLoading(true)
    try {
      await api.patch(`/orders-service/${orderId}/cancel`)
      setLoading(false)
      navigate('/cartoes', {
        state: { bypass: true },
      })
      queryClient.invalidateQueries(['cart'])
    } catch (e) {
      setLoading(false)
      setError('Não cancelado')
    }
  }

  return (
    <>
      <>
        <PageChangePrompt onlyConfirm>
          <p>Você não finalizou seu pedido.</p>
          <p>
            Seu pedido será salvo como <TextDecoration>Rascunho</TextDecoration> por uma semana e você poderá continuar
            de onde parou para finalizá-lo.
          </p>
        </PageChangePrompt>
        <Content>
          <Panel noPadding>
            <PageHeader>Novo Pedido de Cartão</PageHeader>
            <Grid container sx={{ padding: '32px' }} justifyContent='space-between'>
              {loading || addToCart.isLoading ? (
                <Spinner.Box>
                  <Spinner />{' '}
                </Spinner.Box>
              ) : (
                <>
                  <Grid
                    sm={5}
                    style={{
                      position: 'relative',
                      paddingBottom: '50px',
                    }}
                    justifyContent='space-between'
                  >
                    {!editAddress && (
                      <>
                        <Subtitle>Resumo de pedido de 1º Via</Subtitle>
                        <TitlePanel>Colaboradores incluídos no pedido</TitlePanel>
                        {data?.employees?.length
                          ? data.employees.map((employee, i) => (
                              <Options key={i}>
                                <Text>{employee.fullname}</Text>
                                {data.employees.length > 1 && (
                                  <div>
                                    <Icon.ActionIcon
                                      hover='Excluir'
                                      name='trash'
                                      onClick={() => removeEmployee(employee.id)}
                                    />
                                  </div>
                                )}
                              </Options>
                            ))
                          : null}
                        <Delivery>
                          <div>
                            {data?.adresses?.length ? (
                              <>
                                <TitlePanel>Opção de retirada</TitlePanel>
                                <Text> {data.adresses[0].name}</Text>
                                <Text style={{ fontSize: '13px' }}>
                                  {data.adresses[0].laneName} {data.adresses[0].number}
                                </Text>
                                <Text style={{ fontSize: '13px' }}>
                                  {data.adresses[0].city} - {data.adresses[0].state} CEP {data.adresses[0].cep}
                                </Text>
                              </>
                            ) : null}
                          </div>
                        </Delivery>
                      </>
                    )}
                    {editAddress && (
                      <>
                        <Delivery>
                          <div>
                            {data?.adresses?.length
                              ? data.adresses.map((data, i) => (
                                  <>
                                    <TitlePanel>{data.name}</TitlePanel>
                                    <Text> {data.name}</Text>
                                    <Text style={{ fontSize: '13px' }}>
                                      {data.city} - {data.state} CEP {data.cep}
                                    </Text>
                                    <div
                                      style={{
                                        borderBottom: '1px solid #a9a9a93d',
                                      }}
                                    />
                                  </>
                                ))
                              : null}
                          </div>
                        </Delivery>
                      </>
                    )}
                    <Button
                      onClick={() => cancelOrder()}
                      sx={{
                        position: 'absolute',
                        bottom: '0',
                      }}
                    >
                      Cancelar pedido
                    </Button>
                  </Grid>

                  <Grid item sm={5}>
                    <SessionConfirm>
                      <Subtitle style={{ textAlign: 'left' }}>Confirmação do pedido</Subtitle>
                      <div style={{ marginTop: '40px' }} />
                      <SessionText>
                        <p>Nº Cartões solicitados</p>
                        <p>{data?.confirmation?.cardsCount}</p>
                      </SessionText>
                      <SessionText>
                        <p>Data do pedido</p>
                        <p>{data && data?.orderDate ? <Moment format='DD/MM/YYYY'>{data?.orderDate}</Moment> : null}</p>
                      </SessionText>
                      {data?.confirmation?.deliveryDateForecast && (
                        <SessionText>
                          <p>Data de previsão de entrega</p>
                          <p>
                            <Moment format='DD/MM/YYYY'>{data?.confirmation?.deliveryDateForecast}</Moment>
                          </p>
                        </SessionText>
                      )}
                      <SessionText>
                        <p>Valor do Pedido</p>
                        <p>R$ {formatMoney(data?.confirmation?.subTotalValue)}</p>
                      </SessionText>
                      <SessionText>
                        <p>Taxa Administrativa</p>
                        <p>R$ {formatMoney(data?.confirmation?.paymentFeeValue)}</p>
                      </SessionText>
                      <SessionText>
                        <p>Total a pagar</p>
                        <p style={{ color: '#5e39ca' }}>{convert(data?.confirmation?.totalAmountPayable)}</p>
                      </SessionText>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          gap: '16px',
                          marginTop: '30px',
                        }}
                      >
                        <Button icon='filter' variant='outlined' onClick={() => confirmOrder()}>
                          Comprar agora
                        </Button>
                        <FeatureToggle feature='cart'>
                          <Button onClick={() => addToCart.mutate({ orderId })} variant='contained'>
                            Adicionar ao carrinho
                          </Button>
                        </FeatureToggle>
                      </Box>
                    </SessionConfirm>
                  </Grid>
                </>
              )}
            </Grid>
          </Panel>
        </Content>
      </>
      {error && (
        <Message type='error' onXClick={() => setError(false)}>
          Ocorreu um erro
        </Message>
      )}
      {success && (
        <Message type='ok' onXClick={() => setSuccess(false)}>
          Pedido Realizado
        </Message>
      )}
    </>
  )
}

export default FirstCopy
