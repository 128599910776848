import { useTheme } from '@mui/material'
import { faBarcode, faHandHoldingDollar, faWallet } from '@fortawesome/free-solid-svg-icons'
import _ from 'lodash'
import { Typography } from 'presentation/components/atoms'
import { ORDER_PAYMENT_TYPE, ORDER_STATUS } from 'main/utils/constants'
import { StyledIcon } from './styles'
import { DetailCard } from '../detailCard/detailCard'
import { OrderPaymentType } from 'main/interfaces'
import { useAtomValue } from 'jotai'
import { AccountPersistentStore } from 'main/store'
import { faPix } from '@fortawesome/free-brands-svg-icons'

const getPaymentStyle = ({ type }: { type: OrderPaymentType }) => {
  if (['SLIP', 'TERM_BILL'].includes(type))
    return {
      icon: faBarcode,
    }
  if (['FREE'].includes(type))
    return {
      icon: faHandHoldingDollar,
    }
  if (['ACCOUNT_BALANCE', 'TICKETING_BALANCE', 'MOTIVA_CASH', 'PIX'].includes(type))
    return {
      icon: ['PIX'].includes(type) ? faPix : faWallet,
      backgroundColor: (theme: any) => theme.palette.primary.light + '2d',
      color: (theme: any) => theme.palette.primary.main,
    }
}

const getPaymentStatus = ({ status }: { status: number }) => {
  if ([ORDER_STATUS.CANCELED, ORDER_STATUS.CANCELING].includes(status))
    return {
      text: 'Pagamento cancelado',
      color: (theme: any) => theme.palette.error.main,
      opacity: 0.5,
    }
  if (status <= ORDER_STATUS.WAITING_PAYMENT || status === 99)
    return {
      text: 'Pagamento pendente',
      color: (theme: any) => theme.palette.warning.main,
      opacity: 1,
    }
  return {
    text: 'Pagamento confirmado',
    color: (theme: any) => theme.palette.success.main,
    opacity: 1,
  }
}

interface PaymentTypeCardProps {
  type: OrderPaymentType | Array<OrderPaymentType>
  status: number
  slipDueDate?: string
}

export const PaymentTypeCard = ({ type, status, slipDueDate = null }: PaymentTypeCardProps) => {
  const types = Array.isArray(type) ? type : [type]
  const theme = useTheme()
  const { resale } = useAtomValue(AccountPersistentStore)
  const { opacity, color: statusColor, text: statusText } = getPaymentStatus({ status })
  const renderIcons = () => {
    const iconsToRender = types.map((type) => {
      const { icon = null, color, backgroundColor } = getPaymentStyle({ type })
      return (
        <StyledIcon
          key={_.uniqueId()}
          icon={icon}
          color={color && color(theme)}
          backgroundColor={backgroundColor && backgroundColor(theme)}
          sx={{ opacity }}
        />
      )
    })
    return iconsToRender
  }

  const renderTitle = () => {
    const dynamicTitleIfIsAccountBalance = ({ status }: { status: string }) => {
      if (ORDER_PAYMENT_TYPE[status] === ORDER_PAYMENT_TYPE.ACCOUNT_BALANCE) return resale?.digitalAccountName
      return ORDER_PAYMENT_TYPE[ORDER_PAYMENT_TYPE[status]]
    }
    const titleString = types.reduce((finalString, actual, index) => {
      if (index > 0) return (finalString += ` + ${dynamicTitleIfIsAccountBalance({ status: actual })}`)
      else return finalString + dynamicTitleIfIsAccountBalance({ status: actual })
    }, '')
    return titleString
  }

  return (
    <>
      <DetailCard
        icons={renderIcons()}
        title={renderTitle()}
        subtitle={
          slipDueDate !== '' && slipDueDate ? (
            <Typography variant='label' sx={{ color: theme.palette.grey[700], fontWeight: 400 }}>
              Vence em {slipDueDate}
            </Typography>
          ) : undefined
        }
        helperText={
          !types.includes('FREE') && (
            <Typography variant='label3' sx={(theme) => ({ color: statusColor && statusColor(theme) })}>
              {statusText}
            </Typography>
          )
        }
      />
    </>
  )
}
