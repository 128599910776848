import { Icon } from 'presentation/components'
import styled from 'styled-components'

export const Subtitle = styled.p`
  color: var(--color-primary-01);
  font-weight: 600;
  font-size: 20px;
`

export const Label = styled.div`
  margin-bottom: 10px;
  white-space: nowrap;
`

export const TitlePanel = styled.p`
  color: var(--color-primary-04);
  font-weight: 600;
  font-size: 16px;
`

export const IconArrows = styled(Icon)`
  width: 70px;
  background-color: var(--color-primary-05);
  stroke: var(--color-primary-01);
  height: 60px;
  cursor: pointer;

  :active {
    transform: scale(0.9);
  }

  :last-of-type {
    transform: scaleX(-1);
    :active {
      transform: scale(0.9) scaleX(-1);
    }
  }
`
