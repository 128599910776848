import { styled } from '@mui/material'
import { Box } from '@stationkim/front-ui'

export const IconContainer = styled(Box)(({ theme }) => ({
  margin: 'auto',
  borderRadius: '50%',
  backgroundColor: theme.palette.grey[50],
  height: '64px',
  width: '64px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  filter: 'drop-shadow(0px 2.696px 4.044px rgba(0, 0, 0, 0.10))',
  marginBottom: '16px',
  '& svg': {
    height: '34px',
    width: '38px',
  },
  color: theme.palette.error.main,
}))
