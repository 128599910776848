import styled from 'styled-components'
import { BREAK_POINTS } from '@/utils/constants'

export const Body = styled.div`
  background-color: #fff;
  width: 500px;
  overflow: hidden;
`

export const TitleModal = styled.p`
  color: var(--color-primary-01);
  font-weight: bold;
`

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Close = styled.p`
  cursor: pointer;
  text-align: right;
  font-size: 20px;
  margin-top: -5px;
`

export const Subtitle = styled.p`
  color: var(--color-primary-04);
  font-weight: 600;
  font-size: 16px;
  margin-top: 15px;
`

export const Options = styled.div`
  padding: 12px;
  border-top: 1px solid #efe7e7;
  color: #757576;
`

export const SearchWrapper = styled.div`
  padding-left: 54px;
  margin-top: 24px;
  @media (max-width: ${BREAK_POINTS.md}) {
    padding: 0 54px;
  }
`
