import React, { useState } from 'react'
import { Breadcrumb, Content, Spacing, Title } from 'presentation/components'

import CompanyInfoResume from './companyInfoResume'
import MigrationRequestConfimartion from './migrationRequestConfirmation'
import { useLocation } from 'react-router-dom'

const MigrarCadastro = () => {
  const location = useLocation()
  const [confirmation, setConfirmation] = useState(false)

  return (
    <Content>
      <Title>Migrar Cadastro de Empresa</Title>
      <Spacing top='16px' bottom='16px'>
        <Breadcrumb />
      </Spacing>
      {!confirmation && (
        <CompanyInfoResume companyInfo={location?.state?.companyInfo} setConfirmation={setConfirmation} />
      )}
      {confirmation && <MigrationRequestConfimartion />}
    </Content>
  )
}

export default MigrarCadastro
