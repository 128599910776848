import React, { useState } from 'react'
import { DecoratedText, StatusDot, StatusWrappper, Table, Tbody, Td, Th, Thead, Tr } from './styles'
import ModalInfo from './modalInfo'
import DotMenu from 'presentation/components/dotMenu'
import { DEPARTMENTS_STATUS } from '@/utils/constants'
import { useDepartments } from '../../hooks/useDepartments'
import { useNavigate } from 'react-router-dom'

const DepartmentsTable = ({ setAssociate, loading, setDetails, setRemove, setId }) => {
  const { departments } = useDepartments()
  const navigate = useNavigate()
  const [showInfo, setShowInfo] = useState(false)

  const deleteDep = (id) => {
    setRemove(true)
    setId(id)
  }

  function generateHeaders() {
    return (
      <>
        <Tr>
          <Th>Departamento</Th>
          <Th>Qtde. colaboradores</Th>
          <Th>Status</Th>
          <Th>Ações</Th>
        </Tr>
      </>
    )
  }

  return (
    <>
      <Table loading={loading}>
        <Thead>{generateHeaders()}</Thead>
        <Tbody>
          {departments?.data
            ? departments?.data.map((data, i) => (
                <Tr key={'department_' + i}>
                  <Td onClick={() => setDetails(data.id)}>{data.name}</Td>
                  <Td onClick={data.employeesCount > 0 ? () => setDetails(data.id) : null}>
                    {' '}
                    {data.employeesCount > 0 ? (
                      data.employeesCount
                    ) : (
                      <DecoratedText
                        onClick={(e) => {
                          e.stopPropagation()
                          setAssociate(data.id)
                        }}
                      >
                        Associar colaboradores
                      </DecoratedText>
                    )}
                  </Td>
                  <Td>
                    <StatusWrappper>
                      <StatusDot status={data.status} />
                      {DEPARTMENTS_STATUS[data.status]}
                    </StatusWrappper>
                  </Td>

                  <Td>
                    {!data?.isNoDepartment && (
                      <DotMenu>
                        <DotMenu.Option
                          onClick={() => navigate('/departamentos/editar', { state: data.id })}
                          id={`departments_tableBody_edit_${i}`}
                        >
                          Editar
                        </DotMenu.Option>

                        <DotMenu.Option
                          onClick={data.employeesCount > 0 ? () => setShowInfo(true) : () => deleteDep(data.id)}
                          disabled={data.employeesCount > 0}
                          id={`departments_tableBody_delete_${i}`}
                        >
                          Excluir
                        </DotMenu.Option>
                      </DotMenu>
                    )}
                  </Td>
                </Tr>
              ))
            : null}
        </Tbody>
      </Table>
      {showInfo && <ModalInfo open={showInfo} close={() => setShowInfo(false)} />}
    </>
  )
}
export default DepartmentsTable
