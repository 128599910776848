import { DEPARTMENTS_STATUS } from '@/utils/constants'
import styled from 'styled-components'

export const DecoratedText = styled.p`
  color: var(--color-primary-01);
  cursor: pointer;
`

export const Body = styled.div`
  padding: 30px;
  z-index: 99;
`

export const TextConfirm = styled.p`
  text-align: center;
  font-size: 22px;
  padding: 50px 0;
`

export const Close = styled.p`
  cursor: pointer;
  text-align: right;
  font-size: 20px;
`

export const Table = styled.table`
  border-collapse: separate;
  border-spacing: 0 10px;
  margin: auto;
  width: 100%;
`

export const Thead = styled.thead``

export const Tbody = styled.tbody``

export const Tr = styled.tr`
  height: 56px;
  box-shadow: var(--box-shadow-01);
  border-radius: 5px;

  &:only-child {
    box-shadow: none;
  }
`

export const Th = styled.th`
  color: var(--color-primary-01);
  text-align: right;
  width: 20%;
  white-space: nowrap;

  &:first-child {
    text-align: left;
    width: 40%;
    padding-left: 32px;
  }

  &:nth-child(3) {
    text-align: center;
  }

  &:last-child {
    text-align: right;
    padding-right: 24px;
  }
`

export const Td = styled.td`
  border-top: 1px solid var(--color-secundary-05);
  border-bottom: 1px solid var(--color-secundary-05);
  text-align: right;
  width: 20%;
  white-space: nowrap;
  padding: 0 8px;

  &:first-child {
    border-radius: 5px 0 0 5px;
    border-left: 1px solid var(--color-secundary-05);
    text-align: left;
    padding-left: 32px;
    width: 40%;
  }

  &:last-child {
    border-radius: 0 5px 5px 0;
    border-right: 1px solid var(--color-secundary-05);
    text-align: right;
    padding-right: 24px;
  }
`

export const StatusWrappper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StatusDot = styled.div`
  height: 12px;
  width: 12px;
  border-radius: 50%;
  margin-right: 8px;
  ${({ status }) => {
    if (status === DEPARTMENTS_STATUS.ACTIVE) return 'background-color: var(--color-status-01)'
    if (status === DEPARTMENTS_STATUS.INACTIVE) return 'background-color: var(--color-status-06)'
    if (status === DEPARTMENTS_STATUS.WAITING_SYNCHRONISM) return 'background-color: var(--color-status-03)'
    if (status === DEPARTMENTS_STATUS.SYNCHRONISM_ERROR) return 'background-color: var(--color-status-02)'
  }}
`
