import { styled } from '@mui/material'
import { Box } from 'presentation/components/atoms/box'
import { Button } from 'presentation/components/atoms/button'

export const StyledButton = styled(Button)(({ active, theme }) => {
  const style = {
    padding: '16px 32px',
    whiteSpace: 'nowrap',
    flexShrink: 0,
  }

  return !active
    ? {
        ...style,
        ...{
          background: '#F4F4F4',
          color: '#4F4F4F',
          '&:hover': {
            background: '#dbdbdb',
          },
        },
      }
    : style
})

export const CardTable = styled(Box)(() => ({
  display: 'grid',
  gap: '16px',
  height: '394px',
  overflowY: 'auto',
  overflowX: 'hidden',
  padding: '2px 16px 0 2px',
  marginTop: '16px',
}))
