import { Box, styled } from '@mui/material'
import { FieldWrapperProps } from './fieldWrapper'

export const StyledBox = styled(Box)<FieldWrapperProps>(({ theme, fullWidth, error, success }) => {
  let statusColor = 'initial'
  if (error) statusColor = theme.palette.error.main
  if (success) statusColor = theme.palette.success.main
  return {
    width: fullWidth ? '100%' : 'initial',
    '& label': {
      marginBottom: 8,
    },
    '& > p': {
      marginTop: 8,
      color: statusColor,
    },
    '& *': {
      borderColor: statusColor,
    },
  }
})
