import { InputBase } from 'presentation/components/atoms/inputBase/inputBase'
import { styled } from '@mui/material'

const sizes = {
  large: '48px',
  medium: '42px',
  small: '36px',
}

export const StyledInput = styled(InputBase)(({ theme, size }) => ({
  color: theme.palette.grey[500],
  borderColor: theme.palette.grey[500],
  '&::placeholder': {
    color: theme.palette.grey[500],
  },
  margin: '0px',
  border: `1px solid ${theme.palette.grey[400]}`,
  padding: '0 1rem',
  outline: 'none',
  height: sizes[size as keyof typeof sizes],
  width: '100%',
  borderRadius: '8px',
}))
