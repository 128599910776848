import { removeSpecialCharacters } from '@/utils/functionUtils'
import produce from 'immer'

const INITIAL_STATE = {
  data: {
    addresses: null,
    contacts: [],
    documents: [],
  },
}

export default function auth(state = INITIAL_STATE, action) {
  const { type, payload } = action

  return produce(state, (draft) => {
    switch (type) {
      case '@company/STORE_COMPANY_DATA_REQUEST': {
        payload.cnpj = removeSpecialCharacters(payload.cnpj)
        payload.activyBranch = parseInt(payload?.activyBranch?.code)
        payload.numberBeneficiaries = parseInt(payload?.numberBeneficiaries?.value)
        draft.data = { ...state.data, ...payload }
        break
      }

      case '@company/STORE_COMPANY_ID_REQUEST': {
        draft.data = { ...state.data, ...payload }
        break
      }

      case '@company/CLEAR_COMPANY_DATA_REQUEST': {
        draft.data = {}
        break
      }

      case '@company/STORE_ADDRESS_REQUEST': {
        const addresses = JSON.parse(JSON.stringify(payload.addresses))
        const deliveryAddresses = JSON.parse(JSON.stringify(payload.deliveryAddresses))
        const addressesCepWhitoutMask = addresses.concat(deliveryAddresses).map((address) => {
          if (!address.cep) return address
          return {
            ...address,
            cep: removeSpecialCharacters(address.cep),
          }
        })
        draft.data.addresses = addressesCepWhitoutMask
        break
      }

      case '@company/CLEAR_ADDRESS_REQUEST': {
        draft.data.addresses = null
        break
      }

      case '@company/STORE_CONTACTS_REQUEST': {
        const contacts = JSON.parse(JSON.stringify(payload.contacts))
        const contactsWithoutMasks = contacts?.map((contact) => ({
          ...contact,
          cpf: removeSpecialCharacters(contact.cpf),
          phone: removeSpecialCharacters(contact.phone),
          contactTypeCode: contact.contactTypeCode,
        }))
        draft.data.contacts = contactsWithoutMasks
        break
      }

      case '@company/CLEAR_CONTACTS_REQUEST': {
        draft.data.contacts = []
        break
      }

      case '@company/STORE_DOCUMENTS_REQUEST': {
        draft.data.documents = payload.map((file, index) =>
          file?.error === false
            ? {
                fileName: file?.file?.name,
                documentType: index + 1,
                id: file?.id,
              }
            : {},
        )
        break
      }

      case '@company/CLEAR_DOCUMENTS_REQUEST': {
        draft.data.documents = []
        break
      }

      case '@company/STORE_COMPANY_REQUEST': {
        draft.data = payload
        break
      }

      case '@company/CLEAR_REQUEST': {
        draft.data = null
        break
      }

      case '@company/CHANGE_STATUS_REQUEST': {
        draft.data.active = payload.active
        break
      }

      default:
    }
  })
}
