import { Theme } from '@mui/material'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import {
  Box,
  Button,
  CountdownButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  Icon,
  Skeleton,
  Typography,
} from '@stationkim/front-ui'
import { IOrderRow } from '../../hooks/useRechargesList'
import { IconContainer } from './styles'
import { OrderSummary } from '../orderSummary/orderSummary'
import { usePurchaseOrder } from '../../hooks/usePurchaseOrders'
import { useOrderCancelation } from '@/main/hooks/useOrderCancelation'
import { useNotifications } from '@/main/hooks'
import { EMessageType } from '@/main/store'
import { useQueryClient } from '@tanstack/react-query'

interface CancelPurchaseDialog extends Omit<DialogProps, 'open'> {
  order: IOrderRow
}

export const CancelPurchaseDialog = ({ order, ...props }: CancelPurchaseDialog) => {
  const multipleItemsOnPurchase = order.multipleItemsOnPurchase
  const notifications = useNotifications()
  const queryClient = useQueryClient()
  const { purchaseOrders, isLoading: isLoadingOrders } = usePurchaseOrder({ purchaseId: order.purchaseId })
  const renderAllPurchaseOrders = () => {
    return purchaseOrders.map((order, index) => {
      return <OrderSummary key={'order_to_cancel_' + index} order={order} />
    })
  }
  const { cancelPurchase } = useOrderCancelation()
  const isLoading = isLoadingOrders || cancelPurchase.isLoading
  return (
    <Dialog open={Boolean(order)} {...props}>
      <IconContainer>
        <Icon icon={faTriangleExclamation} />
      </IconContainer>
      <DialogTitle
        sx={(theme: Theme) => ({
          margin: '0 auto 16px auto',
          color: theme.palette.grey[700],
        })}
      >
        Cancelar {multipleItemsOnPurchase ? 'compra' : 'pedido'}
      </DialogTitle>
      <DialogContent sx={{ maxHeight: '40vh' }}>
        <Typography variant='body2' sx={{ textAlign: 'center' }}>
          {multipleItemsOnPurchase ? (
            <>
              Este pedido pertence a uma compra unificada.
              <br />
              Caso opte por cancela-lo, toda compra será cancelada.
              <br />
            </>
          ) : (
            <>
              Essa é uma ação irreversível.
              <br />
            </>
          )}
          Tem certeza que deseja continuar?
          <br />
        </Typography>

        <Typography
          variant='label'
          sx={(theme: Theme) => ({
            color: theme.palette.grey[700],
            fontWeight: theme.typography.fontWeightMedium,
            display: 'inline-block',
            margin: '32px 0 16px 0',
          })}
        >
          {multipleItemsOnPurchase ? 'Pedidos inclusos na compra' : 'Pedido a cancelar'}
        </Typography>

        {isLoading ? (
          <>
            <Skeleton variant='rounded' sx={{ height: '70px' }} />
          </>
        ) : multipleItemsOnPurchase ? (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>{renderAllPurchaseOrders()}</Box>
        ) : (
          <OrderSummary
            order={{
              itemsOnOrder: order.itemsOnOrder,
              orderNumber: order.code,
              type: order.orderType,
              value: order.totalValue,
            }}
          />
        )}
      </DialogContent>

      <Divider sx={{ margin: '32px 0' }} />
      <DialogActions>
        <Button
          variant='outlined'
          sx={{ filter: 'grayscale(100)' }}
          fullWidth
          onClick={() => props.onClose({}, 'backdropClick')}
        >
          Cancelar
        </Button>
        <CountdownButton
          count={5}
          color='error'
          fullWidth
          isLoading={isLoading}
          onClick={() =>
            cancelPurchase.mutate(
              { purchaseId: order.purchaseIdEncrypted },
              {
                onSuccess: () => {
                  queryClient.invalidateQueries({ queryKey: ['rechargesList'] })
                  notifications.push({
                    content: 'Cancelamento efetuado com sucesso',
                    type: EMessageType.Success,
                  })
                  props.onClose({}, 'backdropClick')
                },
              },
            )
          }
        >
          Confirmar
        </CountdownButton>
      </DialogActions>
    </Dialog>
  )
}
