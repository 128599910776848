import styled from 'styled-components'
import Bg from 'presentation/assets/images/login.png'

export const Main = styled.div`
  height: 100vh;
  background: url(${Bg}) no-repeat center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const Body = styled.div`
  border-radius: 15px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 450px;
  width: 43%;

  button {
    width: 100%;
  }

  form {
    width: 100%;
    max-width: 370px;
  }

  p {
    max-width: 370px;
  }
`

export const Title = styled.h1`
  text-align: center;
  color: #707070;
  font-size: 1.25em;
  margin-bottom: 80px;
`

export const Label = styled.div`
  margin-bottom: 10px;
`

export const EmailSended = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 43%;

  svg {
    fill: #47f06a;
    height: 55px;
    width: 70px;
  }
`

export const EmailSendedText = styled.p`
  text-align: center;
  max-width: 370px;
`
