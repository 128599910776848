import React from 'react'
import { ModalV2, Button } from 'presentation/components'
import { Row } from 'react-grid-system'
import { Body, TitleModal, Flex } from './styles'

const ConfirmModal = ({ open, close, confirm, alertType, newStatus }) => {
  return (
    <>
      <ModalV2 open={open} onClose={close} blockScroll>
        <Body>
          <span style={{ textAlign: 'left' }}>
            <TitleModal>Ops! Você tem certeza?</TitleModal>
            <br />
            <p>
              {alertType === 'info'
                ? 'Você deseja alterar as informações desse usuário?'
                : `Você deseja alterar o Status deste usuário para ${newStatus ? 'Ativo' : 'Inativo'}?`}
            </p>
          </span>

          <Row style={{ marginTop: '20px', marginLeft: '0px' }}>
            <Flex style={{ width: '98%' }}>
              <Button
                width='50%'
                variant='outlined'
                style={{ marginRight: '10px' }}
                onClick={() => {
                  confirm()
                  close()
                }}
              >
                Sim
              </Button>
              <Button width='50%' variant='action' onClick={() => close()}>
                Não
              </Button>
            </Flex>
          </Row>
        </Body>
      </ModalV2>
    </>
  )
}

export default ConfirmModal
