import { Checkbox, styled } from '@mui/material'

export const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  width: 24,
  height: 24,
  margin: 0,
  borderRadius: 0,
  '& svg': {
    width: 18,
    height: 18,
  },
}))
