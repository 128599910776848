import useOnClickOutside from 'main/hooks/useOnClickOutside'
import React, { useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { Dots, FloatingMenu, MenuContainer } from './styles'
const DotMenu = ({ children, closeOnInnerClick = true, ...props }) => {
  const positionRef = useRef(null)
  const [position, setPosition] = useState(false)
  const [isListOpen, setIsListOpen] = useState(false)

  const clickOutside = useOnClickOutside((e) => {
    if (!positionRef.current.contains(e.target)) {
      setIsListOpen(false)
    }
  })

  const handleButtonClick = (e) => {
    !clickOutside.current.contains(e.target) && setIsListOpen((state) => !state)
    const distances = positionRef.current.getBoundingClientRect()
    setPosition({
      top: window.innerHeight - distances.top - window.scrollY + 8,
      right: window.innerWidth - distances.right,
    })
  }

  return (
    <MenuContainer ref={positionRef} {...props}>
      <Dots onClick={handleButtonClick} />
      {ReactDOM.createPortal(
        <FloatingMenu
          ref={clickOutside}
          visible={isListOpen}
          position={position}
          onClick={() => closeOnInnerClick && setIsListOpen(false)}
        >
          {children}
        </FloatingMenu>,
        document.querySelector('#root'),
      )}
    </MenuContainer>
  )
}

export default DotMenu
