import { TablePagination, styled } from '@mui/material'

export const StyledTablePagination = styled(TablePagination)<{
  component?: string
  counterVariant?: 'results' | 'interval'
}>(({ counterVariant }) => ({
  overflow: 'visible',
  width: counterVariant === 'interval' ? '100%' : 'fit-content',
  '& .MuiToolbar-root': {
    minHeight: 'initial',
    height: 'fit-content',
    paddingLeft: 0,
  },

  '& .MuiInputBase-root': {
    marginRight: '8px',
  },
  '& .MuiTablePagination-spacer, & .MuiTablePagination-actions': {
    display: 'none',
  },
  '& .MuiTablePagination-displayedRows': {
    marginLeft: 'auto',
  },
}))
