import React from 'react'
import { Button, ButtonIcon } from './style'

interface SimpleButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: any
  icon?: any
  size?: string
  variant?: string
  width?: string
}

const Simple = ({ children, icon, size, variant, width, ...props }: SimpleButtonProps) => {
  return (
    <Button size={size} variant={variant} width={width} {...props}>
      {icon && <ButtonIcon className='icon' name={icon} />}
      {children}
    </Button>
  )
}

export default Simple
