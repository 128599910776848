import React from 'react'
import ReactDOM from 'react-dom'
import { CalendarIconWrapper, CalendarWrapper, CompositeDateContainer, DateLabel } from './styles'
import { FiCalendar } from 'react-icons/fi'
import Calendar from 'react-calendar'
import { useState, useRef } from 'react'
import Moment from 'react-moment'
import useOnClickOutside from 'main/hooks/useOnClickOutside'
import { useEffect } from 'react'

const CompositeDate = ({ value, onChange, limitByMonth = 0, ...props }) => {
  const now = new Date()
  const [dates, setDates] = useState([null, null])
  const [isCalendarVisible, setIsCalendarVisible] = useState(false)
  const [position, setPosition] = useState({})
  const targetDate = useRef(0)
  const positionRef = useRef(null)
  const clickOutside = useOnClickOutside((e) => {
    if (!positionRef.current.contains(e.target) && isCalendarVisible) {
      setDates([null, null])
      targetDate.current = 0
      setIsCalendarVisible(false)
    }
  })

  useEffect(() => {
    if (dates[0] && dates[1]) {
      onChange(dates)
      setDates([null, null])
    }
  }, [dates, onChange])

  let limitDate = now
  if (limitByMonth > 0 && dates[0]) {
    const newLimit = new Date(dates[0].valueOf())
    newLimit.setMonth(newLimit.getMonth() + limitByMonth)
    limitDate = newLimit > now ? now : newLimit
  }

  const dateLimits = [
    {
      maxDate: dates[1] || now,
      minDate: null,
    },
    {
      maxDate: limitDate,
      minDate: dates[0] || null,
    },
  ]

  const recalculatePosition = () => {
    const distances = positionRef.current.getBoundingClientRect()
    setPosition({
      top: distances.top + window.scrollY + 32,
      left: distances.left,
    })
  }

  const handleOnClick = (e) => {
    e.currentTarget.contains(e.target) && setIsCalendarVisible((state) => !state)
    recalculatePosition()
  }

  const handleDatePick = (e) => {
    const currentDate = targetDate.current
    setDates((state) => {
      const newDates = [...state]
      newDates[currentDate] = e
      if (currentDate === 1) setIsCalendarVisible(false)
      return newDates
    })
    targetDate.current = currentDate ? 0 : 1
  }

  return (
    <CompositeDateContainer ref={positionRef} onClick={handleOnClick} {...props}>
      <CalendarIconWrapper>
        <FiCalendar />
      </CalendarIconWrapper>

      <DateLabel>
        {value[0] ? <Moment format='DD/MM/YYYY'>{value[0]}</Moment> : '00/00/0000'}
        &nbsp;-&nbsp;
        {value[1] ? <Moment format='DD/MM/YYYY'>{value[1]}</Moment> : '00/00/0000'}
      </DateLabel>
      {ReactDOM.createPortal(
        <CalendarWrapper visible={isCalendarVisible} position={position} ref={clickOutside}>
          <Calendar
            onChange={handleDatePick}
            value={value[targetDate]}
            weekStartDay={0}
            returnValue='start'
            calendarType={'US'}
            minDetail={'decade'}
            navigationLabel={({ label, view }) => `${view === 'month' ? label.match(/[^ ]*/) : label}`}
            maxDate={dateLimits[targetDate.current].maxDate}
            minDate={dateLimits[targetDate.current].minDate}
          />
        </CalendarWrapper>,
        document.querySelector('#root'),
      )}
    </CompositeDateContainer>
  )
}

export default CompositeDate
