const ACTION = '@department/'

export const ACTIONS = {
  GET_DEPARTMENT_REQUEST: ACTION + 'GET_DEPARTMENT_REQUEST',
  DEPARTMENT_REQUEST_SUCCESS: ACTION + 'DEPARTMENT_REQUEST_SUCCESS',
  DEPARTMENT_REQUEST_FAILURE: ACTION + 'DEPARTMENT_REQUEST_FAILURE',
  CLEAR_DEPARTMENT_STORE: ACTION + 'CLEAR_DEPARTMENT_STORE',
}

export function getDepartmentRequest(companyId, departmentId) {
  return {
    type: ACTIONS.GET_DEPARTMENT_REQUEST,
    payload: { companyId, departmentId },
  }
}

export function departmentRequestSuccess(data) {
  return {
    type: ACTIONS.DEPARTMENT_REQUEST_SUCCESS,
    payload: { data },
  }
}

export function departmentRequestFailure(data) {
  return {
    type: ACTIONS.DEPARTMENT_REQUEST_FAILURE,
    payload: { data },
  }
}

export function clearDepartmentStore() {
  return {
    type: ACTIONS.CLEAR_DEPARTMENT_STORE,
  }
}
