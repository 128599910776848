import React, { useEffect, useState } from 'react'
import { Col, Container, Row, useScreenClass } from 'react-grid-system'
import { PaddingBox } from './styles'
import { useDispatch, useSelector } from 'react-redux'
import UsersTable from './usersTable'
import paginatorMethods from '@/utils/paginatorMethods'
import { getUsersInRequest } from '@/store/modules/users/actions'
import { FIVE_SECONDS } from '@/utils/constants'
import Create from './create'
import errorHandler from '@/utils/errorHandler'
import { pushNotification } from '@/store/modules/notification/actions'
import { usePaginationOld } from 'main/hooks/usePaginationOld'
import { Content, Pagination, Panel, Search, Spacing, Message, PageHeader, ButtonV2 } from 'presentation/components'

const Users = () => {
  const dispatch = useDispatch()
  const storeData = useSelector((state) => state.users)
  const usersData = storeData?.data?.data?.data

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [create, setCreate] = useState(false)
  const [message, setMessage] = useState(null)

  const screenClass = useScreenClass()

  const { paginator, setPaginator } = usePaginationOld({ query: {} })

  const refresh = async () => {
    dispatch(
      getUsersInRequest(
        paginatorMethods.buildQuery({
          page: 1,
          pageSize: 10,
          doRequest: true,
        }),
      ),
    )
  }

  const refreshAndNext = () => {
    setCreate(false)
    dispatch(
      getUsersInRequest(
        paginatorMethods.buildQuery({
          page: 1,
          pageSize: 10,
          doRequest: true,
        }),
      ),
    )
    setCreate(true)
  }

  useEffect(() => {
    paginator.doRequest && dispatch(getUsersInRequest(paginatorMethods.buildQuery(paginator)))
    // eslint-disable-next-line
  }, [paginator])

  useEffect(() => {
    setPaginator(
      paginatorMethods.updatePaginator(paginator, {
        page: storeData.data?.data?.pageNumber,
        totalItens: storeData.data?.data?.totalItens,
        totalPages: storeData.data?.data?.totalPages,
      }),
    )
    // eslint-disable-next-line
  }, [storeData])

  useEffect(() => {
    if (storeData.failure) dispatch(pushNotification(errorHandler(storeData.failure)))
  }, [storeData.failure, dispatch])

  useEffect(() => {
    setTimeout(() => {
      success && setSuccess(false)
      error && setError(false)
    }, FIVE_SECONDS)
  }, [success, error])

  return (
    <>
      <Content>
        <Panel style={{ padding: 0 }}>
          <Container fluid style={{ padding: 0 }}>
            <Row
              align='center'
              gutterWidth={16}
              justify={['sm', 'md', 'lg'].includes(screenClass) ? 'center' : 'start'}
            >
              <Col sm={['sm'].includes(screenClass) ? 12 : null}>
                <PageHeader>Usuários</PageHeader>
              </Col>
              <Col sm='content' style={{ marginRight: '35px' }}>
                <ButtonV2 onClick={() => setCreate(true)}>Novo Usuário</ButtonV2>
              </Col>
            </Row>
          </Container>
          <PaddingBox>
            <Container fluid>
              <Row justify={['xs', 'sm'].includes(screenClass) ? 'center' : 'between'}>
                <Col xs={12} sm={12} md={7} lg={6} xl={5}>
                  <Search
                    search={(value) =>
                      setPaginator(
                        paginatorMethods.setFilters(paginator, {
                          filterValue: value,
                          filterProps: 'name,email',
                        }),
                      )
                    }
                    placeholder='Buscar na tabela abaixo ...'
                  />
                </Col>
              </Row>
              <Spacing top='32px' />
              <Row>
                <Col>
                  <UsersTable
                    loading={loading || storeData.loading}
                    rechargesInfo={usersData}
                    clickUp={paginator.orderListAsc}
                    clickDown={paginator.orderListDesc}
                    paginator={paginator}
                    refresh={refresh}
                    changeOrder={(field, direction) =>
                      setPaginator(paginatorMethods.setOrder(paginator, field, direction))
                    }
                    setLoading={setLoading}
                    setMessage={setMessage}
                    setSuccess={setSuccess}
                  />
                </Col>
              </Row>
              <Spacing top='32px' />
              <Row justify={['xs', 'sm'].includes(screenClass) ? 'center' : 'between'} align='center'>
                <Col xs='12' md='content' style={{ display: 'flex', justifyContent: 'center' }}>
                  <Pagination.ResultAmount
                    setPageSize={(newPageSize) => setPaginator(paginatorMethods.setPageSize(paginator, newPageSize))}
                  />
                </Col>
                <Col
                  xs='content'
                  style={{
                    marginTop: ['xs', 'sm'].includes(screenClass) ? '16px' : '0',
                  }}
                >
                  <Pagination
                    paginator={paginator}
                    setPage={(wantedPage) => setPaginator(paginatorMethods.setPage(paginator, wantedPage))}
                  />
                </Col>
              </Row>
            </Container>
          </PaddingBox>
        </Panel>
      </Content>
      {success && (
        <Message type='ok' onXClick={() => setError(false)}>
          {message === 'ok' ? (
            <>
              <strong>Usuário Criado</strong>
              <br />
              <p style={{ fontWeight: '400' }}>
                Peça ao colaborador para cadastrar
                <br /> a senha pelo e-mail informado.
              </p>
            </>
          ) : (
            message
          )}
        </Message>
      )}
      {error && (
        <Message type='error' onXClick={() => setError(false)}>
          {message}
        </Message>
      )}
      {create && (
        <Create
          open={create}
          close={() => setCreate(false)}
          paginator={paginator}
          refresh={refresh}
          refreshAndNext={refreshAndNext}
          setMessage={setMessage}
          setSuccess={setSuccess}
        />
      )}
    </>
  )
}

export default Users
