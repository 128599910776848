import { DialogContent as Content } from '@/presentation/components/atoms'
import styled from 'styled-components'

export const ModalTextContainer = styled.div`
  text-align: left;

  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  color: #060606;

  span {
    color: #1976d2;
    font-weight: 600;
  }
`

export const ModalInputsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
  width: 100%;
`

export const ModalLabel = styled.p`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  color: #616161;
  text-align: left;
  margin-bottom: 8px;
`

export const ModalInputWrapper = styled.div`
  & + & {
    margin-left: 16px;
  }

  input {
    width: 100%;
  }

  input:disabled {
    cursor: not-allowed;
  }
`

export const DialogContent = styled(Content)`
  padding: 24px !important;
  flex-direction: column;
  width: 407px;
`

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin-top 64px;

  button {
    width: 100%;
  }
`
