import { Background } from '../atoms'
import { PageFooter, PageHeader } from '../organisms'

export const HeaderAndFooter = ({ children = null }) => {
  return (
    <>
      <PageHeader />
      <Background>{children}</Background>
      <PageFooter />
    </>
  )
}
