import { popNotification } from 'store/modules/notification/actions'
import { NOTIFICATION_TYPE, TEN_SECONDS } from 'utils/constants'
import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import NotificationMessage from '../notificationMessage'
import { useNotifications } from 'main/hooks'

const Notification = () => {
  const dispatch = useDispatch()
  const notificationsStore = useSelector((state) => state.notifications)
  const notifications = notificationsStore.messages
  const notificationsAmount = notifications.length
  const lastTimeNotificationsAmount = useRef(0)
  const timer = useRef(null)

  // assim que possivel remover 100% sagas ^
  const notification = useNotifications()

  const shouldUseOldStore = notification.total === 0
  useEffect(() => {
    if (shouldUseOldStore) {
      const hasNotification = notificationsAmount > 0
      const hasPopedNotification = lastTimeNotificationsAmount.current > notifications.length

      if (!hasNotification) return

      if (hasPopedNotification && timer.current) {
        clearTimeout(timer.current)
        timer.current = null
      }

      if (!timer.current) {
        timer.current = setTimeout(() => {
          dispatch(popNotification())
          timer.current = null
        }, TEN_SECONDS)
      }

      lastTimeNotificationsAmount.current = notifications.length
    } else {
      notification.removeOldestInSeconds()
    }
    // eslint-disable-next-line
  }, [notifications, notification])

  const oldManualRemoval = () => {
    dispatch(popNotification())
    clearTimeout(timer.current)
    timer.current = null
  }

  const onClose = () => {
    notification.removeOldest()
  }

  const toRender = shouldUseOldStore ? notifications : notification.messages
  const manualRemove = shouldUseOldStore ? oldManualRemoval : onClose

  const renderMessage = () => {
    if (!toRender.length) return null
    return (
      <NotificationMessage
        onClose={manualRemove}
        notificationType={typeof toRender[0].type === 'number' ? NOTIFICATION_TYPE[toRender[0].type] : toRender[0].type}
        title={toRender[0]?.title}
        waitTime={TEN_SECONDS / 1000}
      >
        {toRender[0].content}
      </NotificationMessage>
    )
  }

  return renderMessage()
}

export default Notification
