import React from 'react'
import { Body, Title, SessionButton, Close } from './style'
import { Button, Icon, Input, Spacing, Spinner } from 'presentation/components'
import 'react-responsive-modal/styles.css'
import Modal from 'react-responsive-modal'
import { useState } from 'react'
import { useEffect } from 'react'
import useService from 'main/hooks/useService'
import { getBlockReasons, patchBlockCard } from '@/services/cardsServices'
import { pushNotification } from '@/store/modules/notification/actions'
import successHandler from '@/utils/successHandler'
import { useDispatch } from 'react-redux'

const BlockModal = ({ open, onClose, card }) => {
  const dispatch = useDispatch()
  const [selectedReason, setSelectedReason] = useState(null)
  const [fetchReasonsState, fetchReasonsRequest] = useService(getBlockReasons, {
    silent: true,
  })
  const [blockCardState, blockCardRequest] = useService(patchBlockCard, {
    onCompleted: () => {
      dispatch(pushNotification(successHandler('Cartão Bloqueado!')))
      onClose()
    },
  })

  useEffect(() => {
    fetchReasonsRequest(card.id)
    // eslint-disable-next-line
  }, [])

  const blockCard = () => {
    const blockReason = {
      blockingReason: selectedReason.description,
      blockingReasonCode: selectedReason.code,
    }
    blockCardRequest(card.id, blockReason)
  }

  return (
    <Modal center open={open} onClose={onClose} showCloseIcon={false} styles={{ borderRadius: '5px' }}>
      <Body>
        <Close onClick={onClose}>
          <Icon name='x' />
        </Close>
        <Title>Bloquear cartão</Title>
        {blockCardState.loading ? (
          <Spinner.Box>
            <Spinner />
          </Spinner.Box>
        ) : (
          <>
            <p>Cartão a ser bloqueado: {card?.number}</p>
            <p>Colaborador associado: {card?.employeeName}</p>
            <p>Matrícula: {card?.employeeRegistration}</p>
            <p>Motivo do Bloqueio</p>
            <Spacing top='16px' />
            <Input.Select
              isLoading={fetchReasonsState.loading}
              options={fetchReasonsState.response?.data}
              getOptionLabel={(option) => option.description}
              getOptionValue={(option) => option.code}
              value={selectedReason}
              placeholder='Selecione'
              isClearable={false}
              onChange={(e) => setSelectedReason(e)}
            />
            <SessionButton>
              <Button variant='outlined' width='200px' onClick={onClose}>
                Cancelar
              </Button>
              <Button width='250px' onClick={blockCard} disabled={!selectedReason}>
                Bloquear
              </Button>
            </SessionButton>
          </>
        )}
      </Body>
    </Modal>
  )
}

export default BlockModal
