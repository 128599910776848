import { OrderIcon } from 'presentation/components'

import { TableCell, TableRow, TableHead as Head } from 'presentation/components/atoms'

import { Wrapper } from '../../../../styles'

export const TableHead = () => {
  return (
    <Head>
      <TableRow>
        <TableCell>
          <Wrapper>
            Cartão Antigo
            <OrderIcon />
          </Wrapper>
        </TableCell>
        <TableCell>Via</TableCell>
        <TableCell>Data Bloqueio</TableCell>
        <TableCell>Cartão Novo</TableCell>
        <TableCell>Via</TableCell>
        <TableCell>Data Emissão</TableCell>
      </TableRow>
    </Head>
  )
}
