export function getUsersInRequest(query) {
  return {
    type: '@users/GETUSERS_REQUEST',
    payload: { query },
  }
}

export function getUsersInSuccess(data) {
  return {
    type: '@users/GETUSERS_SUCCESS',
    payload: { data },
  }
}

export function getUsersInFailure(data) {
  return {
    type: '@users/GETUSERS_FAILURE',
    payload: { data },
  }
}
