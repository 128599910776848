import { forwardRef } from 'react'
import { StyledIcon } from './styles'
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { SxProps } from '@mui/material'

export interface IconProps extends FontAwesomeIconProps {
  sx?: SxProps
}

export const Icon = forwardRef<SVGSVGElement, IconProps>((props, ref) => <StyledIcon ref={ref} {...props} />)
