import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSetAtom } from 'jotai'
import Dropzone from 'react-dropzone'
import { Title } from 'presentation/components'
import {
  Atention,
  ButtonWrapper,
  CommonText,
  Container,
  InfoRow,
  InputFile,
  HighlightedText,
  Wrapper,
  DownloadLink,
} from './style'
import { Button, Icon } from 'presentation/components'
import { minTwoDigits } from '@/utils/functionUtils'
import { orderIdAtom } from '@/main/store'

const ImportResult = ({ response, onDrop, ...props }) => {
  const navigate = useNavigate()
  const setOrderId = useSetAtom(orderIdAtom)
  return (
    <>
      <Title.SubTitle style={{ margin: '32px 0px 0 32px' }}>Resultado da Importação</Title.SubTitle>
      <Container>
        <InfoRow>
          <span>
            <HighlightedText style={{ color: 'var(--color-primary-01)' }}>
              {minTwoDigits(response.orderCount)}
            </HighlightedText>
            <CommonText>Colaboradores no pedido</CommonText>
          </span>
          <span>
            <HighlightedText style={{ color: 'var(--color-secundary-01)' }}>
              {minTwoDigits(response.errorsCount)}
            </HighlightedText>
            <CommonText>Erros na importação</CommonText>
          </span>
        </InfoRow>
        {!response.id || response?.errorsCount > 0 ? (
          <>
            <div style={{ textAlign: 'center' }}>
              <Atention>Atenção!</Atention>
              <CommonText>Os arquivos contendo erro não serão importados.</CommonText>
              <CommonText>Verifique os erros apontados no relatório de erros e importe novamente.</CommonText>
              {response?.base64 && (
                <DownloadLink>
                  <a download='erros_importacao.txt' href={`data:text/txt;base64,${response?.base64}`}>
                    Ver relatório de erros <Icon name='external-link' />
                  </a>
                </DownloadLink>
              )}
            </div>
            <Dropzone accept='.txt, .xls' onDrop={onDrop} multiple={false} {...props}>
              {({ getRootProps, getInputProps }) => (
                <>
                  <InputFile {...getRootProps()}>
                    <Icon name='upload' /> Reenviar Modelo Preenchido
                  </InputFile>
                  <input {...getInputProps()} />
                </>
              )}
            </Dropzone>
          </>
        ) : (
          <>
            <Wrapper>
              <Title.SubTitle>Arquivo importado com sucesso!</Title.SubTitle>
              <p>Prossiga para a etapa de revisão do pedido de recarga para finalizar.</p>
              <ButtonWrapper>
                <Button variant='outlined' onClick={() => window.location.reload()}>
                  Voltar
                </Button>
                <Button
                  onClick={() => {
                    setOrderId(response.id)
                    navigate(`/recargas/novo_pedido_importacao_valores#orderId="${response.id}"`)
                  }}
                >
                  Próximo
                </Button>
              </ButtonWrapper>
            </Wrapper>
          </>
        )}
      </Container>
    </>
  )
}

export default ImportResult
