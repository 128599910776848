import { combineReducers } from 'redux'

import user from './user/reducer'
import employee from './employee/reducer'
import employees from './employees/reducer'
import cards from './cards/reducer'
import department from './department/reducer'
import departments from './departments/reducer'
import extracts from './extracts/reducer'
import globalState from './globalState/reducer'
import recharge from './recharge/reducer'
import recharges from './recharges/reducer'
import report from './report/reducer'
import empresa from './empresa/reducer'
import cardOrder from './orders/cards/one/reducer'
import cardsOrders from './orders/cards/all/reducer'
import trackHistory from './cards/trackHistory/reducer'
import notifications from './notification/reducer'
import users from './users/reducer'

export default combineReducers({
  user,
  cardOrder,
  cardsOrders,
  employee,
  employees,
  cards,
  department,
  departments,
  globalState,
  empresa,
  recharge,
  recharges,
  report,
  notifications,
  trackHistory,
  users,
  extracts,
})
