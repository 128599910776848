import React, { useEffect, useMemo, useState } from 'react'
import { Container, Col } from 'react-grid-system'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import schema from '../schema/companyData'
import { useDispatch } from 'react-redux'

import { Input, Button, ErrorMessage, ErrorWrapper, Title } from 'presentation/components'
import { numberOfBeneficiaries } from '@/utils/options'

import { FieldSet, Label, Row } from './style'
import { findOption, removeSpecialCharacters } from '@/utils/functionUtils'
import Modal from '../../modal'
import { companyDataClear } from '@/store/modules/empresa/actions'
import { FORM_MODE } from '@/utils/constants'
import { getActivityBranches } from '@/services/companiesServices'
import { pushNotification } from '@/store/modules/notification/actions'
import errorHandler from '@/utils/errorHandler'
import { useLocation, useNavigate } from 'react-router-dom'

const CompanyData = ({ changeForm, controlNavigation, nextTab, mode, returnToList, saveOnStore, companyData }) => {
  const [cancelModal, setCancelModal] = useState(false)
  const [branches, setBranches] = useState([])
  const [loadingBranches, setLoadingBranches] = useState(false)
  const navigate = useNavigate()
  const readOnly = mode === FORM_MODE.VIEW ? true : false
  const dispatch = useDispatch()
  const {
    state: { token },
  } = useLocation()

  useEffect(() => {
    ;(async () => {
      setLoadingBranches(true)
      const { error, response } = await getActivityBranches(token)
      if (response) setBranches(response.data)
      else dispatch(pushNotification(errorHandler(error.response)))
      setLoadingBranches(false)
    })()
  }, [dispatch])

  useEffect(() => {
    if (!companyData?.activyBranch) return
    setValue('activyBranch', findOption(branches, 'code', companyData?.activyBranch))
    // eslint-disable-next-line
  }, [branches])

  let defaultValues = {}
  if (companyData != null) {
    defaultValues = {
      cnpj: companyData?.cnpj || undefined,
      corporateName: companyData?.corporateName || undefined,
      tradeName: companyData?.tradeName || undefined,
      stateRegistration: companyData?.stateRegistration || undefined,
      municipalRegistration: companyData?.municipalRegistration || undefined,
      numberBeneficiaries:
        {
          value: numberOfBeneficiaries[companyData?.numberBeneficiaries - 1]?.value,
          label: numberOfBeneficiaries[companyData?.numberBeneficiaries - 1]?.label,
        } || undefined,
      allowDefaultDepartmentOrder: companyData?.allowDefaultDepartmentOrder || undefined,
    }
  }
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
    setValue,
  } = useForm({
    defaultValues: defaultValues,
    mode: 'onChange',
    resolver: yupResolver(schema),
    context: { register: !mode ? true : false },
  })

  useMemo(() => {
    controlNavigation(isValid)
  }, [isValid, controlNavigation])

  const onSubmit = (data) => {
    saveOnStore(data)
    nextTab()
  }

  changeForm(() => {
    handleSubmit(saveOnStore)()
  })

  function handlePaste(e, limit, name) {
    e.preventDefault()
    const transferArea = e.clipboardData.getData('text')
    const cleanedValue = removeSpecialCharacters(transferArea).slice(0, limit)
    e.target.value = cleanedValue
    setValue(name, cleanedValue, { shouldValidate: true })
  }

  function confirmCancel() {
    setCancelModal(false)
    dispatch(companyDataClear())
    navigate('/cadastro')
  }
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} id='companyData'>
        <FieldSet disabled={readOnly}>
          <Container style={{ margin: 0, padding: 0, maxWidth: 'initial' }}>
            <Row gutterWidth={40}>
              <Col sm={12}>
                <Title.SubTitle>Dados da empresa</Title.SubTitle>
              </Col>
            </Row>

            <Row gutterWidth={40}>
              <Col sm={3}>
                <ErrorWrapper error={errors.cnpj}>
                  <Label>CNPJ</Label>
                  <Controller
                    control={control}
                    name='cnpj'
                    render={({ field: { onChange, value, ref } }) => (
                      <Input.Masked
                        placeholder='00.000.000/0000-00'
                        mask={'*'}
                        format='##.###.###/####-##'
                        value={value}
                        getInputRef={ref}
                        disabled
                        onChange={onChange}
                      />
                    )}
                  />
                  {errors.cnpj && <ErrorMessage>{errors.cnpj.message}</ErrorMessage>}
                </ErrorWrapper>
              </Col>

              <Col sm={4}>
                <ErrorWrapper error={errors.corporateName}>
                  <Label>Razão Social *</Label>
                  <Input placeholder='Razão social' maxLength='90' {...register('corporateName')} />
                  {errors.corporateName && <ErrorMessage>{errors.corporateName.message}</ErrorMessage>}
                </ErrorWrapper>
              </Col>

              <Col sm={4}>
                <ErrorWrapper error={errors.tradeName}>
                  <Label>Nome Fantasia *</Label>
                  <Input placeholder='Nome Fantasia' maxLength='40' {...register('tradeName')} />
                  {errors.tradeName && <ErrorMessage>{errors.tradeName.message}</ErrorMessage>}
                </ErrorWrapper>
              </Col>
            </Row>

            <Row gutterWidth={40}>
              <Col sm={3}>
                <ErrorWrapper error={errors.stateRegistration}>
                  <Label>Inscrição Estadual *</Label>
                  <Input
                    maxLength='14'
                    placeholder='000000000'
                    name='stateRegistration'
                    onPaste={(e) => handlePaste(e, 14, 'stateRegistration')}
                    onChange={(e) => {
                      const value = removeSpecialCharacters(e.target.value)
                      setValue('stateRegistration', value)
                    }}
                    {...register('stateRegistration')}
                  />
                  {errors.stateRegistration && <ErrorMessage>{errors.stateRegistration.message}</ErrorMessage>}
                </ErrorWrapper>
              </Col>

              <Col sm={3}>
                <ErrorWrapper error={errors.municipalRegistration}>
                  <Label>Inscrição Municipal *</Label>
                  <Input
                    maxLength='11'
                    placeholder='00000000000'
                    name='municipalRegistration'
                    onPaste={(e) => handlePaste(e, 11, 'municipalRegistration')}
                    onChange={(e) => {
                      const value = removeSpecialCharacters(e.target.value)
                      setValue('municipalRegistration', value)
                    }}
                    onBlur={(e) => {
                      const fieldValue = e.target.value
                      const completation = 11 - fieldValue.length
                      if (completation > 0 && completation < 11) {
                        const newValue = '0'.repeat(completation) + fieldValue
                        setValue('municipalRegistration', newValue)
                      }
                    }}
                    {...register('municipalRegistration')}
                  />
                  {errors.municipalRegistration && <ErrorMessage>{errors.municipalRegistration.message}</ErrorMessage>}
                </ErrorWrapper>
              </Col>

              <Col sm={3}>
                <span>
                  <Label>Ramo da Atividade</Label>
                  <Controller
                    control={control}
                    name='activyBranch'
                    render={({ field: { onChange, value, ref } }) => (
                      <Input.Select
                        placeholder='Selecione'
                        value={value}
                        inputRef={ref}
                        onChange={onChange}
                        isDisabled={readOnly}
                        options={branches}
                        getOptionLabel={(option) => option.description}
                        getOptionValue={(option) => option.code}
                        isLoading={loadingBranches}
                        isClearable={false}
                      />
                    )}
                  />
                </span>
              </Col>
              <Col sm={3}>
                <ErrorWrapper error={errors?.numberBeneficiaries?.label}>
                  <Label>Número de Beneficiários *</Label>
                  <Controller
                    control={control}
                    name='numberBeneficiaries'
                    render={({ field: { onChange, value, ref } }) => (
                      <Input.Select
                        placeholder='Selecione'
                        value={value}
                        inputRef={ref}
                        onChange={onChange}
                        isDisabled={readOnly}
                        options={numberOfBeneficiaries}
                        isClearable={false}
                      />
                    )}
                  />
                  {errors?.numberBeneficiaries?.label && (
                    <ErrorMessage>{errors.numberBeneficiaries.label.message}</ErrorMessage>
                  )}
                </ErrorWrapper>
              </Col>
            </Row>

            <Row gutterWidth={40}>
              <Col sm={12}>
                <Input.CheckBox
                  controlled
                  name='allowDefaultDepartmentOrder'
                  onClick={(e) => {
                    setValue('allowDefaultDepartmentOrder', e.target.checked)
                  }}
                />{' '}
                Permitir recarga para colaboradores sem departamento
              </Col>
            </Row>
          </Container>
        </FieldSet>
      </form>
      <Row justify='end' gutterWidth={24} style={{ marginTop: '8vh' }}>
        <Col sm={2}>
          {mode === FORM_MODE.VIEW ? (
            <Button variant='outlined' onClick={returnToList}>
              Fechar
            </Button>
          ) : (
            <Button variant='outlined' onClick={() => setCancelModal(true)}>
              Cancelar
            </Button>
          )}
        </Col>
        <Col sm={2}>
          {mode === FORM_MODE.VIEW ? (
            <Button type='button' onClick={nextTab}>
              Próximo
            </Button>
          ) : (
            <Button type='submit' form='companyData'>
              Avançar
            </Button>
          )}
        </Col>
      </Row>
      {cancelModal && (
        <Modal
          message='Atenção! Os dados preenchidos serão perdidos. Deseja sair da página?'
          open={cancelModal}
          confirm={confirmCancel}
          cancel={() => setCancelModal(false)}
        />
      )}
    </>
  )
}

export default CompanyData
