import React from 'react'
import { Body, TextConfirm, Close } from './styles'
import 'react-responsive-modal/styles.css'
import { Modal } from 'react-responsive-modal'

const ModalInfo = ({ open, close }) => {
  return (
    <Modal open={open} onClose={close} center showCloseIcon={false} styles={{ borderRadius: '5px' }}>
      <Body>
        <Close onClick={close}>x</Close>
        <TextConfirm>Não é possível excluir departamentos com colaboradores associados</TextConfirm>
      </Body>
    </Modal>
  )
}

export default ModalInfo
