import { Body, TextConfirm, ColorDest, SessionButton, Close } from './styles'
import { Button } from 'presentation/components'
import 'react-responsive-modal/styles.css'
import { Modal } from 'react-responsive-modal'
import { useDelete } from '../../hooks/useDelete'

const DeleteComponent = ({ open, close, id, setIsWaitingResponse, refresh }) => {
  const { handleRemove } = useDelete({ id, setIsWaitingResponse, refresh })

  return (
    <>
      <Modal open={open} onClose={close} center showCloseIcon={false} styles={{ borderRadius: '5px' }}>
        <Body>
          <Close onClick={close}>x</Close>
          <TextConfirm>
            Você tem certeza que deseja <ColorDest>excluir</ColorDest> <br />
            esse departamento?
          </TextConfirm>
          <SessionButton>
            <Button variant='action' width='200px' onClick={close}>
              não
            </Button>
            <div style={{ paddingLeft: '15px' }} />
            <Button
              width='250px'
              onClick={() => {
                handleRemove()
                close()
              }}
            >
              Sim, excluir
            </Button>
          </SessionButton>
        </Body>
      </Modal>
    </>
  )
}

export default DeleteComponent
