import styled from 'styled-components'

export const Centralizer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 60px;
  min-height: 64vh;
`
