export const PORTUGUESE_REGEX = /^[a-zA-ZáÁéÉíÍóÓúÚâÂêÊîÎôÔûÛãÃõÕçÇ]+$/

export const ORDER_TYPES = {
  1: 'Primeira Via',
  2: 'Segunda Via',
  3: 'Recarga',
  5: 'Broker',
  FIRST_COPY: 1,
  SECOND_COPY: 2,
  RECHARGE: 3,
  BROKER: 5,
}

export type ORDER_TYPES_KEY = keyof typeof ORDER_TYPES

export const ORDER_STATUS = {
  0: 'Rascunho',
  1: 'Efetivando',
  3: 'Aguardando Pagamento',
  4: 'Aguardando Processamento',
  5: 'Em Processamento',
  6: 'Processamento operadora',
  10: 'Disponível para Retirada',
  11: 'Enviando',
  15: 'Concluído',
  16: 'Cancelado',
  17: 'Cancelando',
  DRAFT: 0,
  EFFECTING: 1,
  WAITING_PAYMENT: 3,
  WAITING_FOR_PROCESSING: 4,
  PROCESSING: 5,
  RUNNING: 6,
  AVAILABLE_PICKUP: 10,
  DELIVERING: 11,
  FINISHED: 15,
  CANCELED: 16,
  CANCELING: 17,
}

export type ORDER_STATUS_KEY = keyof typeof ORDER_STATUS

export const ORDER_PAYMENT_TYPE = {
  1: 'Boleto',
  6: 'Boleto a Prazo',
  3: 'Cartão de Crédito',
  10: 'Conta Corrente',
  14: 'Pix',
  17: 'Gratuito',
  18: 'Carteira digital',
  SLIP: 1,
  TERM_BILL: 6,
  DEBIT_CARD: 2,
  CREDIT_CARD: 3,
  TRANSFER: 4,
  DEPOSIT: 5,
  TICKETING_BALANCE: 10,
  PIX: 14,
  FREE: 17,
  ACCOUNT_BALANCE: 18,
}

export const BENEFIT_TYPE = {
  1: 'Vale Transporte Eletrônico',
  2: 'Vale Alimentação',
  3: 'Vale Refeição',
  4: 'Vale Combustível',
  5: 'Vale Transporte Papel',
  TRANSPORT: 1,
  FOOD: 2,
  SNACK: 3,
  FUEL: 4,
  TRANSPORT_PAPER: 5,
}
export type BENEFIT_TYPE_KEY = keyof typeof BENEFIT_TYPE
