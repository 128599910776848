import React, { useEffect, useState } from 'react'
import { Modal } from 'react-responsive-modal'
import { Button, Input, Spinner } from 'presentation/components'
import api from '@/infra/api'
import { Body, TitleModal, Flex, Close, Options } from './styles'
import { reduceBigTextLines } from '@/utils/functionUtils'
import useService from 'main/hooks/useService'
import { getEmployeesWithoutDepartment } from '@/services/employeesServices'
import { useNotification } from 'main/hooks/useNotification'

const Associate = ({ close, departmentId, open, updateDepartmentList }) => {
  const { errorNotification, successNotification } = useNotification()

  const [data, setData] = useState({
    name: null,
    employees: [],
  })

  useEffect(() => {
    setData({ name: null, employees: [] })
  }, [])

  const [fetchEmployeesWithoutDepartmentState, fetchEmployeesWithoutDepartmentRequest] =
    useService(getEmployeesWithoutDepartment)
  // eslint-disable-next-line
  useEffect(() => {
    fetchEmployeesWithoutDepartmentRequest()
  }, [])

  const employees = fetchEmployeesWithoutDepartmentState.response?.data

  const setIdEmployees = (id) => {
    const find = data.employees.find((element) => element === id)

    if (!find) {
      setData({ ...data, employees: [...data.employees, id] })
    } else {
      const search = data.employees.indexOf(find)
      data.employees.splice(search, 1)
    }
  }

  const save = async () => {
    try {
      if (!(data.employees?.length > 0)) return

      const employees = data.employees.map((employeeId) => ({
        id: employeeId,
        fieldName: 'professionaldata.departmentid',
        value: departmentId,
      }))

      await api.patch('/employees-service/', employees)
      successNotification('Colaborador(s) associado')
      updateDepartmentList()
    } catch (error) {
      errorNotification(error.response)
    }
  }
  return (
    <Modal
      open={open}
      onClose={close}
      center
      showCloseIcon={false}
      styles={{ borderRadius: '5px', modal: { padding: '32px' } }}
    >
      <Body>
        <Flex>
          <TitleModal>Colaboradores sem departamento associado</TitleModal>
          <Close onClick={close}>x</Close>
        </Flex>
        <div style={{ height: '450px', overflow: 'auto', marginTop: '24px' }}>
          {fetchEmployeesWithoutDepartmentState.loading ? (
            <Spinner.Box>
              <Spinner />
            </Spinner.Box>
          ) : employees?.length ? (
            employees.map((data, i) => (
              <Options key={i}>
                <Input.CheckBox onClick={() => setIdEmployees(data.id)} />
                <div style={{ marginLeft: '10px' }} />
                {reduceBigTextLines(data.fullname, 45)}
              </Options>
            ))
          ) : (
            <Options>Sem colaboradores disponiveis</Options>
          )}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            style={{ width: '140px' }}
            onClick={() => {
              save()
              close()
            }}
          >
            Ok
          </Button>
        </div>
      </Body>
    </Modal>
  )
}

export default Associate
