import { Icon } from 'presentation/components/atoms/Icon'
import { Box } from 'presentation/components/atoms/box'
import { Skeleton } from 'presentation/components/atoms/skeleton'
import { Typography } from 'presentation/components/atoms/typography'
import { formatMoney } from '@/utils/functionUtils'
import { faEyeSlash, faEye } from '@fortawesome/free-regular-svg-icons'
import { useState } from 'react'

export const Balance = ({
  value = 0,
  accountName = 'Saldo disponível',
  hide,
  onHide,
  sx,
  isLoading = false,
  ...props
}) => {
  const [internalHide, setInternalHide] = useState(false)

  const isVisible = hide ? hide : internalHide
  const setIsVisible = hide ? onHide : setInternalHide

  return (
    <Box sx={{ width: '120px', whiteSpace: 'nowrap', ...sx }} {...props}>
      <Typography variant='text2'>{accountName}</Typography>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          height: '24px',
        }}
      >
        {isLoading ? (
          <>
            <Skeleton sx={{ width: '70%', height: '100%' }} />
            <Skeleton sx={{ width: '20%', height: '100%', marginLeft: 'auto' }} />
          </>
        ) : (
          <>
            {isVisible ? (
              <>
                <Typography sx={{ marginRight: '4px' }} variant='text2'>
                  R$
                </Typography>
                <Typography sx={{ marginRight: 'auto' }}>{formatMoney(value)}</Typography>
              </>
            ) : (
              <Typography sx={{ marginRight: 'auto' }} variant='h2' component='div'>
                • • • •
              </Typography>
            )}
            <Icon
              icon={isVisible ? faEye : faEyeSlash}
              onClick={() => setIsVisible((state) => !state)}
              sx={{ cursor: 'pointer' }}
            />
          </>
        )}
      </Box>
    </Box>
  )
}
