import { NOTIFICATION_TYPE } from './constants'

export default function errorHandler(error) {
  if (!error || !error.data) {
    return {
      type: 'error',
      content: `Erro Inesperado ${error?.status ? `(${error?.status})` : ''}`,
    }
  }

  const { errors } = error.data

  if (Array.isArray(errors)) {
    return errors.map((err) => {
      if (typeof err === 'object') {
        return {
          type: NOTIFICATION_TYPE[err.type],
          content: `${err.mensagem} ${err.code ? `(${err.code})` : ''}`,
        }
      } else {
        return {
          type: 'error',
          content: err,
        }
      }
    })
  }

  if (typeof error.data.errors === 'object') {
    let notifications = []
    for (let element in error.data.errors) {
      const newNotificaitons = error.data.errors[element].map((message) => ({
        type: 'error',
        content: message,
      }))
      notifications = [...notifications, ...newNotificaitons]
    }
    return notifications
  }

  return {
    type: 'error',
    content: 'Erro Inesperado',
  }
}
