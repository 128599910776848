import React, { forwardRef } from 'react'
import { Radio } from './style.js'

const RadioButton = forwardRef(({ type, disabled, ...props }, ref) => {
  return (
    <Radio disabled={disabled}>
      <input type='radio' ref={ref} {...props} />
      <div />
    </Radio>
  )
})

export default RadioButton
