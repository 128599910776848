import { all, takeLatest, put, call } from 'redux-saga/effects'
import { ACTIONS, departmentRequestSuccess, departmentRequestFailure } from './actions'
import { getDepartment } from '@/services/departmentsServices'

function* fetchDepartment({ payload }) {
  const { response, error } = yield call(getDepartment, payload.companyId, payload.departmentId)
  if (response) yield put(departmentRequestSuccess(response.data))
  else yield put(departmentRequestFailure(error.response))
}

export default all([takeLatest(ACTIONS.GET_DEPARTMENT_REQUEST, fetchDepartment)])
