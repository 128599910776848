import { createContext } from 'react'
import { IOrder } from 'main/interfaces'
import { Button, Divider, Grid, Paper } from 'presentation/components/atoms'
import { PageHeader } from 'presentation/components/molecules'
import { PaymentDetail } from './components/paymentDetail/paymentDetail'
import { StatusInformation } from './components/statusInformation/statusInformation'
import { OrderItems } from './components/orderItems/orderItems'
import { OrderTrackingSkeleton } from './components/orderTrackingSkeleton/orderTrackingSkeleton'
import { useMediaQuery } from '@mui/material'
import { Content } from 'presentation/components'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFilter, useNotifications, usePagination } from '@/main/hooks'
import { useOrderDetail } from './hooks/useOrderDetail'
import { ORDER_TYPES } from '@/main/utils/constants'
import { errorHandler } from '@/main/utils/functions/errorHandler'
import { useOrderItems } from './hooks/useOrderItems'
import { usePix } from '@/main/hooks/usePix'
import { EStatusCompra } from '@/main/enums'
import { atomWithHash } from 'jotai-location'

type ItemsContent = {
  orderItems: any
  isLoading: boolean
  filter: any
  goToPage: (page: number) => void
  setPageSize: (pageSize: number) => void
  updateFilter: (filterValues: object) => void
}
interface IOrderTrackingContext extends IOrder {
  isRecharge: boolean
  items: ItemsContent
}
export const OrderTrackingContext = createContext<IOrderTrackingContext>({} as IOrderTrackingContext)

const filterAtom = atomWithHash<object>('orderItemsFilter', null)
const paginationAtom = atomWithHash<object>('orderItemsPagination', null)

export const OrderTracking = () => {
  const navigate = useNavigate()
  const notify = useNotifications()
  const { filter, updateFilter } = useFilter({ filterAtom })
  const { pagination, goToPage, setPageSize } = usePagination({
    paginationAtom,
    initialValue: {
      pageSize: 200,
    },
  })

  const { items: orderItems } = useOrderItems({ filter, pagination })

  const { order, isLoading: isLoadingOrder } = useOrderDetail({
    config: {
      onError: (e) => {
        navigate('/recargas')
        notify.push(errorHandler(e?.response?.data?.errors))
      },
    },
  })

  const shouldPoolForPix =
    order?.usedPaymentsTypes?.includes('PIX') && order?.status === EStatusCompra.AguardandoPagamento
  const pixInfo = shouldPoolForPix
    ? { orderId: order?.orderId, orderType: order?.orderType ? 'cards' : 'recharge' }
    : { orderId: null, orderType: null }

  const { data } = usePix(pixInfo as any)

  if (order) {
    if (data?.pix) {
      order.pix = {
        qrCode: data.pix?.qrCode,
        orderDate: order.orderDate,
        value: order.orderSummary.totalPaid,
      }
    } else {
      order.pix = {
        qrCode: '',
        orderDate: '',
        value: 0,
      }
    }
  }

  const isLoading = isLoadingOrder
  const isRecharge = order?.orderType >= ORDER_TYPES.RECHARGE

  const handleReturn = useCallback(() => {
    const targetPath = isRecharge
      ? { pathname: '/recargas' }
      : {
          pathname: '/cartoes',
          state: { order: true },
        }
    navigate(targetPath)
  }, [isRecharge, navigate])

  const shouldBreak = useMediaQuery('@media (max-width:1150px)')

  /* const [idToCancel, setIdToCancel] = useState<string | null>(null) */
  /* const isCancelable = order?.orderStatus < ORDER_STATUS.WAITING_FOR_PROCESSING */

  return (
    <Content>
      <OrderTrackingContext.Provider
        value={{
          ...order,
          isRecharge,
          items: {
            filter,
            goToPage,
            isLoading,
            orderItems,
            setPageSize,
            updateFilter,
          },
        }}
      >
        <Paper sx={{ padding: '32px', overflow: 'auto' }}>
          <PageHeader pageTitle='Acompanhamento de Pedido' padding='0' noDivider />
          <Divider sx={{ margin: '32px 0' }} />
          {isLoading || !order ? (
            <OrderTrackingSkeleton />
          ) : (
            <Grid container spacing='32px' justifyContent='space-between'>
              <Grid item xs={12} sm={12} md={shouldBreak ? 12 : 8} lg={7}>
                <StatusInformation />
                <OrderItems />
              </Grid>
              <Grid item xs={12} sm={12} md={shouldBreak ? 12 : 4} lg={5}>
                <PaymentDetail />
              </Grid>
              {/* {isCancelable &&
                    <Grid item xs={12} sm='auto'>
                      <Button variant='outlined' color='error' onClick={() => setIdToCancel(order.orderId)} fullWidth>
                        Cancelar pedido
                      </Button>
                    </Grid>
                  } */}
              <Grid item xs={12} sm='auto' sx={{ marginLeft: 'auto' }}>
                <Button variant='outlined' onClick={handleReturn} fullWidth>
                  Voltar
                </Button>
              </Grid>
            </Grid>
          )}
        </Paper>
      </OrderTrackingContext.Provider>
      {/*   {!isLoading && order &&
            <OrderCancelationModal
              open={Boolean(idToCancel)}
              onCancel={() => setIdToCancel(null)}
              onConfirm={() => {
                if (idToCancel) {
                  cancelOrder.mutate({ orderId: idToCancel })
                  setIdToCancel(null)
                }
              }}
              orders={[
                {
                  code: order.orderCode,
                  id: order.orderId,
                  orderType: order.orderType,
                  totalValue: order.orderSummary.totalPaid,
                  totalItems: order.totalItems,
                },
              ]}
            />
          } */}
    </Content>
  )
}
