import Spinner from 'presentation/components/spinner'
import React from 'react'
import Modal from 'react-responsive-modal'
import { Content, LoadingContainer } from '../styles'

const ModalV2 = ({ open, onClose, children, loading = false, ...props }) => {
  return (
    <Modal open={open} onClose={onClose} center {...props}>
      {loading ? (
        <LoadingContainer>
          <Spinner />
        </LoadingContainer>
      ) : (
        <Content>{children}</Content>
      )}
    </Modal>
  )
}

export default ModalV2
