import React from 'react'
import { Body, TextConfirm, ColorDest, SessionButton, Close } from '../styles'

import 'react-responsive-modal/styles.css'
import { Modal } from 'react-responsive-modal'
import api from '@/infra/api'
import { getEmployeesRequest } from '@/store/modules/employees/actions'
import { useDispatch } from 'react-redux'
import paginatorMethods from '@/utils/paginatorMethods'
import { pushNotification } from '@/store/modules/notification/actions'
import errorHandler from '@/utils/errorHandler'
import successHandler from '@/utils/successHandler'
import { Button } from '@mui/material'

const DeleteComponent = ({ paginator, open, close, id }) => {
  const dispatch = useDispatch()

  const deleteConfirm = async () => {
    try {
      await api.delete(`/employees-service/${id}`)
      dispatch(pushNotification(successHandler('Colaborador excluído com sucesso!')))
      dispatch(getEmployeesRequest(paginatorMethods.buildQuery(paginator)))
      close()
    } catch (error) {
      dispatch(pushNotification(errorHandler(error.response)))
    }
  }

  return (
    <Modal open={open} onClose={close} center showCloseIcon={false} styles={{ borderRadius: '5px' }}>
      <Body>
        <Close onClick={close}>x</Close>
        <TextConfirm>
          Você tem certeza que deseja <ColorDest>excluir</ColorDest> <br />
          esse colaborador?
        </TextConfirm>
        <SessionButton>
          <Button variant='action' width='200px' onClick={close}>
            não
          </Button>
          <div style={{ paddingLeft: '15px' }} />
          <Button width='250px' onClick={() => deleteConfirm()}>
            Sim, excluir
          </Button>
        </SessionButton>
      </Body>
    </Modal>
  )
}

export default DeleteComponent
