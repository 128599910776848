import { IntegrationSwapper } from '@/presentation/components'
import { RechargePayment as PaymentSucceed } from './rechargePayment'
import { useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { pushNotification } from '@/store/modules/notification/actions'
import errorHandler from '@/utils/errorHandler'
import { getCardsOrderSlip } from '@/services/cardsServices'
import { usePayment } from '@/main/hooks'
import { useOrderSlip } from './hooks/useOrderSlip'

const OldIntegration = () => {
  const { state: orderInfo } = useLocation()
  const isSlipNeeded = orderInfo.totalToPay !== 0

  const { boletoLink } = useOrderSlip({ isSlipNeeded, id: orderInfo?.id })

  return <PaymentSucceed slipLink={boletoLink} orderInfo={orderInfo} isSlipNeeded={isSlipNeeded} />
}

const NewIntegration = () => {
  const { state: orderInfo } = useLocation()
  const isSlipNeeded = orderInfo.totalToPay !== 0
  const { paymentData } = usePayment({ purchaseId: orderInfo.purchaseId })
  const slipLink = paymentData?.data?.valor?.boleto?.url

  return <PaymentSucceed slipLink={slipLink} orderInfo={orderInfo} isSlipNeeded={isSlipNeeded} />
}

export const RechargePayment = () => {
  const location = useLocation()
  return (
    <IntegrationSwapper
      isDealer={location?.state?.isDealer}
      components={{
        ifIsMotiva: <OldIntegration />,
        ifIsNotMotiva: <NewIntegration />,
      }}
    />
  )
}
