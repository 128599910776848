import React from 'react'
import { Body, TextConfirm, SessionButton, ColorDest, Close } from './style'
import { Button, Icon } from 'presentation/components'
import 'react-responsive-modal/styles.css'
import { Modal as ModalBox } from 'react-responsive-modal'

const NotificationModal = ({ open, control }) => {
  return (
    <ModalBox open={open} onClose={control} center showCloseIcon={false} styles={{ borderRadius: '5px' }}>
      <Body>
        <Close onClick={control}>
          <Icon name='x' />
        </Close>
        <TextConfirm>
          Você precisa aceitar os <ColorDest>Termos do Contrato de Adesão</ColorDest> para concluir a migração.
        </TextConfirm>
        <SessionButton>
          <Button width='250px' onClick={control}>
            Ok
          </Button>
        </SessionButton>
      </Body>
    </ModalBox>
  )
}

export default NotificationModal
