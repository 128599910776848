import { IntegrationSwapper } from '@/presentation/components'
import { BrokerImport as Import } from './brokerImport'
import { useBrokerOrderOld } from './hooks/useBrokerOrderOld'
import { useBrokerOrder } from './hooks/useBrokerOrder'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

const OldIntegration = () => {
  const integration = useBrokerOrderOld()
  return <Import integration={integration} />
}

const NewIntegration = () => {
  const integration = useBrokerOrder()
  return <Import integration={integration} />
}

export const BrokerImport = () => {
  const location = useLocation()
  const navigate = useNavigate()
  useEffect(() => {
    if (location.state === null) navigate('/recargas')
  })
  return (
    <IntegrationSwapper
      isDealer={location?.state?.isDealer}
      components={{
        ifIsMotiva: <OldIntegration />,
        ifIsNotMotiva: <NewIntegration />,
      }}
    />
  )
}
