import React from 'react'
import { Panel, Spacing, Title } from 'presentation/components'
import { Container, ConfirmationIcon } from './styles'

const RegisterConfirmation = () => {
  return (
    <Panel>
      <Container>
        <Spacing top='100px' />
        <ConfirmationIcon name='check-circle' />
        <Spacing top='64px' />

        <Title.SubTitle>Solicitação de cadastro efetuada!</Title.SubTitle>
        <Spacing top='56px' />

        <p>
          O cadastro foi processado e os dados serão validados.
          <br />
          <br />
          Você receberá uma confirmação e o acesso à conta da sua empresa por email.
          <br />
          <br />
          Em caso de dúvidas, entre em contato com nosso atendimento (31) 2342-1690.
        </p>
      </Container>
    </Panel>
  )
}

export default RegisterConfirmation
