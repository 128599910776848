import moment from 'moment'
import { Typography } from 'presentation/components/atoms'

export const Canceled = ({ cancelDate }: { cancelDate?: string | null }) => {
  return (
    <Typography variant='h3' sx={{ fontWeight: 500 }}>
      Seu pedido foi cancelado{cancelDate && ` em ${moment(cancelDate).format('DD/MM/YYYY')}`}.
    </Typography>
  )
}
