import { BREAK_POINTS } from '@/utils/constants'
import styled from 'styled-components'
import { Row as GridRow } from 'react-grid-system'

export const ButtonWrapper = styled.div`
  padding-right: ${({ last }) => (last ? '32px' : '0')};

  button {
    max-width: 250px;
    @media (max-width: ${BREAK_POINTS.xl}) {
      margin: 24px auto;
      padding: 32px;
    }
  }

  @media (max-width: ${BREAK_POINTS.xl}) {
    padding-right: 0;
  }
`

export const SearchWrapper = styled.div`
  padding-left: 54px;
  margin-top: 24px;
  @media (max-width: ${BREAK_POINTS.md}) {
    padding: 0 54px;
  }
`

export const HasOderText = styled.span`
  font-size: 1rem;

  p:first-of-type {
    font-weight: var(--font-weight-semi-bold);
    font-size: 1.5rem;
    margin-top: 64px;
  }
  p:last-of-type {
    margin-bottom: 64px;
  }

  svg {
    height: 128px;
    width: 128px;
    transform: rotate(180deg);
    fill: #cfffb9;
  }
`

export const ModalContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  width: 100%;
  height: 100%;
`
export const ModalTitle = styled.p`
  color: #632ecb;
  font-weight: bold;
  font-size: 18px;
`

export const ModalHeader = styled.header`
  display: flex;
  justify-content: space-between;
`
export const ModalBody = styled.main`
  display: flex;
  flex: 1;
  overflow: auto;
`

export const ModalFooter = styled.footer`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin: 0 auto;
`

export const NoEmployees = styled.p`
  text-align: center;
`
export const Row = styled(GridRow)`
  & + div {
    margin-top: 40px;
  }
`

export const StyledInput = styled.div`
  input {
    border: 1px solid var(--color-secundary-06);
    borderradius: 0.25rem;
    fontsize: 0.875rem;
    fontweight: var(--font-weight-regular);
    padding: 0 1rem;
    outline: none;
    height: 2.5rem;
    width: 100%;
  }
`

export const StyledInputLabel = styled.div`
  label {
    font-family: Montserrat;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 8px;
    display: block;
  }
`
