export enum ETipoPagamento {
  Gratuito,
  Boleto,
  Pix,
  CarteiraSK,
  CarteiraOperadora,
}

export enum ETipoPedido {
  PrimeiraViaVtDealer = 1,
  SegundaViaVtDealer = 2,
  RecargaVtDealer = 3,
  RecargaVtBroker = 4,
}

export enum EStatusPedido {
  NoCarrinho,
  Efetivando,
  Efetivado,
  AguardandoProcessamento,
  EmProcessamento,
  EmAndamento,
  DisponivelRetirada,
  Entrega,
  Finalizado,
  Cancelando,
  Cancelado,
}

export enum EStatusCompra {
  Carrinho,
  Efetivando,
  ProcessandoFormaPagamento,
  AguardandoPagamento,
  EmAndamento,
  Finalizado,
  Cancelando,
  Cancelado,
}
