import { Chip, styled } from '@mui/material'

export const StyledChip = styled(Chip)(({ theme, textColor, tagColor }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  gap: 'var(--space-4)',
  backgroundColor: tagColor || theme.palette.primary.light + '1A',
  color: textColor || theme.palette.primary.main,
  '-webkit-text-fill-color': textColor || theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightBold,
  padding: '6px 4px',
  borderRadius: 8,
  whiteSpace: 'nowrap',
  userSelect: 'none',
  '& svg': {
    color: theme.palette.primary.light + 'B3 !important',
    opacity: 0.7,
  },
}))
