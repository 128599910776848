import styled from 'styled-components'

export const ToggleContainer = styled.label`
  height: 24px;

  input {
    opacity: 0;
    position: absolute;
  }

  input:checked + span::before {
    background-color: var(--color-primary-05);
    left: calc(100% - 24px);
  }

  input:checked + span {
    background-color: var(--color-primary-01);
    border: none;
    color: var(--color-primary-05);
    justify-content: flex-end;
    padding: 0px 3.25rem 0 1rem;
  }
`

export const Slider = styled.span`
  align-items: center;
  background-color: var(--color-secundary-02);
  border: 0.0625rem solid var(--color-primary-03);
  color: var(--color-primary-03);
  cursor: pointer;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: var(--font-weight-regular);
  display: inline-flex;
  height: 24px;
  letter-spacing: 0.01625rem;
  line-height: 1.5rem;
  width: 100%;
  padding: 0 1rem 0 3.25rem;
  position: relative;
  transition: color 0.2s ease-in-out;
  transition-delay: 0.2s;

  &:before {
    background-color: var(--color-primary-03);
    border: none;
    border-radius: 50%;
    content: '';
    height: 16px;
    width: 16px;
    left: 8px;
    position: absolute;
    transition: all 0.2s linear;
  }
`
