import { styled } from '@mui/material'
import { Box, Typography } from 'presentation/components/atoms'

export const TooltipContent = styled('div')(({ theme }) => ({
  '& p': {
    color: '#616161',

    '& span': {
      fontWeight: 'bold',
      fontStyle: 'italic',
    },
  },
  '& p:last-child': {
    marginTop: '20px',
    fontWeight: 'bold',
    '& small': {
      color: '#1976D2',
    },
  },
}))

export const OrderDetailHeader = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignFlex: 'center',
  justifyContent: 'flex-start',
  gap: '32px',
}))

export const TotalValue = styled(Typography)(() => ({
  fontWeight: 700,
  marginLeft: 'auto',
  '&:before': {
    content: "'R$ '",
    fontSize: '0.625rem',
    fontWeight: 500,
  },
}))

export const ItemsContainer = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[200]}`,
  padding: '16px 16px ',
  position: 'relative',
  '& + &': {
    borderBottom: 'none',
    borderTop: 'none',
  },
  '&:not(:last-of-type):before': {
    content: "' '",
    position: 'absolute',
    bottom: 0,
    width: 'calc(100% - 32px)',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  '&:nth-of-type(1)': {
    borderBottom: 'none',
    borderRadius: '4px 4px 0 0',
  },
  '&:last-of-type': {
    border: `1px solid ${theme.palette.grey[200]}`,
    borderTop: 'none',
    borderRadius: '0 0 4px 4px',
  },
  '&:only-of-type': {
    border: `1px solid ${theme.palette.grey[200]}`,
  },
}))

export const CustomButton = styled(Box)(({ theme }) => ({
  border: 'none',
  background: 'none',
  color: theme.palette.primary.main,
  cursor: 'pointer',
}))
