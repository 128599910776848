import styled from 'styled-components'

export const Body = styled.div`
  padding: 30px;
  z-index: 99;
`

export const TextConfirm = styled.p`
  text-align: center;
  font-size: 22px;
  padding: 50px 0;
`

export const ColorDest = styled.span`
  color: #632ecb;
`

export const SessionButton = styled.div`
  display: flex;
  justify-content: center;
  width: 65%;
  margin: 0 auto;
  padding-bottom: 20px;
`

export const Close = styled.p`
  cursor: pointer;
  text-align: right;
  font-size: 20px;

  svg {
    fill: var(--color-primary-03);
  }
`
