import React, { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import { Button, ErrorMessage, ErrorWrapper, Input, Panel, Spacing, Spinner, Title } from 'presentation/components'
import { Centralizer } from './styles'
import schema from './schema'
import { getResaleSiteFromUrl, removeSpecialCharacters } from '@/utils/functionUtils'
import { useDispatch } from 'react-redux'
import Modal from '../../modal'
import { companyDataStore } from '@/store/modules/empresa/actions'
import { pushNotification } from '@/store/modules/notification/actions'
import errorHandler from '@/utils/errorHandler'
import { getCompanyInfo } from '@/services/companiesServices'
import { useNavigate } from 'react-router-dom'
import useService from '@/main/hooks/useService'
import { getRegisterCompanyToken } from '@/services/authServices'

const ACTIONS = (navigate, token) => ({
  'Não Cadastrado': {
    modal: 'Não foi encontrado cadastro, deseja cadastrar?',
    action: () => {
      navigate('/cadastro', {
        state: { mode: 'FORM', token },
      })
    },
  },
  Cadastrado: {
    modal: 'Foi encontrado cadastro para esse CNPJ, deseja fazer o login?',
    action: () => navigate('/'),
  },
  true: {
    modal:
      'Identificamos seu cadastro na nossa base Transfácil. Gostaria de trazer seu cadastro para a plataforma Motiva?',
    action: (companyInfo) =>
      navigate('/cadastro/migrar', {
        state: { companyInfo: companyInfo, token },
      }),
  },
})

const InsertCnpj = () => {
  const navigate = useNavigate()
  const [isModalVisible, setIsModalVisible] = useState(null)
  const [loading, setLoading] = useState(false)
  const [companyInfo, setCompanyInfo] = useState(null)
  const dispatch = useDispatch()
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  })

  const [genericTokenState, genericTokenStateRequest] = useService(getRegisterCompanyToken)
  const token = genericTokenState?.response?.data?.accessToken

  useEffect(() => {
    genericTokenStateRequest(getResaleSiteFromUrl())
  }, [dispatch])

  async function callApi(cnpj) {
    setLoading(true)
    const { error, response } = await getCompanyInfo(getResaleSiteFromUrl(), cnpj, token)
    if (response) {
      const status = response?.data?.status
      const isMigration = response?.data?.isMigration
      setCompanyInfo(response?.data?.data)
      dispatch(companyDataStore({ cnpj }))
      setIsModalVisible(ACTIONS(navigate, token)[isMigration || status])
    } else {
      dispatch(pushNotification(errorHandler(error)))
    }
    setLoading(false)
  }

  function onSubmit(data) {
    const cnpjWithoutMask = removeSpecialCharacters(data.cnpj)
    callApi(cnpjWithoutMask)
  }

  return (
    <Panel>
      <Centralizer>
        <Title.SubTitle>Cadastro</Title.SubTitle>
        <Spacing top='90px' />
        {loading || genericTokenState.loading ? (
          <Spinner />
        ) : (
          <>
            <p>Informe o CNPJ da sua empresa.</p>
            <Spacing top='40px' />
            <span></span>
            <form onSubmit={handleSubmit(onSubmit)} id='personalData'>
              <ErrorWrapper error={errors.cnpj}>
                <label>CNPJ</label>
                <Spacing top='8px' />
                <Controller
                  control={control}
                  name='cnpj'
                  render={({ field: { onChange, value, ref } }) => (
                    <Input.Masked
                      format='##.###.###/####-##'
                      placeholder='00.000.000/0000-00'
                      value={value}
                      getInputRef={ref}
                      required
                      onChange={onChange}
                    />
                  )}
                />
                {errors.cnpj && <ErrorMessage>{errors.cnpj.message}</ErrorMessage>}
              </ErrorWrapper>
              <Spacing top='40px' />
              <Button width='280px' style={{ margin: 'auto' }}>
                Cadastrar
              </Button>
            </form>
          </>
        )}
      </Centralizer>
      {!!isModalVisible && (
        <Modal
          open={!!isModalVisible}
          message={isModalVisible.modal}
          confirm={() => isModalVisible.action(companyInfo)}
          cancel={() => setIsModalVisible(false)}
        />
      )}
    </Panel>
  )
}

export default InsertCnpj
