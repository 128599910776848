import styled from 'styled-components'

export const CenterText = styled.div`
  margin-top: 32px;
  text-align: center;
`

export const Description = styled.p`
  font-size: 1.2rem;
  margin: 32px 0;
  text-align: center;
  white-space: nowrap;
`

export const CheckIconContainer = styled.div`
  margin: auto;
  width: 96px;
  svg {
    height: 96px;
    width: 96px;
    stroke: #8fef91;
  }
`
