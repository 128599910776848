import Modal from 'react-responsive-modal'

import { Button } from 'presentation/components/atoms'
import { Spinner } from 'presentation/components'

import { ButtonsWrapper, Content, LoadingContainer } from '../styles'

const TwoOptions = ({
  open,
  onClose,
  onLeftClick,
  leftText = 'Não',
  onRightClick,
  rightText = 'Sim',
  children,
  loading = false,
  ...props
}) => {
  return (
    <Modal open={open} onClose={onClose} center {...props} modalId={props?.modalId}>
      {loading ? (
        <LoadingContainer>
          <Spinner />
        </LoadingContainer>
      ) : (
        <Content className={props?.className}>
          {children}
          <ButtonsWrapper>
            <Button variant='outlined' onClick={onLeftClick}>
              {leftText}
            </Button>
            <Button variant='contained' onClick={onRightClick}>
              {rightText}
            </Button>
          </ButtonsWrapper>
        </Content>
      )}
    </Modal>
  )
}

export default TwoOptions
