import { ORDER_STATUS } from '@/utils/constants'

export const status = {
  2: {
    value: 0,
  },
  3: {
    value: 1,
  },
  4: {
    label: ORDER_STATUS['4'],
    value: 2,
  },
  5: {
    label: ORDER_STATUS['5'],
    value: 2,
  },
  6: {
    label: ORDER_STATUS['6'],
    value: 2,
  },
  15: {
    value: 3,
  },
}
