import { Input } from 'presentation/components'
import useDebounce from 'main/hooks/useDebouce'
import { getCards } from '@/services/cardsServices'
import { pushNotification } from '@/store/modules/notification/actions'
import errorHandler from '@/utils/errorHandler'
import { productStatus } from '@/utils/options'
import React, { useCallback, useEffect, useState } from 'react'
import { Col, Row } from 'react-grid-system'
import { Controller } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { InputWrapper, Label } from './styles'

const CardInfoFields = ({ control, disabled, formState }) => {
  const [cards, setCards] = useState([])
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()
  const debounce = useDebounce()

  const fetchData = useCallback(
    async (query) => {
      setLoading(true)
      const { error, response } = await getCards(query)
      if (response) setCards(response.data.data)
      else dispatch(pushNotification(errorHandler(error.response)))
      setLoading(false)
    },
    [dispatch],
  )

  useEffect(() => {
    fetchData('?sort=number&pageSize=10000')
  }, [fetchData])

  const handleCardSearch = useCallback(
    (inputValue) => {
      debounce(() => fetchData(`?sort=number&pageSize=10000&number=${inputValue}`), 500)
    },
    [debounce, fetchData],
  )

  return (
    <Row>
      <Col sm={12} md={6}>
        <InputWrapper>
          <Label>N° do Cartão</Label>
          <Controller
            control={control}
            name='cardIds'
            render={({ field: { onChange, value } }) => (
              <Input.MultiSelect
                placeholder='Selecione os cartões'
                multiSelectionLabel='cartões selecionados'
                value={value}
                onChange={onChange}
                isDisabled={disabled || formState.dirtyFields.cardStatus}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.number}
                options={cards}
                onInputChange={handleCardSearch}
                isLoading={loading}
              />
            )}
          />
        </InputWrapper>
      </Col>
      <Col sm={12} md={6}>
        <InputWrapper>
          <Label>Status do Cartão</Label>
          <Controller
            control={control}
            name='cardStatus'
            render={({ field: { onChange, value, ref } }) => (
              <Input.Select
                placeholder='Selecione o status'
                value={value}
                inputRef={ref}
                onChange={onChange}
                options={productStatus}
                isDisabled={disabled || formState.dirtyFields.cardIds}
              />
            )}
          />
        </InputWrapper>
      </Col>
    </Row>
  )
}

export default CardInfoFields
