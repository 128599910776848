const ACTION = '@report/'

export const ACTIONS = {
  GET_CARD_SIGNATURES_REPORT_REQUEST: ACTION + 'GET_CARD_SIGNATURES_REPORT_REQUEST',

  REPORT_REQUEST_SUCCESS: ACTION + 'REPORT_REQUEST_SUCCESS',
  REPORT_REQUEST_FAILURE: ACTION + 'REPORT_REQUEST_FAILURE',
  CLEAR_REPORT_STORE: ACTION + 'CLEAR_REPORT_STORE',
}

export function getCardSignaturesReportRequest(orderId, query) {
  return {
    type: ACTIONS.GET_CARD_SIGNATURES_REPORT_REQUEST,
    payload: { orderId, query },
  }
}

export function reportRequestSuccess(data) {
  return {
    type: ACTIONS.REPORT_REQUEST_SUCCESS,
    payload: { data },
  }
}

export function reportRequestFailure(data) {
  return {
    type: ACTIONS.REPORT_REQUEST_FAILURE,
    payload: { data },
  }
}
