import { useCallback, useEffect } from 'react'
import { WritableAtom, useAtom } from 'jotai'
import { RESET } from 'jotai/utils'

type SetStateActionWithReset<Value> = Value | typeof RESET | ((prev: Value) => Value | typeof RESET)

interface IUseAtomWithStorageObserver<T> {
  atomWithStorage: WritableAtom<T, [SetStateActionWithReset<T>], void>
  storageName: string
}

export const dispatchAtomWithStorageObserverChangeEvent = ({ storageName }: { storageName: string }) => {
  window.dispatchEvent(new Event(storageName + 'Changed'))
}

export const useAtomWithStorageObserver = <T>({ atomWithStorage, storageName }: IUseAtomWithStorageObserver<T>) => {
  const [atom, setAtom] = useAtom(atomWithStorage)

  const listenerFn = useCallback(() => {
    const newData = JSON.parse(localStorage.getItem(storageName))
    setAtom(newData)
  }, [setAtom, storageName])

  useEffect(() => {
    const customEvent = storageName + 'Changed'
    window.addEventListener(customEvent, listenerFn)
    return () => window.removeEventListener(customEvent, listenerFn)
  }, [listenerFn, storageName])

  return [atom, setAtom] as any
}
