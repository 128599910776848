import React, { useState, useEffect } from 'react'
import { Container, Col } from 'react-grid-system'
import { useForm, useFieldArray } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Title } from 'presentation/components'
import { AddAddress, Fieldset, Hr, Row } from './style'
import { useDispatch } from 'react-redux'
import schema from '../schema/addresses'
import Modal from '../../modal'
import AddressesGrid from './addressesGrid'
import { companyAddressesClear } from '@/store/modules/empresa/actions'
import ErrorModal from '../ErrorModal'
import { FORM_MODE } from '@/utils/constants'
import useService from 'main/hooks/useService'
import { getAddressesTypesForCompanyRegister, getLaneTypesForCompanyRegister } from '@/services/companiesServices'
import { useLocation } from 'react-router-dom'

const ADDRESSES_LIMIT = 1

const Addresses = ({ changeForm, controlNavigation, mode, nextTab, prevTab, saveOnStore, companyData }) => {
  const readOnly = mode === FORM_MODE.VIEW ? true : false
  const [cancelModal, setCancelModal] = useState(false)
  const [needDeliveryAddress, setNeedDeliveryAddress] = useState(false)
  const [removeAddres, setRemoveAddres] = useState(null)
  const {
    state: { token },
  } = useLocation()

  const dispatch = useDispatch()

  let defaultValues = {}
  if (companyData != null) {
    defaultValues = {
      addresses: companyData?.addresses?.filter((address) => address.isMain === true) || [{ cep: '' }],
      deliveryAddresses: companyData?.addresses?.filter((address) => address.isMain !== true) || [],
    }
  }

  const hookForm = useForm({
    defaultValues: defaultValues,
    mode: 'onChange',
    resolver: yupResolver(schema),
    shouldUnregister: true,
  })

  const addressesFieldArray = useFieldArray({
    control: hookForm.control,
    name: 'addresses',
  })

  const deliveryAddressesFieldArray = useFieldArray({
    control: hookForm.control,
    name: 'deliveryAddresses',
  })
  const [fetchAddressTypeCodesState, fetchAddressTypeCodesRequest] = useService(getAddressesTypesForCompanyRegister, {
    silent: true,
  })
  const addressTypeCodes = fetchAddressTypeCodesState?.response?.data?.data || []

  const [fetchLaneTypesState, fetchLaneTypesRequest] = useService(getLaneTypesForCompanyRegister, { silent: true })
  const laneTypeCodes = fetchLaneTypesState?.response?.data?.data || []

  useEffect(() => {
    fetchLaneTypesRequest(token)
    fetchAddressTypeCodesRequest(token)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const canPass = hookForm.formState.isValid
    controlNavigation(canPass)
    // eslint-disable-next-line
  }, [hookForm.formState.isValid, controlNavigation])

  function generateAddressFields(field, fieldsArray) {
    return fieldsArray.fields.map((_, index) => {
      const errors = hookForm.formState.errors[field]?.length >= index - 1 && hookForm.formState.errors[field][index]
      return (
        <AddressesGrid
          errors={errors}
          form={hookForm}
          isMain={field === 'addresses'}
          name={`${field}.${index}`}
          key={_.id}
          addressTypeCodes={addressTypeCodes}
          laneTypeCodes={laneTypeCodes}
          isLoading={fetchAddressTypeCodesState.loading || fetchLaneTypesState.loading}
          remove={
            field !== 'addresses'
              ? () => {
                  setRemoveAddres(() => () => {
                    fieldsArray.remove(index)
                    setRemoveAddres(null)
                  })
                }
              : null
          }
        />
      )
    })
  }

  function confirmCancel() {
    setCancelModal(false)
    dispatch(companyAddressesClear())
    prevTab()
  }

  function checkIfHasADeliveryAddres(data) {
    return data.deliveryAddresses.length < 1 && data.addresses[0].canDeliver !== true
  }

  const onSubmit = (data) => {
    if (checkIfHasADeliveryAddres(data)) {
      setNeedDeliveryAddress(true)
      return
    }
    saveOnStore(data)
    nextTab()
  }

  changeForm(() => {
    if (checkIfHasADeliveryAddres(hookForm.getValues())) {
      controlNavigation(false)
      setNeedDeliveryAddress(true)
      return
    }
    hookForm.handleSubmit(saveOnStore)()
  })

  return (
    <>
      <form onSubmit={hookForm.handleSubmit(onSubmit)} id='addressInformation'>
        <Fieldset disabled={readOnly}>
          <Container style={{ margin: 0, padding: 0, maxWidth: 'initial' }}>
            {generateAddressFields('addresses', addressesFieldArray)}
            {addressesFieldArray.fields.length < ADDRESSES_LIMIT && (
              <Row>
                <Col sm={12}>
                  <AddAddress onClick={() => addressesFieldArray.append({ cep: '' })}>Adicionar endereço</AddAddress>
                </Col>
              </Row>
            )}
            <Row>
              <Col sm={12}>
                <Hr />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <Title.SubTitle>Endereços de entrega dos cartões de transporte</Title.SubTitle>
              </Col>
            </Row>
            {generateAddressFields('deliveryAddresses', deliveryAddressesFieldArray)}
            <Row>
              <Col sm={12}>
                <AddAddress onClick={() => deliveryAddressesFieldArray.append({ cep: '' })}>
                  Adicionar endereço de entrega
                </AddAddress>
              </Col>
            </Row>
          </Container>
        </Fieldset>
      </form>
      <Row justify='end' gutterWidth={24} style={{ marginTop: '8vh' }}>
        <Col sm={2}>
          <Button variant='outlined' onClick={() => setCancelModal(true)}>
            Cancelar
          </Button>
        </Col>
        <Col sm={2}>
          {mode === FORM_MODE.VIEW ? (
            <Button type='button' onClick={nextTab}>
              Próximo
            </Button>
          ) : (
            <Button type='submit' form='addressInformation'>
              Avançar
            </Button>
          )}
        </Col>
      </Row>

      {!!removeAddres && (
        <Modal
          open={!!removeAddres}
          message='Deseja mesmo apagar este endereço?'
          confirm={() => {
            removeAddres()
          }}
          cancel={() => {
            setRemoveAddres(null)
          }}
        />
      )}

      {cancelModal && (
        <Modal
          message='Atenção! Os dados preenchidos serão perdidos. Deseja sair da página?'
          open={cancelModal}
          confirm={confirmCancel}
          cancel={() => setCancelModal(false)}
        />
      )}
      {needDeliveryAddress && (
        <ErrorModal open={needDeliveryAddress} cancel={() => setNeedDeliveryAddress(false)}>
          Atenção! É necessário definir ao menos um endereço de entrega.
        </ErrorModal>
      )}
    </>
  )
}
export default Addresses
