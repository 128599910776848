import styled from 'styled-components'

export const Border = styled.div`
  padding: ${({ paddingSize }) => paddingSize};
`

export const ScrollWrapper = styled.div`
  overflow-x: auto;
  width: 100%;
`
