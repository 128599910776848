import { useState, useEffect } from 'react'
import filesize from 'filesize'
import axios from 'axios'

import { download } from '@/utils/functionUtils'
import api from '@/infra/api'

export const useImportEmployee = () => {
  const [uploadingFile, setUploadingFile] = useState({})
  const [response, setResponse] = useState(null)
  const [error, setError] = useState(null)

  function processUpload(file) {
    const data = new FormData()
    data.append('files', file.file)

    try {
      api
        .post('/rh-bff/employees/import', data, {
          cancelToken: uploadingFile.cancelToken.token,
          onUploadProgress: (e) => {
            const progress = parseInt(Math.round((e.loaded * 100) / e.total))
            setUploadingFile({ ...uploadingFile, percentage: progress, uploading: true, send: false })
          },
        })
        .then((response) => {
          setUploadingFile({ ...uploadingFile, uploaded: true, uploading: false, send: false })
          setResponse(response.data)
        })
        .catch((e) => {
          setUploadingFile({ ...uploadingFile, error: true, uploading: false, send: false })
          setResponse(e.message === 'cancel' ? null : { errors: 1 })
        })
    } catch (error) {
      return
    }
  }

  useEffect(() => {
    uploadingFile.send && processUpload(uploadingFile)
    // eslint-disable-next-line
  }, [uploadingFile])

  function handleDrop(file) {
    if (file.length < 1) return

    const cancel = axios.CancelToken.source()
    setUploadingFile({
      file: file[0],
      name: file[0].name,
      readeableSize: filesize(file[0].size),
      percentage: 0,
      uploaded: false,
      cancelToken: cancel,
      error: false,
      send: true,
    })
    setResponse(null)
  }

  const downloadFileTxt = async () => {
    const response = await axios({
      url: process.env.REACT_APP_IMPORT_EMPLOYEE_FILE_MODEL_URL,
      method: 'GET',
      responseType: 'blob',
    })
    const fileUrl = window.URL.createObjectURL(new Blob([response.data]))
    download(fileUrl, 'Modelo de arquivo para importação de colaboradores.txt')
  }

  const downloadFileExcel = async () => {
    const response = await api.get('/employees-service/generateExcelFile', {
      responseType: 'blob',
    })

    const fileUrl = window.URL.createObjectURL(new Blob([response.data]))
    download(fileUrl, 'Modelo de arquivo para importação de colaboradores.xlsx')
  }

  return {
    downloadFileTxt,
    downloadFileExcel,
    handleDrop,
    response,
    error,
    setError,
    uploadingFile,
  }
}
