import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { Col, Container, Row } from 'react-grid-system'
import {
  Background,
  Panel,
  Content,
  Input,
  Spacing,
  ErrorWrapper,
  ErrorMessage,
  Spinner,
  TransferList,
  Switch,
  Button,
  PageHeader,
} from 'presentation/components'
import { Subtitle, Label } from './styles'
import { pushNotification } from '@/store/modules/notification/actions'
import { getDepartmentEditData, patchDepartmentUpdate } from '@/services/departmentsServices'
import useService from 'main/hooks/useService'
import successHandler from '@/utils/successHandler'
import { DEPARTMENTS_SITUATION } from '@/utils/constants'
import { useLocation, useNavigate } from 'react-router-dom'

const DepartmentEdit = ({ ...props }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
    resolver: yupResolver(
      yup.object().shape({
        abortEarly: false,
        departmentName: yup
          .string()
          .max(100)
          .test(
            'only-letters-digits',
            'Apenas letras e números',
            (value) => !value.match(/[^a-z|0-9| |ªºáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ']/gi),
          )
          .required('O nome do departamento é obrigatório'),
      }),
    ),
  })

  const dispatch = useDispatch()
  const [withoutDepartment, setWithoutDepartment] = useState([])
  const [withDepartment, setWithDepartment] = useState([])
  const [departmentStatus, setDepartmentStatus] = useState(null)
  const [isNameAlreadyInUse, setIsNameAlreadyInUse] = useState(false)
  const departmentInitalState = useRef(null)
  const departmentId = location?.state
  const isDepartmentActive = departmentStatus === DEPARTMENTS_SITUATION.ACTIVE

  const [getDepartmentState, getDepartmentRequest] = useService(getDepartmentEditData, {
    onCompleted: (response) => {
      setDepartmentStatus(response.data.situation)
      setWithDepartment(response.data.employeesIncluded)
      setWithoutDepartment(response.data.employeesNotIncluded)
      setValue('departmentName', response.data.name)
      departmentInitalState.current = response.data
    },
  })

  const [updateDepartmentState, updateDepartmentRequest] = useService(patchDepartmentUpdate, {
    onCompleted: () => {
      dispatch(pushNotification(successHandler('Departamento Editado!')))
      navigate('/departamentos')
    },
  })

  const loading = getDepartmentState.loading || updateDepartmentState.loading

  useEffect(() => {
    getDepartmentRequest(departmentId)
    // eslint-disable-next-line
  }, [])

  const prepareEmployeesToUpdate = (actualList, initialList) => {
    const difference = actualList.filter((actualListEmployee) => {
      return initialList.every((initialListEmployee) => initialListEmployee.id !== actualListEmployee.id)
    })

    return difference.map((employee) => employee.id)
  }

  function handleSaveClick({ departmentName }) {
    if (isNameAlreadyInUse) return
    const departament = departmentInitalState.current
    const payload = {
      name: departmentName,
      situation: departmentStatus,
      employeesIncluded: prepareEmployeesToUpdate(withDepartment, departament.employeesIncluded),
      employeesRemoved: prepareEmployeesToUpdate(withoutDepartment, departament.employeesNotIncluded),
    }
    updateDepartmentRequest(departmentId, payload)
  }

  const checkNameAvailability = (newName) => {
    const isNameInUse = departmentInitalState.current.notAvailableNames.some((inUseName) => inUseName === newName)
    setIsNameAlreadyInUse(isNameInUse)
  }

  return (
    <Content>
      <Panel noPadding>
        <PageHeader>Departamentos</PageHeader>
        <Container
          style={{
            padding: '32px',
            maxWidth: 'auto',
          }}
        >
          {loading ? (
            <Spinner.Box>
              <Spinner />
            </Spinner.Box>
          ) : (
            <>
              <Subtitle>Editar departamento</Subtitle>
              <Container fluid style={{ padding: 0 }}>
                <Row style={{ marginTop: '20px', padding: 0 }} nogutter>
                  <Col
                    sm={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <form onSubmit={handleSubmit(handleSaveClick)} id='newDepartment'>
                      <ErrorWrapper error={errors.departmentName || isNameAlreadyInUse}>
                        <Label>Nome do departamento</Label>
                        <Controller
                          control={control}
                          name='departmentName'
                          required
                          render={({ field: { onChange, value } }) => (
                            <Input
                              maxLength={100}
                              placeholder='Nome do departamento'
                              value={value}
                              {...register('departmentName')}
                              onChange={(e) => {
                                checkNameAvailability(e.target.value)
                                onChange(e)
                              }}
                            />
                          )}
                        />
                        {isNameAlreadyInUse ? (
                          <ErrorMessage>Nome já utilizado</ErrorMessage>
                        ) : (
                          <ErrorMessage>{errors.departmentName && errors.departmentName.message}</ErrorMessage>
                        )}
                      </ErrorWrapper>
                    </form>
                    {(isDepartmentActive || !isDepartmentActive) && (
                      <div style={{ display: 'flex' }}>
                        {isDepartmentActive ? 'Inativar' : 'Ativar'}
                        <Spacing left='10px' />
                        <Switch
                          height={20}
                          onColor='#5e39ca'
                          checkedIcon={false}
                          checked={isDepartmentActive}
                          onChange={() =>
                            setDepartmentStatus(
                              isDepartmentActive ? DEPARTMENTS_SITUATION.INACTIVE : DEPARTMENTS_SITUATION.ACTIVE,
                            )
                          }
                        />
                      </div>
                    )}
                  </Col>
                </Row>
              </Container>
            </>
          )}
        </Container>
      </Panel>
      {!loading && (
        <>
          <Spacing top='32px' />
          <Container style={{ padding: 0, maxWidth: 'auto' }}>
            <Row nogutter>
              <Col>
                <TransferList
                  right={withDepartment}
                  rightName='Colaboradores associados a este departamento'
                  setRight={setWithDepartment}
                  left={withoutDepartment}
                  leftName='Colaboradores não associados a departamentos'
                  setLeft={setWithoutDepartment}
                  displayName='name'
                />
              </Col>
            </Row>
            <Row nogutter>
              <Col sm={10}></Col>
              <Col sm={2}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '32px',
                  }}
                >
                  <Button variant='action-outlined' onClick={() => navigate('/departamentos')}>
                    Cancelar
                  </Button>
                  <div style={{ marginRight: '20px' }} />
                  <Button variant='action' form='newDepartment'>
                    Salvar
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </Content>
  )
}

export default DepartmentEdit
