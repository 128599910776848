const ACTION = '@recharges/'

export const ACTIONS = {
  GET_RECHARGES_REQUEST: ACTION + 'GET_RECHARGES_REQUEST',
  RECHARGES_REQUEST_SUCCESS: ACTION + 'RECHARGES_REQUEST_SUCCESS',
  RECHARGES_REQUEST_FAILURE: ACTION + 'RECHARGES_REQUEST_FAILURE',
}

export function getRechargesRequest(query) {
  return {
    type: ACTIONS.GET_RECHARGES_REQUEST,
    payload: { query },
  }
}

export function rechargesRequestSuccess(data) {
  return {
    type: ACTIONS.RECHARGES_REQUEST_SUCCESS,
    payload: { data },
  }
}

export function rechargesRequestFailure(data) {
  return {
    type: ACTIONS.RECHARGES_REQUEST_FAILURE,
    payload: { data },
  }
}
