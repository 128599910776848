const ACTION = '@departments/'

export const ACTIONS = {
  GET_DEPARTMENTS_REQUEST: ACTION + 'GET_DEPARTMENTS_REQUEST',
  DEPARTMENTS_REQUEST_SUCCESS: ACTION + 'DEPARTMENTS_REQUEST_SUCCESS',
  DEPARTMENTS_REQUEST_FAILURE: ACTION + 'DEPARTMENTS_REQUEST_FAILURE',
}

export function getDepartmentsRequest(query) {
  return {
    type: ACTIONS.GET_DEPARTMENTS_REQUEST,
    payload: { query },
  }
}

export function departmentsRequestSuccess(data) {
  return {
    type: ACTIONS.DEPARTMENTS_REQUEST_SUCCESS,
    payload: { data },
  }
}

export function departmentsRequestFailure(data) {
  return {
    type: ACTIONS.DEPARTMENTS_REQUEST_FAILURE,
    payload: { data },
  }
}
