import { useQuery } from '@tanstack/react-query'
import { useAtomValue } from 'jotai'
import { orderIdAtom } from 'main/store'
import { IFetchHook, IOrder } from 'main/interfaces'
import api from '@/infra/api'

const defaultValue: IOrder['orderItems'] = {
  data: [],
  page: 1,
  pageSize: 200,
  totalItems: 0,
  totalPages: 1,
}

export const useOrderItems = ({ filter, pagination, immutableQuery }: IFetchHook) => {
  const orderId = useAtomValue(orderIdAtom)
  const { data, ...rest } = useQuery({
    queryKey: ['orderDetailItems', filter, pagination, immutableQuery, orderId],
    queryFn: () =>
      orderId !== '' &&
      api.get(`rh-bff/recharge/${orderId}/items`, {
        params: { ...pagination, ...filter, ...immutableQuery, id: orderId },
      }),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })

  const organizeData = (): IOrder['orderItems'] => {
    const rawData = data?.data
    if (!rawData) return defaultValue

    const organizedItems = rawData.data.map((item) => ({
      benefitType: item.benefitType,
      cardNumber: item.cardNumber,
      copy: item.copy || 1,
      days: item.days,
      dayValue: item.dayValue,
      employeeName: item.employeeName,
      employeeRegistration: item.employeeRegistration,
      operatorCardName: item.operatorCardName,
      operatorName: item.operatorName,
      operatorRegistration: item.operatorRegistration,
      total: item.totalValue,
      status: item?.status,
      isTotalValueDiscounted: item?.isTotalValueDiscounted,
    }))

    return {
      data: organizedItems,
      page: rawData.pageNumber,
      pageSize: rawData.pageSize,
      totalItems: rawData.totalItens,
      totalPages: rawData.totalPages,
    }
  }

  return {
    items: organizeData(),
    ...rest,
  }
}
