import { useNavigate } from 'react-router-dom'
import Moment from 'react-moment'
import { useAtomValue } from 'jotai'
import { Button, PageTitle, Typography } from '@stationkim/front-ui'
import { Content, Panel, Spinner, TextDecoration } from 'presentation/components'
import { Container, MessagesWrapper, Resume, ResumeRow, ResumeTitle } from './styles'
import { formatMoney } from '@/utils/functionUtils'
import { AccountPersistentStore } from 'main/store'
import { LoadingButton } from '@mui/lab'

export const RechargePayment = ({ isSlipNeeded, orderInfo, slipLink, ...props }) => {
  const account = useAtomValue(AccountPersistentStore)
  const navigate = useNavigate()

  const defineMessages = (payment) => {
    const messages = []
    if (orderInfo.totalToPay > 0)
      messages.push(
        <p key='payForTheOrder_message'>
          <TextDecoration variant={''}>Parabéns!</TextDecoration> O seu pedido foi realizado com sucesso. Efetue o
          pagamento para que a recarga seja disponibilizada.
        </p>,
      )
    else if (orderInfo.totalToPay === 0)
      messages.push(
        <p key='payForTheOrder_message'>
          <TextDecoration variant={''}>Parabéns!</TextDecoration> O seu pedido foi realizado com sucesso. Informações do
          seu pedido ficarão disponibilizadas no Menu “Recargas"
        </p>,
      )
    if (orderInfo.balanceUsage > 0 || orderInfo.ticketingBalanceValue > 0) {
      const isBalanceUsed = orderInfo.balanceUsage > 0
      const isTicketingBalanceUsed = orderInfo.ticketingBalanceValue > 0
      const bothValuesConnection = isBalanceUsed && isTicketingBalanceUsed ? ' e ' : ''
      const accountText = isBalanceUsed
        ? `R$${formatMoney(orderInfo.balanceUsage)} do seu saldo ${account?.resale?.digitalAccountName}`
        : ''
      const ticketingBalanceText = isTicketingBalanceUsed
        ? `R$ ${formatMoney(orderInfo.ticketingBalanceValue)} do seu saldo da Conta Corrente`
        : ''
      messages.push(
        <p>{`Para este pedido você utilizou ${accountText}${bothValuesConnection}${ticketingBalanceText}.`}</p>,
      )
    }

    if (orderInfo.useBalanceProjection)
      messages.push(
        <p>Você solicitou Projeção de Saldo, em até 24h você receberá um e-mail com o resultado! Fique de olho!</p>,
      )
    return messages
  }

  return (
    <Content>
      <Panel noPadding={true}>
        <PageTitle pageTitle='Pagamento' />
        <Container style={{ padding: '32px' }}>
          <Typography variant='h2'>Pedido gerado!</Typography>

          <MessagesWrapper>
            {defineMessages({ ...orderInfo.payment, useBalanceProjection: orderInfo.useBalanceProjection })}
          </MessagesWrapper>
          <Resume>
            <ResumeTitle>Pedido n° {orderInfo.code}</ResumeTitle>
            <ResumeRow>
              <p>Data da solicitação</p>
              {orderInfo.orderDate ? (
                <Moment format='DD/MM/YYYY'>{orderInfo.orderDate}</Moment>
              ) : (
                new Date().toLocaleDateString()
              )}
            </ResumeRow>
            <ResumeRow>
              <p>Total de colaboradores no pedido</p>
              <p>{orderInfo.numberOfColaborators}</p>
            </ResumeRow>

            <ResumeRow>
              <p>Total a pagar</p>
              <p>R$ {formatMoney(orderInfo.totalToPay)}</p>
            </ResumeRow>
          </Resume>
          {isSlipNeeded ? (
            <a href={slipLink} target='_blank' rel='noreferrer noopener'>
              <LoadingButton variant='contained' style={{ width: '370px' }} loading={!slipLink}>
                {!slipLink ? ' Gerando Boleto' : 'Baixar boleto'}
              </LoadingButton>
            </a>
          ) : (
            <Button variant='contained' style={{ width: '370px' }} onClick={() => navigate('/recargas')}>
              Voltar
            </Button>
          )}
        </Container>
      </Panel>
    </Content>
  )
}
