import { TableCell, styled } from '@mui/material'

export const StyledTableCell = styled(TableCell)(() => ({
  border: 0,
  whiteSpace: 'nowrap',
  lineHeight: '40px',
  fontSize: '16px',
  padding: '4px 8px',
  '&:first-of-type': {
    paddingLeft: 8,
  },
  '&:last-of-type': {
    paddingRight: 8,
  },
}))
