import { CheckboxProps as MUICheckboxProps } from '@mui/material'
import { faSquareCheck } from '@fortawesome/free-solid-svg-icons'
import { StyledCheckbox } from './styles'
import { IFieldStatus } from 'main/interfaces'
import { Icon } from '../Icon'

interface CheckboxProps extends MUICheckboxProps, IFieldStatus {}

export function Checkbox({ blocked, disabled, ...props }: CheckboxProps) {
  return (
    <StyledCheckbox
      disableRipple={true}
      disabled={disabled || blocked}
      checkedIcon={<Icon icon={faSquareCheck} />}
      {...props}
    />
  )
}
