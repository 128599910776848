import { styled } from '@mui/material'
import { Radio as RadioComponent } from '@mui/material'

export const Container = styled('div')<{ checked: boolean }>(({ theme, checked }) => {
  return {
    position: 'relative',
    width: '100%',
    height: 80,
    border: `1px solid ${checked ? theme.palette.primary.main : theme.palette.common.light}`,
    backgroundColor: `${checked ? 'rgba(66, 165, 245, 0.10)' : 'transparent'}`,
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 16,
    cursor: 'pointer',
    transition: '0.5s all',
    div: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      fontFamily: 'Montserrat',
      p: {
        width: '100%',
        fontSize: 16,
        fontWeight: 600,
        textAlign: 'center',
      },
      '> small': {
        width: '100%',
        color: 'rgba(0, 0, 0, 0.54)',
        textAlign: 'center',
        fontSize: '12px',
        fontWeight: 400,
      },
    },
  }
})

export const Radio = styled(RadioComponent)`
  position: absolute;
  left: 0;
  top: 0;
`
