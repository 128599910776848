import { useState } from 'react'
import {
  Panel,
  Spacing,
  Spinner,
  Table,
  Content,
  Steps,
  PageChangePrompt,
  TextDecoration,
  PageHeader,
} from 'presentation/components'
import { patchRechargesExactValue, patchRechargesValueByDays } from '@/services/rechargesServices'
import { ORDER_METHOD } from '@/utils/constants'
import { Col, Container, Row } from 'react-grid-system'
import GlobalRechargeModal from '../../globalRechargeModal'

import { TableHead } from '../components/TableHead'
import { Button, Tooltip } from 'presentation/components/atoms'

import { ButtonWrapper, DescriptionText, StepsWrapper, AddToCart } from './styles'
import { TableBody } from '../components/TableBody'
import { useUpdateValues } from './hooks/useUpdateValues'
import { moneyValueToServerFormat } from './utils/moneyValueToServerFormat'
import { FeatureToggle } from 'utils/featureToggle/featureToggle'
import { useAddCartItem } from 'main/hooks/useAddCartItem'
import { useLocation, useNavigate } from 'react-router-dom'

const ManualRechargeValueDefinition = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const {
    updateOrderValues,
    orderInfo,
    isLoading,
    orderId,
    employeesData,
    isGlobalOrderModalVisible,
    setIsGlobalOrderModalVisible,
    updateTotalRecharge,
    updateDays,
  } = useUpdateValues({ location })
  const { addToCart } = useAddCartItem()

  const operatorCardId = location.state?.operatorCardId

  const [previousGlobalValues, setPreviousGlobalValues] = useState({
    days: '',
    value: '',
  })

  const steps = [
    {
      name: 'Cartões',
      navigable: false,
    },
    {
      name: 'Colaboradores',
      navigable: true,
      handleNavigation: () =>
        navigate('/recargas/novo_pedido_manual', {
          state: { orderId, bypass: true },
        }),
    },
    {
      name: 'Informações',
      navigable: false,
    },
    {
      name: 'Pagamento',
      navigable: false,
    },
  ]

  return (
    <>
      <PageChangePrompt onlyConfirm>
        <p>Você não finalizou seu pedido.</p>
        <p>
          Seu pedido será salvo como <TextDecoration>Rascunho</TextDecoration> por uma semana e você poderá continuar de
          onde parou para finalizá-lo.
        </p>
      </PageChangePrompt>
      <Content>
        <Panel noPadding>
          <PageHeader id='recharge_manual_title'>Pedido de Recarga</PageHeader>
          <Container fluid style={{ padding: '32px' }}>
            {isLoading || addToCart.isLoading ? (
              <Spinner.Box>
                <Spinner />
              </Spinner.Box>
            ) : (
              <>
                <Row>
                  <Col>
                    <StepsWrapper>
                      <Steps currentStep={3} steps={steps} />
                    </StepsWrapper>
                  </Col>
                </Row>
                <Row align='center' justify='end' nogutter>
                  <Col>
                    <DescriptionText>
                      <p>Colaboradores e setores selecionados para o pedido</p>
                      <p>Revise as informações antes de continuar com seu pedido</p>
                    </DescriptionText>
                  </Col>
                  <Col sm={2}>
                    <Button
                      variant='outlined'
                      onClick={() => setIsGlobalOrderModalVisible(true)}
                      id='recharge_manual_global_order'
                    >
                      Pedido Global
                    </Button>
                  </Col>
                </Row>
                <Spacing top='32px' />
                <Row>
                  <Col>
                    <Table>
                      <TableHead />
                      <TableBody
                        employeesData={employeesData}
                        updateTotalRecharge={updateTotalRecharge}
                        updateDays={updateDays}
                      />
                    </Table>
                  </Col>
                </Row>
                <Spacing top='32px' />
                <Row justify='end'>
                  <Col sm='content'>
                    <ButtonWrapper>
                      <Button
                        onClick={
                          orderInfo?.id
                            ? () =>
                                navigate(`/recargas/novo_pedido_manual/#orderId="${orderInfo.id}"`, {
                                  state: {
                                    operatorCardId,
                                    orderId: orderInfo.id,
                                    bypass: true,
                                  },
                                })
                            : null
                        }
                        id='recharge_manual_back_button'
                      >
                        Voltar
                      </Button>
                      <Button
                        variant='outlined'
                        onClick={
                          orderInfo?.id
                            ? () =>
                                navigate('/recargas/novo_pedido_manual/confirmacao', {
                                  state: {
                                    orderId: orderInfo?.id,
                                    method: ORDER_METHOD.MANUAL,
                                    operatorCardName: orderInfo?.items?.[0]?.operatorName,
                                    quantity: orderInfo?.items?.length,
                                    title: 'Recarga',
                                    bypass: true,
                                  },
                                })
                            : null
                        }
                        id='recharge_manual_buy_now'
                      >
                        Comprar agora
                      </Button>
                      <FeatureToggle feature='cart'>
                        <AddToCart
                          variant='contained'
                          disabled={orderInfo?.hasTicketBalance}
                          onClick={
                            orderId
                              ? () => {
                                  addToCart.mutate({ orderId })
                                }
                              : null
                          }
                          id='recharge_manual_add_to_cart'
                        >
                          {orderInfo?.hasTicketBalance ? (
                            <Tooltip
                              title="Não é possível adicionar itens ao carrinho porque há saldo disponível na conta corrente da empresa. Para realizar o pedido, utilize a opção 'Comprar Agora'"
                              placement='top'
                            >
                              Adicionar ao carrinho
                            </Tooltip>
                          ) : (
                            'Adicionar ao carrinho'
                          )}
                        </AddToCart>
                      </FeatureToggle>
                    </ButtonWrapper>
                  </Col>
                </Row>
              </>
            )}
          </Container>
        </Panel>
      </Content>
      {isGlobalOrderModalVisible && (
        <GlobalRechargeModal
          modalId='modal-global'
          open={isGlobalOrderModalVisible}
          onClose={() => setIsGlobalOrderModalVisible(false)}
          initialValues={previousGlobalValues}
          onConfirm={(inputs) => {
            if (inputs.days) {
              updateOrderValues(() => patchRechargesValueByDays(orderId, inputs.days))
              setPreviousGlobalValues({ days: inputs.days, value: '' })
            } else {
              const value = moneyValueToServerFormat(inputs.value)
              updateOrderValues(() => patchRechargesExactValue(orderId, value))
              setPreviousGlobalValues({ days: '', value: inputs.value })
            }
          }}
        />
      )}
    </>
  )
}

export default ManualRechargeValueDefinition
