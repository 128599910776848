import { ReactNode, useState } from 'react'
import { atomWithHash } from 'jotai-location'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { Content, Panel } from 'presentation/components'
import { FilterOption, HeaderFilter } from './style'

import { ORDER_STATUS } from 'utils/constants'
import { useRecharges } from './hooks/useRecharges'
import { RechargesTable } from './components/rechargesTable/rechargesTable'
import {
  DropdownButton,
  DropdownButtonItem,
  OrderCancelationModal,
  PageHeader,
  Search,
} from 'presentation/components/molecules'
import { Button, Grid, Icon } from 'presentation/components/atoms'
import { useCancelOrder, useCartOrdersByOrderId } from 'main/hooks'
import { ReactComponent as CirclePlusIcon } from 'presentation/assets/icons/circle-plus.svg'
import { NULL_ID } from 'utils/constants'
import { useFilter, usePagination } from 'main/hooks'
import Filter from './components/filter'
import { IOrderBasicInformation } from 'main/interfaces/iOrderBasicInformation'
import { useNavigate } from 'react-router-dom'
import { TableFooterPagination } from '@stationkim/front-ui'
import { useOperatorCards } from '@/main/hooks/useOperatorCards'

const orderStatusBasedOnTab = {
  1: ORDER_STATUS.WAITING_PAYMENT,
  2: ORDER_STATUS.RUNNING,
  3: ORDER_STATUS.FINISHED,
}

const filterAtom = atomWithHash<object | null>('oldRechargesFilter', null)
const paginationAtom = atomWithHash<object>('oldRechargesPagination', {})

export const OldDealerList = ({ listSelector }: { listSelector: ReactNode }) => {
  const navigate = useNavigate()
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false)
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState<boolean>(false)
  const [orderToCancel, setOrderToCancel] = useState<IOrderBasicInformation | null>(null)
  const isOrderToCancelACart: boolean = orderToCancel?.cartId !== NULL_ID && Boolean(orderToCancel?.cartId)

  const { cards, isLoading: cardsLoading } = useOperatorCards()

  const { cancelOrder, cancelCartOrders } = useCancelOrder({
    onSuccess: () => {
      setIsCancelDialogOpen(false)
      setOrderToCancel(null)
    },
  })

  const { cartOrders: cartOrdersToCancel, isFetching: isCartOrdersLoading } = useCartOrdersByOrderId({
    orderId: isOrderToCancelACart && orderToCancel ? orderToCancel.id : null,
  })

  const { pagination, goToPage, setPageSize } = usePagination({ paginationAtom })
  const { filter, resetFilter, updateFilter, overrideFilter } = useFilter({
    filterAtom,
    initialValue: { Status: ORDER_STATUS.WAITING_PAYMENT, Sort: 'OrderDate', Desc: true },
  })

  //Auto retrieve other orders from cart when user try to cancel a cart order
  const { rechargeOrders, isLoading: isRechargeOrdersLoading } = useRecharges({ filter, pagination })

  function handleTabNavigation(e: any) {
    const selectedTab = e.target.getAttribute('data-status')
    if (!selectedTab) return

    if (selectedTab === '4') overrideFilter({ Sort: 'OrderDate', Desc: true })
    else
      overrideFilter({
        sort: 'OrderDate',
        Desc: true,
        Status: orderStatusBasedOnTab[selectedTab],
      })
  }

  const isLoading = isRechargeOrdersLoading
  const isLoadingCancel =
    cancelOrder.isLoading || (isCartOrdersLoading && isOrderToCancelACart) || cancelCartOrders.isLoading

  const handleCancelClick = ({ order }: { order: IOrderBasicInformation }) => {
    setOrderToCancel(order)
    setIsCancelDialogOpen(true)
  }

  const onCancelConfirm = ({ idsToCancel }: { idsToCancel: Array<string> }) => {
    if (!isOrderToCancelACart && orderToCancel?.id) {
      cancelOrder.mutate({ orderId: orderToCancel?.id })
    } else {
      cancelCartOrders.mutate(idsToCancel)
    }
  }

  /*   //-----------------------
    const [showDropDownOtimo, setShowDropDownOtimo] = useState(false)
    const [isShowDropDownOtimoLoading, setIsShowDropDownOtimoLoading] = useState(true)
    useEffect(() => {
      setIsShowDropDownOtimoLoading(true)
      api
        .get(`/rh-bff/departments/isCompanyBrokerOtimo/`)
        .then((e) => {
          setShowDropDownOtimo(e.data)
        })
        .finally(() => setIsShowDropDownOtimoLoading(false))
    }, [])
    //----------------------- */

  return (
    <>
      {isCancelDialogOpen && (
        <OrderCancelationModal
          open={isCancelDialogOpen}
          orders={isOrderToCancelACart ? cartOrdersToCancel : [orderToCancel]}
          onCancel={() => setIsCancelDialogOpen(false)}
          onConfirm={onCancelConfirm}
          isCart={isOrderToCancelACart}
          isLoading={isLoadingCancel}
          defaultCheckedId={isOrderToCancelACart ? orderToCancel?.id : undefined}
        />
      )}
      <Content>
        <Panel noPadding>
          <Grid container>
            <Grid item xs={12} md>
              <PageHeader pageTitle='Recargas' />
            </Grid>
            <Grid item xs={12}>
              <HeaderFilter activeFilter={{ 3: 1, 6: 2, 15: 3 }[filter?.Status] || 4} onClick={handleTabNavigation}>
                <FilterOption data-status='1' id='order_tab_waitingPayment'>
                  Aguardando pagamento
                </FilterOption>
                <FilterOption data-status='2' id='order_tab_running'>
                  Em processamento
                </FilterOption>
                <FilterOption data-status='3' id='order_tab_finished'>
                  Concluído
                </FilterOption>
                <FilterOption data-status='4' id='order_tab_all'>
                  Todos
                </FilterOption>
              </HeaderFilter>
            </Grid>
          </Grid>
          <Grid container spacing='16px' padding='16px' alignItems='center'>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <Search
                id='order_search'
                onSearch={(value) => {
                  goToPage(1)
                  updateFilter({
                    filterValue: value,
                    filterProps: 'code',
                  })
                }}
                placeholder='Buscar na tabela abaixo ...'
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <Button
                id='order_button_filter'
                startIcon={<Icon icon={faFilter} />}
                variant='outlined'
                onClick={() => setIsFilterOpen(true)}
                fullWidth
              >
                Filtrar
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md='auto' sx={{ marginLeft: 'auto' }}>
              <DropdownButton
                sx={{ '& svg': { height: '24px' } }}
                startIcon={<CirclePlusIcon />}
                label='Novo pedido'
                fullWidth
                size='medium'
                id='order_button_newOrder'
                // isLoading={isShowDropDownOtimoLoading}
              >
                <DropdownButtonItem
                  onClick={
                    !cardsLoading
                      ? () =>
                          cards.length <= 1
                            ? navigate('/recargas/novo_pedido_manual', {
                                state: {
                                  operatorCardId: cards?.[0]?.operatorCardId,
                                  operatorCardName: cards?.[0]?.operatorCardName,
                                  operatorName: cards?.[0]?.operatorName,
                                },
                              })
                            : navigate('/recargas/novo_pedido_manual_cartoes')
                      : null
                  }
                  id='order_button_newOrder_option_1'
                  disabled={cardsLoading}
                >
                  Pedido manual
                </DropdownButtonItem>
                <DropdownButtonItem
                  onClick={() => navigate('/recargas/pedido_recarga_broker', { state: { isDealer: true } })}
                  id='order_button_newOrder_option_2'
                >
                  Pedido Broker
                </DropdownButtonItem>
                <DropdownButtonItem
                  onClick={() => navigate('/recargas/novo_pedido_importacao')}
                  id='order_button_newOrder_option_3'
                >
                  Pedido por importação
                </DropdownButtonItem>
              </DropdownButton>
            </Grid>
            {listSelector && (
              <Grid
                item
                xs='auto'
                sm='auto'
                md='auto'
                sx={{
                  margin: { xs: 'auto', sm: 'initial' },
                  marginLeft: { sm: 'auto', md: 'initial' },
                }}
              >
                {listSelector}
              </Grid>
            )}
            <Grid item xs={12}>
              <RechargesTable
                isLoading={isLoading}
                rows={rechargeOrders?.data}
                changeOrder={({ fieldName, isDesc }) => {
                  updateFilter({
                    sort: fieldName,
                    desc: isDesc,
                  })
                }}
                cancelOrder={handleCancelClick}
              />
            </Grid>
          </Grid>
          <TableFooterPagination
            id='orders_paginator'
            totalItems={rechargeOrders.totalItens}
            onRowsPerPageChange={(e: any) => setPageSize(e.target.value)}
            rowsPerPage={rechargeOrders.pageSize}
            totalPages={rechargeOrders.totalPages}
            page={rechargeOrders.page}
            onPageChange={(e, value) => goToPage(Number(value))}
          />
        </Panel>
      </Content>
      {isFilterOpen && (
        <Filter
          open={isFilterOpen}
          actualFilters={filter}
          onClose={() => setIsFilterOpen(false)}
          resetFilters={() => resetFilter()}
          filter={(queryObj: any) => {
            updateFilter(queryObj)
            setIsFilterOpen(false)
          }}
        />
      )}
    </>
  )
}
