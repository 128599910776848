import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  > p {
    margin-top: 12px;
  }
`

export const Resume = styled.div`
  max-width: 540px;
  width: 100%;
  background-color: var(--color-secundary-05);
  border-radius: 4px;
  margin: 32px 0;
  padding: 24px;
`

export const ResumeTitle = styled.p`
  font-size: 1.2rem;
  font-weight: var(--font-weight-semi-bold);
  margin-bottom: 60px;
`

export const ResumeRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0;

  & + & {
    border-top: 1px solid var(--color-secundary-04);
  }

  p {
    font-weight: var(--font-weight-medium);
  }

  :nth-child(4) {
    p:last-child {
      color: var(--color-status-02);
    }
  }

  :last-child {
    border: none;
    margin-top: 60px;

    p:last-child {
      color: var(--color-primary-01);
      font-weight: var(--font-weight-semi-bold);
    }
  }
`
