import { Icon } from 'presentation/components/atoms/Icon'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'
import { StyledInput } from './styles'
import { ComponentSizes } from 'main/types'
import { SxProps } from '@mui/material'

export interface SearchProps {
  value?: string
  searchOnChange?: boolean
  onSearch?: (value: string) => void
  onChange?: (value: string) => void
  size?: ComponentSizes
  sx?: SxProps
  placeholder?: string
  id?: string
}

export const Search = ({
  value,
  onSearch = () => null,
  searchOnChange = false,
  onChange,
  size = 'large',
  id,
  ...props
}: SearchProps) => {
  const [internalValue, setInternalValue] = useState<string>('')

  const searchText = value ? value : internalValue
  const setSearchText = value ? onChange : setInternalValue

  const handleSearchIconClick = () => {
    onSearch(searchText)
  }

  const handleEnter = (e: any) => {
    if (e.key === 'Enter') {
      onSearch(searchText)
    }
  }

  return (
    <StyledInput
      id={id}
      size={size as any}
      value={value}
      onChange={(e) => {
        if (setSearchText) setSearchText(e.target.value)
        if (!value && onChange) onChange(e.target.value)
        if (searchOnChange) onSearch(e.target.value)
      }}
      startAdornment={<Icon id='cards_search_icon' icon={faMagnifyingGlass} onClick={handleSearchIconClick} />}
      onKeyDown={handleEnter}
      {...props}
    />
  )
}
