import { AxiosResponse } from 'axios'
import { useMutation, useQuery } from '@tanstack/react-query'
import Decimal from 'decimal.js'
import api from '@/infra/api'
import { ETipoPagamento } from '@/main/enums'
import { useLocation, useNavigate } from 'react-router-dom'
import { useNotifications } from '@/main/hooks'
import { EMessageType } from '@/main/store'
import { useOrderCancelation } from '@/main/hooks/useOrderCancelation'

type TDetail = {
  administrateFee: number
  totalTransferFeeValue: number
  ticketingBalance: number
  oldTicketingBalance: number
  issFee: number
  totalValue: number
  totalItens: number
  totalRechargeValue: number
  id: number
}

type TData = {
  administrateFee: number
  transferFeeValue: number
  ticketingBalanceValue: number
  issFeeValue: number
  accountBalance: number
  totalBeforeBalanceUsage: number
  orderValue: number
  daysForCredit: {
    unavailableDays?: string[]
    initialDate?: string
  }
}
interface IUseOrderReturn {
  data: TData
  details: Array<TDetail>
  confirmOrder: any
  cancelOrder: () => void
  isLoading: boolean
}

interface GetInfoAPIResponse {
  valor: {
    valorTotal: number
    saldoCarteira: number
    taxasAdministracao: number
    taxasRepasse: number
    taxasIss: number
    valorRecarga: number
    dataParaCredito: string
    datasFeriados: string[]
    pedidos: [
      {
        id: number
        colaboradores: number
        tipoPedido: number
        valorTotal: number
        valorPedido: number
        taxaAdministrativa: number
        nomeOperadora: string
        taxaIss: number
        taxaIssCashback: number
        taxaSucesso: number
        taxaRepasseOperadora: number
        taxaRepasseOperadoraCashback: number
        taxaAproveitamentoDeSaldo: number
      },
    ]
  }
  sucesso: boolean
  mensagens: string[]
}

const _generatePayload = ({ data, balanceUsage, useBalanceProjection, method }) => {
  const payload = {
    usarProjecaoDeSaldo: useBalanceProjection,
    pagamentos: [],
  }

  if (data.totalBeforeBalanceUsage === 0) return payload

  let valueToBePaid = new Decimal(data.totalBeforeBalanceUsage || 0)
  valueToBePaid = valueToBePaid.minus(balanceUsage || 0)
  payload.pagamentos = [
    {
      tipo: ETipoPagamento.CarteiraSK,
      valor: balanceUsage,
    },
    {
      tipo: method === 'PIX' ? ETipoPagamento.Pix : ETipoPagamento.Boleto,
      valor: valueToBePaid.toNumber(),
    },
  ]
  payload.pagamentos = payload.pagamentos.filter((payment) => payment.valor > 0)
  return payload
}

export const useOrder = ({ purchaseId }: { purchaseId: string }): IUseOrderReturn => {
  const location = useLocation()
  const navigate = useNavigate()
  const notifications = useNotifications()

  const { data: rawData, isLoading: isInfosLoading } = useQuery<AxiosResponse<GetInfoAPIResponse>>({
    queryKey: ['cartOrdersByOrderId', purchaseId],
    queryFn: () => api.get(`rh-pedidos-bff/carrinho/confirmacao?idCompra=${purchaseId}`),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    cacheTime: 0,
    enabled: Boolean(purchaseId),
  })

  const organizeData = (): IUseOrderReturn['data'] => {
    const dataToOrganize = rawData?.data?.valor
    if (!dataToOrganize) return null
    return {
      administrateFee: dataToOrganize.taxasAdministracao,
      transferFeeValue: dataToOrganize.taxasRepasse,
      ticketingBalanceValue: 0, // precisa confirmar
      issFeeValue: dataToOrganize.taxasIss,
      orderValue: dataToOrganize.valorRecarga,
      accountBalance: dataToOrganize.saldoCarteira,
      totalBeforeBalanceUsage: dataToOrganize.valorTotal,
      daysForCredit: {
        initialDate: dataToOrganize.dataParaCredito,
        unavailableDays: dataToOrganize.datasFeriados,
      },
    }
  }

  const organizeDetails = (): IUseOrderReturn['details'] => {
    const dataToOrganize = rawData?.data?.valor
    if (!dataToOrganize) return null
    return dataToOrganize.pedidos.map((order) => ({
      totalItens: order.colaboradores,
      totalValue: order.valorTotal,
      totalRechargeValue: order.valorPedido,
      operatorCardName: order.nomeOperadora,
      administrateFee: order.taxaAdministrativa,
      totalTransferFeeValue: order.taxaRepasseOperadora,
      ticketingBalance: 0,
      oldTicketingBalance: 0,
      issFee: order.taxaIss,
      id: order.id,
      code: order.id,
    }))
  }

  const details = organizeDetails()

  const confirmOrder = useMutation({
    mutationKey: ['finishBuying'],
    mutationFn: ({ data, balanceUsage, useBalanceProjection, method, purchaseId, days }: any) => {
      const payload = _generatePayload({ data, balanceUsage, useBalanceProjection, method })
      return api.patch<{
        mensagens: string[]
        sucesso: boolean
        valor: {
          numeroPedido: number
          idCompra: string
          pedidos: Array<{
            idPedido: number
            codigoPedido: number
          }>
        }
      }>('rh-pedidos-bff/carrinho/confirmar-compra', { ...payload, idCompra: purchaseId, dataCredito: days })
    },
    onSuccess: (res, { data, balanceUsage, useBalanceProjection, method, orderId }) => {
      let valueToBePaid = new Decimal(data.totalBeforeBalanceUsage || 0)
      valueToBePaid = valueToBePaid.minus(balanceUsage || 0)
      const pathname = method === 'PIX' ? '/recargas/pix' : '/recargas/pedido_recarga_broker/confirmacao_pedido'
      const ordersCodes = res?.data?.valor?.pedidos
      const detailsWithOrderNumber = details.map((details) => {
        return { ...details, code: ordersCodes.find((order) => order.idPedido === details.id).codigoPedido }
      })
      navigate(pathname, {
        state: {
          ...data,
          data,
          details: detailsWithOrderNumber,
          id: orderId,
          totalToPay: valueToBePaid.toNumber(), //, calculateRestToPay({ balanceUsage:args }),
          balanceUsage: balanceUsage,
          useBalanceProjection: useBalanceProjection,
          bypass: true,
          numberOfColaborators: details.reduce((total, actual) => {
            return total + actual.totalItens
          }, 0),
          code: res?.data?.valor?.numeroPedido || '???',
          orderType: 'recharge',
          title: 'Recarga',
          isDealer: false,
          purchaseId: res?.data.valor?.idCompra,
        },
      })
    },
  })

  const { cancelPurchase } = useOrderCancelation()
  const cancelOrder = () => {
    if (location.state)
      cancelPurchase.mutate(
        { purchaseId: location.state?.purchaseId },
        {
          onSuccess: () => {
            navigate('/recargas')
            notifications.push({ type: EMessageType.Success, content: 'Pedido cancelado com sucesso!' })
          },
        },
      )
  }

  return {
    data: organizeData(),
    details,
    confirmOrder: confirmOrder.mutate,
    cancelOrder: cancelOrder,
    isLoading: isInfosLoading || cancelPurchase.isLoading || confirmOrder.isLoading,
  }
}
