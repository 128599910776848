import { useContext, useState, useEffect } from 'react'
import { Tooltip } from 'presentation/components/atoms'
import { ORDER_PAYMENT_TYPE } from 'utils/constants'
import { Box, Button, Divider, Paper, Typography, Grid } from 'presentation/components/atoms'
import { NoItemPaper, TextRates } from './styles'
import { formatMoney } from 'utils/functionUtils'
import { useNotifications } from '@/main/hooks'
import { EMessageType } from '@/main/store'
import { MaskedTextField, ValuesSummary } from 'presentation/components/molecules'
import { CartContext } from 'presentation/pages/cart/cart'
import { MethodPayment } from '../methodPayment/methodPayment'
import { Rate } from '../rate/rate'
import { useFeatures } from 'main/hooks/useFeatures'
import { useAtomValue } from 'jotai'
import { AccountPersistentStore } from 'main/store'
import { BalanceProjection } from './components/balanceProjection/balanceProjection'

export const Payment = () => {
  const { features } = useFeatures()
  const { push } = useNotifications()

  const [walletUsageError, setWalletUsageError] = useState('')
  const [projectionConfig, setProjectionConfig] = useState([])
  const {
    cart,
    confirmCart,
    isCartEmpty,
    totalBeforeBalanceUsage,
    balanceToBeUsed,
    setBalanceToBeUsed,
    method,
    setMethod,
    setRate,
    rate,
  } = useContext(CartContext)
  const { accountBalance } = cart
  const account = useAtomValue(AccountPersistentStore)
  const total = (totalBeforeBalanceUsage - balanceToBeUsed + rate?.rateValue).toFixed(2)
  const hasRecharges = cart?.rechargeOrders?.length > 0

  const notAllowProjection = cart?.ticketingBalanceValue > 0

  useEffect(() => {
    if (cart?.interestRates?.length === 1 && method === 'TERM_BILL') {
      setRate({
        interestRate: cart?.interestRates?.[0]?.rate,
        interestRateDays: cart?.interestRates?.[0]?.days,
        nextDueDate: cart?.interestRates?.[0]?.nextDueDate,
        rateValue: cart?.interestRates?.[0]?.rateValue,
        totalValueWithInterestRate: cart?.interestRates?.[0]?.totalValueWithInterestRate,
      })
    }
  }, [cart, method])

  useEffect(() => {
    if (method !== 'TERM_BILL') {
      setRate({
        interestRate: 0,
        interestRateDays: 0,
        nextDueDate: null,
        rateValue: 0,
        totalValueWithInterestRate: 0,
      })
    }
  }, [method])

  const isOnlyDealer =
    !cart?.firstCopyOrders?.length &&
    !cart?.secondCopyOrders?.length &&
    cart?.rechargeOrders?.every((order) => order?.orderType === 3)

  if (isCartEmpty) {
    return (
      <NoItemPaper>
        <Typography variant='h2'>Pagamento</Typography>
        <Divider sx={{ margin: '32px 0' }} />
        <Typography variant='label2'>Aqui estarão as informações e valores do seu pedido.</Typography>
        <br />
        <Typography variant='label2'>Adicione ao menos um item aop carrinho para visualizar.</Typography>
      </NoItemPaper>
    )
  } else
    return (
      <Paper sx={(theme) => ({ padding: '16px', backgroundColor: theme.palette.grey[50] })}>
        <Typography variant='h2'>Pagamento</Typography>
        {features.methodPaymentCart && isOnlyDealer ? (
          <Box
            sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', margin: '32px 0 12px' }}
          >
            <Typography style={{ marginBottom: 24 }} variant='subtitle2'>
              Método de pagamento
            </Typography>
            <Box sx={{ display: 'flex', gap: '8px' }}>
              <MethodPayment checked={method === 'SLIP'} name='Boleto' onClick={() => setMethod('SLIP')} />

              {cart?.interestRates?.length ? (
                <Tooltip
                  title={
                    cart?.subTotalOrders <= cart?.financedSalesMinimalValue
                      ? `O pagamento via boleto a prazo só está disponível para pedidos acima de R$ ${cart?.financedSalesMinimalValue.toFixed(
                          2,
                        )}. Para pedidos abaixo desse valor, solicitamos que escolha outro método de pagamento.`
                      : null
                  }
                  placement='top'
                >
                  <MethodPayment
                    blocked={cart?.subTotalOrders <= cart?.financedSalesMinimalValue}
                    checked={method === 'TERM_BILL'}
                    name='Boleto a Prazo'
                    onClick={
                      cart?.subTotalOrders >= cart?.financedSalesMinimalValue
                        ? () =>
                            !cart?.financedSalesPendingPayments
                              ? setMethod('TERM_BILL')
                              : push({
                                  content: cart?.financedSalesPendingPaymentsMessage,
                                  type: EMessageType.Warning,
                                })
                        : null
                    }
                  />
                  <div />
                </Tooltip>
              ) : null}
            </Box>
            {method === 'TERM_BILL' ? (
              <Grid
                container
                spacing='8px'
                maxWidth='565px'
                sx={{ display: 'flex', marginTop: '16px', flexWrap: 'wrap' }}
              >
                {cart?.interestRates?.map((rates) => {
                  return (
                    <Grid item md={6} xs={6} sm={4} key={rates?.days}>
                      <Rate
                        date={rates?.nextDueDate}
                        day={rates?.days}
                        totalValue={(rates?.totalValueWithInterestRate).toFixed(2)}
                        checked={rate?.interestRateDays === rates?.days}
                        onClick={() =>
                          setRate({
                            interestRate: rates?.rate,
                            interestRateDays: rates?.days,
                            nextDueDate: rates?.nextDueDate,
                            rateValue: rates?.rateValue,
                            totalValueWithInterestRate: rates?.totalValueWithInterestRate,
                          })
                        }
                      />
                    </Grid>
                  )
                })}
                <TextRates>
                  Ao selecionar o método de pagamento à prazo, você terá até a data selecionada para efetuar o pagamento
                  do boleto. <b>Acréscimo de valores referentes à Taxa Administrativa.</b>
                </TextRates>
              </Grid>
            ) : null}
          </Box>
        ) : null}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '32px 0 12px' }}>
          <Typography variant='subtitle2'>Saldo {account?.resale?.digitalAccountName}</Typography>
          <p>
            <small>R$</small> {formatMoney(cart.accountBalance)}
          </p>
        </Box>
        <Typography variant='label2' sx={{ fontWeight: 400, marginBottom: '16px', display: 'block' }}>
          Para usar o saldo no pagamento deste pedido, digite o valor abaixo.
        </Typography>
        <MaskedTextField
          label={`Valor de uso ${account?.resale?.digitalAccountName}`}
          placeholder='R$ 00,00'
          value={String(balanceToBeUsed)}
          helperText={walletUsageError}
          onChange={(value) => {
            let newValue = value || 0
            if (value > totalBeforeBalanceUsage) {
              newValue = totalBeforeBalanceUsage
              setWalletUsageError(
                `Você não pode exceder o valor total a ser pago de R$ ${formatMoney(totalBeforeBalanceUsage)}`,
              )
            } else if (value > accountBalance) {
              newValue = accountBalance
              setWalletUsageError(`Você não pode exceder o seu saldo atual de R$ ${formatMoney(accountBalance)}`)
            }
            setBalanceToBeUsed(newValue)
          }}
          mask={[
            { mask: '' },
            {
              mask: 'R$ num',
              lazy: true,
              blocks: {
                num: {
                  mask: Number,
                  scale: 2,
                  thousandsSeparator: '.',
                  radix: ',',
                  mapToRadix: ['.'],
                },
              },
            },
          ]}
          disabled={accountBalance === 0 || totalBeforeBalanceUsage === 0}
        />
        <Typography variant='subtitle2' sx={{ marginTop: '32px' }}>
          Resumo da compra
        </Typography>
        <Divider sx={{ margin: '32px 0' }} />
        <ValuesSummary
          values={[
            {
              label: 'Pedido de recarga',
              value: cart.rechargeValue,
              size: 'md',
              hideZero: cart.rechargeOrders.length < 1,
            },
            {
              label: 'Pedido de cartão - 1ª via',
              value: cart.firstCopyValue,
              size: 'md',
              hideZero: cart.firstCopyOrders.length < 1,
            },
            {
              label: 'Pedido de cartão - 2ª via',
              value: cart.secondCopyValue,
              size: 'md',
              hideZero: cart.secondCopyOrders.length < 1,
            },
            {
              label: 'Taxas administrativas',
              value: (cart.administrateFee + rate?.rateValue).toFixed(2),
              size: 'md',
              hideZero: true,
            },
            { label: 'Taxa de repasse', value: cart.transferFeeValue, size: 'md', hideZero: true },
            features.ticketingBalance && cart?.ticketingBalanceValue > 0
              ? {
                  label: 'Conta corrente total',
                  value: cart.ticketingBalanceValue,
                  size: 'md',
                  hideZero: true,
                  isNegative: true,
                  color: 'green',
                }
              : null,
            {
              label: 'Retenção ISS',
              value: cart.issFeeValue,
              size: 'md',
              hideZero: true,
              isNegative: true,
              color: 'green',
            },
            {
              label: account?.resale?.digitalAccountName,
              value: balanceToBeUsed,
              size: 'md',
              isNegative: true,
              color: 'green',
            },
          ]}
        />
        <Divider sx={{ margin: '32px 0' }} />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px', fontWeight: 600 }}>
          <Typography sx={{ fontWeight: 600 }}>Total a ser pago: </Typography>
          <Typography variant='h4' sx={{ fontWeight: 600 }}>
            <span style={{ fontWeight: 400 }}>R$ </span> {formatMoney(total)}
          </Typography>
        </Box>
        {hasRecharges && (
          <BalanceProjection
            notAllowProjection={notAllowProjection}
            onChange={(config) => setProjectionConfig(config)}
          />
        )}

        <Button
          variant='contained'
          color='success'
          fullWidth
          sx={{ color: 'white', marginTop: '32px' }}
          disabled={(features.methodPayments ? !method : null) || (method === 'TERM_BILL' && !rate.interestRateDays)}
          onClick={() =>
            confirmCart.mutate({
              payload: {
                amountWallet: Number(balanceToBeUsed),
                useBalanceProjection: notAllowProjection
                  ? false
                  : projectionConfig.some((card) => card.balanceProjectionEnabled),
                paymentType: ORDER_PAYMENT_TYPE[method === 'TERM_BILL' ? 'SLIP' : method],
                ...rate,
                method,
                basketOperatorRequests: projectionConfig,
              },
            })
          }
        >
          <Tooltip
            title={
              method === 'TERM_BILL' && !rate?.interestRate
                ? 'Para realizar uma compra a prazo, é necessário informar os dias para pagamento'
                : null
            }
            placement='top'
          >
            <p style={{ width: '100%', height: '100%', paddingTop: '4px' }}>Concluir pedido</p>
          </Tooltip>
        </Button>
      </Paper>
    )
}
