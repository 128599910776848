import { Button } from '@stationkim/front-ui'
import { useEffect, useState } from 'react'

export const CooldownButton = ({
  isLoading,
  onClick,
  skipCount,
  disabled,
}: {
  onClick: () => void
  isLoading: boolean
  disabled: boolean
  skipCount: boolean
}) => {
  const [continueTimer, setContinueTimer] = useState(skipCount ? -1 : 10)
  const canContinue = continueTimer <= 0
  const isTimerVisible = continueTimer > -1

  useEffect(() => {
    if (isLoading) return
    const clock = setInterval(() => {
      setContinueTimer((state) => {
        if (state - 1 <= -1) clearInterval(clock)
        return state - 1
      })
    }, 1000)
    return () => clearInterval(clock)
  }, [isLoading])

  return (
    <Button variant='contained' fullWidth disabled={!canContinue || isLoading || disabled} onClick={onClick}>
      {isTimerVisible && continueTimer} Comprar agora
    </Button>
  )
}
