import api from '@/infra/api'

export const changeDepartmentStatus = async (data, setSuccess, setError) => {
  try {
    const response = await api.patch(
      `/companies-service/${data.company}/Departments/${data.id}/activate/${data.status}`,
    )
    setSuccess('Status alterado')
    return response?.data?.data
  } catch (e) {
    setError(e?.response?.message || 'Erro Inesperado')
  }
}
