import { useRef } from 'react'
import { useAtom } from 'jotai'
import { EMessageType, IMessage, NotificationsStore } from 'main/store'

export const useNotifications = () => {
  const [notifications, setNotifications] = useAtom(NotificationsStore)
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)

  const _pushATypeMessage = ({ content, type }: { content: string; type: EMessageType }) => {
    const newNotifications = [...notifications]
    newNotifications.push({ content, type })
    setNotifications(newNotifications)
  }

  const push = (message: IMessage | Array<IMessage>) => {
    let newNotifications = [...notifications]
    if (Array.isArray(message)) newNotifications = [...newNotifications, ...message]
    else newNotifications.push(message)
    setNotifications(newNotifications)
  }

  const pushError = (message: string) => {
    _pushATypeMessage({ content: message, type: EMessageType.Error })
  }

  const pushSuccess = (message: string) => {
    _pushATypeMessage({ content: message, type: EMessageType.Success })
  }

  const pushWarning = (message: string) => {
    _pushATypeMessage({ content: message, type: EMessageType.Warning })
  }

  const removeOldest = () => {
    if (notifications.length === 0) return
    setNotifications((state) => {
      const newNotifications = [...state]
      newNotifications.shift()
      return newNotifications
    })
    timeoutRef.current && clearTimeout(timeoutRef.current)
    timeoutRef.current = null
  }

  const removeOldestInSeconds = (seconds = 10) => {
    if (timeoutRef.current) return
    timeoutRef.current = setTimeout(() => {
      removeOldest()
    }, seconds * 1000)
  }

  return {
    messages: notifications,
    push,
    pushError,
    pushSuccess,
    pushWarning,
    removeOldest,
    removeOldestInSeconds,
    total: notifications.length,
  }
}
