import React, { useEffect, useRef, useState } from 'react'
import { Pagination } from 'presentation/components'
import { useDispatch, useSelector } from 'react-redux'
import paginatorMethods from '@/utils/paginatorMethods'
import { formatMoney } from '@/utils/functionUtils'

import { Grid, Button } from 'presentation/components/atoms'
import { Search } from 'presentation/components/molecules'
import { faEllipsis, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { Icon } from 'presentation/components/atoms/Icon'
import {
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Table,
  CircularProgress,
  Menu,
  MenuItem,
  ColorDot,
  Box,
} from 'presentation/components/atoms'
import useService from 'main/hooks/useService'
import { getEmployeeBenefitCards, patchChangeBindedBenefitStatus } from 'services/benefit'
import {
  BindCardToEmployeeMode,
  BindCardToEmployees,
} from 'presentation/components/organisms/bindCardToEmployeeDialogs'
import { BENEFIT_TYPES, BINDED_BENEFIT_STATUS } from 'utils/constants'
import { pushNotification } from 'store/modules/notification/actions'
import successHandler from 'utils/successHandler'
import { usePaginationOld } from 'main/hooks/usePaginationOld'

const initialMenuRef = {
  anchor: null,
  index: null,
}

const BenefitTable = () => {
  const dispatch = useDispatch()
  const employee = useSelector((state) => state.employee?.data)
  const employeeId = employee?.id || localStorage.getItem('employeeId')
  const [showFilter, setShowFilter] = useState(false)
  const [isBindCardOpen, setIsBindCardOpen] = useState(false)
  const [bindCardDialogMode, setBindCardDialogMode] = useState(undefined)
  const benefitToEdit = useRef(undefined)
  const query = {
    employeeId: employeeId,
    Sort: 'date',
  }
  const { paginator, setPaginator } = usePaginationOld({ query })

  const [benefitMenuRef, setBenefitMenuRef] = React.useState(initialMenuRef)

  const [getBenefitsCardsState, getBenefitsCardsRequest] = useService(getEmployeeBenefitCards, {
    onCompleted: (res) => {
      setPaginator(
        paginatorMethods.updatePaginator(paginator, {
          page: res?.data?.pageNumber,
          totalItens: res?.data?.totalItens,
          totalPages: res?.data?.totalPages,
        }),
      )
    },
  })

  const [patchBindedBenefitStatusState, patchBindedBenefitStatusRequest] = useService(patchChangeBindedBenefitStatus, {
    onCompleted: (res) => {
      dispatch(pushNotification(successHandler('Status alterado com sucesso!')))
      getBenefitsCardsRequest(paginatorMethods.buildQuery(paginator))
    },
  })

  const isLoading = getBenefitsCardsState.loading || patchBindedBenefitStatusState.loading

  const benefits = getBenefitsCardsState.response?.data?.data || []

  const generateTableHeader = () => {
    return (
      <TableRow>
        <TableCell id='employeeBenefit_header_cardNumber'>Número</TableCell>
        <TableCell id='employeeBenefit_header_cardName'>Nome</TableCell>
        <TableCell id='employeeBenefit_header_benefitType'>Benefício</TableCell>
        <TableCell id='employeeBenefit_header_dailyRate'>Valor Diário</TableCell>
        <TableCell id='employeeBenefit_header_status' align='center'>
          Status
        </TableCell>
        <TableCell id='employeeBenefit_header_actions' align='center'>
          Ações
        </TableCell>
      </TableRow>
    )
  }

  const generateTableRow = () => {
    if (benefits.length === 0 || isLoading)
      return (
        <TableRow>
          <TableCell sx={{ textAlign: 'center', overflow: 'hidden' }} colSpan={6}>
            {isLoading ? <CircularProgress /> : 'Sem registros'}
          </TableCell>
        </TableRow>
      )
    return benefits?.map((benefit, index) => {
      const isBenefitActive = benefit.status === BINDED_BENEFIT_STATUS.ACTIVE
      const isBenefitInactive = benefit.status === BINDED_BENEFIT_STATUS.INACTIVE
      const isBenefitAbleToStatusChange = isBenefitActive || isBenefitInactive

      const changeStatus = () => {
        patchBindedBenefitStatusRequest(
          benefit?.benefitId,
          isBenefitActive ? BINDED_BENEFIT_STATUS.INACTIVE : BINDED_BENEFIT_STATUS.ACTIVE,
        )
      }

      return (
        <TableRow
          key={benefit?.benefitId}
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            benefitToEdit.current = benefit
            setBindCardDialogMode(BindCardToEmployeeMode.view)
            setIsBindCardOpen(true)
          }}
        >
          <TableCell id={`employeeBenefit_row_${index}_cardNumber`}>{benefit.cardNumber} </TableCell>
          <TableCell id={`employeeBenefit_row_${index}_cardName`}>{benefit.cardName}</TableCell>
          <TableCell id={`employeeBenefit_row_${index}_benefitType`}>{BENEFIT_TYPES[benefit.benefitType]}</TableCell>
          <TableCell id={`employeeBenefit_row_${index}_dailyRate`}>R$ {formatMoney(benefit.dailyRate)}</TableCell>
          <TableCell id={`employeeBenefit_row_${index}_status`} align='center'>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <ColorDot color={isBenefitActive ? 'success' : (theme) => theme.palette.grey[400]} />
              {BINDED_BENEFIT_STATUS[benefit.status]}
            </Box>
          </TableCell>
          <TableCell id={`employeeBenefit_row_${index}_actions`} align='center' onClick={(e) => e.stopPropagation()}>
            <button
              style={{ all: 'unset', cursor: 'pointer' }}
              id={`employeeBenefit_row_${index}_menu_trigger`}
              onClick={(event) => {
                event.stopPropagation()
                setBenefitMenuRef({
                  anchor: event.currentTarget,
                  index: index,
                })
              }}
            >
              <Icon icon={faEllipsis} />
            </button>
            {!isBenefitAbleToStatusChange ? null : (
              <Menu
                id={`employeeBenefit_row_${index}_menu`}
                anchorEl={benefitMenuRef.anchor}
                open={benefitMenuRef.index === index}
                sx={{ marginTop: '-12px' }}
                onClose={() => setBenefitMenuRef(initialMenuRef)}
                onClick={(e) => {
                  e.stopPropagation()
                  setBenefitMenuRef(initialMenuRef)
                }}
              >
                <MenuItem
                  id={`employeeBenefit_row_${index}_menu_option_0`}
                  onClick={() => {
                    setBindCardDialogMode(BindCardToEmployeeMode.edit)
                    benefitToEdit.current = benefit
                    setIsBindCardOpen(true)
                  }}
                >
                  Editar
                </MenuItem>
                <MenuItem id={`employeeBenefit_row_${index}_menu_option_1`} onClick={changeStatus}>
                  {isBenefitActive ? 'Inativar' : 'Ativar'}
                </MenuItem>
              </Menu>
            )}
          </TableCell>
        </TableRow>
      )
    })
  }

  useEffect(() => {
    paginator.doRequest && getBenefitsCardsRequest(paginatorMethods.buildQuery(paginator))
    // eslint-disable-next-line
  }, [paginator])

  return (
    <>
      <Grid container spacing='16px' rowSpacing='16px' justifyContent='space-between' alignItems='center'>
        <Grid item xs sm md={5} lg={4}>
          <Search
            onSearch={(value) =>
              setPaginator(
                paginatorMethods.setFilters(paginator, {
                  filterValue: value,
                  filterProps: 'cardNumber,cardName',
                }),
              )
            }
            placeholder='Buscar na tabela abaixo ...'
          />
        </Grid>

        <Grid item xs={12} sm={12} md='auto'>
          <Button
            variant='contained'
            startIcon={<Icon icon={faPlusCircle} />}
            fullWidth={true}
            id='employeeBenefit_button_new'
            onClick={() => {
              setBindCardDialogMode(BindCardToEmployeeMode.add)
              setIsBindCardOpen(true)
            }}
          >
            Novo Benefício
          </Button>
        </Grid>

        <Grid item xs={12}>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label='simple table'>
              <TableHead>{generateTableHeader()}</TableHead>
              <TableBody>{generateTableRow()}</TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Pagination.ResultAmount
            id='employeeBenefit_pagination'
            setPageSize={(newPageSize) =>
              !paginator.doRequest && setPaginator(paginatorMethods.setPageSize(paginator, newPageSize))
            }
          />
        </Grid>
        <Grid item xs='auto'>
          <Pagination
            paginator={paginator}
            setPage={(wantedPage) => setPaginator(paginatorMethods.setPage(paginator, wantedPage))}
          />
        </Grid>
      </Grid>
      {isBindCardOpen && BindCardToEmployeeMode !== false && (
        <BindCardToEmployees
          employeeId={employeeId}
          open={isBindCardOpen}
          onClose={setIsBindCardOpen}
          mode={bindCardDialogMode}
          setMode={(mode) => setBindCardDialogMode(mode)}
          benefitToEdit={benefitToEdit.current}
          benefitsEdit={benefits}
          onSuccess={() => {
            getBenefitsCardsRequest(paginatorMethods.buildQuery(paginator))
          }}
        />
      )}
    </>
  )
}

export default BenefitTable
