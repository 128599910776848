import { styled, keyframes } from '@mui/material'

const pulseAnimationChecked = keyframes`
  0% {
    box-shadow: 0 0 0 0px rgb(25, 118, 210, 0.8);
  }
  100% {
    box-shadow: 0 0 0 5px rgb(25, 118, 210, 0);
  }
`

const pulseAnimation = keyframes`
  0% {
    box-shadow: 0 0 0 0px rgb(0, 0, 0, 0.4);
  }
  100% {
    box-shadow: 0 0 0 5px rgb(0, 0, 0, 0);
  }
`

export const Container = styled('div')<{ pulse: boolean; checked: boolean; blocked: boolean }>(
  ({ theme, pulse, checked, blocked }) => {
    return {
      position: 'relative',
      animation: pulse ? `${checked ? pulseAnimationChecked : pulseAnimation} 1.5s infinite` : null,
      maxWidth: 180,
      width: '100%',
      height: 55,
      border: `1px solid ${checked ? theme.palette.primary.main : theme.palette.common.light}`,
      backgroundColor: `${checked ? 'rgba(66, 165, 245, 0.10)' : 'transparent'}`,
      borderRadius: 8,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 16,
      cursor: blocked ? 'not-allowed' : 'pointer',
      transition: '0.5s all',
      div: {
        display: 'flex',
        justifyContent: 'center',
        gap: 12,
        fontSize: 14,
        fontFamily: 'Montserrat',
        fontWeight: 600,
      },
    }
  },
)

export const Tag = styled('small')(({ theme }) => ({
  position: 'absolute',
  right: -10,
  top: -10,
  color: theme.palette.primary.main,
  fontSize: 13,
  fontWeight: 800,
  letterSpacing: 0.16,
  backgroundColor: '#f3f6f9',
}))
