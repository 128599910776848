import { getCards, getCardsBlocked } from '@/services/cardsServices'
import { all, takeLatest, put, call } from 'redux-saga/effects'
import { ACTIONS, cardsRequestSuccess, cardsRequestFailure } from './actions'

function* fetchCards({ payload }) {
  const { response, error } = yield call(getCards, payload.query)
  if (response) yield put(cardsRequestSuccess(response.data))
  else yield put(cardsRequestFailure(error.response))
}

function* fetchCardsBlocked({ payload }) {
  const { response, error } = yield call(getCardsBlocked, payload.query)
  if (response) yield put(cardsRequestSuccess(response.data))
  else yield put(cardsRequestFailure(error.response))
}

export default all([
  takeLatest(ACTIONS.GET_CARDS_REQUEST, fetchCards),
  takeLatest(ACTIONS.GET_CARDS_BLOCKED_REQUEST, fetchCardsBlocked),
])
