import { Drawer, styled } from '@mui/material'

export const StyledDrawer = styled(Drawer)(({ theme }) => {
  const scrollHeight = document.documentElement.scrollHeight
  return {
    width: 322,
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      marginTop: theme.staticSize.header,
      marginBottom: theme.staticSize.footer,
      height: `calc(${scrollHeight}px - (${theme.staticSize.header}px + ${theme.staticSize.footer}px))`,
      width: 322,
      boxSizing: 'border-box',
    },
    '& .MuiBackdrop-root': {
      opacity: '0 !important',
    },
  }
})
