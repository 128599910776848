import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'

import { Icon } from 'presentation/components/atoms/Icon'
import { Tooltip } from 'presentation/components/atoms'

import { TooltipContent } from '../styles'

interface TooltipContentProps {
  value: number
}

export const tooltipContent: React.FC<TooltipContentProps> = ({ value }) => {
  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: '#F3F6F9',
          },
        },
      }}
      title={
        <TooltipContent>
          <p>
            <span>Conta corrente</span> valor gerado a partir de valor de créditos VT não aplicados na operadora
          </p>
          <p>
            Saldo da operadora: <small>R$ {value?.toFixed(2)}</small>
          </p>
        </TooltipContent>
      }
    >
      <Icon icon={faCircleInfo} style={{ color: '#1976D2' }} />
    </Tooltip>
  )
}
