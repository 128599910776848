import { useState } from 'react'

import checkCircle from '@/presentation/assets/icons/check-circle-outlined.svg'
import closeIcon from '@/presentation/assets/icons/close-filled.svg'

import { Container } from './styles'

interface ToastProps {
  title: string
}

export const Toast: React.FC<ToastProps> = ({ title }) => {
  const [close, setClose] = useState(false)

  return !close ? (
    <Container>
      <img src={checkCircle} alt='check icon' />
      <p>{title}</p>
      <img src={closeIcon} alt='close icon' onClick={() => setClose(true)} />
    </Container>
  ) : null
}
