import { styled } from '@mui/material'

export const StyledColorDot = styled('div')(({ color, theme }) => {
  let selectedColor = {}

  if (color === 'grey') {
    selectedColor.backgroundColor = theme.palette.grey[400]
  } else {
    selectedColor.backgroundColor = theme.palette?.[color]?.main || color
  }
  return {
    height: 8,
    width: 8,
    borderRadius: '50%',
    ...selectedColor,
  }
})
