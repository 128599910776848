import React, { useState, useEffect } from 'react'
import { Modal } from 'react-responsive-modal'
import { Body, TitleModal, Flex, Close, Subtitle, Options } from './styles'
import { Col, Row } from 'react-grid-system'
import { Button, Spinner, Switch } from 'presentation/components'
import { useDispatch, useSelector } from 'react-redux'
import { getDepartmentRequest } from '@/store/modules/department/actions'
import { getEmployeesRequestApi } from '@/store/modules/employees/actions'
import { changeDepartmentStatus } from '../../../requestApi'
import { reduceBigTextLines } from '@/utils/functionUtils'
import { pushNotification } from '@/store/modules/notification/actions'
import errorHandler from '@/utils/errorHandler'
import successHandler from '@/utils/successHandler'
import { useEmployees } from 'presentation/pages/employees/hooks/useEmployees'
import { useDepartment } from '../../hooks/useDepartment'
import { useNavigate } from 'react-router-dom'
import { useAtomValue } from 'jotai'
import { AccountPersistentStore } from '@/main/store'

const DepartmentDetails = ({ close, id, open, setIsWaitingResponse }) => {
  const { employeesData, isLoading: employeesIsLoading } = useEmployees({ paginator: { doRequest: false } })
  const { departament, departmentIsLoading, departmentStatus, setDepartmentStatus } = useDepartment()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const account = useAtomValue(AccountPersistentStore)
  const companyId = account.company.id
  const [toUpdate, setToUpdate] = useState(false)
  useEffect(() => {
    dispatch(getDepartmentRequest(companyId, id))
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (departament?.data?.id)
      dispatch(getEmployeesRequestApi(`?Active=true&ProfessionalData.DepartmentId=${departament.data.id}`))
    setDepartmentStatus(departament?.data?.situation)
    // eslint-disable-next-line
  }, [departament])

  return (
    <Modal
      open={open}
      onClose={close}
      center
      showCloseIcon={false}
      styles={{
        borderRadius: '5px',
        modal: { maxWidth: '850px', minHeight: '400px' },
      }}
    >
      <Body>
        <Flex>
          <TitleModal>Detalhes do departamento</TitleModal>
          <Close onClick={close}>x</Close>
        </Flex>
        {departmentIsLoading ? (
          <Spinner.Box>
            <Spinner style={{ height: '220px', padding: '40px' }} />
          </Spinner.Box>
        ) : (
          <>
            <Row style={{ marginTop: '20px' }} nogutter>
              <Col sm={5}>
                <Subtitle>{departament?.data?.name}</Subtitle>
              </Col>
              <Col sm={3} style={{ marginTop: '15px' }}>
                {!departament?.data?.isNoDepartment && (
                  <div style={{ display: 'flex' }}>
                    {departmentStatus === 0 ? 'Inativar' : 'Ativar'}
                    <div style={{ marginRight: '5px' }} />
                    <Switch
                      checked={!departmentStatus}
                      height={20}
                      onColor='#5e39ca'
                      checkedIcon={false}
                      hover
                      onText='Inativar'
                      offText='Ativar'
                      onChange={() =>
                        setDepartmentStatus((state) => {
                          setToUpdate(true)
                          return !state
                        })
                      }
                    />
                  </div>
                )}
              </Col>
              <Col sm={3} style={{ marginLeft: '20px' }}>
                {!departament?.data?.isNoDepartment && (
                  <Button
                    variant='outlined'
                    icon='edit'
                    onClick={() => navigate('/departamentos/editar', { state: id })}
                  >
                    Editar
                  </Button>
                )}
              </Col>
            </Row>
            <Subtitle style={{ marginTop: '30px', marginBottom: '15px' }}>
              {employeesData?.length > 0
                ? 'Colaboradores associados ao departamento'
                : 'Esse departamento ainda não possui colaboradores'}
            </Subtitle>
            <div style={{ minHeight: '100px' }}>
              {employeesIsLoading ? (
                <Spinner.Box style={{ padding: '40px' }}>
                  <Spinner style={{ height: '80px' }} />
                </Spinner.Box>
              ) : employeesData?.length && departament?.data?.id ? (
                employeesData?.map((data, i) => <Options key={i}>{reduceBigTextLines(data.fullname, 45)}</Options>)
              ) : null}
            </div>
          </>
        )}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {toUpdate ? (
            <Button
              variant='action'
              onClick={async () => {
                setIsWaitingResponse(true)
                await changeDepartmentStatus(
                  {
                    status: !departmentStatus,
                    company: companyId,
                    id: id,
                  },
                  () => dispatch(pushNotification(successHandler('Departamento Editado!'))),
                  (error) => dispatch(pushNotification(errorHandler(error.response))),
                )
                setIsWaitingResponse(false)
                close()
              }}
            >
              Salvar
            </Button>
          ) : (
            <Button variant='action' onClick={close}>
              Fechar
            </Button>
          )}
        </div>
      </Body>
    </Modal>
  )
}

export default DepartmentDetails
