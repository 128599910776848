import React from 'react'
import { Button, Paper, Typography } from 'presentation/components/atoms'
import { ReactComponent as NoItemCart } from 'presentation/assets/images/empty-shopping-cart.svg'
import { useNavigate } from 'react-router-dom'
export const NoItems = () => {
  const navigate = useNavigate()
  const handleNewOrder = () => navigate('/recargas')

  return (
    <Paper
      sx={{
        padding: '64px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '16px',
        textAlign: 'center',
      }}
    >
      <NoItemCart style={{ marginBottom: '32px' }} />
      <Typography variant='h2'>Seu carrinho está vazio!</Typography>
      <Typography variant='label'>Adicione os pedidos para concluir sua compra.</Typography>
      <Button variant='contained' sx={{ marginTop: '32px' }} onClick={handleNewOrder}>
        Fazer um novo pedido
      </Button>
    </Paper>
  )
}
