import api from '@/infra/api'

import apiCallHandler from './apiCallHandler'

export const getCompanyStatement = (query = '') => {
  return apiCallHandler(() => api.get(`rh-bff/financial/statement${query}`))
}

export const getDepositOrders = (query = '') => {
  return apiCallHandler(() => api.get(`rh-bff/deposit/orders${query}`))
}

export const postDepositBalance = (payload) => {
  return apiCallHandler(() => api.post(`/orders-service/Deposit`, payload))
}
