import { faPen, faX } from '@fortawesome/free-solid-svg-icons'
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  MenuItem,
  StatusChip,
} from 'presentation/components/atoms'
import { MaskedTextField, Select } from 'presentation/components/molecules'
import { Controller } from 'react-hook-form'
import { BENEFIT_TYPES, BINDED_BENEFIT_STATUS } from 'utils/constants'
import { Multiselect } from 'presentation/components/organisms/multiselect'

export const BindVisualization = ({ form, benefits, cardsFeesData, open, onClose, isLoading, swapDialogs }) => {
  const benefitsUnfiltered = benefits || []
  const benefitsTypes = benefitsUnfiltered.filter((type) => type.operators.length > 0) || []

  const [selectedBenefit, selectedOperator, selectedCard, status] = form.watch([
    'benefitType',
    'operator',
    'operatorCard',
    'status',
  ])

  const benefitInUse = benefitsTypes?.[selectedBenefit]
  const operatorInUse = benefitInUse?.operators?.find((operator) => operator.id === selectedOperator)
  const cardInUse = operatorInUse?.operatorCards?.find((operatorCard) => operatorCard.operatorCardId === selectedCard)
  const isActive = status === BINDED_BENEFIT_STATUS.ACTIVE
  const isInactive = status === BINDED_BENEFIT_STATUS.INACTIVE
  const isEditionAvailable = isActive || isInactive
  return (
    <Dialog open={open} maxWidth='md' onClose={onClose}>
      <DialogTitle>
        {!Boolean(cardInUse?.name) || isLoading ? (
          'Cartão'
        ) : (
          <>
            {cardInUse?.name}
            {!isEditionAvailable ? null : (
              <>
                <IconButton onClick={swapDialogs} sx={{ margin: '0 auto 0 16px' }}>
                  <Icon icon={faPen} />
                </IconButton>
                <StatusChip status={isActive ? 'active' : 'inactive'} label={isActive ? 'Ativo' : 'Inativo'} />
              </>
            )}
          </>
        )}
        <Icon icon={faX} onClick={onClose} sx={{ marginLeft: '16px' }} />
      </DialogTitle>
      {isLoading ? (
        <CircularProgress
          sx={{
            margin: '32px auto',
          }}
        />
      ) : (
        <>
          <DialogContent>
            <form
              id='bindedBenefitVisualization'
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '32px',
                margin: '32px 0',
              }}
            >
              <Grid container columnSpacing='16px' rowSpacing='16px'>
                <Grid item xs={12} sm={6}>
                  <Controller
                    control={form.control}
                    name='benefitType'
                    render={({ field: { value } }) => (
                      <Select label='Benefício' value={value} fullWidth disabled>
                        {benefitsTypes.map((benefit, index) => (
                          <MenuItem key={index + '_benefit_option'} value={index}>
                            {benefit.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    control={form.control}
                    name='operator'
                    render={({ field: { value } }) => (
                      <Select label='Operadora' value={value} fullWidth disabled>
                        {benefitInUse?.operators
                          ? benefitInUse.operators.map((operator, index) => (
                              <MenuItem key={index + '_operator_option'} value={operator.id}>
                                {operator.tradeName}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Controller
                    control={form.control}
                    name='dailyValueTotal'
                    render={({ field: { value } }) => (
                      <MaskedTextField
                        mask={Number}
                        radix=','
                        max={1000}
                        startAdornment='R$'
                        label='Valor diário'
                        placeholder='00,00'
                        disabled
                        value={value}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  {benefitInUse?.benefitType === BENEFIT_TYPES.TRANSPORT ||
                  benefitInUse?.benefitType === BENEFIT_TYPES.TRANSPORT_PAPER ? (
                    <Controller
                      control={form.control}
                      name='productsIds'
                      render={({ field: { value } }) => (
                        <Multiselect
                          label='Tarifas diárias'
                          options={cardsFeesData?.dailyRates}
                          placeholder='Selecione'
                          getOptionLabel={(option) => option?.description}
                          getOptionValue={(option) => option?.productId}
                          autoWidth={false}
                          value={value}
                          fullWidth={true}
                          isChipRemovable={false}
                          variant='outlined'
                          disabled
                        />
                      )}
                    />
                  ) : null}
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </>
      )}
    </Dialog>
  )
}
