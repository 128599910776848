import api from '@/infra/api'
import apiCallHandler from './apiCallHandler'

export const getRecharge = (rechargeId) => {
  return apiCallHandler(() => api.get(`rh-bff/recharge/orders/${rechargeId}`))
}

export const getRecharges = (query) => {
  return apiCallHandler(() => api.get(`rh-bff/recharge/orders${query}`))
}

export const getDraftRecharge = (rechargeId) => {
  return apiCallHandler(() => api.get(`rh-bff/recharge/new/informations/${rechargeId}`))
}

export const patchConfirmRechargeOrder = (orderId, payload) => {
  return apiCallHandler(() => api.patch(`rh-bff/recharge/new/confirmation/${orderId}/confirm`, payload))
}

export const patchPayConfirmRechargeOrder = (orderId) => {
  return apiCallHandler(() => api.patch(`rh-bff/recharge/new/confirmation/${orderId}/pay`))
}

export const getRechageOrderDetails = (orderId) => {
  return apiCallHandler(() => api.get(`rh-bff/recharge/new/informations/${orderId}`))
}

export const patchRechargeValueByDays = (orderId, employeeItemId, daysAmount) => {
  return apiCallHandler(() =>
    api.patch(`rh-bff/recharge/new/informations/${orderId}/${employeeItemId}/days/${daysAmount}`),
  )
}

export const patchRechargeExactValue = (orderId, employeeItemId, value) => {
  return apiCallHandler(() => api.patch(`rh-bff/recharge/new/informations/${orderId}/${employeeItemId}/value/${value}`))
}

export const patchRechargesValueByDays = (orderId, daysAmount) => {
  return apiCallHandler(() => api.patch(`rh-bff/recharge/new/informations/${orderId}/days/${daysAmount}`))
}

export const patchRechargesExactValue = (orderId, value) => {
  return apiCallHandler(() => api.patch(`rh-bff/recharge/new/informations/${orderId}/value/${value}`))
}

/* 
Broker Recharges
*/

export const getLastBrokerRechargeOrder = () => {
  return apiCallHandler(() => api.get(`rh-bff/recharge/broker`))
}

export const postUploadBrokerRechargeImportFileNew = (payload) => {
  return apiCallHandler(() =>
    api.post(`rh-bff/recharge/broker/import-order`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
  )
}

export const postUploadBrokerRechargeImportFileOld = (payload) => {
  return apiCallHandler(() =>
    api.post(`rh-bff/recharge/broker/import`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
  )
}

export const postRedoLastBrokerRechargeOrder = (orderId) => {
  return apiCallHandler(() => api.post(`rh-bff/recharge/broker/${orderId}/copy`))
}
