import { all, takeLatest, call, put } from 'redux-saga/effects'
import { ACTIONS, employeeRequestFailure, employeeRequestSuccess } from './actions'
import { getEmployeeApi } from '@/services/employeesServices'

function* getEmployee({ payload }) {
  const { response, error } = yield call(getEmployeeApi, payload.employeeId)
  if (response) yield put(employeeRequestSuccess(response.data))
  else yield put(employeeRequestFailure(error.response))
}

export default all([takeLatest(ACTIONS.GET_EMPLOYEE_REQUEST, getEmployee)])
