import React, { useState } from 'react'
import { Body, Title, SessionButton, Close } from './style'
import { Button, Icon, Input } from 'presentation/components'
import 'react-responsive-modal/styles.css'
import Modal from 'react-responsive-modal'
import { Controller, useForm } from 'react-hook-form'
import moment from 'moment'

const MODAL_STYLE = {
  borderRadius: '5px',
  modalContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    position: 'relative',
  },
  modal: {
    maxWidth: '380px',
    height: 'calc(100vh - 90px)',
    margin: '0',
    padding: '0',
    overflow: 'initial',
  },
}

const Filter = ({ actualFilters, cancel, filter, resetFiltersWithID, open }) => {
  const initialMinValue = actualFilters?.startDate ? new Date(actualFilters?.startDate.replaceAll('-', '/')) : null
  const [minDate, setMinDate] = useState(initialMinValue)

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      startDate: actualFilters?.startDate,
      endDate: actualFilters?.endDate,
    },
  })

  const momentAdd60Days = moment(minDate).add(90, 'days')

  function onSubmit(data) {
    filter(data)
  }

  const handleClear = () => {
    reset({ defaultValues: { startDate: '', endDate: '' } })
  }

  return (
    <Modal open={open} onClose={cancel} showCloseIcon={false} styles={MODAL_STYLE}>
      <Body>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Title>Filtrar</Title>
          <Close onClick={cancel}>
            <Icon name='x' />
          </Close>
        </div>
        <p>Insira abaixo a data desejada para a visualização do extrato.</p>
        <form onSubmit={handleSubmit(onSubmit)} id='filter'>
          <label>De</label>
          <Controller
            control={control}
            name='startDate'
            render={({ field: { onChange, value, ref } }) => (
              <Input.Date
                value={new Date(value?.replaceAll('-', '/'))}
                placeholder='dd/mm/aaaa'
                maxDate={new Date()}
                getInputRef={ref}
                onChange={(e) => {
                  onChange(e !== '' ? e?.toISOString().split('T')[0] : e)
                  setMinDate(e)
                }}
              />
            )}
          />
          <label>Até</label>
          <Controller
            control={control}
            name='endDate'
            render={({ field: { onChange, value, ref } }) => (
              <Input.Date
                value={new Date(value?.replaceAll('-', '/'))}
                onChange={(e) => onChange(e !== '' ? e?.toISOString().split('T')[0] : e)}
                placeholder='dd/mm/aaaa'
                getInputRef={ref}
                minDate={minDate || null}
                maxDate={momentAdd60Days._d > new Date() ? new Date() : momentAdd60Days._d}
                disabled={!minDate}
              />
            )}
          />
        </form>
        <SessionButton>
          <Button
            variant='outlined'
            width='200px'
            onClick={() => {
              resetFiltersWithID()
              handleClear()
            }}
          >
            Limpar
          </Button>
          <div style={{ paddingLeft: '15px' }} />
          <Button width='250px' form='filter'>
            Filtrar
          </Button>
        </SessionButton>
      </Body>
    </Modal>
  )
}

export default Filter
