import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { ptBR as PTText } from '@mui/x-date-pickers/locales'
import ptBR from 'dayjs/locale/pt-br'

import { StyledDatePicker, Label } from './styles'

const InputDate = (props) => {
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={ptBR}
      localeText={PTText.components.MuiLocalizationProvider.defaultProps.localeText}
    >
      {props.label ? <Label>{props.label}</Label> : null}
      <StyledDatePicker
        localeText={ptBR}
        slotProps={{ textField: { error: props.error, id: props.id } }}
        format='DD/MM/YYYY'
        {...{ ...props, label: null }}
      />
    </LocalizationProvider>
  )
}

export default InputDate
