import styled from 'styled-components'
import { Icon } from 'presentation/components'

export const ConfirmationIcon = styled(Icon)`
  height: 120px;
  width: 120px;
  stroke: var(--color-primary-01);
`
export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  min-height: 60vh;
  text-align: center;
`
