import { Button, ModalV2, Title } from 'presentation/components'
import React from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { CenterText, CheckIconContainer, Description } from './styles'
import { FiCheckCircle } from 'react-icons/fi'
import { sendToTransferArea } from '@/utils/functionUtils'
import { pushNotification } from '@/store/modules/notification/actions'
import successHandler from '@/utils/successHandler'
import { useDispatch } from 'react-redux'
const SlipModal = ({ open, slipInfo, ...props }) => {
  const dispatch = useDispatch()

  return (
    <ModalV2 open={open} {...props}>
      <CheckIconContainer>
        <FiCheckCircle />
      </CheckIconContainer>
      <CenterText>
        <Title.SubTitle>Boleto Gerado!</Title.SubTitle>
      </CenterText>
      <Description>
        Após a compensação do boleto, o valor será creditado na sua <b>Conta Digital</b>
        <br />e você poderá usá-lo nos seus pedidos.
      </Description>
      <Container fluid style={{ padding: 0 }}>
        <Row justify='center'>
          <Col sm={4}>
            <Button
              onClick={() => {
                sendToTransferArea(slipInfo.digitableLine)
                dispatch(pushNotification(successHandler('Código copiado!')))
              }}
            >
              Copiar Código
            </Button>
          </Col>
          <Col sm={4}>
            <a href={slipInfo.slipLink} target='_blank' rel='noopener noreferrer'>
              <Button>Baixar Boleto</Button>
            </a>
          </Col>
        </Row>
      </Container>
    </ModalV2>
  )
}

export default SlipModal
