import { Col, Container, Row } from 'react-grid-system'
import { StepsWrapper, Title, Text, CustomTable, TextAdd, Add, ButtonWrapper, AddToCart } from './styles'
import { Button } from 'presentation/components/atoms'
import { useState, useEffect } from 'react'
import { getCollaborators, remove } from './requests'
import { useDispatch } from 'react-redux'
import { pushNotification } from '@/store/modules/notification/actions'
import errorHandler from '@/utils/errorHandler'
import successHandler from '@/utils/successHandler'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan, faPen } from '@fortawesome/free-solid-svg-icons'
import Modal from './components/modal'
import { Content, Panel, PageHeader, Steps, ModalV2, PageChangePrompt, TextDecoration } from 'presentation/components'
import { TableFooterPagination } from 'presentation/components/molecules'
import { ORDER_METHOD } from '@/utils/constants'
import { formatMoney } from 'utils/functionUtils'
import { FeatureToggle } from 'utils/featureToggle/featureToggle'
import { useAddCartItem } from 'main/hooks/useAddCartItem'
import { useLocation, useNavigate } from 'react-router-dom'
import { orderIdAtom } from 'main/store'
import { useAtomValue } from 'jotai'

const NextStepsImport = () => {
  const { addToCart } = useAddCartItem()
  const navigate = useNavigate()
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)

  const [currentStep] = useState(2)
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [edit, setEdit] = useState(false)
  const [info, setInfo] = useState(null)
  const [openDelete, setOpenDelete] = useState(false)
  const [actualOrderId, setActualOrderId] = useState(null)

  const location = useLocation()
  const atomOrderId = useAtomValue(orderIdAtom)
  const orderId = location?.state?.orderId || atomOrderId
  const dispatch = useDispatch()

  const steps = [
    {
      name: 'Colaboradores',
      navigable: false,
    },
    {
      name: 'Informações',
      navigable: false,
    },
    {
      name: 'Pagamento',
      navigable: false,
    },
  ]

  const requestError = (e) => {
    dispatch(pushNotification(errorHandler(e.response)))
  }

  const collaborators = () => {
    getCollaborators(setLoading, orderId, setData, requestError)
  }

  useEffect(() => {
    collaborators()
    // eslint-disable-next-line
  }, [])

  const clickEdit = (value) => {
    setInfo(value)
    setEdit(true)
    setOpenModal(true)
  }

  const refreshDel = () => {
    setOpenDelete(false)
    collaborators()
    dispatch(pushNotification(successHandler('Colaborador Removido')))
  }

  const confirmRemove = () => {
    remove(actualOrderId, orderId, setLoading, requestError, refreshDel)
    setOpenDelete(false)
  }

  const refresh = () => {
    setOpenModal(false)
    collaborators()
    setInfo(null)
    setEdit(false)
    dispatch(pushNotification(successHandler(edit ? 'Colaborador Atualizado' : 'Colaborador Adicionado')))
  }

  const closeModal = () => {
    setEdit(false)
    setInfo(null)
    setOpenModal(false)
  }

  const openDeleteModal = (id) => {
    setActualOrderId(id)
    setOpenDelete(true)
  }

  return (
    <>
      <Content>
        <Panel noPadding>
          <PageChangePrompt title=''>
            <p>Você não finalizou seu pedido.</p>
            <p>
              Seu pedido será salvo como <TextDecoration>Rascunho</TextDecoration> por uma semana e você poderá
              continuar de onde parou para finalizá-lo.
            </p>
          </PageChangePrompt>
          <PageHeader>Pedido de Recarga Broker Ótimo</PageHeader>
          <Container fluid style={{ padding: '32px' }}>
            <Row>
              <Col>
                <StepsWrapper>
                  <Steps currentStep={currentStep} steps={steps} />
                </StepsWrapper>
              </Col>
            </Row>
            <Title>Colaboradores e setores selecionados para o pedido</Title>
            <Text>Revise as informações antes de continuar com seu pedido</Text>
            <CustomTable loading={loading}>
              <thead>
                <CustomTable.Tr>
                  <CustomTable.Td>Colaborador</CustomTable.Td>
                  <CustomTable.Td>Matrícula Colaborador</CustomTable.Td>
                  <CustomTable.Td>Matrícula Ótimo</CustomTable.Td>
                  <CustomTable.Td>Cartão</CustomTable.Td>
                  <CustomTable.Td>Valor Diário</CustomTable.Td>
                  <CustomTable.Td>Qtd Dias</CustomTable.Td>
                  <CustomTable.Td>Valor Recarga</CustomTable.Td>
                  <CustomTable.Td></CustomTable.Td>
                </CustomTable.Tr>
              </thead>
              <tbody>
                {data?.items?.length
                  ? data.items
                      .slice(page === 1 ? 0 : pageSize * page - pageSize, page === 1 ? pageSize : pageSize * page)
                      .map((employee, i) => (
                        <CustomTable.Tr key={i}>
                          <CustomTable.Td>{employee.employeeName}</CustomTable.Td>
                          <CustomTable.Td>{employee.registration}</CustomTable.Td>
                          <CustomTable.Td>{employee.employeeIntegrationCode ?? 'Não informado'}</CustomTable.Td>
                          <CustomTable.Td>{employee.cardNumber}</CustomTable.Td>
                          <CustomTable.Td>R$ {formatMoney(employee.dailyValue)}</CustomTable.Td>
                          <CustomTable.Td>{employee.quantity}</CustomTable.Td>
                          <CustomTable.Td>R$ {formatMoney(employee.rechargeValue)}</CustomTable.Td>
                          <CustomTable.Td>
                            <div style={{ display: 'flex' }}>
                              <FontAwesomeIcon
                                icon={faPen}
                                size='sm'
                                style={{
                                  marginRight: '15px',
                                  cursor: 'pointer',
                                }}
                                onClick={() => clickEdit(employee)}
                              />
                              {data.items.length < 2 ? null : (
                                <FontAwesomeIcon
                                  icon={faTrashCan}
                                  size='sm'
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => openDeleteModal(employee.id)}
                                />
                              )}
                            </div>
                          </CustomTable.Td>
                        </CustomTable.Tr>
                      ))
                  : null}
              </tbody>
            </CustomTable>
            <TableFooterPagination
              totalItems={data?.items?.length}
              page={page}
              totalPages={Math.round(data?.items?.length / pageSize)}
              rowsPerPage={pageSize}
              onRowsPerPageChange={(e) => setPageSize(e.target.value)}
              onPageChange={(e, value) => setPage(value)}
            />
            <Row justify='space-between' style={{ margin: '32px 0 80px' }}>
              <Col>
                <div
                  style={{
                    display: 'flex',
                    cursor: 'pointer',
                    marginTop: '16px',
                  }}
                  onClick={() => setOpenModal(true)}
                >
                  <Add>+</Add>
                  <TextAdd>Adicionar colaborador</TextAdd>
                </div>
              </Col>
              <Col>
                <div style={{ textAlign: 'right', marginTop: '16px' }}>
                  <strong>Valor total: R$ {formatMoney(data?.total)}</strong>
                </div>
              </Col>
            </Row>

            <Row justify='end'>
              <Col sm='content'>
                <ButtonWrapper>
                  <Button onClick={data?.id ? () => navigate('/recargas/pedido_recarga_broker') : null}>Voltar</Button>
                  <Button
                    variant='outlined'
                    onClick={
                      data?.id
                        ? () =>
                            navigate('/recargas/novo_pedido_importacao/confirmacao', {
                              state: {
                                orderId: data.id,
                                method: ORDER_METHOD.MANUAL,
                                bypass: true,
                                title: 'Recarga',
                                quantity: data?.items?.length,
                                operatorCardName: data.items?.[0]?.benefitName,
                                isBroker: true,
                              },
                            })
                        : null
                    }
                  >
                    Comprar agora
                  </Button>
                  <FeatureToggle feature='cart'>
                    <AddToCart onClick={() => addToCart.mutate({ orderId: data?.id })} variant='contained'>
                      Adicionar ao carrinho
                    </AddToCart>
                  </FeatureToggle>
                </ButtonWrapper>
              </Col>
            </Row>
          </Container>
        </Panel>
      </Content>
      {openModal && (
        <Modal
          open={openModal}
          close={() => closeModal()}
          orderId={orderId}
          refresh={() => refresh()}
          requestError={requestError}
          edit={edit}
          data={info}
        />
      )}
      {openDelete && (
        <ModalV2.TwoOptions
          open={openDelete}
          onClose={() => setOpenDelete(false)}
          leftText={'Fechar'}
          rightText={'Excluir'}
          onLeftClick={() => setOpenDelete(false)}
          onRightClick={() => confirmRemove()}
        >
          Tem certeza que deseja remover esse colaborador do pedido?
        </ModalV2.TwoOptions>
      )}
    </>
  )
}

export default NextStepsImport
