const requiredFieldMessage = 'Campo requerido'

export const rules = {
  benefitType: {
    required: requiredFieldMessage,
  },
  operator: {
    required: requiredFieldMessage,
  },
  operatorCard: {
    required: requiredFieldMessage,
  },
  productsIds: {
    required: requiredFieldMessage,
  },
  dailyValue: {
    required: requiredFieldMessage,
  },
  operatorRegistration: (required) => ({ required: required ? requiredFieldMessage : false }),
}
