import { styled } from '@mui/material'
import { Box, NavLink } from 'presentation/components/atoms'

export const StatusBanner = styled(Box)(({ color }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  padding: '8px',
  background: color.light + '2D',
  borderRadius: '4px',
  marginBottom: '16px',
  '&:before': {
    content: '" "',
    height: '16px',
    width: '16px',
    minHeight: '16px',
    minWidth: '16px',
    backgroundColor: color.main,
    borderRadius: '50%',
  },
}))

export const LinkToOrder = styled(NavLink)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '14px',
  marginTop: '8px',
  marginLeft: '56px',
  display: 'block',
  cursor: 'pointer',
  '& svg': {
    marginLeft: '8px',
    height: '12px',
  },
}))
