import { useEffect, useRef } from 'react'

const useOnClickOutside = (handler) => {
  const domNode = useRef()

  useEffect(() => {
    const maybeHandler = (event) => {
      if (!domNode?.current?.contains(event.target)) {
        handler(event)
      }
    }

    document.addEventListener('mousedown', maybeHandler)

    return () => {
      document.removeEventListener('mousedown', maybeHandler)
    }
  })

  return domNode
}

export default useOnClickOutside
