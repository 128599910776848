import styled from 'styled-components'
import { Row as GridRow } from 'react-grid-system'

export const Row = styled(GridRow)`
  margin-top: 32px;
  &:first-child {
    margin-top: 0;
  }
`
export const Fieldset = styled.fieldset`
  border: none;
`

export const TextDecoration = styled.span`
  color: var(--color-primary-01);
  text-decoration: underline;
  cursor: pointer;
`
