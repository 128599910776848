import { useAtom } from 'jotai'
import { atomWithHash } from 'jotai-location'
import { ButtonSelector, ButtonSelectorOption } from '@stationkim/front-ui'
import { OldDealerList } from './oldDealerList'
import { RechargesList } from './rechargesList'
import { getResaleSiteFromUrl } from '@/utils/functionUtils'

const lists = [RechargesList, OldDealerList]
const listSelectorAtom = atomWithHash('selectedList', 0)

const Recharges = () => {
  const [visibleList, setVisibleList] = useAtom(listSelectorAtom)

  const isMotiva = getResaleSiteFromUrl() === 'motiva'
  const listSelector = (
    <ButtonSelector value={visibleList} onChange={({ targetIndex }) => setVisibleList(targetIndex)}>
      <ButtonSelectorOption>Broker</ButtonSelectorOption>
      <ButtonSelectorOption>Dealer</ButtonSelectorOption>
    </ButtonSelector>
  )

  const Component = isMotiva ? lists[1] : lists[visibleList]
  return <Component listSelector={isMotiva ? null : listSelector} />
}

export default Recharges
