import React, { useEffect, useRef, useState } from 'react'
import {
  Button,
  ErrorMessage,
  ErrorWrapper,
  GradientText,
  Icon,
  Input,
  Notification,
  Spacing,
} from 'presentation/components'
import { useDispatch } from 'react-redux'
import {
  Main,
  Body,
  Content,
  Title,
  Label,
  ChangePasswordSuccess,
  OkBallContainer,
  OkBall,
  SuccessText,
} from './styles'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import schema from './schema'
import { pushNotification } from '@/store/modules/notification/actions'
import errorHandler from '@/utils/errorHandler'
import useQuery from 'main/hooks/useQuery'
import identity from '@/services/identity'
import { useLocation } from 'react-router-dom'

const CONTEXT = {
  RESET: 1,
  CHANGE: 2,
}

const PasswordDefinition = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const query = useQuery()
  const context = useRef(CONTEXT.CHANGE)
  const token = query.get('token')
  const email = query.get('email')
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    const ctx = location.pathname.includes('/cadastro/senha') ? CONTEXT.RESET : CONTEXT.CHANGE
    context.current = ctx
  }, [location])

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  })

  const definePassword = async (data) => {
    try {
      await identity.patch(`/reset-password`, data)
      setSuccess(true)
    } catch (error) {
      dispatch(pushNotification(errorHandler(error.response)))
    }
  }

  const onSubmit = (data) => {
    data.token = token.replaceAll(' ', '+')
    data.email = email?.replaceAll(' ', '+')
    definePassword(data)
  }

  return (
    <Main>
      <Notification style={{ top: '10px' }} />
      <Body>
        {!success && (
          <Content>
            {context.current === CONTEXT.CHANGE && <GradientText>Bem-vindo de volta!</GradientText>}
            <Title>Cadastre sua nova senha</Title>
            <p>
              Sua senha deve conter, ao menos, uma letra maiúscula, uma minúscula, um número e um carácter especial.
            </p>
            <Spacing top='16px' />
            <form onSubmit={handleSubmit(onSubmit)}>
              <ErrorWrapper error={errors.password}>
                <Label>Nova Senha</Label>
                <Input type='password' placeholder='Digite sua senha' maxLength={100} {...register('password')} />
                {errors.password && <ErrorMessage>{errors.password.message}</ErrorMessage>}
              </ErrorWrapper>
              <Spacing top='30px' />
              <ErrorWrapper error={errors.confirmPassword}>
                <Label>Confirmar nova Senha</Label>
                <Input type='password' placeholder='Digite sua senha' {...register('confirmPassword')} />
                {errors.confirmPassword && <ErrorMessage>{errors.confirmPassword.message}</ErrorMessage>}
              </ErrorWrapper>
              <Spacing top='30px' />
              <Button>Cadastrar nova senha</Button>
            </form>
          </Content>
        )}

        {success && (
          <Content>
            <ChangePasswordSuccess>
              <OkBallContainer>
                <OkBall />
                <Icon name='ok' />
              </OkBallContainer>
              <SuccessText>Nova senha cadastrada com sucesso!</SuccessText>
            </ChangePasswordSuccess>
          </Content>
        )}
      </Body>
    </Main>
  )
}

export default PasswordDefinition
