import { PrimitiveAtom, useAtom } from 'jotai'
import { useEffect } from 'react'

interface IUseFilter {
  filterAtom: PrimitiveAtom<Record<string, any>>
  initialValue?: Record<string, any>
}

const deleteAtomWithHash = () => {
  const url = new URL(window.location as any)
  url.hash = ''
  window.history.replaceState({}, '', url.toString())
}

export const useFilter = ({ filterAtom, initialValue = {} }: IUseFilter) => {
  const [filter, setFilter] = useAtom(filterAtom)

  const resetFilter = (params: { filterValues?: object } = {}) => {
    const { filterValues = {} } = params
    setFilter({ ...initialValue, ...filterValues })
  }

  const updateFilter = (filterValues: object) =>
    setFilter(() => {
      if (filter) return { ...filter, ...filterValues }
      return { ...initialValue, ...filterValues }
    })
  const overrideFilter = (filterValues: object) => setFilter(filterValues)

  /* useEffect(() => {
    if (typeof filter === 'object' && filter !== null) if (Object.keys(filter).length === 0) deleteAtomWithHash()
  }, [filter]) */
  /* 
  
  rever logica de desligamento de hash
  n posso limpar a hash inteira, pq n necessariamente apenas o filtro esta nela
  melhorar a logica de limpeza
  */
  useEffect(() => {
    if (typeof initialValue === 'object' && initialValue !== null)
      Object.keys(initialValue).length > 0 && setFilter(initialValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    filter: filter,
    setFilter,
    resetFilter,
    updateFilter,
    overrideFilter,
  }
}
