import { Box, Typography } from 'presentation/components/atoms'
import { IconContainer, StyledCard } from './styles'
import { Theme } from '@mui/material'

interface DetailCardProps {
  title?: string | React.ReactElement
  subtitle?: string | React.ReactElement
  helperText?: string | React.ReactElement
  icons?: Array<React.ReactElement> | React.ReactElement
}

export const DetailCard = ({ icons, title, subtitle, helperText }: DetailCardProps) => {
  return (
    <StyledCard elevation={0}>
      {icons && <IconContainer>{icons}</IconContainer>}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', justifyContent: !subtitle && 'center' }}>
        {typeof title !== 'string' ? title : <Typography variant='h5'>{title}</Typography>}
        {typeof subtitle !== 'string' ? (
          subtitle
        ) : (
          <Typography variant='label' sx={(theme: Theme) => ({ color: theme.palette.grey[700], fontWeight: 400 })}>
            {subtitle}
          </Typography>
        )}
        {typeof helperText !== 'string' ? helperText : <Typography variant='label3'>{helperText}</Typography>}
      </Box>
    </StyledCard>
  )
}
