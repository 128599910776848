import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import api from '@/infra/api'
import { useMemo } from 'react'

interface ApiOperatorCard {
  operatorCardId: string
  operatorCardName: string
}

export const useOrderOperatorsCards = () => {
  const { data, ...rest } = useQuery<AxiosResponse<ApiOperatorCard[]>>({
    queryKey: ['operatorsCardsInOrder'],
    queryFn: () => api.get(`rh-bff/basket/operators/balance-projection`),
    refetchOnWindowFocus: false,
  })

  const organizedData = useMemo(() => {
    const rawData = data?.data
    if (!rawData) return []
    return rawData.map((operatorCard) => {
      return {
        id: operatorCard?.operatorCardId,
        label: operatorCard?.operatorCardName,
      }
    })
  }, [data])

  return {
    operatorsCards: organizedData,
    ...rest,
  }
}
