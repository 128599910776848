import { faX } from '@fortawesome/free-solid-svg-icons'
import React, { useState, useEffect, useCallback } from 'react'
import { ModalHeader, ModalBody, ModalTitle, ModalContainer, ModalFooter, NoCards } from '../styles'
import DiscardModal from './discardModal'
import 'react-responsive-modal/styles.css'
import { Modal } from 'react-responsive-modal'
import { getCardsRequest } from 'store/modules/cards/actions'
import { useDispatch, useSelector } from 'react-redux'
import api from '@/infra/api'
import { NULL_ID } from 'utils/constants'
import errorHandler from 'utils/errorHandler'
import { pushNotification } from 'store/modules/notification/actions'
import { Box, Button, Checkbox, Icon, Skeleton } from '@/presentation/components/atoms'
import successHandler from '@/utils/successHandler'

const AssociateCardModal: React.FC<{
  isOpen: any
  onClose: any
  id: any
}> = ({ isOpen, onClose, id }) => {
  const dispatch = useDispatch()
  const storeData = useSelector((state: any) => state.cards)
  const cardsData = storeData?.cards?.data
  const [selected, setSelected] = useState<any>(null)
  const [confirmClose, setConfirmClose] = useState(false)

  useEffect(() => {
    if (storeData.failure !== null) {
      const errorMessage = errorHandler(storeData.failure)
      dispatch(pushNotification(errorMessage))
    }
  }, [storeData, dispatch])

  const associate = async () => {
    try {
      await api.patch(`/cards-service/${selected.cardId}/associate/${id}`, {
        headers: { 'Content-Type': 'application/json' },
      })
      setSelected(null)
      setConfirmClose(false)
      onClose(true)
      dispatch(pushNotification(successHandler('Cartão associado')))
    } catch (error: any) {
      dispatch(pushNotification(errorHandler(error.response)))
    }
  }

  const onBeforeClose = useCallback(() => {
    if (selected) {
      setConfirmClose(true)
    } else {
      onClose()
    }
  }, [selected, setConfirmClose, onClose])

  const onConfirmClose = useCallback(
    (close: any) => {
      setConfirmClose(false)
      if (close) {
        onClose(true)
      }
    },
    [setConfirmClose, onClose],
  )

  useEffect(() => {
    dispatch(getCardsRequest(`?Status=1&EmployeeId=${NULL_ID}&pageSize=50`))
  }, [dispatch])
  const closeIcon = <Icon icon={faX} />
  return (
    <>
      <Modal
        open={isOpen}
        onClose={onBeforeClose}
        center
        closeIcon={closeIcon}
        styles={{
          modal: {
            borderRadius: '8px',
            width: '400px',
            height: '450px',
            maxWidth: '100%',
            margin: '0',
            maxHeight: '100%',
          },
        }}
      >
        <ModalContainer>
          <ModalHeader>
            <ModalTitle>Cartões disponíveis para associar</ModalTitle>
          </ModalHeader>
          <ModalBody>
            {storeData?.loading ? (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', width: '100%' }}>
                {Array(6)
                  .fill(0)
                  .map((_, index) => (
                    <Skeleton key={`associate_skeleton_${index}`} width='100%' height='42px' variant='rounded' />
                  ))}
              </Box>
            ) : (
              <Box
                sx={(theme: any) => ({
                  width: '100%',
                  '& tr, & table': {
                    width: '100%',
                  },
                  '& td': {
                    height: '42px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    borderBottom: `1px solid ${theme.palette.grey[100]}`,
                  },
                })}
              >
                <table>
                  <tbody>
                    {cardsData?.map((card: any) => (
                      <tr key={card?.id}>
                        <td>
                          <Checkbox
                            checked={selected?.id === card?.id}
                            onClick={(e) => setSelected(() => (selected?.id !== card?.id ? card : null))}
                          />
                          {card?.number}
                        </td>
                      </tr>
                    ))}
                    {(!cardsData?.length || cardsData?.length === 0) && <NoCards>Sem cartões disponíveis</NoCards>}
                  </tbody>
                </table>
              </Box>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              variant='contained'
              onClick={() => associate()}
              disabled={!selected || storeData?.loading}
              fullWidth
            >
              Confirmar
            </Button>
          </ModalFooter>
        </ModalContainer>
      </Modal>
      <DiscardModal isOpen={confirmClose} onClose={onConfirmClose} />
    </>
  )
}

export default AssociateCardModal
