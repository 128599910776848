import { useQuery } from '@tanstack/react-query'
import api from '@/infra/api'
import { IOrderBasicInformation } from '../interfaces/iOrderBasicInformation'

interface ICartOrderInformation extends IOrderBasicInformation {
  id: string
  code: string
  orderType: number
  status: number
  totalItems: number
  totalValue: number
}

export const useCartOrdersByOrderId = ({ orderId }: { orderId?: string | null | undefined }) => {
  const { data, ...rest } = useQuery({
    queryKey: ['cartOrdersByOrderId', orderId],
    queryFn: () => api.get(`rh-bff/basket/ordersBasketByOrder/${orderId}`),
    enabled: Boolean(orderId),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })

  const organizeData = (): Array<ICartOrderInformation> => {
    if (!data) return []
    const organizedData: Array<ICartOrderInformation> = data.data.map(
      (order: any): ICartOrderInformation => ({
        code: order.orderCode,
        id: order.orderId,
        orderType: order.orderType,
        status: order.status,
        totalItems: order.totalItens,
        totalValue: order.totalValue,
      }),
    )
    return organizedData
  }

  return {
    cartOrders: organizeData(),
    ...rest,
  }
}
