import styled from 'styled-components'

export const Card = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  overflow: hidden;
`

export const Title = styled.h1`
  font-size: 19px;
  border-bottom: 1px solid #a9a9a9;
  padding-bottom: 7px;
  margin-left: -20px;
  padding-left: 22px;
  display: flex;
`

export const Option = styled.div`
  width: 50%;
`

export const SubInfo = styled.p`
  font-weight: bold;
  color: #1f78b4;
  text-align: center;
  font-size: 13px;
  margin-top: 10px;
`

export const NumberInfo = styled.p`
  font-weight: bold;
  color: #1f78b4;
  text-align: center;
  font-size: 37px;
  margin-top: 10px;
`

export const ViewAll = styled.div`
  display: flex;
  color: #8462ff;
  font-size: 12px;
  padding-top: 5px;
  position: absolute;
  right: 40px;
`

export const ImageMock = styled.img`
  margin-top: 16px;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  width: 100%;
`

export const Image = styled.img`
  max-width: 100%;
  border-radius: 8px;
`
