import { useSelector } from 'react-redux'

export const useCardsBlocked = () => {
  const storeData = useSelector((state) => state?.cards)
  const cards = storeData?.cards?.data
  const isLoading = storeData?.loading
  const cardsPagination = storeData?.cards

  return {
    cards: cards || [],
    isLoading,
    cardsPagination: {
      pageSize: cardsPagination?.pageSize,
      totalItens: cardsPagination?.totalItens,
      totalPages: cardsPagination?.totalPages,
      pageNumber: cardsPagination?.pageNumber,
    },
  }
}
