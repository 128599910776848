import { Button, styled } from '@mui/material'

export const StyledButton = styled(Button)(() => {
  return {
    '& svg': {
      height: '16px',
    },
    '&:disabled': {
      cursor: 'not-allowed',
      pointerEvents: 'auto',
      color: 'rgba(0, 0, 0, 0.38) !important',
      borderColor: 'rgba(0, 0, 0, 0.38) !important',
    },
  }
})
