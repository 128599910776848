import React, { useState } from 'react'
import { Menu, SxProps } from '@mui/material'
import { TriggerContainer } from './styles'

interface MenuUncontrolledProps {
  children: any
  renderItems: () => React.ReactElement | Array<React.ReactElement>
  isCursorPointer?: boolean
  sx?: SxProps
}

export const MenuUncontrolled = ({
  children,
  isCursorPointer = true,
  renderItems,
  ...props
}: MenuUncontrolledProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <React.Fragment>
      <TriggerContainer isCursorPointer={isCursorPointer} onClick={handleClick}>
        {children}
      </TriggerContainer>
      <Menu id='basic-menu' anchorEl={anchorEl} open={open} onClose={handleClose} {...props}>
        {renderItems()}
      </Menu>
    </React.Fragment>
  )
}
