import { useState } from 'react'
import { useSelector } from 'react-redux'

export const useDepartment = () => {
  const departamentStore = useSelector((state) => state.department)
  const departament = departamentStore.data
  const departmentIsLoading = departament?.loading

  const [departmentStatus, setDepartmentStatus] = useState(departament?.data?.situation === 0 ? true : false)

  return {
    departament,
    departmentIsLoading,
    departmentStatus,
    setDepartmentStatus,
  }
}
