import { useEffect, useState } from 'react'
import { Container, Col, useScreenClass } from 'react-grid-system'
import dayjs from 'dayjs'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { useBenefitTypes } from 'main/hooks/useBenefitTypes'

import { Row } from '../../styles'
import { Pagination, Table } from 'presentation/components'
import { Search } from 'presentation/components/molecules'
import { useDispatch } from 'react-redux'
import { getCardsBlockedRequest } from '@/store/modules/cards/actions'
import paginatorMethods from '@/utils/paginatorMethods'
import moment from 'moment'
import { Filter } from 'presentation/components/organisms'
import { Button } from 'presentation/components/atoms'

import { TableBody } from './components/TableBody/TableBody'
import { TableHead } from './components/TableHead/TableHead'
import { useCardsBlocked } from './hooks/useCardsBlocked'
import { usePaginationOld } from 'main/hooks/usePaginationOld'

// interface ICards {
//   balance: string;
//   benefitName: string;
//   birthDate: null;
//   blockDate: string;
//   blockingReason: string;
//   cardId: string;
//   copy: number;
//   cpf: null;
//   dayValue: number;
//   department: null;
//   departmentId: null;
//   employeeId: null;
//   enabled: boolean;
//   fullname: null;
//   gender: null;
//   issueDate: null;
//   issuingBody: null;
//   motherName: null;
//   number: string;
//   operatorCardId: string;
//   operatorCardName: string;
//   operatorId: string;
//   registration: null;
//   removable: boolean;
//   rg: null;
//   state: null;
//   status: number;
//   type: number;
//   usage: number;
// }

const Options = () => {
  const dispatch = useDispatch()
  const [showFilter, setShowFilter] = useState(false)
  const screenClass = useScreenClass()

  const { cards, cardsPagination, isLoading } = useCardsBlocked()
  const { benefitTypes, loading } = useBenefitTypes()

  const query = {
    Sort: 'number',
    desc: true,
    initDate: null,
    endDate: null,
    Status: 3,
  }
  const { paginator, setPaginator } = usePaginationOld({ query })

  const [maxDate, setMaxDate] = useState(dayjs(new Date()))
  const [selectedInitDate, setSelectedIniDate] = useState(false)

  const dateMax = new Date(maxDate)
  dateMax.setDate(dateMax.getDate() + 180)

  const newMaxDate = moment(new Date(maxDate)).diff(new Date(), 'days') < -180 ? dayjs(dateMax) : dayjs(new Date())

  useEffect(() => {
    const hasInitDate = paginator.query?.initDate
    const hasEndDate = paginator.query?.endDate

    if (hasInitDate || hasEndDate) {
      const initDate = new Date(paginator.query?.initDate)?.toLocaleDateString().replaceAll('/', '-')
      const endDate = new Date(paginator.query?.endDate)?.toLocaleDateString().replaceAll('/', '-')

      delete paginator?.query?.initDate
      delete paginator?.query?.endDate

      const BlockDate = `${initDate}><${endDate}`

      paginator.doRequest &&
        dispatch(
          getCardsBlockedRequest(
            paginatorMethods.buildQuery({ ...paginator, query: { ...paginator.query, BlockDate } }),
          ),
        )

      return
    }

    paginator.doRequest && dispatch(getCardsBlockedRequest(paginatorMethods.buildQuery(paginator)))
    // eslint-disable-next-line
  }, [paginator])

  useEffect(() => {
    setPaginator(
      paginatorMethods.updatePaginator(paginator, {
        page: cardsPagination?.pageNumber,
        totalItens: cardsPagination?.totalItens,
        totalPages: cardsPagination?.totalPages,
      }),
    )
    // eslint-disable-next-line
  }, [cards])

  return (
    <Container style={{ padding: 0, maxWidth: '100%' }}>
      <Row justify={['xs', 'sm'].includes(screenClass) ? 'center' : 'between'}>
        <Col xs={12} sm={12} md={7} lg={6} xl={5}>
          <Search
            onSearch={(value) =>
              setPaginator(
                paginatorMethods.setFilters(paginator, {
                  filterValue: value,
                }),
              )
            }
            placeholder='Buscar na tabela abaixo ...'
          />
        </Col>
        <Col
          sm={12}
          md='content'
          style={{
            marginTop: ['xs', 'sm'].includes(screenClass) ? '15px' : '0',
          }}
        >
          <Button
            variant='outlined'
            onClick={() => setShowFilter(true)}
            fullWidth={['xs', 'sm'].includes(screenClass)}
            startIcon={<FontAwesomeIcon icon={faFilter} />}
          >
            Filtrar
          </Button>
        </Col>
      </Row>

      <Row>
        <Col>
          <Table loading={isLoading}>
            <TableHead setPaginator={setPaginator} paginator={paginator} />
            <TableBody cards={cards} />
          </Table>
        </Col>
      </Row>
      <Row justify={['xs', 'sm'].includes(screenClass) ? 'center' : 'between'} align='center'>
        <Col xs={12} md='content' style={{ display: 'flex', justifyContent: 'center' }}>
          <Pagination.ResultAmount
            totalItems={cardsPagination?.totalItens}
            setPageSize={(newPageSize) => setPaginator(paginatorMethods.setPageSize(paginator, newPageSize))}
          />
        </Col>
        <Col
          xs='content'
          style={{
            marginTop: ['xs', 'sm'].includes(screenClass) ? '16px' : '0',
          }}
        >
          <Pagination
            paginator={paginator}
            setPage={(wantedPage) => setPaginator(paginatorMethods.setPage(paginator, wantedPage))}
          />
        </Col>
      </Row>
      <Filter
        open={showFilter}
        isLoading={loading}
        onClose={() => {
          setShowFilter(false)
          setSelectedIniDate(false)
          setMaxDate(dayjs(new Date()))
        }}
        onFilter={(value) => {
          const filterValues = Object.keys(value).reduce((final, actual) => {
            const newFinal = { ...final }
            if (value[actual].length !== 0 && value[actual] !== '') newFinal[actual] = value[actual]
            return newFinal
          }, {})
          setPaginator(paginatorMethods.setFilters(paginator, filterValues))
          setShowFilter(false)
        }}
        onClean={() => {
          setPaginator(paginatorMethods.resetFilters(paginator))
          setMaxDate(dayjs(new Date()))
          setSelectedIniDate(false)
        }}
        fields={[
          {
            type: 'multiSelect',
            name: 'BenefitType',
            props: {
              label: 'Benefícios',
              fullWidth: true,
              variant: 'contained',
              options: benefitTypes,
            },
          },
          {
            type: 'date',
            name: 'initDate',
            props: {
              label: 'Data Inicial',
              fullWidth: true,
              maxDate: dayjs(new Date()),
              setMaxDate,
              setSelectedIniDate,
            },
          },
          {
            type: 'date',
            name: 'endDate',
            rules: { required: selectedInitDate },
            props: {
              label: 'Data Final',
              fullWidth: true,
              maxDate: newMaxDate,
              minDate: maxDate,
              disabled: !selectedInitDate,
            },
          },
        ]}
      />
    </Container>
  )
}

export default Options
