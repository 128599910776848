import produce from 'immer'
import { ACTIONS } from './actions'

const INITIAL_STATE = {
  extracts: null,
  loading: null,
  failure: null,
}

export default function extracts(state = INITIAL_STATE, action) {
  const { type, payload } = action

  return produce(state, (draft) => {
    switch (type) {
      case ACTIONS.GET_EXTRACTS_REQUEST: {
        draft.extracts = null
        draft.failure = null
        draft.loading = true
        break
      }

      case ACTIONS.EXTRACTS_REQUEST_SUCCESS: {
        draft.loading = false
        draft.extracts = payload.data
        draft.failure = null
        break
      }

      case ACTIONS.EXTRACTS_REQUEST_FAILURE: {
        draft.loading = false
        draft.failure = payload.data
        break
      }

      default:
    }
  })
}
