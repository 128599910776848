import React from 'react'
import { ModalBody, ModalContainer, ModalFooter, TextConfirm } from '../styles'
import { Button, Icon } from 'presentation/components'
import 'react-responsive-modal/styles.css'
import { Modal } from 'react-responsive-modal'

const DiscardModal = ({ isOpen, onClose }) => {
  const closeIcon = <Icon name='x' />
  return (
    <Modal open={isOpen} center closeIcon={closeIcon} onClose={() => onClose(false)} styles={{ borderRadius: '5px' }}>
      <ModalContainer>
        <ModalBody>
          <TextConfirm>
            As informações inseridas serão descartadas, <br />
            deseja continuar?
          </TextConfirm>
        </ModalBody>
        <ModalFooter>
          <Button variant='outlined' width='250px' onClick={() => onClose(false)}>
            Não
          </Button>
          <Button width='250px' onClick={() => onClose(true)}>
            Sim, descartar
          </Button>
        </ModalFooter>
      </ModalContainer>
    </Modal>
  )
}

export default DiscardModal
