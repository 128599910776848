import React from 'react'
import { TButton } from 'shared/interfaces/button'
import { StyledButton } from 'presentation/components/atoms/button/styles'

const Button: React.FC<TButton> = ({
  startIcon,
  onClick,
  children,
  disabled = false,
  type = 'button',
  size = 'medium',
  variant = 'contained',
}) => {
  return (
    <StyledButton type={type} variant={variant} startIcon={startIcon} size={size} disabled={disabled} onClick={onClick}>
      {children}
    </StyledButton>
  )
}

export default Button
