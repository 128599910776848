import { styled } from '@mui/material'

export const Body = styled('div')(({ theme }) => ({}))

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  padding: '40px 0 40px 0',
  minHeight: `calc(100vh - (${theme.staticSize.header}px + ${theme.staticSize.footer}px))`,
  height: 'auto',
  position: 'relative',
  margin: ' 0 auto',
  overflow: 'hidden',
}))
