import { InputLabel, styled } from '@mui/material'

interface IPositionStyles {
  flexDirection: 'row' | 'row-reverse' | 'column' | 'column-reverse'
  justifyContent: 'flex-end' | 'flex-start' | 'center'
}

export const TriggerContainer = styled('span')<{ isCursorPointer: boolean }>(({ isCursorPointer }) => ({
  cursor: isCursorPointer ? 'pointer' : 'auto',
}))
