import { faX } from '@fortawesome/free-solid-svg-icons'
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  Icon,
  Typography,
} from '@stationkim/front-ui'
import { useEffect, useState } from 'react'

type TOption = { id: string; label: string }
interface ProjectionCheckDialogProps extends DialogProps {
  options: TOption[]
  onConfirm: (selected: TOption['id'][]) => void
  initialSelection?: TOption['id'][]
}

const CardOptions = ({
  option,
  onCheck,
  checked,
}: {
  option: TOption
  checked: boolean
  onCheck: (option: TOption) => void
}) => {
  return (
    <Box
      onClick={() => {
        onCheck(option)
      }}
      sx={(theme) => ({
        borderRadius: '8px',
        padding: '16px',
        border: `1px solid ${checked ? theme.palette.primary.main : theme.palette.grey[400]}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
        background: checked ? theme.palette.primary.main + '0D' : 'transparent',
        fontWeight: '600',
      })}
    >
      {option.label}
      <Checkbox checked={checked} />
    </Box>
  )
}

export const ProjectionCheckDialog = ({
  open,
  options,
  onClose,
  onConfirm,
  initialSelection = [],
}: ProjectionCheckDialogProps) => {
  const [selected, setSelected] = useState(initialSelection)

  const handleOptionCheck = (option: TOption) => {
    const optionIndex = selected.indexOf(option.id)
    if (optionIndex > -1) {
      setSelected((actualSelection) => {
        const newSelection = [...actualSelection]
        newSelection.splice(optionIndex, 1)
        return newSelection
      })
    } else setSelected([...selected, option.id])
  }

  useEffect(() => {
    setSelected(initialSelection)
  }, [open, initialSelection])

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
        Selecionar Operadoras
        <Icon icon={faX} onClick={(e) => onClose(e, 'escapeKeyDown')} />
      </DialogTitle>
      <DialogContent>
        <Typography variant='body2' sx={{ marginBottom: '16px ' }}>
          Selecione as operadoras para as quais você fará a projeção de saldo neste pedido.
        </Typography>
        <Grid container spacing='16px'>
          {options.map((option) => {
            return (
              <Grid item xs={12} sm={6} key={option.id}>
                <CardOptions option={option} onCheck={handleOptionCheck} checked={selected.includes(option.id)} />
              </Grid>
            )
          })}
        </Grid>
      </DialogContent>
      <DialogActions sx={{ marginTop: '16px ' }}>
        <Button
          variant='outlined'
          sx={{ filter: 'grayscale(100)' }}
          fullWidth
          onClick={(e) => onClose(e, 'escapeKeyDown')}
        >
          Cancelar
        </Button>
        <Button variant='contained' fullWidth onClick={() => onConfirm([...selected])}>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
