import styled from 'styled-components'

export const ExplanationText = styled.p`
  margin-top: 40px;
  line-height: 30px;
  letter-spacing: 0.29px;
  padding: 0 32px;

  + p {
    margin-top: 0px;
    margin-bottom: 40px;
  }
`
export const ContentDropzone = styled.div`
  padding: 0 32px;
`

export const ContentButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 32px;
`

export const ColorDest = styled.span`
  color: var(--color-primary-01);
  font-weight: bold;
`

export const Spacing = styled.div`
  margin-top: 36px;
`

export const ProgressPosition = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 700px;
`
