export function trackHistoryRequest(paginator) {
  return {
    type: '@trackHistory/TRACKHISTORY_REQUEST',
    payload: paginator,
  }
}

export function trackHistorySuccess(history) {
  return {
    type: '@trackHistory/TRACKHISTORY_SUCCESS',
    payload: { history },
  }
}

export function trackHistoryFailure() {
  return {
    type: '@trackHistory/TRACKHISTORY_ERROR',
  }
}
