import { all, takeLatest, put, call } from 'redux-saga/effects'
import { ACTIONS, extractsRequestSuccess, extractsRequestFailure } from './actions'
import { getEmplyeesUsageHistory } from '@/services/employeesServices'

function* fetchExtracts({ payload }) {
  const { response, error } = yield call(getEmplyeesUsageHistory, payload.query)
  if (response) yield put(extractsRequestSuccess(response.data))
  else yield put(extractsRequestFailure(error.response))
}

export default all([takeLatest(ACTIONS.GET_EXTRACTS_REQUEST, fetchExtracts)])
