import { styled } from '@mui/material'

export const PaymentFinished = styled('div')(() => ({
  p: {
    fontSize: 18,
    span: {
      fontWeight: 700,
      fontSize: 18,
    },
  },
  h5: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    fontSize: '23px',
    fontWeight: 500,
  },
  '.qrcode-finished': {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    width: 200,
    height: 200,
    'img:first-child': {
      position: 'absolute',
    },
    'img:last-child': {
      position: 'relative',
      zIndex: 10,
      margin: '0 auto',
    },
  },
}))
