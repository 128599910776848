import { Box } from 'presentation/components/atoms/box'
import { Typography } from 'presentation/components/atoms/typography'
import { Checkbox } from 'presentation/components/atoms'
import { Icon } from 'presentation/components/atoms/Icon'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { StyledCard, StyledCheckbox } from './styles'
import { useState } from 'react'

export const SelectableCard = ({ title, description, value, onChange }) => {
  const [internalValue, setInternalValue] = useState(false)

  const checked = value !== undefined ? value : internalValue
  const setChecked = value !== undefined ? onChange : setInternalValue

  const handleClick = () => {
    setChecked((value) => {
      if (onChange) onChange(value)
      return !value
    })
  }

  return (
    <StyledCard onClick={handleClick}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Typography variant='label2'>{title}</Typography>
        {description ? <Typography variant='text2'>{description}</Typography> : null}
      </Box>
      <Checkbox
        checked={checked}
        sx={{
          '&:hover': { bgcolor: 'transparent' },
        }}
        icon={<StyledCheckbox />}
        checkedIcon={
          <StyledCheckbox>
            {' '}
            <Icon icon={faCheck} />{' '}
          </StyledCheckbox>
        }
      />
    </StyledCard>
  )
}
