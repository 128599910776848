import { BREAK_POINTS } from '@/utils/constants'
import styled, { css } from 'styled-components'

export const Body = styled.div`
  padding: 20px;
`

export const Subtitle = styled.p`
  color: var(--color-primary-04);
  font-weight: bold;
  ${({ withMargin }) =>
    withMargin &&
    css`
      margin: 32px 0;
    `}
`

export const StatementIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  width: 28px;
  border-radius: 50%;
  background-color: #48f06b;
  margin-right: 16px;
  svg {
    min-height: 24px;
    min-width: 24px;
  }
`

export const BodyIcon = styled.div`
  background-color: #9a81cb;
  padding: 3px;
  border-radius: 50%;
  margin-top: -3px;
  margin-right: 10px;
  width: 26px;
  height: 27px;
`

export const Separator = styled.div`
  border-left: 2px solid var(--color-secundary-06);
  height: 24px;
`

export const Balance = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
  font-weight: var(--font-weight-bold);
  font-size: 21px;
`

export const BalanceValue = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 130px;
  height: 26px;
  margin-left: 16px;
  color: transparent;
  background-color: var(--color-secundary-05);
  border-radius: 4px;
  transition: all 0.26s ease-in-out;
  ${({ visible }) =>
    visible &&
    css`
      color: initial;
      background-color: transparent;
    `}
`

export const BalanceVisibilitySelector = styled.div`
  display: flex;
  align-items: center;
  margin-left: 16px;
  cursor: pointer;
`

export const BalanceTableFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: var(--font-weight-semi-bold);
`

export const LoadingWrapper = styled.div`
  text-align: center;
`

export const NoRegister = styled.p`
  margin: auto;
  text-align: center;
  color: var(--color-secundary-06);
`

export const DateWrapper = styled.div`
  display: inline-block;
  text-align: center;
  height: 100%;
  color: var(--color-primary-02);
`

export const Value = styled.div`
  text-align: right;
  color: ${({ value }) => (value < 0 ? 'var(--color-status-02)' : 'var(--color-primary-01)')};
  font-weight: var(--font-weight-medium);
  white-space: nowrap;
`

export const CustomTableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  max-height: 606px;
`

export const CustomTable = styled.table`
  border-collapse: collapse;
  width: 100%;

  th:last-of-type {
    text-align: right;
  }
`

export const CustomTr = styled.tr`
  height: 32px;
  ${({ colored }) =>
    colored &&
    css`
      background-color: var(--color-secundary-05);
    `}
  ${({ underlined }) =>
    underlined &&
    css`
      border-bottom: 1px solid var(--color-secundary-05);
    `}
  :last-child {
    position: sticky;
    bottom: 0;
    left: 0;
    td:last-child {
      padding: 0 24px;
      vertical-align: middle;
    }
  }
`

export const CustomTd = styled.td`
  padding: 16px 24px;
  vertical-align: ${({ header }) => (header ? 'top' : 'middle')};
`

export const OrderSelector = styled.span`
  display: inline-flex;
  flex-direction: column;
  margin-left: 8px;
  cursor: pointer;

  svg:nth-child(1) {
    margin-bottom: -4px;
  }
  cursor: pointer;
  svg:nth-child(2) {
    margin-top: -4px;
  }
`

export const SlipLink = styled.a`
  color: var(--color-primary-04);
  text-decoration: none;
`

export const FilterButton = styled.button`
  border: none;
  border-radius: 8px;
  padding: 8px 20px;
  cursor: pointer;
  transition: all 0.24s;
  background-color: ${({ active }) => (active ? '#48f06b' : '')};
  width: 100px;
  margin-top: 12px;

  & + & {
    margin-left: 8px;
  }

  @media (max-width: ${BREAK_POINTS.lg}) {
    padding: 16px 20px;
  }
`

export const ButtonWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: ${BREAK_POINTS.lg}) {
    margin-bottom: 24px;
  }
`
