import api from '@/infra/api'
import apiCallHandler from './apiCallHandler'
import axios from 'axios'

export const getFile = (query, token) => {
  return apiCallHandler(() =>
    axios.get(`${process.env.REACT_APP_API_HOST}/fileuploads-service/download${query}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  )
}
