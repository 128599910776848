export function companyDataStore(data) {
  return {
    type: '@company/STORE_COMPANY_DATA_REQUEST',
    payload: data,
  }
}

export function companyIdStore(data) {
  return {
    type: '@company/STORE_COMPANY_ID_REQUEST',
    payload: data,
  }
}

export function companyDataClear() {
  return {
    type: '@company/CLEAR_COMPANY_DATA_REQUEST',
  }
}

export function companyAddressesStore(data) {
  return {
    type: '@company/STORE_ADDRESS_REQUEST',
    payload: data,
  }
}

export function companyAddressesClear() {
  return {
    type: '@company/CLEAR_ADDRESS_REQUEST',
  }
}

export function companyContactsStore(data) {
  return {
    type: '@company/STORE_CONTACTS_REQUEST',
    payload: data,
  }
}

export function companyContactsClear() {
  return {
    type: '@company/CLEAR_CONTACTS_REQUEST',
  }
}

export function companyDocumentsStore(data) {
  return {
    type: '@company/STORE_DOCUMENTS_REQUEST',
    payload: data,
  }
}

export function companyDocumentsClear() {
  return {
    type: '@company/CLEAR_DOCUMENTS_REQUEST',
  }
}

export function sendToStore(data) {
  return {
    type: '@company/STORE_COMPANY_REQUEST',
    payload: data,
  }
}

export function companyClear() {
  return {
    type: '@company/CLEAR_REQUEST',
  }
}

export function companyStatusChange(data) {
  return {
    type: '@company/CHANGE_STATUS_REQUEST',
    payload: data,
  }
}
