import React from 'react'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  Typography,
} from 'presentation/components/atoms'

interface CancelDialogProps {
  open: boolean
  setOpen: (isOpen: boolean) => void
  cancelOrder: () => Promise<void>
}

export const CancelDialog = ({ open, setOpen, cancelOrder }: CancelDialogProps) => {
  return (
    <Dialog open={open} maxWidth='md'>
      <Icon icon={faExclamationTriangle} sx={(theme: any) => ({ color: theme.palette.error.main, height: '42px' })} />
      <DialogTitle
        sx={(theme: any) => ({
          margin: '32px auto',
          color: theme.palette.grey[700],
        })}
      >
        Cancelar Pedido
      </DialogTitle>
      <DialogContent>
        <Typography>
          Ao cancelar este pedido, todas as informações serão perdidas.
          <br />
          Deseja continuar?
        </Typography>
        <DialogActions sx={{ justifyContent: 'space-between', gap: '16px', marginTop: '32px' }}>
          <Button
            variant='outlined'
            sx={{ filter: 'grayscale(100%)', flex: 1 }}
            onClick={async () => {
              setOpen(false)
            }}
          >
            Cancelar
          </Button>
          <Button variant='outlined' color='error' sx={{ flex: 1 }} onClick={async () => await cancelOrder()}>
            Confirmar
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}
