import _ from 'lodash'
import { Box } from '@/presentation/components/atoms'
import { Radio, SxProps } from '@mui/material'

interface ReportFormatSelectorProps {
  availableFormatsOptions: Array<{
    label: string
    value: string
  }>
  onChange: (value: string) => void
  value: string
  customRadio?: any
  sx?: SxProps
}

export const ReportFormatSelector = ({
  value,
  onChange,
  availableFormatsOptions,
  customRadio,
  sx,
}: ReportFormatSelectorProps) => {
  const RadioToBeUsed = customRadio ? customRadio : Radio

  const renderAvailableFormats = () => {
    return availableFormatsOptions.map((formatOptions) => {
      return (
        <Box
          sx={{ display: 'flex', gap: '8px', alignItems: 'center', ...sx }}
          key={_.uniqueId()}
          onClick={() => onChange(formatOptions.value)}
        >
          <RadioToBeUsed disableRipple checked={value === formatOptions.value} controlled />
          <span style={{ whiteSpace: 'nowrap' }}> {formatOptions.label}</span>
        </Box>
      )
    })
  }
  return <Box sx={{ display: 'flex', gap: '16px' }}>{renderAvailableFormats()}</Box>
}
