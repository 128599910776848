import { BREAK_POINTS } from '@/utils/constants'
import styled from 'styled-components'

export const FloatingInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 94px;
  width: 314px;
  padding: 24px 27px;
  border-radius: 10px;
  color: var(--color-primary-05);
  background-color: ${({ color }) => color};
  margin: 53px 0 63px 94px;

  span:first-child {
    max-width: 170px;
  }

  span:last-child {
    font-size: 2.25rem;
    font-weight: var(--font-weight-semi-bold);
  }

  @media (max-width: ${BREAK_POINTS.xl}) {
    margin: 53px 0 63px 0;
  }

  @media (max-width: ${BREAK_POINTS.md}) {
    margin: 20px auto;
  }
`

export const ButtonWrapper = styled.div`
  max-width: 314px;
  padding: 0 32px 0 0;
  width: 100%;
  margin-left: auto;

  @media (max-width: ${BREAK_POINTS.md}) {
    padding: 0;
    margin: auto;
    margin-top: 24px;
  }
`

export const PaginatorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 54px;
  margin: 24px auto;
`

export const LoadingWrapper = styled.div`
  padding: 64px;
  text-align: center;
`
