import React from 'react'
import { Panel, Spacing, Title } from 'presentation/components'
import { Container, ConfirmationIcon } from './styles'

const MigrationRequestConfimartion = () => {
  return (
    <Panel>
      <Container>
        <Spacing top='100px' />
        <ConfirmationIcon name='check-circle' />
        <Spacing top='64px' />

        <Title.SubTitle>Migração de cadastro solicitada!</Title.SubTitle>
        <Spacing top='56px' />

        <p>
          A migração de cadastro da sua empresa será analisada e confirmada pelo email do cadastro.
          <br />
          <br />
          Você receberá um link para seu primeiro acesso neste mesmo email.
        </p>
      </Container>
    </Panel>
  )
}

export default MigrationRequestConfimartion
