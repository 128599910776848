import React, { useState, forwardRef } from 'react'
import { Slider, ToggleContainer } from './styles'

const Toggle = forwardRef(({ activeText, inactiveText, active, controlled = false, ...props }, ref) => {
  const [isActive, setIsActive] = useState(active)
  const value = controlled ? active : isActive

  function handleOnChange() {
    if (controlled) return
    setIsActive(!isActive)
  }

  return (
    <ToggleContainer className='toggle'>
      <input ref={ref} type='checkbox' checked={value} onChange={handleOnChange} {...props} />
      <Slider>{value ? activeText : inactiveText}</Slider>
    </ToggleContainer>
  )
})

export default Toggle
