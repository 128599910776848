import { Divider } from 'presentation/components/atoms/divider'
import { Drawer } from 'presentation/components/atoms/drawer'
import { Icon } from 'presentation/components/atoms/Icon'
import { Typography } from 'presentation/components/atoms/typography'
import { StyledNavLink } from './styles'
import { Fragment } from 'react'

export const SideNavigationMenu = ({ destinations = [], open, onClose, ...props }) => {
  return (
    <Drawer anchor='left' open={open} onClose={onClose}>
      {destinations.map((destination, index) => {
        return (
          <Fragment key={`${index}_sideMenuItem`}>
            <StyledNavLink
              id={`navigation_${destination.pathname.replace('/', '')}`}
              to={destination.pathname}
              exact
              onClick={onClose}
            >
              <Icon icon={destination.icon} />
              <Typography>{destination.displayName}</Typography>
            </StyledNavLink>
            {index !== 0 ? null : <Divider />}
          </Fragment>
        )
      })}
    </Drawer>
  )
}
