import { styled } from '@mui/material'

export const Container = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: 40,
  backgroundColor: theme.palette.success.dark,
  borderRadius: 4,
  marginBottom: 32,
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  padding: '0 16px',
  p: {
    fontSize: 16,
    fontWeight: 700,
    color: '#fff',
  },
  'img:last-child': {
    position: 'absolute',
    right: 16,
    cursor: 'pointer',
  },
}))
