import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  min-height: 645px;
`

export const Categories = styled.ul`
  width: min(315px, 45%);
  padding: 32px 0;
  position: relative;
  border-right: 1px solid var(--color-secundary-05);
`

export const Category = styled.li`
  height: 45px;
  font-size: 1.25rem;
  padding-left: 32px;

  display: flex;
  align-items: center;
  cursor: pointer;

  ${({ active }) =>
    active &&
    `background-color: var(--color-secundary-07);
     font-weight: var(--font-weight-semi-bold);
     color: var(--color-primary-05);`}
`

export const ReportsList = styled.ul`
  width: 100%;
  padding: 32px 0;
  position: relative;
  border-right: 1px solid var(--color-secundary-05);
`

export const Group = styled.div``

export const SubTitle = styled.div`
  height: 45px;
  font-size: 1.25rem;
  padding-left: 32px;
  font-weight: var(--font-weight-bold);
  border: none;
  cursor: initial;
`
