import { useState, useEffect } from 'react'
import { QRCodeSVG } from 'qrcode.react'

import { Divider, Button, Box, Tooltip } from 'presentation/components/atoms'
import { formatMoney } from 'utils/functionUtils'

import copy from '@/presentation/assets/icons/copy.png'
import loadingCopy from '@/presentation/assets/icons/spinner-pix-copy.svg'
import qrcodeFinished from '@/presentation/assets/icons/qrcode-finished.svg'
import checkCircleGreen from '@/presentation/assets/icons/check-circle-green.svg'
import loadingPix from '@/presentation/assets/icons/loading-pix.png'

import { Details, Steps, Total, Clipboard, PaymentFinished } from './styles'
import { usePixCart } from '@/main/hooks/usePixCart'

interface IPix {
  orderId: string
  totalValue: number
}

export const Pix: React.FC<IPix> = ({ orderId, totalValue }) => {
  const { data, loading } = usePixCart({ orderId })

  const [isCopyTipOpen, setIsCopyTipOpen] = useState(false)

  const copyText = () => {
    navigator.clipboard.writeText(data?.pix?.qrCode)
    const element: any = document.querySelector('#rawPix')
    element.select()
  }

  useEffect(() => {
    setTimeout(() => {
      setIsCopyTipOpen(false)
    }, 1000)
  }, [isCopyTipOpen])

  const isLoading = !data?.pix?.qrCode || loading

  const paymentFinished = data?.status > 3 || totalValue === 0

  return (
    <Details>
      {paymentFinished ? (
        <PaymentFinished>
          <h5>
            Pagamento confirmado <img src={checkCircleGreen} height={20} width={20} alt='check circle green' />
          </h5>
          <Box sx={{ margin: '24px 0' }}>
            <p>
              Valor: <span>R$ {formatMoney(totalValue)}</span>
            </p>
          </Box>
          <Box className='qrcode-finished'>
            <img src={qrcodeFinished} alt='qrcode' />
            <img src={checkCircleGreen} alt='confirmed pix' />
          </Box>
        </PaymentFinished>
      ) : (
        <>
          {isLoading ? (
            <h5>Aguarde, gerando QRCode...</h5>
          ) : (
            <>
              <h4>Escaneie o código para efetuar o pagamento</h4>
              <Steps>
                <li>1. Acesse o app de pagamentos</li>
                <li>2. Escolha pagamento via PIX</li>
                <li>3. Escaneie o código:</li>
              </Steps>
            </>
          )}
          <Total>
            Valor: <span>R$ {formatMoney(totalValue)}</span>
          </Total>
          <Box className='qrcode-finished'>
            {isLoading ? (
              <>
                <img src={qrcodeFinished} alt='qrcode' />
                <img src={loadingPix} alt='loading pix' className='loading' />
              </>
            ) : (
              <QRCodeSVG width={200} height={200} value={data?.pix?.qrCode} />
            )}
          </Box>
          <Divider sx={{ margin: '32px 0' }} />
          <h5>Ou copie o código QR para efetuar o pagamento</h5>
          <p>Escolha o pagamento via PIX no seu app de pagamentos. Cole o seguinte código no campo informado:</p>
          <Clipboard>
            <input
              readOnly
              value={isLoading ? 'Aguarde até que o código seja gerado...' : data?.pix?.qrCode}
              id='rawPix'
            />
            {isLoading ? (
              <img src={loadingCopy} alt='loading-copy' className='loading' />
            ) : (
              <Tooltip
                title='Código copiado'
                placement='top'
                disableFocusListener
                disableHoverListener
                disableTouchListener
                open={isCopyTipOpen}
              >
                <img
                  src={copy}
                  alt='copy'
                  onClick={() => {
                    copyText()
                    setIsCopyTipOpen(true)
                  }}
                />
              </Tooltip>
            )}
          </Clipboard>
          {!isLoading ? (
            <Button
              variant='contained'
              onClick={() => {
                copyText()
                setIsCopyTipOpen(true)
              }}
            >
              Copiar código
            </Button>
          ) : null}
        </>
      )}
    </Details>
  )
}
