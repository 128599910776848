import { useState } from 'react'

export const useList = <T>() => {
  const [list, setList] = useState<Array<T>>([])

  const add = ({ value }: { value: T }) => {
    setList((state) => [...state, value])
  }

  const remove = ({ value }: { value: T }) => {
    setList((state) => {
      const newList = [...state]
      const index = newList.findIndex((item) => item === value)
      newList.splice(index, 1)
      return newList
    })
  }

  return {
    list,
    add,
    remove,
  }
}
