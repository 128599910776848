import { ReactNode } from 'react'
import { PageTitle, Paper } from '@stationkim/front-ui'
import { Content } from '@/presentation/components'

export const BrokerHOC = ({ children }: { children: ReactNode }) => {
  return (
    <Content>
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          transition: 'all .5s ease-in',
          overflow: 'hidden',
        }}
        noPadding
      >
        <PageTitle pageTitle='Pedido de Recarga Broker' />
        {children}
      </Paper>
    </Content>
  )
}
