import { styled } from '@mui/material'
import { Stepper } from 'presentation/components/atoms'

export const StyledStepper = styled(Stepper)<{ statusColor: string }>(({ statusColor }) => ({
  '& .Mui-active svg': {
    color: statusColor,
  },
  '& .MuiStep-root:last-of-type .Mui-completed svg, & .Mui-active svg': {
    color: statusColor,
  },
}))
