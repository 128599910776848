import { Select, styled } from '@mui/material'
import { colord } from 'colord'
import { Box } from 'presentation/components/atoms'

export const StyledSelect = styled(Select)(({ blocked, theme }) => {
  const blockedStyle = blocked
    ? {
        backgroundColor: theme.palette.grey[200],
        '& fieldset': {
          border: 'none',
        },
        '& .MuiSelect-select': {
          cursor: 'not-allowed',
        },
      }
    : {}
  return {
    height: '48px',
    borderRadius: '8px',
    ...blockedStyle,
  }
})

export const StyledBox = styled(Box)(({ theme, error, success, full }) => {
  const newColors =
    error || success
      ? {
          '& p, & label': {
            color: error ? theme.palette.error.main : success ? theme.palette.success.main : 'initial',
          },
          '& .MuiSelect-select ~ fieldset': {
            borderColor: error
              ? colord(theme.palette.error.main).alpha(0.3).toHex()
              : success
              ? colord(theme.palette.success.main).alpha(0.3).toHex()
              : 'initial',
          },
          '& .MuiInputBase-root:hover  fieldset, & .MuiInputBase-root .MuiOutlinedInput-notchedOutline': {
            borderColor: error ? theme.palette.error.main : success ? theme.palette.success.main : 'initial',
          },
        }
      : {}
  return {
    width: full ? '100%' : 'auto',
    '& label': {
      marginBottom: 8,
    },
    '& p': {
      marginTop: 8,
    },
    ...newColors,
  }
})
