import { useRef } from 'react'
import {
  Button,
  ErrorMessage,
  ErrorWrapper,
  GradientText,
  Icon,
  Input,
  Notification,
  Spacing,
  Spinner,
  TextDecoration,
} from 'presentation/components'
import { Main, Body, Content, Title, Label, EmailSended, EmailSendedText } from './styles'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import schema from './schema'
import { NavLink } from 'react-router-dom'
import useService from 'main/hooks/useService'
import { postRequestResetPassword } from 'services/authServices'
import { getResaleSiteFromUrl } from 'utils/functionUtils'

const ForgotPassword = () => {
  const isEmailSended = useRef(false)

  const [resetPasswordState, resetPasswordRequest] = useService(postRequestResetPassword, {
    onCompleted: () => {
      isEmailSended.current = true
    },
  })
  const loading = resetPasswordState.loading

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    shouldUnregister: true,
  })

  const onSubmit = (data) => {
    resetPasswordRequest({ email: data.email, site: getResaleSiteFromUrl() })
  }

  return (
    <>
      <Main>
        <Notification style={{ top: '10px' }} />
        <Body>
          {!isEmailSended.current ? (
            <Content>
              <Title>Recupere sua senha</Title>
              {loading ? (
                <Spinner.Box>
                  <Spinner />
                </Spinner.Box>
              ) : (
                <>
                  <p>Você receberá um link por email para cadastrar uma nova senha.</p>
                  <Spacing top='40px' />
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <ErrorWrapper error={errors.email}>
                      <Label>E-mail</Label>
                      <Input placeholder='Digite seu email' maxLength={255} {...register('email')} />
                      {errors.email && <ErrorMessage>{errors.email.message}</ErrorMessage>}
                    </ErrorWrapper>
                    <Spacing top='40px' />
                    <Button disabled={!isValid}>Recuperar senha</Button>
                  </form>
                  <Spacing top='96px' />
                  <NavLink to=''>
                    <TextDecoration variant='underlined'>Acessar minha conta</TextDecoration>
                  </NavLink>
                </>
              )}
            </Content>
          ) : (
            <EmailSended>
              <Icon name='email-outlined' />
              <GradientText>Link enviado</GradientText>
              <EmailSendedText>
                Acesse seu email de cadastro e entre no link enviado para cadastrar uma nova senha.
              </EmailSendedText>
            </EmailSended>
          )}
        </Body>
      </Main>
    </>
  )
}

export default ForgotPassword
