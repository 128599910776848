const ACTION = '@globalState/'

export const ACTIONS = {
  SET_GLOBAL_STATE: ACTION + 'SET_GLOBAL_STATE',
  DELETE_GLOBAL_STATE: ACTION + 'DELETE_GLOBAL_STATE',
}

export function setGlobalState(atributeName, value) {
  return {
    type: ACTIONS.SET_GLOBAL_STATE,
    payload: { atributeName, value },
  }
}

export function deleteGlobalState(atributeName) {
  return {
    type: ACTIONS.DELETE_GLOBAL_STATE,
    payload: { atributeName },
  }
}
