import styled from 'styled-components'
import Icon from 'presentation/components/icon'

export const ActionIcon = styled(Icon)`
  cursor: pointer;
  height: 16px;
  width: 16px;

  :hover {
    fill: var(--color-primary-01);
  }
`

export const ActionIconWrapper = styled.div`
  display: inline-grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 8px;
  width: 40px;
  > div {
    height: 20px;
    width: 16px;
  }
`

export const Wrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const TextConfirm = styled.p`
  text-align: center;
  font-size: 22px;
  padding: 50px 0;
`

export const ColorDest = styled.span`
  color: #632ecb;
  font-weight: bold;
`

export const SessionButton = styled.div`
  display: flex;
  justify-content: center;
  width: 65%;
  margin: 0 auto;
  padding-bottom: 20px;
`

export const Close = styled.p`
  cursor: pointer;
  text-align: right;
  font-size: 20px;
`

export const StatusDot = styled.div`
  display: inline-block;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  margin-right: 8px;

  ${({ status }) => (status ? 'background-color: var(--color-status-01)' : 'background-color: var(--color-status-06)')}
`
