import { styled } from '@mui/material'
import { Box, Typography } from '@stationkim/front-ui'

export const SummaryContainer = styled(Box)(({ theme }) => ({
  height: '64px',
  width: '100%',
  border: '1px solid ' + theme.palette.grey[300],
  borderRadius: '8px',
  padding: '8px',
  display: 'flex',
  gap: '8px',
  overflow: 'hidden',
}))

export const IconContainer = styled(Box)(({ theme }) => ({
  height: '48px',
  width: '48px',
  borderRadius: '50%',
  backgroundColor: theme.palette.grey[50],
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& svg': {
    width: '32px',
    color: theme.palette.grey[700],
  },
}))

export const DataContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: '8px',
  marginTop: '-2px',
  whiteSpace: 'nowrap',
}))

export const OrderNumber = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  '& span': {
    textDecoration: 'underline',
    fontStyle: 'italic',
  },
}))

export const OrderValue = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  marginLeft: 'auto',
  textAlign: 'right',
  minWidth: '120px',
  marginTop: '-2px',
  '& small': {
    fontSize: '0.75rem',
  },
}))
