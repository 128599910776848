import { useState } from 'react'
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { Popover } from '../../../../styles'

const IconWithPopoverBenefit: React.FC<{
  icon: FontAwesomeIconProps['icon']
  title: string
  listItems: Array<string>
  color: string
}> = ({ icon, title, listItems, color }) => {
  const [showPopover, setShowPopover] = useState(false)

  const handleMouseEnter = () => {
    setShowPopover(true)
  }

  const handleMouseLeave = () => {
    setShowPopover(false)
  }

  return (
    <Popover onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <FontAwesomeIcon icon={icon} color={color} />
      {showPopover && (
        <div className='popover'>
          <h4>{title}</h4>
          <ul>
            {listItems?.map((item, index) => (
              <li key={`item-${item}`}>{item}</li>
            ))}
          </ul>
        </div>
      )}
    </Popover>
  )
}

export default IconWithPopoverBenefit
