import styled, { css } from 'styled-components'

export const CustomTable = styled.table`
  border-collapse: collapse;
  width: 100%;
`

export const TableContainer = styled.div`
  position: relative;
  z-index: 100;

  width: 100%;
  ${({ overflow }) =>
    overflow &&
    css`
      overflow: ${overflow} !important;
    `}
  overflow: auto;
  ${({ maxHeight }) =>
    maxHeight &&
    css`
      max-height: ${maxHeight || 'auto'};
    `}
`
