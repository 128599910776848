import * as yup from 'yup'

const checkIfPhoneIsCompleate = (value) => {
  return value.replace(/\(|\)| |-/g, '').length >= 10
}

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const schema = yup.object().shape(
  {
    abortEarly: false,
    registration: yup
      .string()
      .max(15)
      .test('only-letters-digits', 'Apenas números e letras', (value) => !value.match(/[^0-9|a-z]/gi))
      .required('Matrícula é um campo requerido'),
    workingDays: yup
      .number()
      .typeError('Apenas números')
      .min(1, 'Mínimo de 1 dia.')
      .max(31, 'Máximo de 31 dias')
      .transform((curr, orig) => (orig === '' ? null : curr))
      .nullable(),
    cellphoneNumber: yup
      .string()
      .nullable()
      .when('cellphoneNumber', {
        is: (value) => value?.length,
        then: yup.string().required().test('is-phone-complete', 'Insira o telefone completo', checkIfPhoneIsCompleate),
      }),
    secondCellphoneNumber: yup
      .string()
      .nullable()
      .when('secondCellphoneNumber', {
        is: (value) => value?.length,
        then: yup.string().required().test('is-phone-complete', 'Insira o telefone completo', checkIfPhoneIsCompleate),
      }),
    email: yup
      .string()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .nullable()
      .matches(EMAIL_REGEX, 'Insira um e-mail válido'),
    job: yup.string().nullable().max(50, 'Campo limitado a 50 caracteres'),
  },
  [
    ['cellphoneNumber', 'cellphoneNumber'],
    ['secondCellphoneNumber', 'secondCellphoneNumber'],
  ],
)

export default schema
