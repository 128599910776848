import { DialogTitle, styled } from '@mui/material'

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  fontSize: '1.5rem',
  lineHeight: '2rem',
  fontWeight: 700,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: 0,
  '& h2': {},
  '& svg': {
    height: 18,
    width: 18,
    color: theme.palette.common.light,
    cursor: 'pointer',
  },
}))
