import * as yup from 'yup'
const schema = yup.object().shape({
  password: yup
    .string()
    .required('Esse é um campo requerido')
    .min(6, 'Mínimo de 6 caracteres')
    .max(100, 'Máximo de 100 caracteres')
    .test('atleast-one-lower', 'A senha deve ter ao menos uma letra minúscula', (value) => value.match(/[a-z]/))
    .test('atleast-one-upper', 'A senha deve ter ao menos uma letra maiúscula', (value) => value.match(/[A-Z]/))
    .test('atleast-one-upper', 'A senha deve ter ao menos um número', (value) => value.match(/[0-9]/))
    .test(
      'atleast-one-special',
      'A senha deve ter ao menos um caractere especial', // eslint-disable-next-line
      (value) => value.match(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/),
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'As senhas não estão iguais')
    .required('Esse é um campo requerido'),
})

export default schema
