import React, { useMemo, useState } from 'react'
import { Container, Col } from 'react-grid-system'
import { Button, Dropzone, Input, Spacing, Spinner, Title } from 'presentation/components'
import { Fieldset, TextDecoration, Row } from './style'
import { sendDocument } from '../requestApi'
import ErrorModal from '../ErrorModal'
import fileSize from 'filesize'
import axios from 'axios'
import Modal from '../../modal'
import { useDispatch } from 'react-redux'
import { companyDocumentsClear } from '@/store/modules/empresa/actions'
import { FORM_MODE, TEMP_CONTEXT_ID, FIVE_MB } from '@/utils/constants'

import { getFile } from '@/services/filesServices'
import { FILE_CONTEXT, FILE_KIND, FILE_SUBCONTEXT } from '@/utils/constants'
import useService from 'main/hooks/useService'
import { pushNotification } from '@/store/modules/notification/actions'
import { download } from '@/utils/functionUtils'
import { useLocation, useNavigate } from 'react-router-dom'

const DOCUMENT_NAME = [
  { name: '1. Contrato social' },
  { name: '2. Cartão CNPJ' },
  { name: '3. CAGED ', info: '(Acima de 20 funcionários)' },
  { name: '4. Formulário ISSQN', info: '(assinado por representante legal)' },
]

const Documents = ({ changeForm, controlNavigation, done, mode, nextTab, prevTab, saveOnStore, companyData }) => {
  const [documents, setDocuments] = useState(new Array(4).fill(null))
  const [cancelModal, setCancelModal] = useState(false)
  const [agree, setAgree] = useState(false)
  const [error, setError] = useState(null)
  const [stillUploadingError, setStillUploadingError] = useState(false)
  const dispatch = useDispatch()
  const {
    state: { token },
  } = useLocation()
  const { navigate } = useNavigate()

  const [downloadTermState, downloadTermRequest] = useService(getFile, {
    onCompleted: (response) => {
      if (response.data?.length === 0)
        dispatch(
          pushNotification({
            type: 'error',
            content: response.data.message,
          }),
        )
      else download('data:application/pdf;base64,' + response.data.content, 'Termo de Adesão.pdf')
    },
  })

  const downloadTerm = () => {
    downloadTermRequest(
      `?context=${FILE_CONTEXT.RESALE}&subcontext=${FILE_SUBCONTEXT.TERM}&kind=${FILE_KIND.TERM}&ContextId=${TEMP_CONTEXT_ID}`,
      token,
    )
  }

  const readOnly = mode === FORM_MODE.VIEW ? true : false
  function handleOnDrop(e, type) {
    const cancel = axios.CancelToken.source()
    setDocuments((state) =>
      state.map((file, index) =>
        index === type - 1 ? { file: e[0], uploaded: 0, uploading: true, cancelToken: cancel } : file,
      ),
    )
    sendDocument(
      {
        id: companyData.id,
        type: type,
        file: e[0],
      },
      cancel,
      setDocuments,
      token,
    )
  }

  function analyseError(file) {
    const errorMessage =
      file.errors[0].code === 'file-invalid-type'
        ? 'Arquivo com extensão não permitida!'
        : 'Não foi possível fazer upload, seu arquivo ultrapassa o tamanho de 10mb'
    setError(errorMessage)
  }

  function generateDropLine() {
    return documents.map((file, index) => (
      <React.Fragment key={'doc_' + index}>
        {index !== 0 && <Spacing top='16px' />}
        <Dropzone.Line
          accept='.pdf, .jpg'
          formatsLabel='PDF, JPG'
          onDrop={(e) => {
            handleOnDrop(e, index + 1)
          }}
          onDropRejected={(dropedFile) => analyseError(dropedFile[0])}
          maxSize={FIVE_MB}
          multiple={false}
          disabled={!!file?.file?.name}
          setError={setError}
          cancelUpload={() => {
            file?.cancelToken?.cancel('cancel')
            setDocuments((state) => state.map((actualFile, i) => (i === index ? {} : actualFile)))
          }}
          error={file?.error}
          exclude={() => {
            setDocuments((state) => state.map((actualFile, i) => (i === index ? {} : actualFile)))
          }}
          uploadingFile={{
            name: file?.file?.name,
            readeableSize: fileSize(file?.file?.size || 0),
            percentage: file?.uploaded,
            uploading: file?.uploading,
          }}
        >
          <strong>{DOCUMENT_NAME[index].name}</strong>
        </Dropzone.Line>
      </React.Fragment>
    ))
  }

  useMemo(() => {
    if (companyData?.documents?.lenght <= 0) return
    setDocuments((state) =>
      state.map((file, index) => {
        const targetDocument = companyData?.documents?.find((document) => document?.documentType === index + 1)
        return {
          file: { name: targetDocument?.fileName },
          id: targetDocument?.id,
        }
      }),
    )
  }, [companyData])

  useMemo(() => {
    controlNavigation(true)
  }, [controlNavigation])

  changeForm(() => {
    const anyUploading = documents.find((file) => file.uploading)
    if (anyUploading) {
      setStillUploadingError(true)
      controlNavigation(false)
      return
    } else controlNavigation(true)
    saveOnStore(documents)
  })

  function confirmCancel() {
    setCancelModal(false)
    documents.forEach((file) => file?.cancelToken?.cancel())
    dispatch(companyDocumentsClear())
    navigate('/')
  }

  function handleConcludeClick() {
    if (!agree) {
      setError(
        <>
          Você precisa aceitar os{' '}
          {downloadTermState.loading ? (
            <Spinner variant='sm' />
          ) : (
            <TextDecoration onClick={downloadTerm}>Termo de Adesão.</TextDecoration>
          )}{' '}
          para concluir o cadastro.
        </>,
      )
      return
    }
    done()
  }

  return (
    <>
      <Fieldset disabled={readOnly}>
        <Container style={{ margin: 0, padding: 0, maxWidth: 'initial' }}>
          <Title.SubTitle>Envio de documentos</Title.SubTitle>
          <Spacing top='8px' />
          <p>
            Precisamos de alguns documentos para finalizar o cadastro. Os documentos serão validados e você receberá uma
            confirmação por e-mail.
          </p>
          <Spacing top='24px' />
          {generateDropLine()}
          <Row>
            <Col sm={12}>
              <Input.CheckBox controlled checked={agree} onClick={() => setAgree(!agree)} />
              <span style={{ marginLeft: '16px' }}>
                Li e aceito os termos do &nbsp;
                {downloadTermState.loading ? (
                  <Spinner variant='sm' />
                ) : (
                  <TextDecoration onClick={downloadTerm}>Termo de Adesão.</TextDecoration>
                )}
              </span>
            </Col>
          </Row>
        </Container>
      </Fieldset>
      <Row justify='end' gutterWidth={24} style={{ marginTop: '3vh' }}>
        <Col sm={2}>
          {mode === FORM_MODE.VIEW ? (
            <Button variant='outlined' onClick={prevTab}>
              Voltar
            </Button>
          ) : (
            <Button variant='outlined' onClick={() => setCancelModal(true)}>
              Cancelar
            </Button>
          )}
        </Col>
        <Col sm={2}>
          {mode === FORM_MODE.VIEW ? (
            <Button type='button' onClick={nextTab}>
              Próximo
            </Button>
          ) : (
            <Button type='submit' form='documents' onClick={handleConcludeClick}>
              Concluir
            </Button>
          )}
        </Col>
      </Row>
      {!!error && (
        <ErrorModal open={error} cancel={() => setError(null)}>
          {error}
        </ErrorModal>
      )}
      {!!stillUploadingError && (
        <ErrorModal open={stillUploadingError} cancel={() => setStillUploadingError(null)}>
          Ainda tem arquivos sendo enviados para o servidor, cancele ou aguarde.
        </ErrorModal>
      )}
      {cancelModal && (
        <Modal
          message='Atenção! Os documentos em processo de envio serão perdidos e sua aprovação pode ser comprometida. Deseja sair da página?'
          open={cancelModal}
          confirm={confirmCancel}
          cancel={() => setCancelModal(false)}
        />
      )}
    </>
  )
}
export default Documents
