import { TableCell, TableRow, TableHead as Head } from 'presentation/components/atoms'

import paginatorMethods from '@/utils/paginatorMethods'
import { OrderIcon } from 'presentation/components'

import { Wrapper } from '../../../../styles'

export const TableHead = ({ setPaginator, paginator }) => {
  return (
    <Head>
      <TableRow>
        <TableCell>Data Bloqueio</TableCell>
        <TableCell>Nome Cartão</TableCell>
        <TableCell>
          <Wrapper>
            Número Cartão
            <OrderIcon
              clickUp={() => setPaginator(paginatorMethods.setOrder(paginator, 'number'))}
              clickDown={() => setPaginator(paginatorMethods.setOrder(paginator, 'number', false))}
            />
          </Wrapper>
        </TableCell>
        <TableCell>Colaborador</TableCell>
        <TableCell>Matrícula Operadora</TableCell>
        <TableCell>Benefício</TableCell>
        <TableCell>Saldo</TableCell>
      </TableRow>
    </Head>
  )
}
