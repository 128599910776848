import { InputBase, styled } from '@mui/material'
import { InputBaseProps } from './inputBase'

export const StyledInputBase = styled(InputBase)<InputBaseProps>(({ blocked, disabled, theme, error, success }) => {
  const blockedStyle = blocked
    ? {
        backgroundColor: theme.palette.grey[200],
        border: 'none',
      }
    : {}
  const statusColor = error ? theme.palette.error.main : success ? theme.palette.success.main : theme.palette.grey[400]
  return {
    display: 'flex',
    alignItems: 'center',
    padding: '12px 8px',
    gap: 8,
    height: '48px',
    border: '1px solid ' + statusColor,
    borderRadius: 8,
    backgroundColor: disabled ? theme.palette.grey[100] : theme.palette.common.white,
    color: theme.palette.grey[700],
    '& svg': {
      color: theme.palette.grey[500],
    },
    '& input': {
      border: 'none',
      fontSize: 16,
      color: blocked ? theme.palette.grey[400] : theme.palette.grey[700],
      padding: 0,
      width: '100%',
    },
    '& input:disabled': {
      backgroundColor: theme.palette.grey[100],
    },
    ...blockedStyle,
  }
})
