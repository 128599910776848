import { useMutation, useQueryClient } from '@tanstack/react-query'
import api from '@/infra/api'

export const useConfirmCart = ({ onSuccess }: { onSuccess: () => void }) => {
  const queryClient = useQueryClient()
  const confirmCart = useMutation({
    mutationFn: ({ payload }: { payload: object }) => {
      return api.patch(`rh-bff/basket/ConfirmBasket`, payload)
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['cart'])
      onSuccess()
    },
  })

  return { confirmCart }
}
