import { useState } from 'react'

export const usePaginationOld = ({ query = {} }: { query: any }) => {
  const [paginator, setPaginator] = useState({
    page: 1,
    pageSize: 10,
    query,
    totalItens: 0,
    totalPages: 0,
    doRequest: true,
  })

  return {
    paginator,
    setPaginator,
  }
}
