import { deepmerge } from '@mui/utils'
import { createTheme, ThemeProvider as MUIThemeProvider } from '@mui/material'
import { useAtom } from 'jotai/react'
import { GlobalThemePersistentStore } from '@/main/store/globalTheme'
import { defaultTheme } from '@/config/themes/defaultTheme'

export const ThemeProvider = ({ children }) => {
  const [globalTheme] = useAtom(GlobalThemePersistentStore)
  const hasCustomTheme = Boolean(globalTheme?.palette?.brand)
  const theme = hasCustomTheme
    ? createTheme(deepmerge(defaultTheme, { palette: { brand: globalTheme?.palette?.brand } }))
    : defaultTheme
  return <MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>
}
