import * as yup from 'yup'

const compositeNameRegex =
  /^[0-9A-Za-zªºáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ'\s]{1,}\s[0-9A-Za-zªºáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ'\s]{1,}/

const schema = yup.object().shape({
  abortEarly: false,
  addressDescript: yup
    .string()
    .matches(compositeNameRegex, {
      message: 'O endereço deve ser composto',
      excludeEmptyString: true,
    })
    .nullable()
    .max(120),
  numberAddress: yup.string().nullable().max(5),
  complement: yup.string().nullable().max(50),
  district: yup.string().nullable().max(60),
  cep: yup
    .string()
    .nullable()
    .min(8, 'Preencha o CEP completo.')
    .transform((curr, orig) => (orig === '' ? '' : curr))
    .nullable()
    .when(['district', 'addressDescript', 'numberAddress', 'complement'], {
      is: (district, addressDescript, numberAddress, complement) =>
        !!district || !!addressDescript || !!numberAddress || !!complement,
      then: yup.string().required('Caso digite parte de um endereço o CEP é requerido'),
    }),
})

export default schema
