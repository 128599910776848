import { useEffect, useState } from 'react'
import _ from 'lodash'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { Summary } from 'presentation/components/molecules'
import { formatMoney } from 'utils/functionUtils'
import { ReactComponent as CardIcon } from 'presentation/assets/icons/card.svg'
import { ReactComponent as RechargeIcon } from 'presentation/assets/icons/recharge.svg'
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Skeleton,
  Typography,
} from 'presentation/components/atoms'
import { DialogIcon, SummaryContainer } from './styles'
import { IOrderBasicInformation } from 'main/interfaces/iOrderBasicInformation'
import { ORDER_STATUS, ORDER_TYPES } from 'main/utils/constants'
import { useMediaQuery, useTheme } from '@mui/material'

interface CartOrderCancelationModalProps {
  open: boolean
  onCancel: () => void
  onConfirm: ({ idsToCancel }: { idsToCancel: Array<string> }) => void
  defaultCheckedId?: string
  orders: Array<IOrderBasicInformation>
  isCart?: boolean
  isLoading?: boolean
}

export const OrderCancelationModal = ({
  open,
  onCancel = () => null,
  onConfirm = () => null,
  defaultCheckedId,
  orders = [],
  isCart,
  isLoading,
}: CartOrderCancelationModalProps) => {
  const theme = useTheme()
  const isSmallerThanSm = useMediaQuery(theme.breakpoints.down('sm'))
  const [ordersOrderedByCode, setOrdersOrderedByCode] = useState<Array<any>>([])

  const onlyOneOrder = ordersOrderedByCode.length === 1
  const [ordersIdsToCancel, setOrdersIdsToCancel] = useState<Array<string>>(defaultCheckedId ? [defaultCheckedId] : [])

  useEffect(() => {
    const waitingPaymentOrders = orders.filter((order) =>
      [ORDER_STATUS.WAITING_PAYMENT, ORDER_STATUS.DRAFT].includes(order?.status),
    )
    const orderedOrders = waitingPaymentOrders.sort((orderA: any, orderB: any) => {
      return orderB.code - orderA.code
    })
    setOrdersOrderedByCode(orderedOrders)
  }, [orders])

  useEffect(() => {
    if (defaultCheckedId) setOrdersIdsToCancel([defaultCheckedId])
  }, [defaultCheckedId])

  const handleCheckBoxClick = ({ orderId }: { orderId: string }) => {
    const isSelected = ordersIdsToCancel.includes(orderId)
    if (isSelected) setOrdersIdsToCancel((state) => state.filter((id) => id !== orderId))
    else setOrdersIdsToCancel((state) => [...state, orderId])
  }

  const handleCancel = () => {
    onCancel && onCancel()
    setOrdersIdsToCancel(defaultCheckedId ? [defaultCheckedId] : [])
  }

  const renderOrdersAvailableToCancel = () => {
    return ordersOrderedByCode.map((order: any) => {
      if (!order) return null
      const isSelected = ordersIdsToCancel.includes(order.id)
      const { orderType, totalItems, id, code, totalValue } = order
      const OrderIcon = orderType < ORDER_TYPES.RECHARGE ? CardIcon : RechargeIcon

      let title = 'Recarga'
      if (orderType === ORDER_TYPES.FIRST_COPY) title = 'Cartão 1ª Via'
      else if (orderType === ORDER_TYPES.SECOND_COPY) title = 'Cartão 2ª Via'

      return (
        <SummaryContainer isSelected={isSelected} key={_.uniqueId()}>
          {!onlyOneOrder && (
            <Checkbox
              sx={{ margin: '0 16px 0 8px' }}
              onChange={() => handleCheckBoxClick({ orderId: id })}
              checked={ordersIdsToCancel.includes(id)}
              color='error'
              id={'orderToCancelCheckbox_' + order.code}
            />
          )}
          <Summary
            icon={<OrderIcon />}
            title={
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: isSmallerThanSm ? 'repeat(2, 3fr)' : 'repeat(3, 1fr)',
                  gap: isSmallerThanSm ? '4px 8px' : '16px',
                }}
              >
                <Typography sx={{ whiteSpace: 'nowrap' }}>{title}</Typography>
                <Typography sx={{ textAlign: isSmallerThanSm ? 'right' : 'left' }}>#{code}</Typography>
                <Typography sx={{ textAlign: isSmallerThanSm ? 'left' : 'right' }}>
                  <small style={{ fontSize: '0.625rem' }}>R$</small> {formatMoney(totalValue)}
                </Typography>
              </Box>
            }
            subtitle={`Colab. no pedido: ${totalItems}`}
          />
        </SummaryContainer>
      )
    })
  }

  return (
    <Dialog open={open} maxWidth='md'>
      <DialogIcon icon={faExclamationTriangle} />
      <DialogTitle sx={(theme: any) => ({ alignSelf: 'center', color: theme.palette.grey[700] })}>
        {isCart ? 'Cancelar item de carrinho' : 'Cancelar pedido'}
      </DialogTitle>
      <DialogContent sx={{ overflow: 'hidden' }}>
        {isCart ? (
          <>
            <Typography
              variant='label2'
              sx={{ margin: '16px 0px 32px', display: 'block', textAlign: 'center', fontWeight: '400' }}
            >
              Cancelar este item de carrinho é uma ação permanente e <br />
              irreversível. Ao concluir a ação um novo boleto será gerado <br />
              com o valor total do carrinho atualizado.
            </Typography>
            <Typography variant='h4' sx={{ fontWeight: 500 }}>
              Itens no carrinho
            </Typography>
            <Typography variant='label2' sx={{ margin: '4px 0px 16px', display: 'block', fontWeight: '400' }}>
              Você pode cancelar outros itens presentes no mesmo carrinho.
            </Typography>
          </>
        ) : (
          <>
            <Typography
              variant='label'
              sx={{
                margin: '32px 0px',
                display: 'block',
                fontWeight: '400',
                textAlign: 'center',
              }}
            >
              Essa ação é permanente e não poderá ser desfeita.
            </Typography>
          </>
        )}
        {isLoading ? (
          <Box>
            <Skeleton variant='rounded' height='54px' />
            {isCart && <Skeleton variant='rounded' height='54px' sx={{ marginTop: '16px' }} />}
          </Box>
        ) : (
          <Box sx={{ overflow: 'auto', maxHeight: '248px', paddingRight: '4px' }}>
            {renderOrdersAvailableToCancel()}
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={{ marginTop: '24px', gap: '8px' }}>
        <Button
          disabled={isLoading}
          variant='outlined'
          sx={{ flex: 1, filter: 'grayScale(100%)' }}
          onClick={handleCancel}
        >
          Cancelar
        </Button>
        <Button
          disabled={isLoading || (ordersIdsToCancel.length === 0 && isCart)}
          color='error'
          variant='contained'
          sx={{ flex: 1 }}
          onClick={() => onConfirm({ idsToCancel: ordersIdsToCancel })}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
