import { all, takeLatest, put, call } from 'redux-saga/effects'
import { getListSuccess, getListFailure, ACTIONS } from './actions'
import api from '@/infra/api'
import { pushNotification } from '@/store/modules/notification/actions'
import errorHandler from '@/utils/errorHandler'

function* requestList({ payload }) {
  try {
    const response = yield call(api.get, `rh-bff/cards/orders/${payload}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const cardData = response.data
    yield put(getListSuccess(cardData))
  } catch (error) {
    yield put(getListFailure())
    yield put(pushNotification(errorHandler(error.response)))
  }
}

export default all([takeLatest(ACTIONS.GET_LIST_REQUEST, requestList)])
