const ACTION = '@cards/'

export const ACTIONS = {
  GET_CARDS_REQUEST: ACTION + 'GET_CARDS_REQUEST',
  CARDS_REQUEST_SUCCESS: ACTION + 'CARDS_REQUEST_SUCCESS',
  CARDS_REQUEST_FAILURE: ACTION + 'CARDS_REQUEST_FAILURE',
  GET_CARDS_BLOCKED_REQUEST: ACTION + 'GET_CARDS_BLOCKED_REQUEST',
}

export function getCardsBlockedRequest(query) {
  return {
    type: ACTIONS.GET_CARDS_BLOCKED_REQUEST,
    payload: { query },
  }
}

export function getCardsRequest(query) {
  return {
    type: ACTIONS.GET_CARDS_REQUEST,
    payload: { query },
  }
}

export function cardsRequestSuccess(data) {
  return {
    type: ACTIONS.CARDS_REQUEST_SUCCESS,
    payload: { data },
  }
}

export function cardsRequestFailure(data) {
  return {
    type: ACTIONS.CARDS_REQUEST_FAILURE,
    payload: { data },
  }
}
