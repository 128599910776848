import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Popper } from '@mui/material'
import { Box } from 'presentation/components/atoms'
import { useState } from 'react'

const IconWithPopover = ({ icon, title, listItems, color }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleMouseEnter = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMouseLeave = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <Box onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <FontAwesomeIcon icon={icon} color={color} />
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement='bottom-start'
        onClose={handleMouseLeave}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          padding: '10px',
          backgroundColor: '#fff',
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.8)',
          zIndex: 999,
          borderRadius: '5px',
          minWidth: '291px',
          '& h4': {
            fontSize: '16px',
            paddingBlockEnd: '4px',
          },
          '& ul': {
            padding: '0 20px',
            li: {
              listStyleType: 'disc',
            },
          },
        }}
      >
        <div className='popover'>
          <h4>{title}</h4>
          <ul>
            {listItems?.map((item, index) => (
              <li key={`item-${item}`}>{item}</li>
            ))}
          </ul>
        </div>
      </Popper>
    </Box>
  )
}

export default IconWithPopover
