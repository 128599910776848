import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import api from '@/infra/api'

export const useCart = (params: any = {}) => {
  const { config = {} } = params
  const { data, ...rest } = useQuery<AxiosResponse<any>>({
    queryKey: ['cart'],
    queryFn: () => api.get(`rh-bff/basket/GetByCompanyId`),
    refetchOnWindowFocus: false,
    ...config,
  })
  return {
    cart: data?.data || {},
    ...rest,
  }
}
