import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'
import { faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { Logo } from 'presentation/components/atoms/logo'
import defaultLogo from 'presentation/assets/defaultLogo.png'
import { Footer } from 'presentation/components/atoms/footer'
import { Contact } from 'presentation/components/molecules/contact'
import { SocialMedias } from 'presentation/components/molecules/socialMedias'
import { Box } from 'presentation/components/atoms/box'
import { isUrlValid } from 'utils/functionUtils'
import { Movidesk } from 'presentation/components/movidesk'
import { useAtomValue } from 'jotai'
import { AccountPersistentStore } from '@/main/store'

export const PageFooter = () => {
  const account = useAtomValue(AccountPersistentStore)

  const medias = [
    { link: account?.resale?.linkedin, icon: faLinkedin },
    { link: account?.resale?.instagram, icon: faInstagram },
    { link: account?.resale?.twitter, icon: faTwitter },
  ]

  const mediasToRender = medias.filter((media) => isUrlValid(media.link))
  return (
    <Footer>
      <Movidesk />
      <Logo
        src={account?.resale?.images?.footer || defaultLogo}
        sx={{
          display: { xs: 'none', md: 'initial' },
        }}
      />
      <Box
        sx={{
          display: 'flex',
          gap: { xs: '4px', md: '40px' },
          flexDirection: { xs: 'column', md: 'row' },
          marginRight: '16px',
        }}
      >
        {account?.resale?.phone ? (
          <Contact icon={faPhone} name='Telefone' value={account?.resale?.phone} type='phone' />
        ) : null}
        {account?.resale?.email ? <Contact icon={faEnvelope} name='E-mail' value={account?.resale?.email} /> : null}
      </Box>
      <SocialMedias medias={mediasToRender} sx={{ display: { xs: 'none', sm: 'flex' } }} />
    </Footer>
  )
}
