import { Dialog, styled } from '@mui/material'

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': { padding: '32px' },
  '& .css-o5sip8-MuiDialogContent-root': {
    width: '100%',
  },
  '& .css-1t1j96h-MuiPaper-root-MuiDialog-paper': {
    width: '100%',
    maxWidth: 360,
  },
}))
