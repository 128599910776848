import Moment from 'react-moment'

import { DATE_TO_IGNORE } from '@/utils/constants'

import { Table } from 'presentation/components'
import { TableBody as Body } from 'presentation/components/atoms'

export const TableBody = ({ cards }) => {
  return (
    <Body>
      {cards?.length
        ? cards?.map((data, id) => (
            <Table.Tr key={data?.employeeId + id}>
              <Table.Td>{data?.oldCardNumber}</Table.Td>
              <Table.Td>{data?.oldCopy}</Table.Td>
              <Table.Td>
                {data?.blockDate && !data?.blockDate.includes(DATE_TO_IGNORE) && (
                  <Moment format='DD/MM/YYYY'>{data?.blockDate}</Moment>
                )}
              </Table.Td>
              <Table.Td>{data?.newCardNumber}</Table.Td>
              <Table.Td>{data?.newCopy}</Table.Td>
              <Table.Td>
                {data?.emissionDate && !data?.emissionDate.includes(DATE_TO_IGNORE) && (
                  <Moment format='DD/MM/YYYY'>{data?.emissionDate}</Moment>
                )}
              </Table.Td>
            </Table.Tr>
          ))
        : null}
    </Body>
  )
}
