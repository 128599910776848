import React from 'react'
import { Breadcrumb } from '..'
import { PageHeaderContainer, PageName } from './styles'

const PageHeader = ({ children, borderless, location, ...props }) => {
  return (
    <PageHeaderContainer borderless={borderless}>
      <PageName {...props}>{children}</PageName>
      <Breadcrumb />
    </PageHeaderContainer>
  )
}

export default PageHeader
