import { Feature, useFeatures } from 'main/hooks/useFeatures'
import { ReactElement } from 'react'

interface FeatureToggleProps {
  feature: Feature
  children?: ReactElement
  render?: ({ isAvailable }: { isAvailable: boolean }) => ReactElement
}

export const FeatureToggle = ({ children, feature, render }: FeatureToggleProps) => {
  const { features } = useFeatures()

  const isAvailable = features[feature]
  if (render) return render({ isAvailable })
  else return isAvailable ? children : null
}
