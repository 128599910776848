import blobDownload from '@/utils/blobDownload'
import blobToJson from '@/utils/blobToJson'

import { pushNotification } from '@/store/modules/notification/actions'
import errorHandler from '@/utils/errorHandler'
import { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'

const initialState = {
  loading: false,
}

const useDownloadReport = (reportService) => {
  const [state, setState] = useState({ ...initialState })
  const dispatch = useDispatch()

  const requestReport = useCallback(
    async (fileName, ...args) => {
      setState({ ...initialState, loading: true })
      const { error, response } = await reportService(...args)
      if (response) {
        const blob = new Blob([response.data], { type: 'text/plain' })
        const filename = fileName
        blobDownload(blob, filename)
      } else {
        dispatch(pushNotification(errorHandler({ ...error.response, data: await blobToJson(error?.response?.data) })))
      }
      setState({ ...initialState, loading: false })
    },
    [dispatch, reportService],
  )

  return [state, requestReport]
}

export default useDownloadReport
