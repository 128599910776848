import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { Icon } from '../../atoms/Icon'
import { MenuItem } from '../../atoms/menuItem'
import { StyledBox, StyledSelect } from './styles'
import { InputLabel, Typography } from 'presentation/components/atoms'

export const Select = ({
  label,
  id,
  children,
  placeholder = 'Selecione',
  options = [],
  getOptionLabel = (option) => option.label,
  getOptionValue = (option) => option.value,
  fullWidth = false,
  error,
  success,
  helperText,
  blocked,
  disabled,
  ...props
}) => {
  const renderOptions = () => {
    if (options.length !== 0)
      return options.map((option, index) => (
        <MenuItem id={`${id}_option_${index}`} key={getOptionValue(option)} value={getOptionValue(option)}>
          {getOptionLabel(option)}
        </MenuItem>
      ))
    return children
  }

  return (
    <StyledBox error={error} success={success}>
      <InputLabel htmlFor={id} id={id + '_label'} children={label} />
      <StyledSelect
        id={id}
        displayEmpty
        sx={{ width: fullWidth ? '100%' : 'initial' }}
        blocked={blocked}
        disabled={disabled || blocked}
        IconComponent={(iconProps) => (
          <Icon
            sx={{
              right: '16px !important',
              cursor: 'pointer',
            }}
            icon={faChevronDown}
            {...iconProps}
          />
        )}
        {...props}
      >
        <MenuItem value='' id={id + '_option_0'} disabled sx={{ display: 'none' }}>
          {placeholder}
        </MenuItem>
        {renderOptions()}
      </StyledSelect>
      {helperText && <Typography>{helperText}</Typography>}
    </StyledBox>
  )
}
