import { Table } from 'presentation/components'
import NumberFormat from 'react-number-format'
import styled from 'styled-components'

export const DescriptionText = styled.div`
  p:first-child {
    font-weight: var(--font-weight-bold);
    font-size: 1.125rem;
    margin-bottom: 16px;
  }
  min-width: 310px;
`

export const ButtonWrapper = styled.div`
  display: flex;
  button {
    margin-left: 16px;
  }
`

export const CustomInput = styled(NumberFormat)`
  border: none;
  height: 35px;
  border-radius: 8px;
  max-width: 96px;
  padding: 0 8px;
  border: 1px solid transparent;
  :hover,
  :focus {
    border: 1px solid var(--color-secundary-05);
  }
`

export const TotalValue = styled.div`
  font-size: 1.25rem;
  letter-spacing: 0.32px;
  margin-top: 20px;
  color: var(--color-primary-01);
  font-weight: var(--font-weight-medium);
`

export const CustomTable = styled(Table)`
  td:last-child,
  th:last-child {
    text-align: right;
  }
`

export const StepsWrapper = styled.div`
  max-width: 800px;
  margin: auto;
  margin-bottom: 48px;
`
