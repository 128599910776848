import { styled } from '@mui/material'
import { Box } from '@stationkim/front-ui'

export const OrderLink = styled('a')(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 600,
}))

export const NewOrderContainer = styled(Box)<{ isNewOrder: boolean }>(({ isNewOrder }) => ({
  filter: !isNewOrder ? 'grayscale(1)' : '',
  opacity: !isNewOrder ? '0.5' : '1',
  cursor: !isNewOrder ? 'not-allowed' : 'pointer',
  overflow: 'hidden',
  transition: 'all .5s cubic-bezier(0.4, 0, 1, 1)',
  marginBottom: '16px',
  position: 'relative',
  '&:after': {
    content: !isNewOrder ? "' '" : 'unset',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 2,
  },
}))
