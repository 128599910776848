import { styled } from '@mui/material'

export const Cart = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: '40px',
  '& a': {
    position: 'relative',
    textDecoration: 'none',
    color: '#fff',
    '& p': {
      position: 'absolute',
      left: 'calc(50% + 2px)',
      transform: 'translateX(-50%)',
      top: '-5px',
      color: '#fff',
      fontWeight: 600,
    },
  },
}))
