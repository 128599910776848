import { all, takeLatest, call, put } from 'redux-saga/effects'
import { decode } from 'jsonwebtoken'
import api from '@/infra/api'
import reportApi from '@/services/reportApi'
import { getRegisterCompanyToken } from '@/services/authServices'
import {
  ACTIONS,
  genericUserSignInRequestFailure,
  genericUserSignInRequestSuccess,
  genericUserSignOutRequest,
} from './actions'

function* signIn({ payload }) {
  if (api.defaults.headers.common.Authorization) return
  const { response, error } = yield call(getRegisterCompanyToken, payload.site)
  if (response) {
    api.defaults.headers.common.Authorization = `Bearer ${response.data.accessToken}`
    reportApi.defaults.headers.common.Authorization = `Bearer ${response.data.accessToken}`
    yield put(genericUserSignInRequestSuccess(response.data.accessToken))
  } else {
    yield put(genericUserSignInRequestFailure(error.response))
  }
}

function* setToken({ payload }) {
  const token = payload?.auth?.token
  if (token) {
    const { exp: tokenExpiration, role } = decode(token)
    if (role !== 'service') return
    if (typeof payload === 'undefined') return
    if (tokenExpiration <= Math.floor(Date.now() / 1000)) return yield put(genericUserSignOutRequest())

    api.defaults.headers.common.Authorization = `Bearer ${token}`
    reportApi.defaults.headers.common.Authorization = `Bearer ${token}`
  }
}

export default all([takeLatest(ACTIONS.SIGN_IN_REQUEST, signIn), takeLatest('persist/REHYDRATE', setToken)])
