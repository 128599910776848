export const steps = [
  {
    name: 'Cartões',
    navigable: false,
  },
  {
    name: 'Colaboradores',
    navigable: false,
  },
  {
    name: 'Informações',
    navigable: false,
  },
  {
    name: 'Pagamento',
    navigable: false,
  },
]
