import { SelectableCard } from 'presentation/components/molecules/selectableCard'
import { Box } from 'presentation/components/atoms/box'
import { Typography } from '@mui/material'
import { Button } from 'presentation/components/atoms/button'
import { useState } from 'react'
import { CardTable, StyledButton } from './style'
import { Search } from 'presentation/components/molecules/search'
import { Carousel } from 'presentation/components/molecules/carousel'
import { Grid } from 'presentation/components/atoms/grid'
import useDebounce from 'main/hooks/useDebouce'

const baseCategory = [{ name: 'Todos', id: null }]
export const CategorizedItemsSelection = ({
  title,
  searchHolder,
  items = [],
  categories = baseCategory,
  onClose,
  onConfirm,
  value,
  onChange,
  getItemName = (item) => item.name,
  getItemDescription = (item) => item.description,
  getItemCategory = (item) => item.category,
  getItemId = (item) => item.id,
  getCategoryName = (category) => category.name,
  getCategoryId = (category) => category.id,
}) => {
  const debounce = useDebounce()
  const [internalValue, setInternalValue] = useState([])

  const selecteds = value !== undefined ? value : internalValue
  const setSelecteds = value !== undefined ? onChange : setInternalValue

  const [categoryFilter, setCategoryFilter] = useState(null)
  const [textFilter, setTextFilter] = useState('')

  const handleCategoryClick = (key) => {
    setCategoryFilter(key)
  }

  let itemsToRender = items
  if (categoryFilter) itemsToRender = items.filter((item) => String(getItemCategory(item)) === String(categoryFilter))

  itemsToRender = itemsToRender.filter((item) =>
    String(getItemName(item)).toLocaleLowerCase().includes(textFilter.toLocaleLowerCase()),
  )

  const renderCards = () => {
    return itemsToRender.map((item, index) => {
      const isItemSelected = selecteds.some((selected) => getItemId(selected) === getItemId(item))
      return (
        <SelectableCard
          key={`${index}_item_for_selection`}
          title={getItemName(item)}
          description={getItemDescription(item)}
          value={isItemSelected}
          onChange={isItemSelected ? () => removeFromSelecteds(getItemId(item)) : () => addToSelecteds(item)}
        />
      )
    })
  }

  const addToSelecteds = (item) => {
    setSelecteds((state) => {
      const newState = [...state]
      newState.push(item)
      return newState
    })
  }

  const removeFromSelecteds = (itemId) => {
    const itemIndex = selecteds.indexOf((selected) => getItemId(selected) === itemId)
    setSelecteds((state) => {
      const newState = [...state]
      newState.splice(itemIndex, 1)
      return newState
    })
  }

  return (
    <Box
      sx={{
        maxWidth: '1230px',
        margin: 'auto',
      }}
    >
      <Typography
        variant='h2'
        sx={{
          margin: '0 0 16px 0',
        }}
      >
        {title}
      </Typography>
      <Grid
        container
        spacing={{ xs: '32px' }}
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Grid item xs={12} sm={6} md={8}>
          <Carousel>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
              }}
            >
              {[...baseCategory, ...categories].map((category, index) => {
                return (
                  <StyledButton
                    key={`${index}_cards`}
                    variant='contained'
                    active={categoryFilter === getCategoryId(category)}
                    onClick={() => {
                      handleCategoryClick(getCategoryId(category))
                    }}
                  >
                    <Typography variant='label2'>{getCategoryName(category)}</Typography>
                  </StyledButton>
                )
              })}
            </Box>
          </Carousel>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Search
            placeholder={searchHolder}
            onSearch={(value) => debounce(() => setTextFilter(value), 300)}
            searchOnChange
          />
        </Grid>
      </Grid>
      <CardTable
        sx={{
          alignContent: 'baseline',
          justifyContent: 'start',
          gridTemplateColumns: {
            xs: '1fr',
            md: 'repeat( auto-fit, 320px )',
          },
        }}
      >
        {renderCards()}
      </CardTable>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '28px',
        }}
      >
        <Button variant='outlined' onClick={onClose}>
          Voltar
        </Button>
        <Button variant='contained' onClick={onConfirm}>
          Continuar
        </Button>
      </Box>
    </Box>
  )
}
