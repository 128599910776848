import { all, takeLatest, put, call } from 'redux-saga/effects'
import { ACTIONS, departmentsRequestSuccess, departmentsRequestFailure } from './actions'
import { getDepartments } from '@/services/departmentsServices'

function* fetchDepartments({ payload }) {
  const { response, error } = yield call(getDepartments, payload.query)
  if (response) yield put(departmentsRequestSuccess(response.data))
  else yield put(departmentsRequestFailure(error.response))
}

export default all([takeLatest(ACTIONS.GET_DEPARTMENTS_REQUEST, fetchDepartments)])
