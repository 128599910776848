import { styled } from '@mui/material'
import { Box, Paper } from 'presentation/components/atoms'

export const StyledPaper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '32px',
  padding: '32px',
  overflow: 'hidden',
  '& h5 > span': { color: theme.palette.success.dark, fontWeight: 600 },
  '& h5 ': { fontWeight: 500 },
}))

export const BasicInformationContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '16px',
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    gap: '32px',
    flexDirection: 'row',
    marginLeft: '56px',
  },
  '& h3': {
    textAlign: 'center',
  },
  '& b, & span': {
    whiteSpace: 'nowrap',
  },
}))
