import { useEffect } from 'react'
import { useAtomValue } from 'jotai'
import { AccountPersistentStore } from '@/main/store'

export const Movidesk = () => {
  const account = useAtomValue(AccountPersistentStore)

  const injectChatElement = () => {
    const key = account?.resale?.chatBotKey
    if (!key) return
    const script = document.createElement('script')
    script.innerHTML = `var mdChatClient="${key}";`
    script.type = 'text/javascript'
    script.id = 'movideskScript'
    document.body.appendChild(script)

    const moviDeskScript = document.createElement('script')
    moviDeskScript.src = 'https://chat.movidesk.com/Scripts/chat-widget.min.js'
    document.body.appendChild(moviDeskScript)
  }

  useEffect(() => {
    const movideskScriptElement = document.getElementById('movideskScript')
    if (!movideskScriptElement) injectChatElement()
    // eslint-disable-next-line
  }, [])
}
