import Box from '@mui/material/Box'
import StepperComponent from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepButton from '@mui/material/StepButton'

const steps = ['Efetivando', 'Aguardando Pagamento', 'Aguardando Processamento', 'Concluído']

interface StepperProps {
  step: {
    label?: string
    value: number
  }
}

export const Stepper: React.FC<StepperProps> = ({ step }) => {
  return (
    <Box sx={{ width: '100%', marginBottom: '32px' }}>
      <StepperComponent nonLinear activeStep={step?.value}>
        {steps.map((label, index) => (
          <Step key={label} completed={step?.value > index}>
            <StepButton color='inherit'>{index === 2 && step?.value === 2 ? step?.label : label}</StepButton>
          </Step>
        ))}
      </StepperComponent>
    </Box>
  )
}
