import React from 'react'
import { Body, TextConfirm, ColorDest, SessionButton, Close } from './style'
import { Button, Icon } from 'presentation/components'
import 'react-responsive-modal/styles.css'
import { Modal } from 'react-responsive-modal'

const CancelModal = ({ open, close, cancel, mode }) => {
  const text = mode ? 'a edição' : 'o cadastro'

  return (
    <Modal open={open} onClose={close} center showCloseIcon={false} styles={{ borderRadius: '5px' }}>
      <Body>
        <Close onClick={close}>
          <Icon name='x' />
        </Close>
        <TextConfirm>
          Você tem certeza que deseja <ColorDest>cancelar</ColorDest> <br />
          {text} desse colaborador?
        </TextConfirm>
        <SessionButton>
          <Button variant='outlined' width='200px' onClick={close}>
            Não
          </Button>
          <div style={{ paddingLeft: '15px' }} />
          <Button width='250px' onClick={cancel}>
            Sim, cancelar
          </Button>
        </SessionButton>
      </Body>
    </Modal>
  )
}

export default CancelModal
