import { Box, Skeleton } from '@/presentation/components/atoms'

export const OrderItemsSkeleton = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', marginBottom: '16px' }}>
      <Skeleton variant='rounded' height='98px' width='100%' />
      <Skeleton variant='rounded' height='98px' width='100%' />
    </Box>
  )
}
