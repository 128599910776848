import { useAtomValue } from 'jotai'
import { IntegrationSwapper } from '@/presentation/components'
import { OrderTracking as OldOrderTracking } from './oldOrderTracking/orderTracking'
import { OrderTracking as NewOrderTracking } from './orderTracking'
import { isDealerAtom } from '@/main/store'

export const OrderTracking = () => {
  const isDealer = useAtomValue(isDealerAtom)
  return (
    <IntegrationSwapper
      isDealer={isDealer}
      components={{
        ifIsMotiva: <OldOrderTracking />,
        ifIsNotMotiva: <NewOrderTracking />,
      }}
    />
  )
}
