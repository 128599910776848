import axios from 'axios'

export const requestMigration = async (company, token) => {
  return await axios.post(`${process.env.REACT_APP_API_HOST}/companies-service/migration/`, company, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
}
