import { Theme } from '@mui/material'
import { ORDER_STATUS } from 'main/utils/constants'

export const applyMask = ({
  mask,
  text,
  maskChar = '#',
}: {
  mask: string | undefined
  text: string | undefined
  maskChar?: string
}) => {
  if (!mask || !text) return ''
  let newString = mask
  for (let i = 0; i < text?.length; i++) {
    if (mask[i] === text[i]) continue
    newString = newString.replace(maskChar, text[i])
  }
  return newString.split('#')[0]
}

export const autoAbbreviate = ({ string }: { string: string }) => {
  const names = string.split(' ')
  const abbreviation = names.reduce((final: string, word: string) => final + word[0], '')
  return abbreviation
}

export const bytesToHumanFileSize = (bytes: number) => {
  if (!bytes) return ''
  if (bytes == 0) return '0.00 B'
  const e = Math.floor(Math.log(bytes) / Math.log(1024))
  return (bytes / Math.pow(1024, e)).toFixed(2) + ' ' + ' KMGTP'.charAt(e) + 'B'
}

export const downloadString = (string: string, fileName = 'Arquivo de texto') => {
  const link = document.createElement('a')
  link.download = fileName + '.txt'
  const blob = new Blob([string], { type: 'text/plain' })
  link.href = window.URL.createObjectURL(blob)
  link.click()
}

export const formatMoney = (value = 0) => {
  const valueToBeFormatted = Number(value) ? value : 0
  return parseFloat(String(valueToBeFormatted)).toLocaleString('pt-br', { minimumFractionDigits: 2 })
}

export const hasWidthOverflow = ({ element }: { element: any }): boolean | null => {
  if (!element) return null
  const rect = element.getBoundingClientRect()
  const scrollWidth = element.scrollWidth
  return scrollWidth > Math.ceil(rect.width)
}

export const orderStatusToColors = ({ status, theme }: { status: number; theme: Theme }) => {
  const isWarningColor = [ORDER_STATUS.AVAILABLE_PICKUP, ORDER_STATUS.WAITING_PAYMENT].includes(status)
  const isErrorColor = [ORDER_STATUS.CANCELING, ORDER_STATUS.CANCELED].includes(status)
  const isSuccessColor = [ORDER_STATUS.FINISHED].includes(status)

  if (isWarningColor) return theme.palette.warning
  if (isErrorColor) return theme.palette.error
  if (isSuccessColor) return theme.palette.success
  return theme.palette.primary
}

export const sortArrayByNestedValue = ({ array, getValue }: { array: Array<any>; getValue: (option: any) => any }) => {
  return [...array].sort((a, b) => {
    if (getValue(a) > getValue(b)) return 1
    if (getValue(a) < getValue(b)) return -1
    return 0
  })
}
