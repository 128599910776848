import { Box, Skeleton } from 'presentation/components/atoms'

export const CartOrderConfirmationSkeleton = () => {
  return (
    <Box sx={{ width: '100%', display: 'flex', gap: '32px', flexDirection: 'column' }}>
      <Skeleton variant='rounded' width='220px' height='32px' sx={{ margin: 'auto' }} />
      <Box sx={{ width: '100%', display: 'flex', gap: '16px', flexDirection: 'column' }}>
        <Skeleton variant='rounded' />
        <Box sx={{ display: 'flex', gap: '16px', justifyContent: 'center' }}>
          <Skeleton variant='rounded' width='50%' />
          <Skeleton variant='rounded' width='50%' />
        </Box>
        <Skeleton variant='rounded' />
      </Box>
      <Box sx={{ display: 'flex', gap: '16px', justifyContent: 'space-between' }}>
        <Skeleton variant='rounded' width='30%' />
        <Skeleton variant='rounded' width='30%' />
        <Skeleton variant='rounded' width='30%' />
      </Box>

      <Skeleton height='200px' variant='rounded' />
      <Skeleton height='200px' variant='rounded' />
    </Box>
  )
}
