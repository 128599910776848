import React from 'react'

import { Grid, Skeleton } from 'presentation/components/atoms'

export const CartSkeleton = () => {
  return (
    <Grid container spacing='32px'>
      <Grid item xs={12} md={7}>
        <Skeleton height={150} variant='rounded' sx={{ marginBottom: '16px' }} />
        <Skeleton height={150} variant='rounded' />
      </Grid>
      <Grid item xs={12} md={5}>
        <Skeleton height={500} variant='rounded' />
      </Grid>
    </Grid>
  )
}
