import styled from 'styled-components'
import { Row as GridRow } from 'react-grid-system'

export const Content = styled.div`
  width: 90%;
`

export const Label = styled.div`
  margin-bottom: 10px;
  white-space: nowrap;
`

export const Row = styled(GridRow)`
  margin-top: 32px;
  &:first-child {
    margin-top: 0;
  }
`

export const FieldSet = styled.fieldset`
  border: none;
`
