import axios from 'axios'

export const sendDocument = async (data, cancel, setFiles, token) => {
  const formData = new FormData()
  formData.append('idCompany', data.id)
  formData.append('type', data.type)
  formData.append('file', data.file)
  try {
    await axios.post(`${process.env.REACT_APP_API_HOST}/companies-service/document`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancel.token,
      onUploadProgress: (progressEvent) => {
        let progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        setFiles((state) =>
          state.map((file, index) =>
            index === data.type - 1 ? { ...file, uploaded: progress, uploading: progress !== 100, error: false } : file,
          ),
        )
      },
    })
  } catch (e) {
    setFiles((state) =>
      state.map((file, index) =>
        index === data.type - 1 ? { ...file, error: e.message !== 'cancel', uploading: false } : file,
      ),
    )
  }
}
