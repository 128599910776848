import produce from 'immer'

const INITIAL_STATE = {
  data: null,
  loading: false,
  error: false,
}

export default function trackHistory(state = INITIAL_STATE, action) {
  const { type, payload } = action

  return produce(state, (draft) => {
    switch (type) {
      case '@trackHistory/TRACKHISTORY_REQUEST': {
        draft.loading = true
        break
      }

      case '@trackHistory/TRACKHISTORY_SUCCESS': {
        draft.loading = false
        draft.data = payload.history
        break
      }

      case '@trackHistory/TRACKHISTORY_ERROR': {
        draft.loading = false
        draft.error = true
        break
      }

      default:
    }
  })
}
