import _ from 'lodash'

import { Box, Typography, Divider } from 'presentation/components/atoms'
import { Summary } from 'presentation/components/molecules'

import { formatMoney } from 'utils/functionUtils'
import { nameFields } from './utils/nameFields'
import { tooltipContent } from './utils/tooltip'

import { ReactComponent as RechargeIcon } from 'presentation/assets/icons/recharge.svg'

import { ItemsContainer, OrderDetail } from './styles'

interface IOrders {
  totalItens: number
  totalRechargeValue: number
  administrateFee: number
  totalTransferFeeValue: number
  ticketingBalance: number
  issFee: number
  totalValue: number
}

interface ItemsProps {
  title: string
  order: string
  operator: string
  subtitle: string
  orders?: IOrders[]
  orderType: 'recharge'
  price: number
}

export const Items: React.FC<ItemsProps> = ({ title, order, operator, subtitle, orders, orderType, price }) => {
  return (
    <ItemsContainer>
      <Summary
        title={
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', gap: '32px' }}>
              <Typography variant='h5'>{title}</Typography>
              <Typography variant='h5' fontStyle='italic' style={{ textDecoration: 'underline' }}>
                {order}
              </Typography>
              <Typography variant='h5' fontWeight={400} fontSize='18px !important' title={operator}>
                {operator}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <Typography
                variant='h5'
                fontWeight={400}
                fontSize='18px !important'
                style={{ display: 'flex', alignItems: 'baseline' }}
              >
                <small style={{ fontSize: 12, marginRight: '5px' }}>R$ </small> {formatMoney(price)}
              </Typography>
            </Box>
          </Box>
        }
        subtitle={subtitle}
        icon={<RechargeIcon />}
      >
        {orders.map((order, index) => {
          return (
            <Box key={_.uniqueId()}>
              {index !== 0 && <Divider sx={{ margin: '16px 0' }} />}
              {Object.entries(nameFields).map((values, index) => {
                const name = values[1]
                const key = values[0]
                const value = Number(order[key as keyof typeof order])
                const isLast = index + 1 === Object.entries(nameFields).length
                const valueLabel = 'totalSubOrder' === key && orderType === 'recharge' ? 'Valor da recarga' : name
                if (
                  (orderType !== 'recharge' && key === 'ticketingBalance') ||
                  (key === 'ticketingBalance' && value === 0)
                )
                  return null
                return (
                  <OrderDetail key={_.uniqueId()}>
                    <span>
                      <Typography variant='label2' sx={{ fontWeight: isLast ? 700 : 400, marginRight: '16px' }}>
                        {valueLabel}
                      </Typography>
                      {key === 'ticketingBalance' && tooltipContent({ value: Number(order[key]) })}
                    </span>
                    <Typography
                      variant='label2'
                      sx={{ fontWeight: isLast ? 700 : 400, fontStyle: 'italic', whiteSpace: 'nowrap' }}
                    >
                      {['issFee', 'issFee', 'ticketingBalance'].includes(key) && value > 0 && '- '}
                      {key !== 'totalItens' ? `R$ ${formatMoney(value)}` : value}
                    </Typography>
                  </OrderDetail>
                )
              })}
            </Box>
          )
        })}
      </Summary>
    </ItemsContainer>
  )
}
