import styled from 'styled-components'

export const Operator = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;

  p {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.87);
  }

  small {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    color: rgba(158, 158, 158, 1);
  }

  a {
    margin-top: 14px;
    display: flex;
    align-items: center;

    p {
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 400;
      color: rgba(21, 101, 192, 1);
      margin-right: 8px;
    }

    svg {
      width: 16px;
      height: 16px;
      fill: rgba(21, 101, 192, 1);
    }
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
  padding: 24px;

  button {
    width: 180px;
    height: 48px;
    max-width: 166px;
    margin-left: 24px;

    :first-child {
      border: 1px solid rgba(97, 97, 97, 1);
      color: rgba(97, 97, 97, 1);
    }
  }
`
export const StepsWrapper = styled.div`
  max-width: 100%;
  margin: auto;
  margin-top: 32px;
`
