import { styled } from '@mui/material'
import { Paper } from 'presentation/components/atoms'

export const SummaryValues = styled('ul')(() => ({
  '& li': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '32px',
  },
}))

export const TextRates = styled('div')`
  color: var(--Grey-700, #616161);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  margin-top: 16px;
  margin-left: 10px;
`

export const NoItemPaper = styled(Paper)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
  padding: '16px',
  backgroundColor: theme.palette.grey[50],
  opacity: '.5',
  '& span': {
    color: theme.palette.grey[600],
    fontWeight: 500,
  },
}))
