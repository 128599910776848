import { styled } from '@mui/material'

export const Details = styled('div')(({ theme }) => ({
  width: '100%',
  borderRadius: 4,
  border: `1px solid ${theme.palette.common.light}`,
  boxShadow: '0px 4px 6px 0px rgba(0, 0, 0, 0.15)',
  padding: 16,
  h4: {
    fontSize: 23,
    marginBottom: 16,
  },
  h5: {
    marginBottom: 24,
    fontSize: 23,
    fontWeight: 500,
  },
  '.qrcode-finished': {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    width: 200,
    height: 200,
    'img:first-child': {
      position: 'absolute',
    },
    'img:last-child': {
      position: 'relative',
      zIndex: 10,
      margin: '0 auto',
    },
    '.loading': {
      animation: 'loading 1.5s infinite linear',
      '@keyframes loading': {
        '0%': {
          transform: 'rotate(0deg)',
        },
        '100%': {
          transform: 'rotate(360deg)',
        },
      },
    },
  },
}))

export const Steps = styled('ul')(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 16,
  fontSize: 18,
}))

export const Total = styled('p')(() => ({
  fontSize: 18,
  fontWeight: 400,
  span: {
    fontWeight: 700,
  },
  marginBottom: 26,
}))

export const Title = styled('h4')(() => ({
  fontWeight: 500,
  fontSize: 20,
  marginBottom: 16,
}))

export const Clipboard = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',
  padding: '12px 8px',
  borderRadius: 4,
  background: theme.palette.grey['50'],
  width: '100%',
  maxWidth: 825,
  height: 48,
  marginTop: 24,
  marginBottom: 8,
  input: {
    width: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.grey['700'],
    textOverflow: 'ellipsis',
    border: 'none',
    background: 'transparent',
  },
  img: {
    cursor: 'pointer',
  },
  '.loading': {
    animation: 'loading 1.5s infinite linear',
    '@keyframes loading': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
  },
}))

export const Order = styled('ul')(() => ({
  display: 'flex',
  gap: 30,
  li: {
    fontSize: 20,
    fontWeight: 500,
  },
}))

export const PaymentFinished = styled('div')(() => ({
  p: {
    span: {
      fontWeight: 700,
    },
  },
  h5: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
}))
