import styled from 'styled-components'

const dashedBorderColors = {
  purple: `background-image:url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='13' ry='13' stroke='%235E39CAC2' stroke-width='1' stroke-dasharray='24%2c 24' stroke-dashoffset='97' stroke-linecap='square'/%3e%3c/svg%3e");background-color: #5E39CA05;`,
  gray: `background-image:url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='13' ry='13' stroke='%23999999FF' stroke-width='1' stroke-dasharray='24%2c 24' stroke-dashoffset='97' stroke-linecap='square'/%3e%3c/svg%3e")`,
  red: `background-color: #E02E0005; background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='13' ry='13' stroke='%23E02E00FF' stroke-width='1' stroke-dasharray='24%2c 24' stroke-dashoffset='97' stroke-linecap='square'/%3e%3c/svg%3e");`,
  green: `background-color: #00E07712; background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='13' ry='13' stroke='%2300E077FF' stroke-width='1' stroke-dasharray='24%2c 24' stroke-dashoffset='97' stroke-linecap='square'/%3e%3c/svg%3e");`,
}

const chooseBorderColor = (error, filename) => {
  if (error) return dashedBorderColors.red
  if (filename) return dashedBorderColors.green
  if (!error && !filename) return dashedBorderColors.gray
}

export const DropContainer = styled.div`
  ${({ error, filename }) => chooseBorderColor(error, filename)};

  border-radius: 13px;
  display: flex;
  align-items: center;
  height: 134px;
  width: 100%;
  margin: auto;
  padding: 26px;
  cursor: pointer;
`

export const Title = styled.p`
  color: #6b6576;
  font-size: 1.5rem;
  letter-spacing: 0;
`

export const SubTitle = styled.p`
  color: var(--color-primary-04);
  font-size: 1.125rem;
  margin-top: 8px;
  color: #6b6576;
`

export const TitleWrapper = styled.div`
  margin: 0 26px;
  flex: 1;
`

export const DocumentIcon = styled.img`
  filter: ${({ hasfile }) => (hasfile ? 'none' : 'grayscale(100%)')};
`
