import { useContext } from 'react'
import { useTheme } from '@mui/material'
import { Card, Typography } from 'presentation/components/atoms'
import { OrderTrackingContext } from '../../orderTracking'
import { WaitingPayment } from './components/waitingPayment/waitingPayment'
import { Finished } from './components/finished/finished'
import { Effecting } from './components/effecting/effecting'
import { ORDER_STATUS } from 'main/utils/constants'
import { Processing } from './components/processing/processing'
import { WaitingForProcessing } from './components/waitingForProcessing/waitingForProcessing'
import { Canceled } from './components/canceled/canceled'
import { AvailablePickUp } from './components/availablePickUp/availablePickUp'
import { OrderStatusStepper, selectStepColor } from 'presentation/components/molecules'

export const StatusInformation = () => {
  const { palette } = useTheme()
  const { usedPaymentsTypes, cart, status, orderType, cancelDate, isRecharge, destinationAddress } =
    useContext(OrderTrackingContext)
  const statusColor = selectStepColor({ status: status, palette: palette })
  const pickupAddress = destinationAddress
    ? `${destinationAddress?.laneName}, N° ${destinationAddress?.number}. ${destinationAddress?.city}, ${destinationAddress.state}`
    : 'Endereço padrão'

  const statusComponents = {
    1: <Effecting />,
    3: <WaitingPayment paymentType={usedPaymentsTypes} />,
    4: <Processing />,
    5: <WaitingForProcessing />,
    10: <AvailablePickUp address={pickupAddress} />,
    15: <Finished isCart={Boolean(cart?.id)} orderType={orderType} paymentType={usedPaymentsTypes} />,
    16: <Canceled cancelDate={cancelDate} />,
    17: <Canceled cancelDate={cancelDate} />,
  }

  const StatusComponent = statusComponents[status as keyof typeof statusComponents] || null
  return (
    <Card sx={{ padding: '16px', overflow: 'auto' }}>
      <OrderStatusStepper orderStatus={status} isRecharge={isRecharge} />
      <Typography
        sx={{
          padding: '32px 0 8px',
          fontWeight: 600,
          color: statusColor,
        }}
      >
        {ORDER_STATUS[status as keyof typeof ORDER_STATUS]}
      </Typography>
      {StatusComponent}
    </Card>
  )
}
