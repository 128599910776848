import { useQueryClient } from '@tanstack/react-query'
import { faEllipsis } from '@fortawesome/free-solid-svg-icons'
import { Icon, formatMoney } from '@stationkim/front-ui'
import { Box, CircularProgress, MenuItem, MenuUncontrolled } from '@/presentation/components/atoms'
import { ORDER_STATUS, ORDER_TYPES, PURCHASE_STATUS } from '@/main/utils/enumsMapping'
import { EStatusCompra, EStatusPedido, ETipoPedido } from '@/main/enums'
import { useOrderCancelation } from '@/main/hooks/useOrderCancelation'

interface useRechargesListColumnsConfigurationProps {
  updateFilter: (params: Record<string, any>) => void
  setOrderToCancel: React.Dispatch<React.SetStateAction<Record<string, any>>>
}
const baseId = 'orders_table_header_'
export const useRechargesListColumnsConfiguration = ({
  updateFilter,
  setOrderToCancel,
}: useRechargesListColumnsConfigurationProps) => {
  const { cancelOrder } = useOrderCancelation()
  const columns = [
    {
      headerId: baseId + 'orderNumber',
      columnDisplayName: 'Nr. Pedido',
      columnValue: ({ row }) => row.code,
      onOrder: ({ order }) => {
        updateFilter({ OrdenarPor: 'NumeroPedido', OrdenarDecrescente: !order })
      },
    },
    {
      headerId: baseId + 'orderDate',
      columnDisplayName: 'Data do Pedido',
      columnValue: ({ row }) => new Date(row.orderDate).toLocaleDateString(),
      onOrder: ({ order }) => {
        updateFilter({ OrdenarPor: 'DataCriacao', OrdenarDecrescente: !order })
      },
    },
    {
      headerId: baseId + 'operator',
      columnDisplayName: 'Operadora',
      columnValue: ({ row }) => row.operatorName,
      onOrder: ({ order }) => {
        updateFilter({ OrdenarPor: 'Operadora', OrdenarDecrescente: !order })
      },
    },
    {
      headerId: baseId + 'value',
      columnDisplayName: 'Valor',
      columnValue: ({ row }) => `R$ ${formatMoney(row.totalValue)}`,
      onOrder: ({ order }) => {
        updateFilter({ OrdenarPor: 'Valor', OrdenarDecrescente: !order })
      },
    },
    {
      headerId: baseId + 'status',
      columnDisplayName: 'Status',
      columnValue: ({ row }) => {
        if (
          ![EStatusPedido.Cancelado, EStatusPedido.Cancelando].includes(row.status) &&
          [EStatusCompra.AguardandoPagamento, EStatusCompra.ProcessandoFormaPagamento].includes(row.purchaseStatus)
        )
          return PURCHASE_STATUS[row.purchaseStatus]
        else if (EStatusPedido.EmAndamento === row.status && ETipoPedido.RecargaVtBroker === row.orderType) {
          return 'Processamento Operadora'
        }
        return ORDER_STATUS[row.status]
      },
    },
    {
      headerId: baseId + 'orderType',
      columnDisplayName: 'Tipo de pedido',
      columnValue: ({ row }) => ORDER_TYPES[row.orderType],
      onOrder: ({ order }) => {
        updateFilter({ OrdenarPor: 'tipoPedido', OrdenarDecrescente: !order })
      },
    },
    {
      headerId: baseId + 'documents',
      columnDisplayName: 'Documentos',
      columnValue: () => null,
    },
    {
      headerId: baseId + 'actions',
      columnDisplayName: 'Ações',
      columnValue: () => '',
      //align: 'center',
      preventLink: true,
      customRender: ({ row }) => {
        const isLoading = cancelOrder.variables?.orderId === row.id && cancelOrder.isLoading
        return (
          ([EStatusPedido.NoCarrinho, EStatusPedido.Efetivando].includes(row.status) ||
            [EStatusCompra.AguardandoPagamento, EStatusCompra.ProcessandoFormaPagamento].includes(
              row.purchaseStatus,
            )) && (
            <Box
              onClick={(e: any) => {
                e.stopPropagation()
              }}
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              {isLoading ? (
                <CircularProgress size='16px' />
              ) : (
                <MenuUncontrolled
                  renderItems={() => (
                    <MenuItem
                      onClick={(e: any) => {
                        e.stopPropagation()
                        setOrderToCancel(row)
                      }}
                    >
                      Cancelar pedido
                    </MenuItem>
                  )}
                >
                  <Icon icon={faEllipsis} />
                </MenuUncontrolled>
              )}
            </Box>
          )
        )
      },
    },
  ]

  return columns
}
