import api from '@/infra/api'
import { AccountPersistentStore } from '@/main/store'
import { useAtomValue } from 'jotai'
import { useNotification } from 'main/hooks/useNotification'

export const useDelete = ({ id, setIsWaitingResponse, refresh }) => {
  const { errorNotification, successNotification } = useNotification()

  const account = useAtomValue(AccountPersistentStore)
  const companyId = account.company.id

  const handleRemove = async () => {
    setIsWaitingResponse(true)
    try {
      await api.delete(`/companies-service/${companyId}/Departments/${id}`)
      successNotification('Excluido com sucesso')
      setIsWaitingResponse(false)
      refresh()
    } catch (error) {
      errorNotification(error.response)
      setIsWaitingResponse(false)
    }
  }

  return {
    handleRemove,
  }
}
