import styled, { css } from 'styled-components'

export const CompositeDateContainer = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
`

export const CalendarIconWrapper = styled.button`
  height: 28px;
  width: 28px;
  border: none;
  border-radius: 50%;
  background-color: #48f06b;
  margin-right: 8px;
  cursor: pointer;

  > svg {
    height: 18px;
    width: 18px;
  }
`

export const CalendarWrapper = styled.div`
  position: absolute;
  z-index: 1001;
  ${({ position }) =>
    position &&
    css`
      top: ${position.top}px;
      left: ${position.left}px;
    `}

  ${({ visible }) =>
    !visible &&
    css`
      height: 0;
      width: 0;
      overflow: hidden;
    `}
`

export const DateLabel = styled.span`
  font-size: 0.875rem;
  white-space: nowrap;
`
