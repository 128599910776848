import React, { useEffect, useState } from 'react'
import { Container, Text, SelectPositionHolder } from './style'
import DropdownButton from 'presentation/components/dropdownButton'
import _ from 'lodash'

const ResultAmount = ({ initialValue = 10, setPageSize, totalItems, id = 'pagination_' + _.uniqueId(), ...props }) => {
  const [options, setOptions] = useState([10, 15, 20, 25, 50, 100, 500, 1000])

  function handleOnOptionClick(index, amount) {
    options[index] = options[0]
    const orderedOptions = options.slice(1, options.length).sort((a, b) => a - b)
    setOptions([amount, ...orderedOptions])
    setPageSize && setPageSize(amount)
  }

  useEffect(() => {
    handleOnOptionClick(options.indexOf(initialValue), initialValue)
    // eslint-disable-next-line
  }, [])

  return (
    <Container {...props}>
      <Text>Exibir</Text>
      <SelectPositionHolder>
        <DropdownButton isMenuOpen={true} title={options[0]} id={id}>
          {options.map((amount, index) => (
            <DropdownButton.Item
              id={id + '_option_' + index}
              key={index}
              onClick={() => {
                handleOnOptionClick(index, amount)
              }}
            >
              {amount}
            </DropdownButton.Item>
          ))}
        </DropdownButton>
      </SelectPositionHolder>
      <Text>resultados de {totalItems}</Text>
    </Container>
  )
}

export default ResultAmount
