import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { useNotification } from 'main/hooks/useNotification'

import { EMPLOYEE_STATUS } from 'utils/constants'
import { patchActivateEmployee, patchInactivateEmployee } from '@/services/employeesServices'
import { getEmployeesRequest } from '@/store/modules/employees/actions'
import { getEmployeesReport } from '@/services/reportsServices'
import blobDownload from '@/utils/blobDownload'

import paginatorMethods from '@/utils/paginatorMethods'

import { clearEmployeeStore, getEmployeeRequest } from '@/store/modules/employee/actions'
import { useAtomValue } from 'jotai'
import { AccountPersistentStore } from '@/main/store'

export const useEmployee = ({ paginator }) => {
  const dispatch = useDispatch()
  const account = useAtomValue(AccountPersistentStore)

  const { successNotification, errorNotification } = useNotification()

  const [employeeToChangeStatus, setEmployeeToChangeStatus] = useState(null)
  const [changingEmployeeStatus, setChangingEmployeeStatus] = useState(false)
  const [isHasOrderModalVisible, setHasOrderModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  const isEmployeeActive = employeeToChangeStatus?.status === EMPLOYEE_STATUS.ACTIVE

  function sendEmployeeToStore(data) {
    dispatch(getEmployeeRequest(data?.employeeId))
  }

  const changeEmployeeStatus = async (employeeId) => {
    setChangingEmployeeStatus(true)
    const action = isEmployeeActive ? patchInactivateEmployee : patchActivateEmployee
    const { error, response } = await action(employeeId)
    if (response) {
      successNotification(`Colaborador ${!isEmployeeActive ? 'ativado' : 'inativado'}!`)
    } else {
      error.response?.data?.errors?.[0]?.mensagem === 'Colaborador possui pedidos em aberto!'
        ? setHasOrderModalVisible(true)
        : errorNotification(error.response)
    }
    dispatch(getEmployeesRequest(paginatorMethods.buildQuery(paginator)))
    setEmployeeToChangeStatus(false)
    setChangingEmployeeStatus(false)
  }

  const requestEmployeesReport = async () => {
    setLoading(true)

    const { response, error } = await getEmployeesReport(account.company.id)
    if (response) {
      const blob = new Blob([response.data], { type: 'text/plain' })
      const filename = `Relatório de colaboradores.pdf`
      blobDownload(blob, filename)
    } else errorNotification(error.response)
    setLoading(false)
  }

  useEffect(() => {
    dispatch(clearEmployeeStore())
    // eslint-disable-next-line
  }, [])

  return {
    sendEmployeeToStore,
    changeEmployeeStatus,
    employeeToChangeStatus,
    changingEmployeeStatus,
    isHasOrderModalVisible,
    isEmployeeActive,
    setEmployeeToChangeStatus,
    setHasOrderModalVisible,
    requestEmployeesReport,
    loading,
  }
}
