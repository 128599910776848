import { Spinner } from 'presentation/components'
import React from 'react'
import { LoadingWrapper, ReportContainer, ReportLink } from './styles'

export const Report = ({ children, generateReport, loading }) => {
  return (
    <ReportContainer>
      {children}
      {loading ? (
        <LoadingWrapper>
          <Spinner variant='sm' />
        </LoadingWrapper>
      ) : (
        generateReport && <ReportLink onClick={generateReport}>Gerar Relatório</ReportLink>
      )}
    </ReportContainer>
  )
}

export default Report
