import { Box, Button, Divider, Paper, Typography } from 'presentation/components/atoms'
import { Items, OrderType } from '../Items/items'
import { useState } from 'react'
import { CancelDialog } from './cancelDialog'
import { formatMoney } from 'utils/functionUtils'
import { IDetails } from '@/presentation/pages/recharge/rechargePaymentConfirmation/hooks/useRequestInfos/utils/detailsData'

interface DetailsProps {
  items: IDetails[]
  cancelOrder: () => Promise<void>
  title: 'Recarga' | 'Cartão 1ª via' | 'Cartão 2ª via'
  operatorCardName: string
  total?: number
}

export const Details = ({ items, cancelOrder, title, operatorCardName, total }: DetailsProps) => {
  const [isCartCancelDialogOpen, setIsCartCancelDialogOpen] = useState(false)
  const orderType = {
    Recarga: 'recharge',
    'Cartão 1ª via': 'firstCopy',
    'Cartão 2ª via': 'secondCopy',
  }

  return (
    <Paper sx={{ padding: '16px' }} elevation={0}>
      <Typography variant='h2' sx={{ marginBottom: '8px' }}>
        Detalhes do pedido
      </Typography>
      <Typography variant='subtitle2' sx={{ fontWeight: 400 }}>
        Revise as informações do seu pedido, cancele ou conclua sua compra.
      </Typography>
      <Divider sx={{ margin: '16px 0' }} />
      <Box sx={{ marginBottom: '32px', padding: '0 16px' }}>
        <Typography variant='h4' sx={{ margin: '16px 0' }}>
          Resumo
        </Typography>
        <Box sx={{ margin: '16px 0' }}>
          <p>
            {items?.length} {items?.length > 1 ? 'itens' : 'item'}
          </p>
        </Box>
      </Box>
      <span>
        <Items
          operatorCardName={operatorCardName}
          orders={items}
          title={title}
          orderType={orderType[title as keyof typeof orderType] as OrderType}
        />
      </span>
      <Box sx={{ marginTop: '32px', display: 'flex', alignItems: 'end', gap: '16px' }}>
        <Typography sx={{ marginLeft: 'auto', fontWeight: 600 }} id='recharge_manual_detail_total_label'>
          Total:{' '}
        </Typography>
        <Typography variant='h4' sx={{ fontWeight: 600 }} id='recharge_manual_detail_total_value'>
          <span style={{ fontWeight: 400 }}>R$ </span> {formatMoney(total || items?.[0]?.totalValue)}
        </Typography>
      </Box>
      <Button
        variant='outlined'
        sx={{ marginTop: '32px', filter: 'grayscale(100%)' }}
        onClick={() => setIsCartCancelDialogOpen(true)}
        id='recharge_manual_cancel_order'
      >
        Cancelar Pedido
      </Button>
      <CancelDialog open={isCartCancelDialogOpen} setOpen={setIsCartCancelDialogOpen} cancelOrder={cancelOrder} />
    </Paper>
  )
}
