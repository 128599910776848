import { styled } from '@mui/material'

export const StyledFooter = styled('footer')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: theme.staticSize.footer,
  padding: '8px 32px 0px 32px',
  backgroundColor: theme.palette.brand.primary.main,
  overflow: 'hidden',
  ':before': {
    content: "' '",
    height: 8,
    width: '100%',
    backgroundColor: theme.palette.brand.secondary.main,
    position: 'absolute',
    top: 0,
    left: 0,
  },
}))
