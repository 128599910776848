import { useState, useEffect } from 'react'
import { Container, Col, useScreenClass } from 'react-grid-system'
import { Row } from '../../styles'
import dayjs from 'dayjs'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { Pagination, Table } from 'presentation/components'
import { Search } from 'presentation/components/molecules'
import { Filter } from 'presentation/components/organisms'
import { useDispatch } from 'react-redux'
import { trackHistoryRequest } from '@/store/modules/cards/trackHistory/actions'
import paginatorMethods from '@/utils/paginatorMethods'
import { useCardsHistoric } from './hooks/useCardsHistoric'
import { TableHead } from './components/TableHead/TableHead'
import { TableBody } from './components/TableBody/TableBody'
import { Button } from 'presentation/components/atoms'
import { usePaginationOld } from 'main/hooks/usePaginationOld'

const Historic = () => {
  const { cards, loading, cardsPagination } = useCardsHistoric()

  const [filterModal, setFilterModal] = useState(false)
  const screenClass = useScreenClass()

  const query = {
    Sort: 'number',
  }
  const { paginator, setPaginator } = usePaginationOld({ query })

  const [maxDate, setMaxDate] = useState(dayjs(new Date()))
  const [selectedInitDate, setSelectedIniDate] = useState(false)

  const dateMax = new Date(maxDate)
  dateMax.setDate(dateMax.getDate() + 180)

  const newMaxDate = moment(new Date(maxDate)).diff(new Date(), 'days') < -180 ? dayjs(dateMax) : dayjs(new Date())

  const dispatch = useDispatch()

  useEffect(() => {
    const hasInitDate = paginator.query?.initDate
    const hasEndDate = paginator.query?.endDate

    if (hasInitDate || hasEndDate) {
      const initDate = new Date(paginator.query?.initDate)?.toLocaleDateString().replaceAll('/', '-')
      const endDate = new Date(paginator.query?.endDate)?.toLocaleDateString().replaceAll('/', '-')

      delete paginator?.query?.initDate
      delete paginator?.query?.endDate

      const BlockDate = `${initDate}><${endDate}`

      paginator.doRequest &&
        dispatch(
          trackHistoryRequest(paginatorMethods.buildQuery({ ...paginator, query: { ...paginator.query, BlockDate } })),
        )

      return
    }

    paginator.doRequest && dispatch(trackHistoryRequest(paginatorMethods.buildQuery(paginator)))
    // eslint-disable-next-line
  }, [paginator])

  useEffect(() => {
    setPaginator(
      paginatorMethods.updatePaginator(paginator, {
        ...cardsPagination,
      }),
    )
    // eslint-disable-next-line
  }, [])

  const search = (value) => {
    setPaginator(
      paginatorMethods.setFilters(paginator, {
        filterValue: value,
        filterProps: 'oldCardNumber,newCardNumber,employeeName',
      }),
    )
  }

  return (
    <Container style={{ padding: 0, maxWidth: '100%' }}>
      <Row justify={['xs', 'sm'].includes(screenClass) ? 'center' : 'between'}>
        <Col xs={12} sm={12} md={7} lg={6} xl={5}>
          <Search onSearch={search} placeholder='Buscar na tabela abaixo ...' />
        </Col>
        <Col
          sm={12}
          md='content'
          style={{
            marginTop: ['xs', 'sm'].includes(screenClass) ? '15px' : '0',
          }}
        >
          <Button
            variant='outlined'
            onClick={() => setFilterModal(true)}
            fullWidth={['xs', 'sm'].includes(screenClass)}
            startIcon={<FontAwesomeIcon icon={faFilter} />}
          >
            Filtrar
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table loading={loading}>
            <TableHead />
            <TableBody cards={cards} />
          </Table>
        </Col>
      </Row>

      <Row justify={['xs', 'sm'].includes(screenClass) ? 'center' : 'between'} align='center'>
        <Col xs='12' md='content' style={{ display: 'flex', justifyContent: 'center' }}>
          <Pagination.ResultAmount
            totalItems={cardsPagination?.totalItens}
            setPageSize={(newPageSize) => setPaginator(paginatorMethods.setPageSize(paginator, newPageSize))}
          />
        </Col>
        <Col
          xs='content'
          style={{
            marginTop: ['xs', 'sm'].includes(screenClass) ? '16px' : '0',
          }}
        >
          <Pagination
            paginator={paginator}
            setPage={(wantedPage) => setPaginator(paginatorMethods.setPage(paginator, wantedPage))}
          />
        </Col>
      </Row>
      <Filter
        open={filterModal}
        onClose={() => {
          setFilterModal(false)
          setSelectedIniDate(false)
          setMaxDate(dayjs(new Date()))
        }}
        onFilter={(value) => {
          const filterValues = Object.keys(value).reduce((final, actual) => {
            const newFinal = { ...final }
            if (value[actual].length !== 0 && value[actual] !== '') newFinal[actual] = value[actual]
            return newFinal
          }, {})
          setPaginator(paginatorMethods.setFilters(paginator, filterValues))
          setFilterModal(false)
        }}
        onClean={() => {
          setPaginator(paginatorMethods.resetFilters(paginator))
          setMaxDate(dayjs(new Date()))
          setSelectedIniDate(false)
        }}
        fields={[
          {
            type: 'date',
            name: 'initDate',
            props: {
              label: 'Data inicial do bloqueio',
              fullWidth: true,
              maxDate: dayjs(new Date()),
              setMaxDate,
              setSelectedIniDate,
            },
          },
          {
            type: 'date',
            name: 'endDate',
            rules: { required: selectedInitDate },
            props: {
              label: 'Data final do bloqueio',
              fullWidth: true,
              maxDate: newMaxDate,
              minDate: maxDate,
              disabled: !selectedInitDate,
            },
          },
        ]}
      />
    </Container>
  )
}

export default Historic
