import styled from 'styled-components'

import { TableHead as Head } from 'presentation/components/atoms'

export const TableHead = styled(Head)`
  & {
    background-color: #f3f6f9;
    border-radius: 6px;
    height: 48px;

    th {
      padding: 0 16px;
    }
  }
`
