import { Box, styled } from '@mui/material'

export const Steps = styled('ul')(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 24,
  fontSize: 18,
  marginTop: 24,
  li: {
    fontSize: 18,
    fontWeight: 400,
  },
}))

export const QRCodeContent = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  width: 200,
  height: 200,
  'img:first-child': {
    position: 'absolute',
  },
  'img:last-child': {
    position: 'relative',
    zIndex: 10,
    margin: '0 auto',
  },
  '.loading': {
    animation: 'loading 1.5s infinite linear',
    '@keyframes loading': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
  },
}))

export const Total = styled('p')(() => ({
  fontSize: 18,
  fontWeight: 400,
  span: {
    fontWeight: 700,
  },
  marginBottom: 8,
}))

export const Clipboard = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',
  padding: '12px 8px',
  borderRadius: 4,
  background: theme.palette.grey['50'],
  width: '100%',
  maxWidth: 825,
  height: 48,
  marginTop: 24,
  marginBottom: 8,
  input: {
    width: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.grey['700'],
    textOverflow: 'ellipsis',
    border: 'none',
    background: 'transparent',
  },
  img: {
    cursor: 'pointer',
  },
  '.loading': {
    animation: 'loading 1.5s infinite linear',
    '@keyframes loading': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
  },
}))

export const Expire = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  marginTop: 24,
  p: {
    fontFamily: 'Montserrat',
    fontSize: '14px',
    color: theme.palette.grey['900'],
  },
  span: {
    color: theme.palette.warning.dark,
    fontWeight: 600,
  },
}))

export const Expired = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.warning.dark,
  fontWeight: 600,
  fontSize: 18,
  marginTop: 24,
}))
