const ACTION = '@employee/'

export const ACTIONS = {
  GET_EMPLOYEE_REQUEST: ACTION + 'GET_EMPLOYEE_REQUEST',
  EMPLOYEE_REQUEST_SUCCESS: ACTION + 'EMPLOYEE_REQUEST_SUCCESS',
  EMPLOYEE_REQUEST_FAILURE: ACTION + 'EMPLOYEE_REQUEST_FAILURE',
  STORE_EMPLOYEE_PERSONAL_DATA: ACTION + 'STORE_EMPLOYEE_PERSONAL_DATA',
  STORE_EMPLOYEE_ADDRESS_DATA: ACTION + 'STORE_EMPLOYEE_ADDRESS_DATA',
  STORE_EMPLOYEE_PROFESSIONAL_DATA: ACTION + 'STORE_EMPLOYEE_PROFESSIONAL_DATA',
  STORE_EMPLOYEE_DATA: ACTION + 'STORE_EMPLOYEE_DATA',
  CLEAR_EMPLOYEE_STORE: ACTION + 'CLEAR_EMPLOYEE_STORE',
}

export function getEmployeeRequest(employeeId) {
  return {
    type: ACTIONS.GET_EMPLOYEE_REQUEST,
    payload: { employeeId },
  }
}

export function employeeRequestSuccess(data) {
  return {
    type: ACTIONS.EMPLOYEE_REQUEST_SUCCESS,
    payload: { data },
  }
}

export function employeeRequestFailure(data) {
  return {
    type: ACTIONS.EMPLOYEE_REQUEST_FAILURE,
    payload: { data },
  }
}

export function employeePersonalDataStore(data) {
  return {
    type: ACTIONS.STORE_EMPLOYEE_PERSONAL_DATA,
    payload: data,
  }
}

export function employeeAddressStore(data) {
  return {
    type: ACTIONS.STORE_EMPLOYEE_ADDRESS_DATA,
    payload: data,
  }
}

export function employeeProfessionalDataStore(data) {
  return {
    type: ACTIONS.STORE_EMPLOYEE_PROFESSIONAL_DATA,
    payload: data,
  }
}

export function sendToStore(data) {
  return {
    type: ACTIONS.STORE_EMPLOYEE_DATA,
    payload: data,
  }
}

export function clearEmployeeStore() {
  return {
    type: ACTIONS.CLEAR_EMPLOYEE_STORE,
  }
}
