import { StyledLink, StyledNavLink } from './styles'
import { NavLinkProps } from 'react-router-dom'

interface LinkProps {
  external?: boolean
  to?: string
  children: any
}

export const Link = ({ external, to = '', ...props }: LinkProps) => {
  const LinkComp = external ? StyledLink : StyledNavLink
  return <LinkComp to={to} {...props} />
}
