import { Spinner } from 'presentation/components'
import { CustomTable, TableContainer } from './style.js'

const Table = ({ children, loading, maxHeight, overflow = 'auto', ...props }) => (
  <TableContainer maxHeight={maxHeight} overflow={overflow}>
    {loading ? (
      <Spinner.Box style={{ height: maxHeight }}>
        <Spinner />
      </Spinner.Box>
    ) : (
      <CustomTable {...props}>{children}</CustomTable>
    )}
  </TableContainer>
)

export default Table
