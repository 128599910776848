import { useDispatch } from 'react-redux'

import { pushNotification } from '@/store/modules/notification/actions'

import errorHandler from '@/utils/errorHandler'
import successHandler from 'utils/successHandler'

export const useNotification = () => {
  const dispatch = useDispatch()

  const errorNotification = (error) => {
    dispatch(pushNotification(errorHandler(error)))
  }

  const successNotification = (message) => {
    if (typeof message === 'string') dispatch(pushNotification(successHandler(message)))
    else dispatch(pushNotification(message))
  }

  return {
    errorNotification,
    successNotification,
  }
}
