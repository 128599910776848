import apiCallHandler from './apiCallHandler'
import api from '@/infra/api'

export const getEmployeeBenefitCards = (query = '') => {
  return apiCallHandler(() => api.get(`rh-bff/Benefit/employees/cards/paged${query}`))
}

export const getBenefitsCards = (employeeId) => {
  return apiCallHandler(() => api.get(`rh-bff/Benefit/linkcard/operatorcards?employeeId=${employeeId}`))
}

export const getCardsFees = (employeeId, operatorCardId, cardNumber) => {
  return apiCallHandler(() =>
    api.get(
      `rh-bff/Benefit/linkcard/employeecardsfees?employeeId=${employeeId}&operatorCardId=${operatorCardId}&cardNumber=${cardNumber}`,
    ),
  )
}

export const getBenefitToEdit = (benefitId) => {
  return apiCallHandler(() => api.get(`rh-bff/Benefit/edit/${benefitId}`))
}

export const postNewBenefit = (payload) => {
  return apiCallHandler(() => api.post(`rh-bff/Benefit/new`, payload))
}

export const putEditBenefit = (benefitId, payload) => {
  return apiCallHandler(() => api.put(`rh-bff/Benefit/edit/${benefitId}`, payload))
}

export const patchChangeBindedBenefitStatus = (benefitId, status) => {
  return apiCallHandler(() => api.patch(`rh-bff/Benefit/${benefitId}/activate/${status}`))
}
