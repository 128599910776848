import React, { useState, useEffect } from 'react'
import {
  ModalHeader,
  ModalBody,
  ModalTitle,
  ModalContainer,
  ModalFooter,
  NoEmployees,
  StyledInput,
  StyledInputLabel,
} from '../../styles'
import { Icon, Input, Table } from 'presentation/components'
import 'react-responsive-modal/styles.css'
import { Modal } from 'react-responsive-modal'
import { useDispatch } from 'react-redux'
import { pushNotification } from '@/store/modules/notification/actions'
import { Row } from '../../styles'
import { Container, Col } from 'react-grid-system'
import { Search } from 'presentation/components/molecules/search'
import useDebounce from 'main/hooks/useDebouce'
import Button from 'presentation/components/molecules/form/button/button'
import { Checkbox } from 'presentation/components/atoms'

import { Controller, useForm } from 'react-hook-form'
import useService from 'main/hooks/useService'
import { getBenefitsEmployees, patchInsertWorkingDays } from 'services/employeesServices'
import successHandler from 'utils/successHandler'

const AssignDaysModal = ({ open, onClose }) => {
  const debounce = useDebounce()
  const dispatch = useDispatch()
  const [textFilter, setTextFilter] = useState('')

  const { handleSubmit, control, reset } = useForm({ defaultValues: { days: '' } })

  const [insertDaysState, insertDaysRequest] = useService(patchInsertWorkingDays, {
    onCompleted: (res) => {
      dispatch(pushNotification(successHandler('Dias em massa inseridos com sucesso!')))
      reset()
      onClose(null, true)
    },
  })

  const [fetchEmployeesState, fetchEmployeesRequest] = useService(getBenefitsEmployees)

  const employees = fetchEmployeesState?.response?.data || []
  const isLoading = insertDaysState.loading || fetchEmployeesState.loading

  const [selectedEmployees, setSelectedEmployees] = useState([])

  const onSubmit = async (data) => {
    insertDaysRequest(data.days, selectedEmployees)
  }

  const filterCustom = () => {
    let itemsToRender = employees.filter((employee) => employee.status === 1)

    const sortByKey = (key) => (a, b) =>
      String(a[key]).toLocaleLowerCase() > String(b[key]).toLocaleLowerCase() ? 1 : -1
    itemsToRender = itemsToRender.slice().sort(sortByKey('fullName'))
    if (textFilter !== '');
    itemsToRender = itemsToRender.filter(
      (item) =>
        String(item.fullName).toLocaleLowerCase().includes(textFilter.toLocaleLowerCase()) ||
        String(item.registration).toLocaleLowerCase().includes(textFilter.toLocaleLowerCase()),
    )
    return itemsToRender
  }

  const toRender = filterCustom()
  const isFiltered = toRender.length !== employees.length
  const isAllSelected = toRender.every((employee) => {
    return selectedEmployees.some((selectedId) => selectedId === employee.employeeId)
  })

  const selectEmployess = (id) => {
    const select = [...selectedEmployees]
    if (select.includes(id)) {
      const indexToRemove = select.indexOf(id)
      select.splice(indexToRemove, 1)
      setSelectedEmployees(select)
    } else {
      select.push(id)
      setSelectedEmployees(select)
    }
  }

  const selectAllEmployees = () => {
    if (isAllSelected) {
      if (!isFiltered) setSelectedEmployees([])
      else {
        const filteredToRemove = selectedEmployees.filter(
          (employeeId) => !toRender.some((employee) => employee.employeeId === employeeId),
        )
        setSelectedEmployees(filteredToRemove)
      }
    } else {
      if (!isFiltered) setSelectedEmployees(toRender.map((employee) => employee.employeeId))
      else {
        const notIn = toRender.filter((employee) => !selectedEmployees.includes(employee.employeeId))
        const notInIds = notIn.map((employee) => employee.employeeId)
        const newSelecteds = [...selectedEmployees, ...notInIds]
        setSelectedEmployees(newSelecteds)
      }
    }
  }

  const renderRows = () => {
    if (textFilter !== '' && toRender.length === 0)
      return (
        <Table.Tr>
          <Table.Td colSpan={3} style={{ padding: 0 }}>
            <NoEmployees>Nenhum resultado encontrado</NoEmployees>
          </Table.Td>
        </Table.Tr>
      )

    if (toRender.length === 0)
      return (
        <Table.Tr>
          <Table.Td colSpan={3} style={{ padding: 0 }}>
            <NoEmployees>Sem colaboradores disponíveis</NoEmployees>
          </Table.Td>
        </Table.Tr>
      )

    return (
      <>
        {toRender?.map((employee, index) => (
          <Table.Tr key={employee?.employeeId}>
            <Table.Td style={{ padding: 0 }}>
              <Checkbox
                style={{
                  float: 'left',
                  padding: '0 0px 0 14px',
                }}
                checked={selectedEmployees.includes(employee?.employeeId)}
                onChange={() => selectEmployess(employee?.employeeId, index)}
              />
            </Table.Td>
            <Table.Td>{employee?.fullName}</Table.Td>
            <Table.Td>{employee?.registration}</Table.Td>
          </Table.Tr>
        ))}
      </>
    )
  }

  useEffect(() => {
    fetchEmployeesRequest()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        closeOnOverlayClick={false}
        center
        closeIcon={<Icon name='x' />}
        styles={{
          modal: {
            width: '770px',
            height: 'auto',
            maxWidth: '100%',
            margin: '0',
            maxHeight: '87%',
          },
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalContainer style={{ overflow: 'hidden' }}>
            <ModalHeader>
              <ModalTitle>Inserir dias em massa</ModalTitle>
            </ModalHeader>
            <div>
              Informe a quantidade de dias e em seguida selecione os colaboradores que terão os dias creditados.
            </div>
            <Row>
              <Col xs={4}>
                <StyledInputLabel>
                  <label>Qtde de dias</label>
                </StyledInputLabel>
                <StyledInput>
                  <Controller
                    control={control}
                    name='days'
                    render={({ field: { onChange, value, ref } }) => (
                      <Input.Masked
                        required
                        isAllowed={(values) => {
                          const { floatValue } = values
                          return (floatValue < 32 && floatValue > 0) || floatValue === undefined
                        }}
                        value={value}
                        placeholder='0'
                        onValueChange={(values) => {
                          onChange(values.value)
                        }}
                        allowNegative={false}
                        getInputRef={ref}
                      />
                    )}
                  />
                </StyledInput>
              </Col>
              <Col xs={1} />
              <Col xs={7}>
                <StyledInputLabel>
                  <label>&nbsp;</label>
                </StyledInputLabel>
                <Search
                  style={{ margin: 0 }}
                  placeholder='Buscar na tabela abaixo ...'
                  onSearch={(value) => debounce(() => setTextFilter(value), 300)}
                  searchOnChange
                />
              </Col>
            </Row>

            <ModalBody>
              <Container style={{ width: '100%' }}>
                <Row>
                  <Table loading={isLoading} maxHeight='250px'>
                    <Col>
                      <tbody>
                        <Table.Tr>
                          <Table.Th
                            style={{
                              width: '5%',
                              float: 'left',
                              padding: '0 0px 0 4px',
                            }}
                          >
                            <Checkbox
                              style={{
                                float: 'left',
                                padding: '0 0px 0 14px;',
                              }}
                              checked={isAllSelected}
                              onChange={selectAllEmployees}
                            />
                          </Table.Th>
                          <Table.Th style={{ width: '70%' }}>Colaborador</Table.Th>
                          <Table.Th style={{ width: '25%' }}>Matrícula</Table.Th>
                        </Table.Tr>
                        {renderRows()}
                      </tbody>
                    </Col>
                  </Table>
                </Row>
              </Container>
            </ModalBody>
            <ModalFooter
              style={{
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Button width='250px' variant='outlined' onClick={onClose}>
                Cancelar
              </Button>
              <Button
                type='submit'
                width='250px'
                disabled={selectedEmployees.length === 0}
                sx={{ width: '248px', float: 'right' }}
                form='accountInfoEditForm'
                style={{ float: 'rigth' }}
              >
                Salvar
              </Button>
            </ModalFooter>
          </ModalContainer>
        </form>
      </Modal>
    </>
  )
}

export default AssignDaysModal
