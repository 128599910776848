import styled from 'styled-components'

export const Label = styled.label`
  display: block;
  margin-bottom: 16px;
`

export const CheckBoxWrapper = styled.div`
  display: flex;
  span {
    margin-left: 8px;
  }
`

export const InputWrapper = styled.div`
  min-width: 280px;
`
