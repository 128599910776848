export const minTwoDigits = (number) => {
  var s = String(number)
  while (s.length < 2) {
    s = '0' + s
  }
  return s
}

export const hideDocument = (document) => {
  if (!document) return
  const hidedDoc = `***.${document.slice(3, 6)}.${document.slice(6, 9)}-**`
  return hidedDoc
}

export const removeSpecialCharacters = (string) => string?.replace(/[^0-9|a-z]/gi, '')

export const removeCharactersFromString = (charactersString, string) => {
  const charactersArray = Array.from(charactersString)
  return charactersArray.reduce((prev, char) => prev.replaceAll(char, ''), string)
}

export const reduceBigTextLines = (text, limit) => {
  if (!text) return
  return text.length > limit ? text.slice(0, limit) + ' . . .' : text
}

export const isValidDate = (date) => {
  return date instanceof Date && !isNaN(date)
}

export const sumDaysToDate = (date, days) => {
  const newDate = new Date(date)
  newDate.setDate(newDate.getDate() + days)
  return newDate
}

export const formatMoney = (value = 0) => {
  if (value === null) value = 0
  if (typeof value === 'string') value = value.replace(',', '.')
  const valueToBeFormated = Number(value) ? value : 0
  if (Object.is(Number(value), -0)) value *= -1
  return parseFloat(valueToBeFormated).toLocaleString('pt-br', { minimumFractionDigits: 2 })
}

export const findOption = (options, key, keyValue) => {
  const option = options.find((item) => item[key] === keyValue)
  return option
}

export const download = (url, filename = 'file') => {
  const a = document.createElement('a')
  a.href = url
  a.download = filename
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

export const roundToMaxTwoDecimals = (num) => {
  return +(Math.round(num + 'e+2') + 'e-2')
}

export const removeObjectEmptyItems = (object) => {
  let newObject = { ...object }
  for (let key in newObject) {
    if (!newObject[key]) delete newObject[key]
  }
  return newObject
}

export const sendToTransferArea = (string) => {
  navigator.clipboard.writeText(string)
}

export const getResaleSiteFromUrl = () => {
  const site = window.location.hostname.split('.')[0]
  if (process.env.NODE_ENV === 'development' && site === 'localhost') return 'motiva'
  return window.location.hostname.split('.')[0]
}

export const queryStringToJSON = (qs) => {
  let pairs = qs.split('&')
  let result = {}
  pairs.forEach(function (p) {
    const pair = p.split('=')
    const key = pair[0]
    const value = decodeURIComponent(pair[1] || '')

    if (result[key]) {
      if (Object.prototype.toString.call(result[key]) === '[object Array]') {
        result[key].push(value)
      } else {
        result[key] = [result[key], value]
      }
    } else {
      result[key] = value
    }
  })

  return JSON.parse(JSON.stringify(result))
}

export const isOverflowing = (element) => {
  if (!element) return undefined
  return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth
}

export const isUrlValid = (str) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' +
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
      '((\\d{1,3}\\.){3}\\d{1,3}))' +
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
      '(\\?[;&a-z\\d%_.~+=-]*)?' +
      '(\\#[-a-z\\d_]*)?$',
    'i',
  )
  return pattern.test(str)
}
