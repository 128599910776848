import { AppBar, styled } from '@mui/material'

export const StyledHeader = styled(AppBar)(({ theme }) => ({
  flexDirection: 'row',
  height: theme.staticSize.header,
  padding: '0px 32px 8px 32px',
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',
  backgroundColor: theme.palette.brand.primary.main,
  ':before': {
    content: "' '",
    height: 8,
    width: '100%',
    backgroundColor: theme.palette.brand.secondary.main,
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
}))
