import { styled } from '@mui/material'
import { Box, Typography } from 'presentation/components/atoms'

export const ItemContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  borderBottom: '1px solid' + theme.palette.grey[100],
  paddingBottom: '16px',
  '& + &': { marginTop: '32px' },
}))

export const ItemHeader = styled(Box)(({ theme }) => ({
  padding: '4px 8px',
  backgroundColor: theme.palette.grey[50],
  height: '32px',
  display: 'flex',
  gap: '32px',
  borderRadius: '4px',
}))

export const ItemValuesContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  alignItems: 'start',
  '&:last-of-type': {
    textAlign: 'left',
  },
}))

export const ItemLabel = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  color: theme.palette.grey[700],
}))
