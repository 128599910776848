import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import './config/ReactotronConfig'
import { store, persistor } from './store'
import { QueryProvider } from '@/config/providers'
import { RoutesProvider } from './config/providers/routesProvider'
import { ThemeProvider } from './config/providers/themeProvider'

function App() {
  return (
    <ThemeProvider>
      <QueryProvider>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <RoutesProvider />
          </PersistGate>
        </Provider>
      </QueryProvider>
    </ThemeProvider>
  )
}
export default App
