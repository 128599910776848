import { useState, useCallback, useEffect } from 'react'
import { Container, Col } from 'react-grid-system'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import schema from '../schema/professionalData'
import { useSelector, useDispatch } from 'react-redux'
import { FORM_MODE } from '@/utils/constants'
import { getAddresses } from '../requestApi'
import { IoIosInformationCircleOutline } from 'react-icons/io'
import { Input, Button, ErrorMessage, ErrorWrapper, ModalV2, Spacing } from 'presentation/components'
import { FieldSet, HasOderText, Label, Row } from './styles'
import { pushNotification } from '@/store/modules/notification/actions'
import successHandler from '@/utils/successHandler'
import errorHandler from '@/utils/errorHandler'
import {
  patchActivateEmployee,
  patchInactivateEmployee,
  postCreateEmployee,
  putEditEmployee,
} from '@/services/employeesServices'
import useService from 'main/hooks/useService'
import { getAllDepartments } from '@/services/departmentsServices'
import { useNavigate } from 'react-router-dom'
import { useAtomValue } from 'jotai'
import { AccountPersistentStore } from '@/main/store'

const ProfessionalData = ({
  changeForm,
  returnToList,
  employeeData,
  prevTab,
  cancel,
  mode,
  saveOnStore,
  setIsLoading,
}) => {
  const navigate = useNavigate()
  const [addresses, setAddresses] = useState(null)
  const [isChangeEmployeeStatusModalVisible, setIsChangeEmployeeStatusModalVisible] = useState()
  const [isHasOrderModalVisible, setHasOrderModalVisible] = useState(false)
  const [isEmployeeActive, setIsEmployeeActive] = useState(employeeData?.enabled)
  const [sendToApi, setSendToApi] = useState(false)
  const dispatch = useDispatch()
  const account = useAtomValue(AccountPersistentStore)
  const store = useSelector((state) => state?.employee)
  const [getDepartmentState, getDepartmentsRequest] = useService(getAllDepartments)
  const departments = getDepartmentState.response?.data
  const readOnly = mode === FORM_MODE.VIEW ? true : false
  let defaultValues = {}
  if (employeeData != null) {
    defaultValues = {
      registration: employeeData?.professionalData?.registration || undefined,
      workingDays: employeeData?.professionalData?.workingDays || undefined,
      email: employeeData?.professionalData?.email || undefined,
      cellphoneNumber: employeeData?.professionalData?.cellphoneNumber || undefined,
      secondCellphoneNumber: employeeData?.professionalData?.secondCellphoneNumber || undefined,
      role: employeeData?.professionalData?.role || undefined,
    }
  }

  const saveApi = useCallback(
    async (payload) => {
      const { error, response } = await postCreateEmployee(payload)
      if (response) return response
      else dispatch(pushNotification(errorHandler(error.response)))
    },
    [dispatch],
  )

  const updateApi = useCallback(
    async (payload) => {
      const { error, response } = await putEditEmployee(payload)
      if (response) return response
      else dispatch(pushNotification(errorHandler(error.response)))
    },
    [dispatch],
  )

  const apiCall = useCallback(async () => {
    if (sendToApi) {
      setIsLoading(true)
      let response = null
      if (mode === FORM_MODE.ADD) {
        response = await saveApi(store?.data)
      } else if (mode === FORM_MODE.EDIT) {
        response = await updateApi({ ...store?.data, id: employeeData?.id })
      }
      if (response) {
        if (mode === FORM_MODE.ADD) {
          saveOnStore({
            ...store?.data?.professionalData,
            id: response?.data,
          })
        }

        dispatch(pushNotification(successHandler('Colaborador salvo com sucesso')))
        const benefitTab = 3
        navigate('/colaboradores/visualizar', {
          state: { mode: FORM_MODE.VIEW, tab: benefitTab },
        })
      }
      setSendToApi(false)
      setIsLoading(false)
    }
    // eslint-disable-next-line
  }, [store])
  // eslint-disable-next-line
  useEffect(() => {
    apiCall()
  }, [store])

  useEffect(() => {
    getDepartmentsRequest(account.company?.id)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    ;(async () => {
      const addressesResponse = await getAddresses(account.company?.id)
      setAddresses(addressesResponse)
    })()
  }, [account.company?.id])

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: defaultValues,
    mode: 'onChange',
    resolver: yupResolver(schema),
    shouldUnregister: true,
  })
  const onSubmit = (data) => {
    setSendToApi(true)
    saveOnStore({ ...data, id: mode === FORM_MODE.EDIT ? employeeData?.id : store?.data?.id })
  }
  function createOptionLabel(options, selectedOption, valueName, labelName) {
    const index = options.findIndex((option) => option[valueName] === selectedOption)

    if (index < 0) return
    let optionToDisplay = {}
    optionToDisplay[valueName] = options[index][valueName]
    optionToDisplay[labelName] = options[index][labelName]
    return optionToDisplay
  }

  useEffect(() => {
    if (departments?.length)
      setValue(
        'departmentId',
        createOptionLabel(departments, employeeData?.professionalData?.departmentId, 'id', 'name'),
      )
    // eslint-disable-next-line
  }, [departments])

  useEffect(() => {
    if (addresses?.length)
      setValue('workingPlace', createOptionLabel(addresses, employeeData?.professionalData?.workingPlace, 'id', 'name'))
    // eslint-disable-next-line
  }, [addresses])

  changeForm(() => {
    handleSubmit(saveOnStore)
  })

  const changeEmployeeStatus = async (employeeId) => {
    const action = isEmployeeActive ? patchInactivateEmployee : patchActivateEmployee
    const { error, response } = await action(employeeId)
    if (response) {
      dispatch(pushNotification(successHandler(`Colaborador ${!isEmployeeActive ? 'ativado' : 'inativado'}!`)))
      setIsEmployeeActive((state) => !state)
    } else {
      error.response?.data?.errors[0]?.mensagem === 'Colaborador possui pedidos em aberto!'
        ? setHasOrderModalVisible(true)
        : dispatch(pushNotification(errorHandler(error.response)))
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} id='dadosProfissionais'>
        <FieldSet disabled={readOnly}>
          <Container fluid style={{ margin: 0, padding: 0, maxWidth: 'initial' }}>
            <Row gutterWidth={40}>
              <Col sm={12} md={4} lg={4} xl={3}>
                <ErrorWrapper error={errors.registration}>
                  <Label>Matrícula *</Label>
                  <Input
                    maxLength='15'
                    placeholder='00000000'
                    id='employeeForm_registration'
                    required
                    {...register('registration')}
                  />
                  {errors.registration && <ErrorMessage>{errors.registration.message}</ErrorMessage>}
                </ErrorWrapper>
              </Col>
              <Col sm={12} md={4} lg={4} xl={3}>
                <span>
                  <Label>Departamento</Label>
                  <Controller
                    control={control}
                    name='departmentId'
                    render={({ field: { onChange, value, ref } }) => (
                      <Input.Select
                        placeholder='Selecione o departamento'
                        value={value}
                        inputRef={ref}
                        onChange={onChange}
                        isDisabled={readOnly}
                        options={departments}
                        id='employeeForm_departmentId'
                        getOptionValue={(option) => option?.id}
                        getOptionLabel={(option) => option?.name}
                        isLoading={getDepartmentState?.loading}
                        noOptionsMessage={() => 'Sem opções'}
                      />
                    )}
                  />
                </span>
              </Col>

              <Col sm={12} md={4} lg={4} xl={3}>
                <ErrorWrapper error={errors.role}>
                  <Label>Cargo</Label>
                  <Input maxLength='50' id='employeeForm_role' {...register('role')} />
                  {errors.role && <ErrorMessage>{errors.role.message}</ErrorMessage>}
                </ErrorWrapper>
              </Col>
            </Row>

            <Row gutterWidth={40}>
              <Col sm={12} md={4} lg={4} xl={3}>
                <span>
                  <Label>Endereço do Local de Trabalho</Label>
                  <Controller
                    control={control}
                    name='workingPlace'
                    render={({ field: { onChange, value, ref } }) => (
                      <Input.Select
                        placeholder='Selecione o local'
                        value={value}
                        inputRef={ref}
                        onChange={onChange}
                        isDisabled={readOnly}
                        id='employeeForm_workingPlace'
                        options={addresses}
                        getOptionValue={(option) => option?.id}
                        getOptionLabel={(option) => option?.name}
                        noOptionsMessage={() => 'Sem opções'}
                      />
                    )}
                  />
                </span>
              </Col>
              <Col sm={12} md={4} lg={4} xl={3}>
                <ErrorWrapper error={errors.cellphoneNumber}>
                  <Label>Telefone</Label>
                  <Controller
                    control={control}
                    name='cellphoneNumber'
                    required
                    render={({ field: { onChange, value, ref } }) => (
                      <Input.Masked
                        format='(##) ####-####'
                        placeholder='(00) 0000-0000'
                        value={value}
                        id='employeeForm_cellphoneNumber'
                        getInputRef={ref}
                        onChange={onChange}
                      />
                    )}
                  />
                  {errors.cellphoneNumber && <ErrorMessage>{errors.cellphoneNumber.message}</ErrorMessage>}
                </ErrorWrapper>
              </Col>
              <Col sm={12} md={4} lg={4} xl={3}>
                <ErrorWrapper error={errors.secondCellphoneNumber}>
                  <Label>Celular</Label>
                  <Controller
                    control={control}
                    name='secondCellphoneNumber'
                    required
                    render={({ field: { onChange, value, ref } }) => (
                      <Input.Masked
                        format='(##) #####-####'
                        placeholder='(00) 00000-0000'
                        value={value}
                        id='employeeForm_secondCellphoneNumber'
                        getInputRef={ref}
                        onChange={onChange}
                      />
                    )}
                  />
                  {errors.secondCellphoneNumber && <ErrorMessage>{errors.secondCellphoneNumber.message}</ErrorMessage>}
                </ErrorWrapper>
              </Col>
            </Row>

            <Row gutterWidth={40}>
              <Col sm={12} md={4} lg={4} xl={3}>
                <ErrorWrapper error={errors.email}>
                  <Label>E-mail</Label>
                  <Input
                    maxLength='256'
                    placeholder='colaborador@empresa.com.br'
                    id='employeeForm_email'
                    {...register('email')}
                  />
                  {errors.email && <ErrorMessage>{errors.email.message}</ErrorMessage>}
                </ErrorWrapper>
              </Col>

              <Col sm={12} md={4} lg={4} xl={3}>
                <ErrorWrapper error={errors.workingDays}>
                  <Label>Dias a Trabalhar no Mês</Label>
                  <Controller
                    control={control}
                    name='workingDays'
                    required
                    render={({ field: { onChange, value, ref } }) => (
                      <Input.Masked
                        maxLength='2'
                        placeholder='00'
                        value={value}
                        getInputRef={ref}
                        onChange={onChange}
                        id='employeeForm_workingDays'
                      />
                    )}
                  />
                  {errors.workingDays && <ErrorMessage>{errors.workingDays.message}</ErrorMessage>}
                </ErrorWrapper>
              </Col>
            </Row>
          </Container>
        </FieldSet>
      </form>
      <Spacing top='16px' />
      <Row justify='end'>
        <Col sm='content'>
          {mode === FORM_MODE.VIEW ? (
            <Button variant='outlined' onClick={prevTab} id='voltar'>
              Voltar
            </Button>
          ) : (
            <Button variant='outlined' onClick={cancel} id='cancelar'>
              Cancelar
            </Button>
          )}
        </Col>
        <Col sm='content'>
          {mode === FORM_MODE.VIEW ? (
            <Button type='button' onClick={returnToList} id='fechar'>
              Fechar
            </Button>
          ) : (
            <Button type='submit' form='dadosProfissionais' id='salvar'>
              Salvar
            </Button>
          )}
        </Col>
      </Row>

      {isChangeEmployeeStatusModalVisible && (
        <ModalV2.TwoOptions
          open={isChangeEmployeeStatusModalVisible}
          onClose={() => setIsChangeEmployeeStatusModalVisible(false)}
          leftText={isEmployeeActive ? 'Manter Ativo' : 'Manter Inativo'}
          rightText={isEmployeeActive ? 'Inativar' : 'Ativar'}
          onLeftClick={() => setIsChangeEmployeeStatusModalVisible(false)}
          onRightClick={() => {
            changeEmployeeStatus(employeeData?.id)
            setIsChangeEmployeeStatusModalVisible(false)
          }}
        >
          Esse colaborador está {isEmployeeActive ? 'ativo' : 'inativo'}. Tem certeza que
          <br />
          deseja {isEmployeeActive ? 'inativar' : 'ativar'}?
        </ModalV2.TwoOptions>
      )}

      {isHasOrderModalVisible && (
        <ModalV2.OneOption
          open={isHasOrderModalVisible}
          onClose={() => setHasOrderModalVisible(false)}
          onOptionClick={() => setHasOrderModalVisible(false)}
        >
          <HasOderText>
            <IoIosInformationCircleOutline />
            <p>Você não pode inativar esse colaborador.</p>
            <br />
            <p>
              Existe um pedido de recarga para o cartão associado ao colaborador.
              <br />
              Pague ou cancele o pedido e tente novamente.
            </p>
          </HasOderText>
        </ModalV2.OneOption>
      )}
    </>
  )
}

export default ProfessionalData
