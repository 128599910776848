import styled, { css } from 'styled-components'

export const TooltipContainer = styled.span`
  position: relative;
`

export const TooltipBody = styled.div`
  color: ${({ color }) => color ?? '#2d2d59'};
  background-color: var(--color-primary-05);
  border-radius: 4px;
  box-shadow: 0 0.125rem 0.3125rem var(--color-secundary-04);
  text-align: center;
  min-height: 26px;
  padding: 8px;
  transform: translateX(-50%);
  z-index: 2001;
  position: absolute;
  ${({ position }) => css`
    top: ${position.top}px;
    left: ${position.left}px;
  `}

  animation: tooltip-show .26s ease-in-out;

  &::before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid var(--color-primary-05);
    left: 50%;
    transform: translateX(-50%);
    bottom: -5px;
  }

  @keyframes tooltip-show {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`
