import { useSelector } from 'react-redux'

export const useCardsHistoric = () => {
  const storeData = useSelector((state) => state?.trackHistory)
  const cards = storeData?.data?.data
  const loading = storeData?.loading
  const cardsPagination = storeData?.data

  return {
    cards: cards || [],
    loading,
    cardsPagination: {
      pageSize: cardsPagination?.pageSize,
      totalItens: cardsPagination?.totalItens,
      totalPages: cardsPagination?.totalPages,
      pageNumber: cardsPagination?.pageNumber,
    },
  }
}
