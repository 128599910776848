import axios from 'axios'

const identity = axios.create({
  baseURL: process.env.REACT_APP_IDENTITY_HOST,
  headers: {
    common: {
      'time-zone': 'America/Sao_Paulo',
    },
  },
})

export default identity
