import { all, takeLatest, put, call } from 'redux-saga/effects'
import { ACTIONS, reportRequestSuccess, reportRequestFailure } from './actions'
import { getCardSignaturesReport } from '@/services/reportsServices'

function* fetchCardSignaturesReport({ payload }) {
  const { response, error } = yield call(getCardSignaturesReport, payload.orderId, payload.query)
  if (response) yield put(reportRequestSuccess(response.data))
  else yield put(reportRequestFailure(error.response))
}

export default all([takeLatest(ACTIONS.GET_CARD_SIGNATURES_REPORT_REQUEST, fetchCardSignaturesReport)])
