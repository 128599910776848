import { Box, styled } from '@mui/material'

export const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  minHeight: `calc(100vh - (${theme.staticSize.footer}px + ${theme.staticSize.header}px))`,
  padding: '32px 16px',
  overflow: 'hidden',
}))
