import dayjs from 'dayjs'
import { Box, Typography } from '@stationkim/front-ui'
import { DatePicker } from '@/presentation/components/atoms'

interface DaysForCreditProps {
  unavailableDays?: string[]
  initialDate?: string

  //remove
  tempSetDate: any
  tempSetError: any
  tempError: any
}

const shouldDisableDate = ({
  day,
  unavailableDays,
  minDate,
  maxDate,
}: {
  day: any
  unavailableDays: string[]
  minDate: any
  maxDate: any
}) => {
  const isUnavailable = unavailableDays.some((date) => {
    return day.isSame(date, 'day')
  })
  const isOutOfRange = maxDate.isBefore(day, 'day') || minDate.isAfter(day, 'day')
  const isWeekend = [0, 6].includes(day.day())
  return isUnavailable || isWeekend || isOutOfRange
}

export const DaysForCredit = ({
  unavailableDays = [],
  initialDate,
  tempError,
  tempSetDate,
  tempSetError,
}: DaysForCreditProps) => {
  const maxDate = dayjs().add(60, 'day')
  const minDate = dayjs(initialDate)

  return (
    <Box
      sx={{
        '& .MuiFormHelperText-root': {
          margin: '4px 0 0 0 !important',
          fontSize: '14px',
        },
      }}
    >
      <Typography variant='subtitle2' sx={{ marginBottom: '8px' }}>
        Data para crédito do pedido
      </Typography>
      <DatePicker
        format='DD/MM/YYYY'
        shouldDisableDate={(day) => shouldDisableDate({ day, unavailableDays, maxDate, minDate })}
        minDate={minDate}
        maxDate={maxDate}
        onError={tempSetError}
        slotProps={{
          textField: {
            error: tempError,
            helperText: tempError ? 'Selecione uma data valida' : null,
          },
        }}
        onChange={tempSetDate}
      />
    </Box>
  )
}
